define("huvepharma-aviapp-admin/templates/invitation/notfound", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "91B0eT8I",
    "block": "[[[10,\"main\"],[14,0,\"container mod-content error\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"centre\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/not-found.svg\"],[14,\"width\",\"250\"],[14,\"height\",\"250\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"message\"],[12],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"invitation_error_notfound\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[11,\"button\"],[4,[38,1],[[30,0],\"goHome\"],null],[12],[1,[28,[35,0],[\"invitation_error_goback\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"t\",\"action\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/invitation/notfound.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});