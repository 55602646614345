define("huvepharma-aviapp-admin/mixins/reset-controller-route", ["exports", "@ember/object/mixin", "@ember/object", "lodash-es/isPlainObject"], function (_exports, _mixin, _object, _isPlainObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    /**
     * Reset the controllers properties dynamically
     * @param {Controller} controller the controller
     * @param {Boolean} isExiting is exiting
     */
    resetController: function resetController(controller, isExiting) {
      var _this = this;

      if (!isExiting) {
        return;
      } // If specified, rollback the model


      if (this.rollbackModel) {
        var _model$rollbackAttrib;

        var model = (0, _object.get)(controller, 'model');
        (_model$rollbackAttrib = model.rollbackAttributes) === null || _model$rollbackAttrib === void 0 ? void 0 : _model$rollbackAttrib.call(model);
      } // Reset query params


      var queryParams = (0, _object.get)(controller, 'queryParams');
      queryParams.forEach(function (queryParam) {
        (0, _object.set)(controller, queryParam, undefined);
      }); // Reset properties on the controller

      if ((0, _object.get)(this, 'resetProperties.length')) {
        // We delete the property from the Controller instance as to make sure
        // they get reset to the defined values on the Controller prototype.
        //
        // Having a property defined on a Controller where the value of that prop
        // is a string, and when this.set(prop, 'sth') is called in the Controller,
        // it puts that property on the Controller instance meaning that when
        // we call controller.set(prop, undefined) here - because a Controller is
        // a singleton - when revisiting this route, prop will be undefined.
        //
        // This wasn't a problem before because we mostly used this to reset
        // boolean values to false (== undefined) and other non-boolean props
        // on a Controller weren't being changed in the Controller.
        //
        // Deleting the property makes it so that went the route is reentered,
        // Ember will initialise the properties set on the Controller (prototype,
        // not instance) if not set on the singleton instance.
        this.resetProperties.forEach(function (key) {
          if ((0, _isPlainObject.default)(controller[key])) {
            _this.recursivelyClearObject(controller[key]);
          }

          delete controller[key];
        });
      }
    },

    /**
     * Fully clear all (nested) properties
     * @param {Object} obj
     */
    recursivelyClearObject: function recursivelyClearObject(obj) {
      var _this2 = this;

      Object.keys(obj).forEach(function (key) {
        if ((0, _isPlainObject.default)(obj[key])) {
          _this2.recursivelyClearObject(obj[key]);
        }

        delete obj[key];
      });
    }
  });

  _exports.default = _default;
});