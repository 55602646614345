define("huvepharma-aviapp-admin/helpers/power-calendar-format-date", ["exports", "ember-power-calendar/helpers/power-calendar-format-date"], function (_exports, _powerCalendarFormatDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _powerCalendarFormatDate.default;
    }
  });
  Object.defineProperty(_exports, "powerCalendarFormatDate", {
    enumerable: true,
    get: function get() {
      return _powerCalendarFormatDate.powerCalendarFormatDate;
    }
  });
});