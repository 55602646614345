define("huvepharma-aviapp-admin/helpers/build-test-id", ["exports", "@huvepharma/huvepharma-aviapp-components/helpers/build-test-id"], function (_exports, _buildTestId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _buildTestId.default;
    }
  });
});