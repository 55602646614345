define("huvepharma-aviapp-admin/components/search/search-button", ["exports", "@ember/component", "@ember/application", "@ember/object"], function (_exports, _component, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'section',
    classNames: ['search-button-input'],

    /**
     * Searches for given 'search' in a list of given items according toUpperCase
     * a list of given searchAttributes
     * @return {Array} results
     */
    executeSearch: function executeSearch() {
      var _this = this;

      if (!this.countryCodes) {
        this.set('countryCodes', (0, _object.get)((0, _application.getOwner)(this).lookup('application:main'), 'countries'));
      }

      var searchTerm = this.search.toUpperCase();
      var searchAttributes = this.searchAttributes;

      if (this.search) {
        var attrLength = searchAttributes.get('length');
        var result = this.model.filter(function (client) {
          var found = false;

          for (var i = 0; i < attrLength; i++) {
            var searchAttribute = searchAttributes.objectAt(i);
            var value = client.get(searchAttribute);
            var predicate = void 0; // If searchAttribute is countryCode, enable searching on the country code
            // AND country name

            if (searchAttribute === 'countryCode') {
              var countryObj = _this.countryCodes.findBy('code', value);

              var countryName = countryObj ? countryObj.country : '';
              var codePredicate = value && value.toUpperCase().includes(searchTerm);
              var namePredicate = countryName && countryName.toUpperCase().includes(searchTerm);
              predicate = codePredicate || namePredicate;
            } else {
              predicate = value && value.toUpperCase().includes(searchTerm);
            }

            if (predicate) {
              found = true;
              break;
            }
          }

          return found;
        });
        return result;
      }

      return this.model;
    },
    actions: {
      search: function search() {
        var result = this.executeSearch();
        return this.updateList(result, this.search);
      }
    }
  });

  _exports.default = _default;
});