define("huvepharma-aviapp-admin/models/flock-report", ["exports", "huvepharma-aviapp-admin/base/models/flock-report"], function (_exports, _flockReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _flockReport.default.extend({});

  _exports.default = _default;
});