define("huvepharma-aviapp-admin/base/models/scoring-module", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ScoringModule = _emberData.default.Model.extend({});

  var _default = ScoringModule;
  _exports.default = _default;
});