define("huvepharma-aviapp-admin/components/charts/base-chart-legends", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @legends}}
    {{#if @pngExportOptimized}}
    {{!--
      This legend only exists for the download to png functionality.
      It has a fixed width equal to that of the chart png size.
    --}}
      <div class="custom-legend" style={{@customLegendMaxWidthStyle}}>
        <Charts::HuveLegends
          @items={{@legends}}
          @fixedWidth={{@fixedLegendExportWidth}}
        />
      </div>
    {{else}}
      <div class="custom-legend">
        <Charts::HuveLegends @items={{@legends}}/>
      </div>
    {{/if}}
  {{/if}}
  */
  {
    "id": "4Bzlr7K+",
    "block": "[[[41,[30,1],[[[41,[30,2],[[[1,\"    \"],[10,0],[14,0,\"custom-legend\"],[15,5,[30,3]],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@items\",\"@fixedWidth\"],[[30,1],[30,4]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"custom-legend\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@items\"],[[30,1]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],null]],[\"@legends\",\"@pngExportOptimized\",\"@customLegendMaxWidthStyle\",\"@fixedLegendExportWidth\"],false,[\"if\",\"charts/huve-legends\"]]",
    "moduleName": "huvepharma-aviapp-admin/components/charts/base-chart-legends.hbs",
    "isStrictMode": false
  });

  var BaseChartLegends = (0, _templateOnly.default)();

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BaseChartLegends);

  _exports.default = _default;
});