define("huvepharma-aviapp-admin/templates/components/users/user-password-reset", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "t4KLtzeL",
    "block": "[[[6,[39,0],null,[[\"show\",\"overlayAction\"],[[33,1],[28,[37,2],[[30,0],\"onCloseModal\"],null]]],[[\"default\"],[[[[1,\"  \"],[10,\"section\"],[14,0,\"body\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-sm-12\"],[12],[1,\"\\n          \"],[10,\"h1\"],[12],[1,[28,[35,3],[\"user_passwordreset_label_heading\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-sm-12\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"user-password-reset-modal-description\"],[12],[1,\"\\n            \"],[10,2],[12],[1,[28,[35,3],[\"user_passwordreset_label_abouttoreset\"],null]],[1,\" \"],[10,\"strong\"],[12],[1,[33,4,[\"username\"]]],[1,\".\"],[13],[13],[1,\"\\n            \"],[10,2],[12],[1,[28,[35,3],[\"prompt_pleaseverify\"],null]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n          \"],[11,\"button\"],[4,[38,2],[[30,0],\"onAccept\"],null],[12],[1,[28,[35,3],[\"label_yes\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n          \"],[11,\"button\"],[24,0,\"button-red\"],[4,[38,2],[[30,0],\"onCloseModal\"],null],[12],[1,[28,[35,3],[\"label_no\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"modal/huve-base\",\"showModal\",\"action\",\"t\",\"user\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/components/users/user-password-reset.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});