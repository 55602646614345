define("huvepharma-aviapp-admin/base/models/flock", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Flock = _emberData.default.Model.extend({
    breed: _emberData.default.attr('string'),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    mergedToId: _emberData.default.belongsTo('flock', {
      async: true
    }),
    coccidiostatPrograms: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    coccidiostatProgram: _emberData.default.belongsTo('program', {
      async: true
    }),
    otherProgram: _emberData.default.belongsTo('program', {
      async: true
    }),
    farm: _emberData.default.belongsTo('farm', {
      async: true
    }),
    feedMill: _emberData.default.attr('string'),
    flockReport: _emberData.default.belongsTo('flock-report', {
      async: true
    }),
    flockId: _emberData.default.attr('string'),
    origins: _emberData.default.attr(),
    houseId: _emberData.default.attr('string'),
    nofAnimals: _emberData.default.attr('number'),
    trialCode: _emberData.default.attr('string'),
    owners: _emberData.default.hasMany('user', {
      async: true
    }),
    otherPrograms: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    poultry: _emberData.default.attr('string'),
    broilerType: _emberData.default.attr('string', {
      defaultValue: '2To3'
    }),
    scoringReports: _emberData.default.hasMany('scoring-report', {
      async: true
    }),
    startedAt: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    slaughterAge: _emberData.default.attr('number'),
    firstVisitDate: _emberData.default.attr('date'),
    isArchived: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isSoftDeleted: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    sampleSize: _emberData.default.attr('string', {
      defaultValue: 'normal'
    }),
    reportLength: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });

  var _default = Flock;
  _exports.default = _default;
});