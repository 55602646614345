define("huvepharma-aviapp-admin/base/enum/countries/en-pl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AF",
    "country": "Afganistan"
  }, {
    "code": "AL",
    "country": "Albania"
  }, {
    "code": "DZ",
    "country": "Algieria"
  }, {
    "code": "AD",
    "country": "Andora"
  }, {
    "code": "AO",
    "country": "Angola"
  }, {
    "code": "AI",
    "country": "Anguilla"
  }, {
    "code": "AQ",
    "country": "Antarktyda"
  }, {
    "code": "AG",
    "country": "Antigua i Barbuda"
  }, {
    "code": "SA",
    "country": "Arabia Saudyjska"
  }, {
    "code": "AR",
    "country": "Argentyna"
  }, {
    "code": "AM",
    "country": "Armenia"
  }, {
    "code": "AW",
    "country": "Aruba"
  }, {
    "code": "AU",
    "country": "Australia"
  }, {
    "code": "AT",
    "country": "Austria"
  }, {
    "code": "AZ",
    "country": "Azerbejdżan"
  }, {
    "code": "BS",
    "country": "Bahamy"
  }, {
    "code": "BH",
    "country": "Bahrajn"
  }, {
    "code": "BD",
    "country": "Bangladesz"
  }, {
    "code": "BB",
    "country": "Barbados"
  }, {
    "code": "BE",
    "country": "Belgia"
  }, {
    "code": "BZ",
    "country": "Belize"
  }, {
    "code": "BJ",
    "country": "Benin"
  }, {
    "code": "BM",
    "country": "Bermudy"
  }, {
    "code": "BT",
    "country": "Bhutan"
  }, {
    "code": "BY",
    "country": "Białoruś"
  }, {
    "code": "BO",
    "country": "Boliwia"
  }, {
    "code": "BA",
    "country": "Bośnia i Hercegowina"
  }, {
    "code": "BW",
    "country": "Botswana"
  }, {
    "code": "BR",
    "country": "Brazylia"
  }, {
    "code": "BN",
    "country": "Brunei"
  }, {
    "code": "IO",
    "country": "Brytyjskie Terytorium Oceanu Indyjskiego"
  }, {
    "code": "VG",
    "country": "Brytyjskie Wyspy Dziewicze"
  }, {
    "code": "BG",
    "country": "Bułgaria"
  }, {
    "code": "BF",
    "country": "Burkina Faso"
  }, {
    "code": "BI",
    "country": "Burundi"
  }, {
    "code": "CL",
    "country": "Chile"
  }, {
    "code": "CN",
    "country": "Chiny"
  }, {
    "code": "HR",
    "country": "Chorwacja"
  }, {
    "code": "CI",
    "country": "Côte d’Ivoire"
  }, {
    "code": "CW",
    "country": "Curaçao"
  }, {
    "code": "CY",
    "country": "Cypr"
  }, {
    "code": "TD",
    "country": "Czad"
  }, {
    "code": "ME",
    "country": "Czarnogóra"
  }, {
    "code": "CZ",
    "country": "Czechy"
  }, {
    "code": "UM",
    "country": "Dalekie Wyspy Mniejsze Stanów Zjednoczonych"
  }, {
    "code": "DK",
    "country": "Dania"
  }, {
    "code": "CD",
    "country": "Demokratyczna Republika Konga"
  }, {
    "code": "DM",
    "country": "Dominika"
  }, {
    "code": "DO",
    "country": "Dominikana"
  }, {
    "code": "DJ",
    "country": "Dżibuti"
  }, {
    "code": "EG",
    "country": "Egipt"
  }, {
    "code": "EC",
    "country": "Ekwador"
  }, {
    "code": "ER",
    "country": "Erytrea"
  }, {
    "code": "EE",
    "country": "Estonia"
  }, {
    "code": "SZ",
    "country": "Eswatini"
  }, {
    "code": "ET",
    "country": "Etiopia"
  }, {
    "code": "FK",
    "country": "Falklandy"
  }, {
    "code": "FJ",
    "country": "Fidżi"
  }, {
    "code": "PH",
    "country": "Filipiny"
  }, {
    "code": "FI",
    "country": "Finlandia"
  }, {
    "code": "FR",
    "country": "Francja"
  }, {
    "code": "TF",
    "country": "Francuskie Terytoria Południowe i Antarktyczne"
  }, {
    "code": "GA",
    "country": "Gabon"
  }, {
    "code": "GM",
    "country": "Gambia"
  }, {
    "code": "GS",
    "country": "Georgia Południowa i Sandwich Południowy"
  }, {
    "code": "GH",
    "country": "Ghana"
  }, {
    "code": "GI",
    "country": "Gibraltar"
  }, {
    "code": "GR",
    "country": "Grecja"
  }, {
    "code": "GD",
    "country": "Grenada"
  }, {
    "code": "GL",
    "country": "Grenlandia"
  }, {
    "code": "GE",
    "country": "Gruzja"
  }, {
    "code": "GU",
    "country": "Guam"
  }, {
    "code": "GG",
    "country": "Guernsey"
  }, {
    "code": "GY",
    "country": "Gujana"
  }, {
    "code": "GF",
    "country": "Gujana Francuska"
  }, {
    "code": "GP",
    "country": "Gwadelupa"
  }, {
    "code": "GT",
    "country": "Gwatemala"
  }, {
    "code": "GN",
    "country": "Gwinea"
  }, {
    "code": "GW",
    "country": "Gwinea Bissau"
  }, {
    "code": "GQ",
    "country": "Gwinea Równikowa"
  }, {
    "code": "HT",
    "country": "Haiti"
  }, {
    "code": "ES",
    "country": "Hiszpania"
  }, {
    "code": "NL",
    "country": "Holandia"
  }, {
    "code": "HN",
    "country": "Honduras"
  }, {
    "code": "IN",
    "country": "Indie"
  }, {
    "code": "ID",
    "country": "Indonezja"
  }, {
    "code": "IQ",
    "country": "Irak"
  }, {
    "code": "IR",
    "country": "Iran"
  }, {
    "code": "IE",
    "country": "Irlandia"
  }, {
    "code": "IS",
    "country": "Islandia"
  }, {
    "code": "IL",
    "country": "Izrael"
  }, {
    "code": "JM",
    "country": "Jamajka"
  }, {
    "code": "JP",
    "country": "Japonia"
  }, {
    "code": "YE",
    "country": "Jemen"
  }, {
    "code": "JE",
    "country": "Jersey"
  }, {
    "code": "JO",
    "country": "Jordania"
  }, {
    "code": "KY",
    "country": "Kajmany"
  }, {
    "code": "KH",
    "country": "Kambodża"
  }, {
    "code": "CM",
    "country": "Kamerun"
  }, {
    "code": "CA",
    "country": "Kanada"
  }, {
    "code": "QA",
    "country": "Katar"
  }, {
    "code": "KZ",
    "country": "Kazachstan"
  }, {
    "code": "KE",
    "country": "Kenia"
  }, {
    "code": "KG",
    "country": "Kirgistan"
  }, {
    "code": "KI",
    "country": "Kiribati"
  }, {
    "code": "CO",
    "country": "Kolumbia"
  }, {
    "code": "KM",
    "country": "Komory"
  }, {
    "code": "CG",
    "country": "Kongo"
  }, {
    "code": "KR",
    "country": "Korea Południowa"
  }, {
    "code": "KP",
    "country": "Korea Północna"
  }, {
    "code": "CR",
    "country": "Kostaryka"
  }, {
    "code": "CU",
    "country": "Kuba"
  }, {
    "code": "KW",
    "country": "Kuwejt"
  }, {
    "code": "LA",
    "country": "Laos"
  }, {
    "code": "LS",
    "country": "Lesotho"
  }, {
    "code": "LB",
    "country": "Liban"
  }, {
    "code": "LR",
    "country": "Liberia"
  }, {
    "code": "LY",
    "country": "Libia"
  }, {
    "code": "LI",
    "country": "Liechtenstein"
  }, {
    "code": "LT",
    "country": "Litwa"
  }, {
    "code": "LU",
    "country": "Luksemburg"
  }, {
    "code": "LV",
    "country": "Łotwa"
  }, {
    "code": "MK",
    "country": "Macedonia Północna"
  }, {
    "code": "MG",
    "country": "Madagaskar"
  }, {
    "code": "YT",
    "country": "Majotta"
  }, {
    "code": "MW",
    "country": "Malawi"
  }, {
    "code": "MV",
    "country": "Malediwy"
  }, {
    "code": "MY",
    "country": "Malezja"
  }, {
    "code": "ML",
    "country": "Mali"
  }, {
    "code": "MT",
    "country": "Malta"
  }, {
    "code": "MP",
    "country": "Mariany Północne"
  }, {
    "code": "MA",
    "country": "Maroko"
  }, {
    "code": "MQ",
    "country": "Martynika"
  }, {
    "code": "MR",
    "country": "Mauretania"
  }, {
    "code": "MU",
    "country": "Mauritius"
  }, {
    "code": "MX",
    "country": "Meksyk"
  }, {
    "code": "FM",
    "country": "Mikronezja"
  }, {
    "code": "MM",
    "country": "Mjanma (Birma)"
  }, {
    "code": "MD",
    "country": "Mołdawia"
  }, {
    "code": "MC",
    "country": "Monako"
  }, {
    "code": "MN",
    "country": "Mongolia"
  }, {
    "code": "MS",
    "country": "Montserrat"
  }, {
    "code": "MZ",
    "country": "Mozambik"
  }, {
    "code": "NA",
    "country": "Namibia"
  }, {
    "code": "NR",
    "country": "Nauru"
  }, {
    "code": "NP",
    "country": "Nepal"
  }, {
    "code": "BQ",
    "country": "Niderlandy Karaibskie"
  }, {
    "code": "DE",
    "country": "Niemcy"
  }, {
    "code": "NE",
    "country": "Niger"
  }, {
    "code": "NG",
    "country": "Nigeria"
  }, {
    "code": "NI",
    "country": "Nikaragua"
  }, {
    "code": "NU",
    "country": "Niue"
  }, {
    "code": "NF",
    "country": "Norfolk"
  }, {
    "code": "NO",
    "country": "Norwegia"
  }, {
    "code": "NC",
    "country": "Nowa Kaledonia"
  }, {
    "code": "NZ",
    "country": "Nowa Zelandia"
  }, {
    "code": "OM",
    "country": "Oman"
  }, {
    "code": "PK",
    "country": "Pakistan"
  }, {
    "code": "PW",
    "country": "Palau"
  }, {
    "code": "PA",
    "country": "Panama"
  }, {
    "code": "PG",
    "country": "Papua-Nowa Gwinea"
  }, {
    "code": "PY",
    "country": "Paragwaj"
  }, {
    "code": "PE",
    "country": "Peru"
  }, {
    "code": "PN",
    "country": "Pitcairn"
  }, {
    "code": "PF",
    "country": "Polinezja Francuska"
  }, {
    "code": "PL",
    "country": "Polska"
  }, {
    "code": "PR",
    "country": "Portoryko"
  }, {
    "code": "PT",
    "country": "Portugalia"
  }, {
    "code": "ZA",
    "country": "Republika Południowej Afryki"
  }, {
    "code": "CF",
    "country": "Republika Środkowoafrykańska"
  }, {
    "code": "CV",
    "country": "Republika Zielonego Przylądka"
  }, {
    "code": "RE",
    "country": "Reunion"
  }, {
    "code": "RU",
    "country": "Rosja"
  }, {
    "code": "RO",
    "country": "Rumunia"
  }, {
    "code": "RW",
    "country": "Rwanda"
  }, {
    "code": "EH",
    "country": "Sahara Zachodnia"
  }, {
    "code": "KN",
    "country": "Saint Kitts i Nevis"
  }, {
    "code": "LC",
    "country": "Saint Lucia"
  }, {
    "code": "VC",
    "country": "Saint Vincent i Grenadyny"
  }, {
    "code": "BL",
    "country": "Saint-Barthélemy"
  }, {
    "code": "MF",
    "country": "Saint-Martin"
  }, {
    "code": "PM",
    "country": "Saint-Pierre i Miquelon"
  }, {
    "code": "SV",
    "country": "Salwador"
  }, {
    "code": "WS",
    "country": "Samoa"
  }, {
    "code": "AS",
    "country": "Samoa Amerykańskie"
  }, {
    "code": "SM",
    "country": "San Marino"
  }, {
    "code": "SN",
    "country": "Senegal"
  }, {
    "code": "RS",
    "country": "Serbia"
  }, {
    "code": "SC",
    "country": "Seszele"
  }, {
    "code": "SL",
    "country": "Sierra Leone"
  }, {
    "code": "SG",
    "country": "Singapur"
  }, {
    "code": "SX",
    "country": "Sint Maarten"
  }, {
    "code": "SK",
    "country": "Słowacja"
  }, {
    "code": "SI",
    "country": "Słowenia"
  }, {
    "code": "SO",
    "country": "Somalia"
  }, {
    "code": "HK",
    "country": "SRA Hongkong (Chiny)"
  }, {
    "code": "MO",
    "country": "SRA Makau (Chiny)"
  }, {
    "code": "LK",
    "country": "Sri Lanka"
  }, {
    "code": "US",
    "country": "Stany Zjednoczone"
  }, {
    "code": "SD",
    "country": "Sudan"
  }, {
    "code": "SS",
    "country": "Sudan Południowy"
  }, {
    "code": "SR",
    "country": "Surinam"
  }, {
    "code": "SJ",
    "country": "Svalbard i Jan Mayen"
  }, {
    "code": "SY",
    "country": "Syria"
  }, {
    "code": "CH",
    "country": "Szwajcaria"
  }, {
    "code": "SE",
    "country": "Szwecja"
  }, {
    "code": "TJ",
    "country": "Tadżykistan"
  }, {
    "code": "TH",
    "country": "Tajlandia"
  }, {
    "code": "TW",
    "country": "Tajwan"
  }, {
    "code": "TZ",
    "country": "Tanzania"
  }, {
    "code": "PS",
    "country": "Terytoria Palestyńskie"
  }, {
    "code": "TL",
    "country": "Timor Wschodni"
  }, {
    "code": "TG",
    "country": "Togo"
  }, {
    "code": "TK",
    "country": "Tokelau"
  }, {
    "code": "TO",
    "country": "Tonga"
  }, {
    "code": "TT",
    "country": "Trynidad i Tobago"
  }, {
    "code": "TN",
    "country": "Tunezja"
  }, {
    "code": "TR",
    "country": "Turcja"
  }, {
    "code": "TM",
    "country": "Turkmenistan"
  }, {
    "code": "TC",
    "country": "Turks i Caicos"
  }, {
    "code": "TV",
    "country": "Tuvalu"
  }, {
    "code": "UG",
    "country": "Uganda"
  }, {
    "code": "UA",
    "country": "Ukraina"
  }, {
    "code": "UY",
    "country": "Urugwaj"
  }, {
    "code": "UZ",
    "country": "Uzbekistan"
  }, {
    "code": "VU",
    "country": "Vanuatu"
  }, {
    "code": "WF",
    "country": "Wallis i Futuna"
  }, {
    "code": "VA",
    "country": "Watykan"
  }, {
    "code": "VE",
    "country": "Wenezuela"
  }, {
    "code": "HU",
    "country": "Węgry"
  }, {
    "code": "GB",
    "country": "Wielka Brytania"
  }, {
    "code": "VN",
    "country": "Wietnam"
  }, {
    "code": "IT",
    "country": "Włochy"
  }, {
    "code": "BV",
    "country": "Wyspa Bouveta"
  }, {
    "code": "CX",
    "country": "Wyspa Bożego Narodzenia"
  }, {
    "code": "IM",
    "country": "Wyspa Man"
  }, {
    "code": "SH",
    "country": "Wyspa Świętej Heleny"
  }, {
    "code": "AX",
    "country": "Wyspy Alandzkie"
  }, {
    "code": "CK",
    "country": "Wyspy Cooka"
  }, {
    "code": "VI",
    "country": "Wyspy Dziewicze Stanów Zjednoczonych"
  }, {
    "code": "HM",
    "country": "Wyspy Heard i McDonalda"
  }, {
    "code": "CC",
    "country": "Wyspy Kokosowe"
  }, {
    "code": "MH",
    "country": "Wyspy Marshalla"
  }, {
    "code": "FO",
    "country": "Wyspy Owcze"
  }, {
    "code": "SB",
    "country": "Wyspy Salomona"
  }, {
    "code": "ST",
    "country": "Wyspy Świętego Tomasza i Książęca"
  }, {
    "code": "ZM",
    "country": "Zambia"
  }, {
    "code": "ZW",
    "country": "Zimbabwe"
  }, {
    "code": "AE",
    "country": "Zjednoczone Emiraty Arabskie"
  }];
  _exports.default = _default;
});