define("huvepharma-aviapp-admin/helpers/time-helper", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var timeHelper = {
    /**
     * Get UTC converted date based on timestamp or current time of client.
     * @param [timestamp] - Timestamp to calculate the UTC date one
     * @returns {Moment|*} - UTC date
     */
    extractDateZoneInvariant: function extractDateZoneInvariant(timestamp) {
      var formatted = _moment.default.isMoment(timestamp) ? timestamp.format() : timestamp;
      return (0, _moment.default)(timestamp).utc().add(_moment.default.parseZone(formatted).utcOffset(), 'minutes').startOf('day');
    },

    /**
     * Regular convert to UTC
     * @param {*} timestamp
     * @returns {Moment}
     */
    toUTC: function toUTC(timestamp) {
      return _moment.default.utc(timestamp);
    },

    /**
     * Gets the diff between two UTC dates, optionally by a given granularity.
     * If the granularity is given, it'll set that granularity to the start of its
     * unit (.startOf(granularity)) so we get whole numbers
     * @param {Int / Date / Moment} timestampA
     * @param {Int / Date / Moment} timestampB
     * @param {String} granularity (eg. day, month, year)
     * @returns {Int} diff
     */
    diffFromUTCDate: function diffFromUTCDate(timestampA, timestampB, granularity) {
      var dateA = granularity ? _moment.default.utc(timestampA || _moment.default.utc()).startOf(granularity) : _moment.default.utc(timestampA || _moment.default.utc());
      var dateB = granularity ? _moment.default.utc(timestampB || _moment.default.utc()).startOf(granularity) : _moment.default.utc(timestampB || _moment.default.utc());
      var args = granularity ? [dateB, granularity] : [dateB];
      return dateA.diff.apply(dateA, args);
    },

    /**
     * Adds a value (with an optional granularity) to a UTC date
     * @param {} params
     * @param {Moment} mutated date
     */
    addToUTCDate: function addToUTCDate() {
      for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
        params[_key] = arguments[_key];
      }

      return timeHelper.mutateUTCDate.apply(timeHelper, ['add'].concat(params));
    },

    /**
     * Subtracts a value (with an optional granularity) from a UTC date
     * @param {} params
     * @param {Moment} mutated date
     */
    subtractFromUTCDate: function subtractFromUTCDate() {
      for (var _len2 = arguments.length, params = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        params[_key2] = arguments[_key2];
      }

      return timeHelper.mutateUTCDate.apply(timeHelper, ['subtract'].concat(params));
    },

    /**
     * Mutates a UTC date by a given mutation and value (and an optional granularity)
     * @param {String} mutation ('subtract', 'add')
     * @param {Int, Date, Moment} timestamp
     * @param {Int} value
     * @param {String} granularity (eg. day, month, year)
     */
    mutateUTCDate: function mutateUTCDate(mutation, timestamp, value, granularity) {
      var date = timestamp ? _moment.default.utc(timestamp) : _moment.default.utc().startOf(granularity);
      var parsedValue = parseInt(value, 10);
      var args = granularity ? [parsedValue, granularity] : [parsedValue];

      if (mutation === 'add') {
        return date.add.apply(date, args);
      } else if (mutation === 'subtract') {
        return date.subtract.apply(date, args);
      }

      throw Error('Unknown moment mutation given.');
    },

    /**
     * Get the offset from UTC time in MS
     * @param {Date} epoch (optional)
     * @returns {Number}
     */
    getUTCOffsetMs: function getUTCOffsetMs(epoch) {
      return -(0, _moment.default)(epoch).utcOffset() * 60000;
    },

    /**
     * Add the offset from UTC time to a given epoch
     * @param {Unix timestamp} epoch
     * @returns {Number}
     */
    addUTCOffsetToEpoch: function addUTCOffsetToEpoch(epoch) {
      return epoch + timeHelper.getUTCOffsetMs(epoch);
    },

    /**
     * Subtract the offset from UTC time from a given epoch
     * @param {Unix timestamp} epoch
     * @returns {Number}
     */
    subtractUTCOffsetFromEpoch: function subtractUTCOffsetFromEpoch(epoch) {
      return epoch - timeHelper.getUTCOffsetMs(epoch);
    },

    /**
     * Add the offset from UTC time to a given date
     * @param {Date} date
     * @returns {Date}
     */
    addUTCOffsetToDate: function addUTCOffsetToDate(date) {
      var epoch = +_moment.default.utc(date);
      return _moment.default.utc(timeHelper.addUTCOffsetToEpoch(epoch)).toDate();
    },

    /**
     * Subtract the offset from UTC time from a given date
     * @param {Date} date
     * @returns {Date}
     */
    subtractUTCOffsetFromDate: function subtractUTCOffsetFromDate(date) {
      var epoch = +_moment.default.utc(date);
      return _moment.default.utc(timeHelper.subtractUTCOffsetFromEpoch(epoch)).toDate();
    }
  };
  var _default = timeHelper;
  _exports.default = _default;
});