define("huvepharma-aviapp-admin/components/users/user-password-reset", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    session: (0, _service.inject)(),
    message: '',
    actions: {
      /**
       * Set the user has forgotten the password
       */
      onAccept: function onAccept() {
        this.set('showModal', false);
        this.set('user.hasForgotPassword', true);
        this.user.save();
      },

      /**
       * Closes the modal
       */
      onCloseModal: function onCloseModal() {
        this.set('showModal', false);
      }
    }
  });

  _exports.default = _default;
});