define("huvepharma-aviapp-admin/validations/models/register", ["exports", "ember-changeset-validations/validators", "huvepharma-aviapp-admin/validations/change-description"], function (_exports, _validators, _changeDescription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    user: {
      firstName: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_firstname'),
      name: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_name'),
      street: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_street'),
      number: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_number'),
      city: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_city'),
      zipcode: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_zipcode'),
      region: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_region'),
      username: (0, _changeDescription.default)([(0, _validators.validateFormat)({
        allowBlank: false,
        type: 'email'
      })], 'label_email'),
      telephone: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_phone'),
      countryCode: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_country'),
      password: (0, _changeDescription.default)([(0, _validators.validateLength)({
        min: 6
      }), (0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_password')
    },
    acceptedUserLicenceAgreement: (0, _changeDescription.default)([(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'errors_accepted'
    }), (0, _validators.validateInclusion)({
      list: [true],
      allowBlank: true,
      message: 'errors_accepted'
    })], 'userlicenseagreement_termsandconditions'),
    acceptedPrivacyStatement: (0, _changeDescription.default)([(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'errors_accepted'
    }), (0, _validators.validateInclusion)({
      list: [true],
      allowBlank: true,
      message: 'errors_accepted'
    })], 'userlicenseagreement_termsandconditions'),
    passwordConfirmation: (0, _changeDescription.default)([(0, _validators.validateConfirmation)({
      on: 'user.password',
      message: 'users_errors_equalpasswordfields'
    }), (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    })], 'label_password')
  };
  _exports.default = _default;
});