define("huvepharma-aviapp-admin/base/enum/mail-subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "AVIAPP_FEATURES": "aviapp_features",
    "AVIAPP_INSIGHTS": "aviapp_insights"
  };
  _exports.default = _default;
});