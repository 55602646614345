define("huvepharma-aviapp-admin/base/models/coccidiostat-programme", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var CoccidiostatProgramme = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    components: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });

  var _default = CoccidiostatProgramme;
  _exports.default = _default;
});