define("huvepharma-aviapp-admin/base/models/invitation-token", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var InvitationToken = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    role: _emberData.default.attr('string', {
      defaultValue: 'user'
    }),
    isUserAlreadyExist: _emberData.default.attr('boolean'),
    isOwnerUnconfirmed: _emberData.default.attr('boolean'),
    user: _emberData.default.attr(),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    expires: _emberData.default.attr('date'),
    owner: _emberData.default.belongsTo('user', {
      async: true
    }),
    token: _emberData.default.attr('string'),
    accessRights: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return {
          client: '',
          clientRights: {
            entireClient: false,
            companies: [],
            complexes: [],
            farms: []
          },
          geographicRights: {
            entireWorld: false,
            countries: [],
            regions: []
          }
        };
      }
    })
  });

  var _default = InvitationToken;
  _exports.default = _default;
});