define("huvepharma-aviapp-admin/components/clients/client-delete", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    flash: (0, _service.inject)(),
    metrics: (0, _service.inject)(),

    /**
     * Initialiser
     */
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      /**
       * Deletes the company
       */
      onAccept: function onAccept() {
        var _this = this;

        this.set('showModal', false);
        this.company.destroyRecord().then(function () {
          _this.flash.success({
            message: 'company_deletion_success'
          });

          var afterDeleteAction = _this.afterDeleteAction;

          if (afterDeleteAction && typeof afterDeleteAction === 'function') {
            afterDeleteAction();
          }
        }).catch(function () {
          _this.flash.danger({
            message: 'company_deletion_failed'
          });
        }).finally(function () {
          _this.metrics.trackEvent({
            event: 'Remove Client',
            event_category: 'Company Detail'
          });
        });
      },

      /**
       * Closes the modal
       */
      onCloseModal: function onCloseModal() {
        this.set('showModal', false);
      }
    }
  });

  _exports.default = _default;
});