define("huvepharma-aviapp-admin/base/enum/continents/en-uk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AF",
    "continent": "Африка"
  }, {
    "code": "NA",
    "continent": "Північна Америка"
  }, {
    "code": "OC",
    "continent": "Австралія"
  }, {
    "code": "AN",
    "continent": "Антарктида"
  }, {
    "code": "AS",
    "continent": "Азія"
  }, {
    "code": "EU",
    "continent": "Європа"
  }, {
    "code": "SA",
    "continent": "Південна Америка"
  }];
  _exports.default = _default;
});