define("huvepharma-aviapp-admin/components/programs/huve-program-list", ["exports", "@huvepharma/huvepharma-aviapp-components/components/programs/program-list"], function (_exports, _programList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _programList.default;
    }
  });
});