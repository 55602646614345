define("huvepharma-aviapp-admin/components/charts/huve-stacked-bar-chart", ["exports", "@huvepharma/huvepharma-aviapp-components/components/charts/stacked-bar-chart"], function (_exports, _stackedBarChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _stackedBarChart.default;
    }
  });
});