define("huvepharma-aviapp-admin/templates/users/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "M+lWFqZV",
    "block": "[[[1,[28,[35,0],null,[[\"model\",\"isCurrentUser\",\"isCompanyOwner\",\"userRole\",\"isOnlyAdmin\",\"saveUser\"],[[33,1],[33,2],[33,3],[33,4],[33,5],[28,[37,6],[[30,0],\"saveUser\"],null]]]]],[1,\"\\n\"]],[],false,[\"users/user-form\",\"model\",\"isCurrentUser\",\"isCompanyOwner\",\"userRole\",\"isOnlyAdmin\",\"action\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/users/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});