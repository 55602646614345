define("huvepharma-aviapp-admin/controllers/invitation/notfound", ["exports", "@ember/controller", "huvepharma-aviapp-admin/config/environment"], function (_exports, _controller, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    actions: {
      /**
       * Go back to home page
       */
      goHome: function goHome() {
        window.location.href = _environment.default.APP.common.host;
      }
    }
  });

  _exports.default = _default;
});