define("huvepharma-aviapp-admin/templates/company-switch/company-switch", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iHPD4jlE",
    "block": "[[[1,[28,[35,0],null,[[\"isVisible\",\"justLoggedIn\",\"session\",\"companies\",\"hideCompanySwitch\",\"switchToCompany\",\"logout\"],[[33,1],[33,2],[33,3],[33,4],[28,[37,5],[[30,0],\"hideCompanySwitch\"],null],[28,[37,5],[[30,0],\"switchToCompany\"],null],[28,[37,5],[[30,0],\"logout\"],null]]]]],[1,\"\\n\\n\"],[41,[51,[33,3,[\"isSuperUser\"]]],[[[41,[33,8],[[[1,\"    \"],[10,0],[14,1,\"show-company-switch\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"button\"],[24,4,\"button\"],[4,[38,5],[[30,0],\"showCompanySwitchModal\"],null],[12],[1,\"\\n        \"],[1,[33,3,[\"data\",\"loginCompany\",\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"company-switch\",\"showCompanySwitch\",\"justLoggedIn\",\"session\",\"companies\",\"action\",\"unless\",\"if\",\"showCompanySwitchButton\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/company-switch/company-switch.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});