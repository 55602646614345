define("huvepharma-aviapp-admin/serializers/program", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The linkedFlocks attribute is not needed for the api so we don't need to serialize it
   */
  var _default = _emberData.default.RESTSerializer.extend({
    primaryKey: '_id',
    attrs: {
      linkedFlocks: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});