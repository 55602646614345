define("huvepharma-aviapp-admin/models/invitation", ["exports", "huvepharma-aviapp-admin/base/models/invitation-token"], function (_exports, _invitationToken) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var InvitationToken = _invitationToken.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  var _default = InvitationToken;
  _exports.default = _default;
});