define("huvepharma-aviapp-admin/routes/invitation/notfound", ["exports", "@ember/routing/route", "huvepharma-aviapp-admin/mixins/header-hidden-route"], function (_exports, _route, _headerHiddenRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_headerHiddenRoute.default, {
    skipSessionExecution: true,

    /**
     * Render the template
     */
    renderTemplate: function renderTemplate() {
      this.render(); // Remove the navigation bar

      this.disconnectOutlet({
        outlet: 'header',
        parentView: 'application'
      }); // Remove the company switch

      this.disconnectOutlet({
        outlet: 'company-switch',
        parentView: 'application'
      });
    }
  });

  _exports.default = _default;
});