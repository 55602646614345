define("huvepharma-aviapp-admin/templates/components/form/field-number", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XmJdPETB",
    "block": "[[[6,[39,0],null,[[\"prop\",\"changeset\",\"label\"],[[33,1],[33,2],[33,3]]],[[\"default\"],[[[[1,\"  \"],[18,2,[[28,[37,5],null,[[\"label\",\"input\",\"errors\"],[[30,1,[\"label\"]],[50,\"one-way-number-mask\",0,null,[[\"value\",\"options\",\"update\",\"class\",\"disabled\"],[[28,[37,7],[[33,2],[33,1]],null],[33,8],[28,[37,9],[[30,0],[28,[37,10],[[28,[37,7],[[33,2],[33,1]],null]],null]],null],[52,[30,1,[\"hasErrors\"]],\"error\"],[33,12]]]],[30,1,[\"errors\"]]]]]]],[1,\"\\n\"]],[1]]]]]],[\"field\",\"&default\"],false,[\"form-builder/field-wrapper\",\"prop\",\"changeset\",\"label\",\"yield\",\"hash\",\"component\",\"get\",\"options\",\"action\",\"mut\",\"if\",\"disabled\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/components/form/field-number.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});