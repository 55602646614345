define("huvepharma-aviapp-admin/components/users/user-form", ["exports", "@ember/component", "@ember/service", "jquery", "huvepharma-aviapp-admin/utils/role"], function (_exports, _component, _service, _jquery, roles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    roles: roles.withoutReporter(),
    tagName: 'section',
    classNames: 'mod-user',
    session: (0, _service.inject)('session'),
    actions: {
      /**
       * Bubble up action to the edit controller to save user.
       */
      saveUser: function saveUser() {
        this.saveUser();
      },

      /**
       * When a new role gets selected, check if it's different from admin
       * and if the user being edited is the current user. If so, if the
       * current user is the only admin, reset the select field and show an error.
       * If he's not the only admin, it'll warn the user that he'll be logged out
       * after saving the form.
       *
       * NB: the role select is disabled for the ADMIN0/SPOC/owner of company
       * @param {String} selected role
       */
      changeAction: function changeAction(selected) {
        if (selected !== 'admin' && this.get('model.id') === this.get('session.me.id')) {
          if (this.isOnlyAdmin) {
            setTimeout(function () {
              (0, _jquery.default)('#role select').val('admin');
            }, 200);
            this.set('onlyAdminError', true);
          } else {
            this.set('noAccessWarning', true);
          }
        } else {
          this.set('onlyAdminError', false);
          this.set('noAccessWarning', false);
        }

        this.set('userRole', selected);
      }
    }
  });

  _exports.default = _default;
});