define("huvepharma-aviapp-admin/validations/messages", ["exports", "@ember/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var errorKeys = ['inclusion', 'exclusion', 'invalid', 'confirmation', 'accepted', 'empty', 'blank', 'present', 'collection', 'singular', 'too_long', 'too_short', 'between', 'before', 'on_or_before', 'after', 'on_or_after', 'wrong_date_format', 'wrong_length', 'not_a_number', 'not_an_integer', 'greater_than', 'greater_than_or_equal_to', 'equal_to', 'less_than', 'less_than_or_equal_to', 'other_than', 'odd', 'even', 'positive', 'multiple_of', 'date', 'email', 'phone', 'url', 'required'];
  var messages = errorKeys.reduce(function (acc, key) {
    var translateKey = "errors_".concat(key);
    acc[(0, _string.camelize)(key)] = translateKey;
    return acc;
  }, {});
  var _default = messages;
  _exports.default = _default;
});