define("huvepharma-aviapp-admin/helpers/find-val-by-key", ["exports", "@huvepharma/huvepharma-aviapp-components/helpers/find-val-by-key"], function (_exports, _findValByKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _findValByKey.default;
    }
  });
});