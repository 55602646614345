define("huvepharma-aviapp-admin/helpers/is-equal", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function (args) {
    if (args.length === 2) {
      return args[0] === args[1];
    } else if (typeof args[0].get === 'function') {
      return args[0].get(args[1]) === args[2];
    }

    return args[0][args[1]] === args[2];
  });

  _exports.default = _default;
});