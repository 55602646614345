define("huvepharma-aviapp-admin/components/phases/lines/cocci-line", ["exports", "@ember/object", "@ember/service", "huvepharma-aviapp-admin/components/phases/lines/base"], function (_exports, _object, _service, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _base.default.extend({
    attributeBindings: ['getTestId:data-test-id'],
    getTestId: (0, _object.computed)('phaseIndex', function () {
      return "cocci-phase-".concat(this.phaseIndex);
    }),
    poultryConfig: (0, _service.inject)(),

    /**
     * Essentially makes sure 'NONE' program gets translated correctly
     */
    displayedPrograms: (0, _object.computed)('programs', 'intl.locale', function () {
      var _this = this;

      var programs = (0, _object.get)(this, 'programs.cocci');

      if ((0, _object.get)(this, 'cocciType') === 'vaccination' && (0, _object.get)(this, 'phaseIndex') === 0) {
        programs = (0, _object.get)(this, 'programs.vaccination');
      }

      return programs.map(function (p) {
        return _objectSpread(_objectSpread({}, p), {}, {
          name: (0, _object.get)(p, 'id') === _base.NONE ? _this.getNoneProgramLabel() : (0, _object.get)(p, 'name'),
          id: (0, _object.get)(p, 'id') === _base.NONE ? _base.NONE : (0, _object.get)(p, 'name')
        });
      });
    }),
    actions: {
      onChangeStart: function onChangeStart(phase, currentIndex, value) {
        var newValue = Number(value);
        var oldValue = phase.get('startDay');
        var diff = newValue - oldValue; // commit the value to the changeset

        (0, _object.set)(phase, 'startDay', newValue);
        var phases = (0, _object.get)(this, 'phases');

        if (phases.length > 1) {
          phases.forEach(function (p, idx) {
            if (idx === currentIndex) {
              // adjust end of the current phase if already set
              if ((0, _object.get)(p, 'endDay') !== null) {
                (0, _object.set)(p, 'endDay', (0, _object.get)(p, 'endDay') + diff);
              }
            } else if (idx === 0) {
              // the start of the first phase must always be 0
              (0, _object.set)(p, 'startDay', 0);
              (0, _object.set)(p, 'endDay', p.get('endDay') + diff);
            } else {
              // adjust prev and next phases
              (0, _object.set)(p, 'startDay', p.get('startDay') + diff);

              if ((0, _object.get)(p, 'endDay') !== null) {
                (0, _object.set)(p, 'endDay', p.get('endDay') + diff);
              }
            } // check if start/end is still valid


            if ((0, _object.get)(p, 'startDay') < (0, _object.get)(p, 'endDay') && (0, _object.get)(p, 'endDay') == null) {
              (0, _object.set)(p, 'startDay', (0, _object.get)(p, 'startDay'));
            }
          });
        }
      },
      onChangeEnd: function onChangeEnd(phase, currentIndex, value) {
        // commit the value to the changeset
        (0, _object.set)(phase, 'endDay', Number(value));
        var phases = (0, _object.get)(this, 'phases');
        var startDay = phase.get('startDay');
        var endDay = phase.get('endDay'); // validate the current phase and set disabled accordingly

        if (endDay <= startDay) {
          phases.forEach(function (p, idx) {
            if (idx === currentIndex) {
              (0, _object.set)(p, 'disabledStart', true);
              (0, _object.set)(p, 'disabledEnd', false);
            } else {
              (0, _object.set)(p, 'disabledStart', true);
              (0, _object.set)(p, 'disabledEnd', true);
            }
          });
          return;
        } // reset disabled states


        phases.forEach(function (p) {
          (0, _object.set)(p, 'disabledStart', false);
          (0, _object.set)(p, 'disabledEnd', false);
        }); // make sure end and start follow each other

        if (phases.length > 1) {
          phases.forEach(function (p, idx) {
            var nextPhase = phases[idx + 1];

            if (nextPhase && p.get('endDay') + 1 !== nextPhase.get('startDay')) {
              var diff = nextPhase.get('endDay') - nextPhase.get('startDay');
              var newStart = p.get('endDay') + 1;
              (0, _object.set)(nextPhase, 'startDay', newStart); // when end is not yet set or 0

              if (!nextPhase.get('endDay')) {
                (0, _object.set)(nextPhase, 'endDay', newStart + 1);
              } else {
                (0, _object.set)(nextPhase, 'endDay', newStart + diff);
              }
            } else if (nextPhase) {
              // trigger changeset to remove the error
              (0, _object.set)(nextPhase, 'startDay', nextPhase.get('startDay'));
            }
          });
        }
      },
      onChangeBrand: function onChangeBrand(phase, brand) {
        // when selecting 'none' disable the dosage and set empty
        if (brand === _base.NONE) {
          (0, _object.set)(this, 'dosageDisabled', true);
          phase.set('dosage', _base.DISABLED_DOSAGE_VALUE);
        } else {
          // Reset dosage back to null if it's ' ' on change
          if ((0, _object.get)(phase, 'dosage') === _base.DISABLED_DOSAGE_VALUE) {
            (0, _object.set)(phase, 'dosage', null);
          }

          (0, _object.set)(this, 'dosageDisabled', false);
        }

        (0, _object.set)(phase, 'brand', brand);
      }
    }
  });

  _exports.default = _default;
});