define("huvepharma-aviapp-admin/components/clients/client-block", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    flash: (0, _service.inject)(),
    metrics: (0, _service.inject)(),
    message: '',

    /**
     * Initialiser
     */
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      /**
       * Blocks the company
       */
      onAccept: function onAccept() {
        var _this = this;

        this.set('showModal', false);
        this.company.toggleProperty('isBlocked');
        this.set('company.notifyUpdatedCompany', {
          property: 'isBlocked',
          keys: {
            MESSAGE: this.message,
            STATUS: this.get('company.isBlocked') ? 'disabled' : 'enabled'
          }
        });
        return this.company.save().catch(function () {
          // Undo the change
          _this.company.toggleProperty('isBlocked');

          _this.flash.danger({
            message: 'error_block_company'
          });
        }).finally(function () {
          _this.set('company.notifyUpdatedCompany', undefined);

          _this.set('message', '');

          _this.metrics.trackEvent({
            event: (0, _object.get)(_this, 'company.isBlocked') ? 'Block Client' : 'Unblock Client',
            event_category: 'Company Detail'
          });
        });
      },

      /**
       * Closes the modal
       */
      onCloseModal: function onCloseModal() {
        this.set('showModal', false);
        this.set('message', '');
      }
    }
  });

  _exports.default = _default;
});