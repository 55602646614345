define("huvepharma-aviapp-admin/helpers/region-code", ["exports", "@huvepharma/huvepharma-aviapp-components/helpers/region-code"], function (_exports, _regionCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  var _exportNames = {};
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _regionCode.default;
    }
  });
  Object.keys(_regionCode).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in _exports && _exports[key] === _regionCode[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _regionCode[key];
      }
    });
  });
});