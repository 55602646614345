define("huvepharma-aviapp-admin/validations/models/cocci-phase", ["exports", "ember-changeset-validations/validators", "huvepharma-aviapp-admin/validations/validators/larger-than", "huvepharma-aviapp-admin/validations/change-description"], function (_exports, _validators, _largerThan, _changeDescription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    startDay: (0, _changeDescription.default)([(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      positive: true,
      integer: true
    })], 'flocks_label_startsat'),
    endDay: (0, _changeDescription.default)([(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      positive: true,
      integer: true
    }), (0, _largerThan.default)('startDay')], 'flocks_label_endsat'),
    brand: (0, _changeDescription.default)([(0, _validators.validatePresence)(true)], 'reports_label_brand'),
    dosage: (0, _changeDescription.default)([(0, _validators.validateLength)({
      allowBlank: true,
      allowNone: true
    }), (0, _validators.validateFormat)({
      regex: /^[a-zA-Z0-9., ]*$/,
      allowBlank: true
    })], 'reports_label_dosage')
  };
  _exports.default = _default;
});