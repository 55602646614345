define("huvepharma-aviapp-admin/models/user-connection", ["exports", "huvepharma-aviapp-admin/base/models/user-connection"], function (_exports, _userConnection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UserConnection = _userConnection.default;
  var _default = UserConnection;
  _exports.default = _default;
});