define("huvepharma-aviapp-admin/components/button-spinner", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'button',
    classNames: ['button-spinner'],
    classNameBindings: ['isLoading:loading'],
    attributeBindings: ['isLoading:disabled', 'testId:data-test-id'],
    testId: 'button-spinner',
    isLoading: false,
    click: function click(e) {
      e.preventDefault();
      this.set('isLoading', true);
      var action = this.action;

      if (action && typeof action === 'function') {
        action();
      }
    }
  });

  _exports.default = _default;
});