define("huvepharma-aviapp-admin/routes/session/logout", ["exports", "huvepharma-aviapp-admin/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _protected.default.extend({});

  _exports.default = _default;
});