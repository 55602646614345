define("huvepharma-aviapp-admin/services/user", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    store: (0, _service.inject)(),

    /**
     * Populate the available users to the logged in user
     * @returns {Promise} promise The collection promise
     */
    populateAvailableUsers: function populateAvailableUsers() {
      return this.store.query('user', {}).then(function (users) {
        return users.filter(function (user) {
          return !(user.get('username') === undefined);
        });
      });
    },

    /**
     * Find users for this company which have pending invitations
     */
    findPendingUsers: function findPendingUsers() {
      return this.store.query('user', {
        isPending: true
      }).then(function (users) {
        return users.filter(function (user) {
          return !(user.get('username') === undefined);
        });
      });
    }
  });

  _exports.default = _default;
});