define("huvepharma-aviapp-admin/base/models/scoring-report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ScoringReport = _emberData.default.Model.extend({
    flock: _emberData.default.belongsTo('flock', {
      async: true
    }),
    isVisited: _emberData.default.attr('boolean'),
    visitedAt: _emberData.default.attr('date'),
    selectedModules: _emberData.default.attr(),
    scores: _emberData.default.attr(),
    summarizedScores: _emberData.default.attr(),
    tmls: _emberData.default.attr('number'),
    dysbacteriosis: _emberData.default.attr('number'),
    ics: _emberData.default.attr('number'),
    nofAnimals: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    sampleSize: _emberData.default.attr('string', {
      defaultValue: 'normal'
    }),
    visitType: _emberData.default.attr('string', {
      defaultValue: 'routine'
    }),
    lastScoredModule: _emberData.default.attr('string'),
    lastScoredBird: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    farm: _emberData.default.belongsTo('farm', {
      async: true
    }),
    owner: _emberData.default.belongsTo('user', {
      async: true
    }),
    isSoftDeleted: _emberData.default.attr('boolean', {
      defaultValue: false
    })
  });

  var _default = ScoringReport;
  _exports.default = _default;
});