define("huvepharma-aviapp-admin/base/models/program", ["exports", "ember-data", "huvepharma-aviapp-admin/helpers/time-helper"], function (_exports, _emberData, _timeHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Program = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    type: _emberData.default.attr('string'),
    cocciType: _emberData.default.attr('string', {
      defaultValue: 'cocci'
    }),
    startDate: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return _timeHelper.default.extractDateZoneInvariant().toDate();
      }
    }),
    endDate: _emberData.default.attr('date'),
    phases: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    isArchived: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });

  var _default = Program;
  _exports.default = _default;
});