define("huvepharma-aviapp-admin/templates/header/app", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "NGP2QKjZ",
    "block": "[[[8,[39,0],null,[[\"@headerId\",\"@logo\",\"@logoTitle\",\"@primaryNavItems\",\"@secondaryNavItems\",\"@roleName\",\"@menuItems\",\"@isAdmin\",\"@isMenuOpen\",\"@onHamburgerClicked\",\"@onOverlayClicked\"],[\"main-header\",[30,0,[\"logo\"]],\"Aviapp\",[30,0,[\"menuItems\",\"primary\"]],[30,0,[\"menuItems\",\"secondary\"]],[52,[30,0,[\"session\",\"isSuperUser\"]],\"master\",\"admin\"],[30,0,[\"menuItems\"]],true,[30,0,[\"isMenuOpen\"]],[30,0,[\"toggleMenu\"]],[30,0,[\"toggleMenu\"]]]],null],[1,\"\\n\"]],[],false,[\"huve-header\",\"if\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/header/app.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});