define("huvepharma-aviapp-admin/base/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var User = _emberData.default.Model.extend({
    countryCode: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    locale: _emberData.default.attr('string', {
      defaultValue: 'en'
    }),
    username: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    function: _emberData.default.attr('string', {
      defaultValue: 'employee'
    }),
    password: _emberData.default.attr('string'),
    isApproved: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isPreferred: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    city: _emberData.default.attr('string'),
    zipcode: _emberData.default.attr('string'),
    street: _emberData.default.attr('string'),
    region: _emberData.default.attr('string'),
    number: _emberData.default.attr('string'),
    hasForgotPassword: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isBlocked: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    telephone: _emberData.default.attr('string'),
    role: _emberData.default.attr('string', {
      defaultValue: 'user'
    }),
    newCompany: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    measurementSystem: _emberData.default.attr('string', {
      defaultValue: 'metric'
    }),
    dateFormat: _emberData.default.attr('string', {
      defaultValue: 'DD/MM/YYYY'
    }),
    connectedUsers: _emberData.default.hasMany('user', {
      async: true,
      inverse: null
    }),
    preferences: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return {
          prefill: {},
          filters: {
            visitPage: {},
            farmPage: {},
            reportPage: {}
          },
          defaultFlockFeature: 'house-management'
        };
      }
    }),
    localId: _emberData.default.attr('string'),
    updateNotification: _emberData.default.attr(),
    deletionRequestAt: _emberData.default.attr('date'),
    isDeletedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    accessRights: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    emailSettings: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return {
          subscriptions: [],
          areaOfExpertise: '',
          fallbackToEnglish: false
        };
      }
    })
  });

  var _default = User;
  _exports.default = _default;
});