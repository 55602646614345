define("huvepharma-aviapp-admin/controllers/programs/add-other-program", ["exports", "@ember/object", "ember-changeset", "ember-changeset-validations", "huvepharma-aviapp-admin/models/other-phase", "huvepharma-aviapp-admin/validations/models/other-phase", "huvepharma-aviapp-admin/controllers/programs/base-add"], function (_exports, _object, _emberChangeset, _emberChangesetValidations, _otherPhase, _otherPhase2, _baseAdd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseAdd.default.extend({
    headerTitle: 'programs_add_other_program',
    programType: 'otherProgram',
    init: function init() {
      this._super();

      (0, _object.set)(this, 'selectedFlocks', []);
      (0, _object.set)(this, 'flocks', []);
    },
    setupPhases: function setupPhases() {
      var defaultPhase = _otherPhase.default.create({
        startDay: 0
      });

      var phase = new _emberChangeset.default(defaultPhase, (0, _emberChangesetValidations.default)(_otherPhase2.default), _otherPhase2.default, {
        skipValidate: true
      });
      (0, _object.set)(this, 'phases', [phase]);
    },
    actions: {
      close: function close() {
        var _this$model$otherProg, _this$model$otherProg2;

        (_this$model$otherProg = this.model.otherProgram) === null || _this$model$otherProg === void 0 ? void 0 : (_this$model$otherProg2 = _this$model$otherProg.deleteRecord) === null || _this$model$otherProg2 === void 0 ? void 0 : _this$model$otherProg2.call(_this$model$otherProg);
        (0, _object.set)(this, 'selectedFlocks', []);
        this.transitionToRoute('programs');
      },
      addPhase: function addPhase(currentPhase) {
        // create new phase and set the start to end of the current phase
        var newPhase = _otherPhase.default.create({
          startDay: Number((0, _object.get)(currentPhase, 'endDay')) + 1
        });

        var csPhase = new _emberChangeset.default(newPhase, (0, _emberChangesetValidations.default)(_otherPhase2.default), _otherPhase2.default, {
          skipValidate: true
        });
        this.phases.addObject(csPhase);
      },
      deletePhase: function deletePhase(deletedPhase) {
        this.phases.removeAt(this.phases.indexOf(deletedPhase));
      }
    }
  });

  _exports.default = _default;
});