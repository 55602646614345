define("huvepharma-aviapp-admin/base/enum/countries/en-ja", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AC",
    "country": "アンティグアバーブーダ"
  }, {
    "code": "AD",
    "country": "アンドラ"
  }, {
    "code": "AE",
    "country": "アラブ首長国連邦"
  }, {
    "code": "AF",
    "country": "アフガニスタン"
  }, {
    "code": "AG",
    "country": "アンティグア・バーブーダ"
  }, {
    "code": "AI",
    "country": "アンギラ"
  }, {
    "code": "AL",
    "country": "アルバニア"
  }, {
    "code": "AM",
    "country": "アルメニア"
  }, {
    "code": "AO",
    "country": "アンゴラ"
  }, {
    "code": "AQ",
    "country": "南極"
  }, {
    "code": "AR",
    "country": "アルゼンチン"
  }, {
    "code": "AS",
    "country": "米領サモア"
  }, {
    "code": "AT",
    "country": "オーストリア"
  }, {
    "code": "AU",
    "country": "オーストラリア"
  }, {
    "code": "AW",
    "country": "アルバ"
  }, {
    "code": "AX",
    "country": "オーランド諸島"
  }, {
    "code": "AZ",
    "country": "アゼルバイジャン"
  }, {
    "code": "BA",
    "country": "ボスニア・ヘルツェゴビナ"
  }, {
    "code": "BB",
    "country": "バルバドス"
  }, {
    "code": "BD",
    "country": "バングラデシュ"
  }, {
    "code": "BE",
    "country": "ベルギー"
  }, {
    "code": "BF",
    "country": "ブルキナファソ"
  }, {
    "code": "BG",
    "country": "ブルガリア"
  }, {
    "code": "BH",
    "country": "バーレーン"
  }, {
    "code": "BI",
    "country": "ブルンジ"
  }, {
    "code": "BJ",
    "country": "ベナン"
  }, {
    "code": "BL",
    "country": "サン・バルテルミー"
  }, {
    "code": "BM",
    "country": "バミューダ"
  }, {
    "code": "BN",
    "country": "ブルネイ"
  }, {
    "code": "BO",
    "country": "ボリビア"
  }, {
    "code": "BQ",
    "country": "オランダ領カリブ"
  }, {
    "code": "BR",
    "country": "ブラジル"
  }, {
    "code": "BS",
    "country": "バハマ"
  }, {
    "code": "BT",
    "country": "ブータン"
  }, {
    "code": "BV",
    "country": "ブーベ島"
  }, {
    "code": "BW",
    "country": "ボツワナ"
  }, {
    "code": "BY",
    "country": "ベラルーシ"
  }, {
    "code": "BZ",
    "country": "ベリーズ"
  }, {
    "code": "CA",
    "country": "カナダ"
  }, {
    "code": "CC",
    "country": "ココス(キーリング)諸島"
  }, {
    "code": "CD",
    "country": "コンゴ民主共和国(キンシャサ)"
  }, {
    "code": "CF",
    "country": "中央アフリカ共和国"
  }, {
    "code": "CG",
    "country": "コンゴ共和国(ブラザビル)"
  }, {
    "code": "CH",
    "country": "スイス"
  }, {
    "code": "CI",
    "country": "コートジボワール"
  }, {
    "code": "CK",
    "country": "クック諸島"
  }, {
    "code": "CL",
    "country": "チリ"
  }, {
    "code": "CM",
    "country": "カメルーン"
  }, {
    "code": "CN",
    "country": "中国"
  }, {
    "code": "CO",
    "country": "コロンビア"
  }, {
    "code": "CR",
    "country": "コスタリカ"
  }, {
    "code": "CU",
    "country": "キューバ"
  }, {
    "code": "CV",
    "country": "カーボベルデ"
  }, {
    "code": "CW",
    "country": "キュラソー"
  }, {
    "code": "CX",
    "country": "クリスマス島"
  }, {
    "code": "CY",
    "country": "キプロス"
  }, {
    "code": "CZ",
    "country": "チェコ"
  }, {
    "code": "DE",
    "country": "ドイツ"
  }, {
    "code": "DG",
    "country": "ディエゴガルシア"
  }, {
    "code": "DJ",
    "country": "ジブチ"
  }, {
    "code": "DK",
    "country": "デンマーク"
  }, {
    "code": "DM",
    "country": "ドミニカ国"
  }, {
    "code": "DO",
    "country": "ドミニカ共和国"
  }, {
    "code": "DZ",
    "country": "アルジェリア"
  }, {
    "code": "EA",
    "country": "ユーラシア特許庁"
  }, {
    "code": "EC",
    "country": "エクアドル"
  }, {
    "code": "EE",
    "country": "エストニア"
  }, {
    "code": "EG",
    "country": "エジプト"
  }, {
    "code": "EH",
    "country": "西サハラ"
  }, {
    "code": "ER",
    "country": "エリトリア"
  }, {
    "code": "ES",
    "country": "スペイン"
  }, {
    "code": "ET",
    "country": "エチオピア"
  }, {
    "code": "EZ",
    "country": "ユーロ圏"
  }, {
    "code": "FI",
    "country": "フィンランド"
  }, {
    "code": "FJ",
    "country": "フィジー"
  }, {
    "code": "FK",
    "country": "フォークランド諸島"
  }, {
    "code": "FM",
    "country": "ミクロネシア連邦"
  }, {
    "code": "FO",
    "country": "フェロー諸島"
  }, {
    "code": "FR",
    "country": "フランス"
  }, {
    "code": "GA",
    "country": "ガボン"
  }, {
    "code": "GB",
    "country": "イギリス"
  }, {
    "code": "GD",
    "country": "グレナダ"
  }, {
    "code": "GE",
    "country": "ジョージア"
  }, {
    "code": "GF",
    "country": "仏領ギアナ"
  }, {
    "code": "GG",
    "country": "ガーンジー"
  }, {
    "code": "GH",
    "country": "ガーナ"
  }, {
    "code": "GI",
    "country": "ジブラルタル"
  }, {
    "code": "GL",
    "country": "グリーンランド"
  }, {
    "code": "GM",
    "country": "ガンビア"
  }, {
    "code": "GN",
    "country": "ギニア"
  }, {
    "code": "GP",
    "country": "グアドループ"
  }, {
    "code": "GQ",
    "country": "赤道ギニア"
  }, {
    "code": "GR",
    "country": "ギリシャ"
  }, {
    "code": "GS",
    "country": "サウスジョージア・サウスサンドウィッチ諸島"
  }, {
    "code": "GT",
    "country": "グアテマラ"
  }, {
    "code": "GU",
    "country": "グアム"
  }, {
    "code": "GW",
    "country": "ギニアビサウ"
  }, {
    "code": "GY",
    "country": "ガイアナ"
  }, {
    "code": "HK",
    "country": "中華人民共和国香港特別行政区"
  }, {
    "code": "HM",
    "country": "ハード島・マクドナルド諸島"
  }, {
    "code": "HN",
    "country": "ホンジュラス"
  }, {
    "code": "HR",
    "country": "クロアチア"
  }, {
    "code": "HT",
    "country": "ハイチ"
  }, {
    "code": "HU",
    "country": "ハンガリー"
  }, {
    "code": "IC",
    "country": "カナリア諸島"
  }, {
    "code": "ID",
    "country": "インドネシア"
  }, {
    "code": "IE",
    "country": "アイルランド"
  }, {
    "code": "IL",
    "country": "イスラエル"
  }, {
    "code": "IM",
    "country": "マン島"
  }, {
    "code": "IN",
    "country": "インド"
  }, {
    "code": "IO",
    "country": "英領インド洋地域"
  }, {
    "code": "IQ",
    "country": "イラク"
  }, {
    "code": "IR",
    "country": "イラン"
  }, {
    "code": "IS",
    "country": "アイスランド"
  }, {
    "code": "IT",
    "country": "イタリア"
  }, {
    "code": "JE",
    "country": "ジャージー"
  }, {
    "code": "JM",
    "country": "ジャマイカ"
  }, {
    "code": "JO",
    "country": "ヨルダン"
  }, {
    "code": "JP",
    "country": "日本"
  }, {
    "code": "KE",
    "country": "ケニア"
  }, {
    "code": "KG",
    "country": "キルギス"
  }, {
    "code": "KH",
    "country": "カンボジア"
  }, {
    "code": "KI",
    "country": "キリバス"
  }, {
    "code": "KM",
    "country": "コモロ"
  }, {
    "code": "KN",
    "country": "セントクリストファー・ネーヴィス"
  }, {
    "code": "KP",
    "country": "北朝鮮"
  }, {
    "code": "KR",
    "country": "韓国"
  }, {
    "code": "KW",
    "country": "クウェート"
  }, {
    "code": "KY",
    "country": "ケイマン諸島"
  }, {
    "code": "KZ",
    "country": "カザフスタン"
  }, {
    "code": "LA",
    "country": "ラオス"
  }, {
    "code": "LB",
    "country": "レバノン"
  }, {
    "code": "LC",
    "country": "セントルシア"
  }, {
    "code": "LI",
    "country": "リヒテンシュタイン"
  }, {
    "code": "LK",
    "country": "スリランカ"
  }, {
    "code": "LR",
    "country": "リベリア"
  }, {
    "code": "LS",
    "country": "レソト"
  }, {
    "code": "LT",
    "country": "リトアニア"
  }, {
    "code": "LU",
    "country": "ルクセンブルク"
  }, {
    "code": "LV",
    "country": "ラトビア"
  }, {
    "code": "LY",
    "country": "リビア"
  }, {
    "code": "MA",
    "country": "モロッコ"
  }, {
    "code": "MC",
    "country": "モナコ"
  }, {
    "code": "MD",
    "country": "モルドバ"
  }, {
    "code": "ME",
    "country": "モンテネグロ"
  }, {
    "code": "MF",
    "country": "サン・マルタン"
  }, {
    "code": "MG",
    "country": "マダガスカル"
  }, {
    "code": "MH",
    "country": "マーシャル諸島"
  }, {
    "code": "MK",
    "country": "北マケドニア"
  }, {
    "code": "ML",
    "country": "マリ"
  }, {
    "code": "MM",
    "country": "ミャンマー (ビルマ)"
  }, {
    "code": "MN",
    "country": "モンゴル"
  }, {
    "code": "MO",
    "country": "中華人民共和国マカオ特別行政区"
  }, {
    "code": "MP",
    "country": "北マリアナ諸島"
  }, {
    "code": "MQ",
    "country": "マルティニーク"
  }, {
    "code": "MR",
    "country": "モーリタニア"
  }, {
    "code": "MS",
    "country": "モントセラト"
  }, {
    "code": "MT",
    "country": "マルタ"
  }, {
    "code": "MU",
    "country": "モーリシャス"
  }, {
    "code": "MV",
    "country": "モルディブ"
  }, {
    "code": "MW",
    "country": "マラウイ"
  }, {
    "code": "MX",
    "country": "メキシコ"
  }, {
    "code": "MY",
    "country": "マレーシア"
  }, {
    "code": "MZ",
    "country": "モザンビーク"
  }, {
    "code": "NA",
    "country": "ナミビア"
  }, {
    "code": "NC",
    "country": "ニューカレドニア"
  }, {
    "code": "NE",
    "country": "ニジェール"
  }, {
    "code": "NF",
    "country": "ノーフォーク島"
  }, {
    "code": "NG",
    "country": "ナイジェリア"
  }, {
    "code": "NI",
    "country": "ニカラグア"
  }, {
    "code": "NL",
    "country": "オランダ"
  }, {
    "code": "NO",
    "country": "ノルウェー"
  }, {
    "code": "NP",
    "country": "ネパール"
  }, {
    "code": "NR",
    "country": "ナウル"
  }, {
    "code": "NU",
    "country": "ニウエ"
  }, {
    "code": "NZ",
    "country": "ニュージーランド"
  }, {
    "code": "OM",
    "country": "オマーン"
  }, {
    "code": "PA",
    "country": "パナマ"
  }, {
    "code": "PE",
    "country": "ペルー"
  }, {
    "code": "PF",
    "country": "仏領ポリネシア"
  }, {
    "code": "PG",
    "country": "パプアニューギニア"
  }, {
    "code": "PH",
    "country": "フィリピン"
  }, {
    "code": "PK",
    "country": "パキスタン"
  }, {
    "code": "PL",
    "country": "ポーランド"
  }, {
    "code": "PM",
    "country": "サンピエール島・ミクロン島"
  }, {
    "code": "PN",
    "country": "ピトケアン諸島"
  }, {
    "code": "PR",
    "country": "プエルトリコ"
  }, {
    "code": "PS",
    "country": "パレスチナ自治区"
  }, {
    "code": "PT",
    "country": "ポルトガル"
  }, {
    "code": "PW",
    "country": "パラオ"
  }, {
    "code": "PY",
    "country": "パラグアイ"
  }, {
    "code": "QA",
    "country": "カタール"
  }, {
    "code": "RE",
    "country": "レユニオン"
  }, {
    "code": "RO",
    "country": "ルーマニア"
  }, {
    "code": "RS",
    "country": "セルビア"
  }, {
    "code": "RU",
    "country": "ロシア"
  }, {
    "code": "RW",
    "country": "ルワンダ"
  }, {
    "code": "SA",
    "country": "サウジアラビア"
  }, {
    "code": "SB",
    "country": "ソロモン諸島"
  }, {
    "code": "SC",
    "country": "セーシェル"
  }, {
    "code": "SD",
    "country": "スーダン"
  }, {
    "code": "SE",
    "country": "スウェーデン"
  }, {
    "code": "SG",
    "country": "シンガポール"
  }, {
    "code": "SH",
    "country": "セントヘレナ"
  }, {
    "code": "SI",
    "country": "スロベニア"
  }, {
    "code": "SJ",
    "country": "スバールバル諸島・ヤンマイエン島"
  }, {
    "code": "SK",
    "country": "スロバキア"
  }, {
    "code": "SL",
    "country": "シエラレオネ"
  }, {
    "code": "SM",
    "country": "サンマリノ"
  }, {
    "code": "SN",
    "country": "セネガル"
  }, {
    "code": "SO",
    "country": "ソマリア"
  }, {
    "code": "SR",
    "country": "スリナム"
  }, {
    "code": "SS",
    "country": "南スーダン"
  }, {
    "code": "ST",
    "country": "サントメ・プリンシペ"
  }, {
    "code": "SV",
    "country": "エルサルバドル"
  }, {
    "code": "SX",
    "country": "シント・マールテン"
  }, {
    "code": "SY",
    "country": "シリア"
  }, {
    "code": "SZ",
    "country": "エスワティニ"
  }, {
    "code": "TA",
    "country": "トリスタンダクーニャ"
  }, {
    "code": "TC",
    "country": "タークス・カイコス諸島"
  }, {
    "code": "TD",
    "country": "チャド"
  }, {
    "code": "TF",
    "country": "仏領極南諸島"
  }, {
    "code": "TG",
    "country": "トーゴ"
  }, {
    "code": "TH",
    "country": "タイ"
  }, {
    "code": "TJ",
    "country": "タジキスタン"
  }, {
    "code": "TK",
    "country": "トケラウ"
  }, {
    "code": "TL",
    "country": "東ティモール"
  }, {
    "code": "TM",
    "country": "トルクメニスタン"
  }, {
    "code": "TN",
    "country": "チュニジア"
  }, {
    "code": "TO",
    "country": "トンガ"
  }, {
    "code": "TR",
    "country": "トルコ"
  }, {
    "code": "TT",
    "country": "トリニダード・トバゴ"
  }, {
    "code": "TV",
    "country": "ツバル"
  }, {
    "code": "TW",
    "country": "台湾"
  }, {
    "code": "TZ",
    "country": "タンザニア"
  }, {
    "code": "UA",
    "country": "ウクライナ"
  }, {
    "code": "UG",
    "country": "ウガンダ"
  }, {
    "code": "UM",
    "country": "合衆国領有小離島"
  }, {
    "code": "UN",
    "country": "国連"
  }, {
    "code": "US",
    "country": "アメリカ合衆国"
  }, {
    "code": "UY",
    "country": "ウルグアイ"
  }, {
    "code": "UZ",
    "country": "ウズベキスタン"
  }, {
    "code": "VA",
    "country": "バチカン市国"
  }, {
    "code": "VC",
    "country": "セントビンセント及びグレナディーン諸島"
  }, {
    "code": "VE",
    "country": "ベネズエラ"
  }, {
    "code": "VG",
    "country": "英領ヴァージン諸島"
  }, {
    "code": "VI",
    "country": "米領ヴァージン諸島"
  }, {
    "code": "VN",
    "country": "ベトナム"
  }, {
    "code": "VU",
    "country": "バヌアツ"
  }, {
    "code": "WF",
    "country": "ウォリス・フツナ"
  }, {
    "code": "WS",
    "country": "サモア"
  }, {
    "code": "XK",
    "country": "コソボ"
  }, {
    "code": "YE",
    "country": "イエメン"
  }, {
    "code": "YT",
    "country": "マヨット"
  }, {
    "code": "ZA",
    "country": "南アフリカ"
  }, {
    "code": "ZM",
    "country": "ザンビア"
  }, {
    "code": "ZW",
    "country": "ジンバブエ"
  }];
  _exports.default = _default;
});