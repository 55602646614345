define("huvepharma-aviapp-admin/models/flock", ["exports", "ember-data", "@ember/object", "huvepharma-aviapp-admin/base/models/flock", "huvepharma-aviapp-admin/helpers/time-helper", "huvepharma-aviapp-admin/helpers/id-generator"], function (_exports, _emberData, _object, _flock, _timeHelper, _idGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Due to limitations of mongoose, there cannot be a subschema with defined sub elements
   * and a defaultValue. So set it here.
   */
  _flock.default.reopen({
    origins: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });

  var _default = _flock.default.extend({
    /**
     * Computed property for the calculated age.
     * Calculated by getting the diff between 'today' (extractDateZoneInvariant)
     * and flock.startedAt.
     */
    calculatedAge: (0, _object.computed)('startedAt', {
      get: function get() {
        return _timeHelper.default.diffFromUTCDate(_timeHelper.default.extractDateZoneInvariant(), this.startedAt, 'days');
      },
      set: function set(key, value) {
        this.set('startedAt', _timeHelper.default.subtractFromUTCDate(_timeHelper.default.extractDateZoneInvariant(), value, 'days').toDate());
        return value;
      }
    }),

    /**
     * The ID identifier for the flock
     */
    identifier: (0, _object.computed)('farm.name', 'houseId', 'calculatedAge', function () {
      return (0, _idGenerator.IdGenerator)([this.get('farm.name'), this.calculatedAge.toString(), this.houseId]);
    })
  });

  _exports.default = _default;
});