define("huvepharma-aviapp-admin/templates/components/modal/reminder-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "SEAPyhg5",
    "block": "[[[8,[39,0],null,[[\"@show\",\"@noDefault\",\"@class\",\"@testId\",\"@header\",\"@headerText\",\"@headerCloseAction\"],[[30,1],true,\"mod-modal-list\",[30,2],true,[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"body\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"body-content\"],[12],[1,\"\\n      \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,5]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[12],[1,\"\\n          \"],[11,3],[24,0,\"item\"],[4,[38,3],[\"click\",[28,[37,4],[[30,7],[30,6]],null]],null],[12],[1,\"\\n              \"],[10,\"svg\"],[12],[10,\"use\"],[14,\"xlink:href\",\"#mail\",\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n              \"],[10,0],[12],[1,\"\\n                \"],[10,0],[14,0,\"date\"],[12],[1,[30,6,[\"name\"]]],[13],[1,\"\\n              \"],[13],[1,\"\\n              \"],[10,\"svg\"],[14,0,\"next-arrow\"],[12],[10,\"use\"],[14,\"xlink:href\",\"#arrow-down\",\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[6]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@show\",\"@testId\",\"@title\",\"@headerCloseAction\",\"@invitations\",\"invite\",\"@sendReminder\"],false,[\"modal/huve-base\",\"each\",\"-track-array\",\"on\",\"fn\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/components/modal/reminder-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});