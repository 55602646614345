define("huvepharma-aviapp-admin/initializers/translateable-attributes", ["exports", "@ember/component/text-field", "@ember/component", "huvepharma-aviapp-admin/mixins/translateable-attributes"], function (_exports, _textField, _component, _translateableAttributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'translateable-attributes',
    initialize: function initialize() {
      _textField.default.reopen(_translateableAttributes.default);

      _component.default.reopen(_translateableAttributes.default);
    }
  };
  _exports.default = _default;
});