define("huvepharma-aviapp-admin/base/enum/house-management-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "WATER_INTAKE": "water_intake_category",
    "FEED_INTAKE": "feed_intake_category",
    "WATER_FEED_INTAKE": "water_feed_intake_category",
    "MORTALITY": "mortality_category",
    "RELATIVE_HUMIDITY": "relative_humidity_category",
    "AMMONIA": "ammonia_category",
    "BIRD_DISTRIBUTION": "bird_distribution_category",
    "UNIFORMITY": "uniformity_category",
    "FEED_SPILLAGE": "feed_spillage_category",
    "DROPPINGS": "droppings_category",
    "LITTER_QUALITY": "litter_quality_category",
    "HYGIENE": "hygiene_category",
    "FEED_MANAGEMENT": "feed_management_category",
    "WATER_MANAGEMENT": "water_management_category",
    "LITTER_SCORING": "litter_scoring_category",
    "FEED_WASTAGE": "feed_wastage_category",
    "DRINKING_LINE_HEIGHT": "water_management_drinking_line_height_category",
    "DRINKING_LINE_WATER_PRESSURE": "water_management_drinking_line_water_pressure_category",
    "WATER_QUALITY": "water_quality_category",
    "BIRD_BEHAVIOUR": "bird_behaviour_category",
    "FLIES": "flies_category",
    "MITES": "mites_category",
    "NEST_CLEANNESS": "nest_cleanness_category",
    "BELT_CLEANNESS": "belt_cleanness_category",
    "CHICK_FLUFF": "chick_fluff_category",
    "AGGRESSIVE_BEHAVIOUR": "aggressive_behaviour_category",
    "WOUNDS_BACK_CLOACA": "wounds_back_cloaca_category",
    "WOUNDS_COMB": "wounds_comb_category",
    "CANNIBALISM": "cannibalism_category",
    "FEATHER_PECKING_NECK_HEAD": "feather_pecking_neck_head_category",
    "FEATHER_PECKING_BACK_CLOACA": "feather_pecking_back_cloaca_category"
  };
  _exports.default = _default;
});