define("huvepharma-aviapp-admin/templates/components/collapsible-panel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "sI+BIFsZ",
    "block": "[[[10,0],[15,0,[29,[[36,0]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"collapsible-panel-heading \",[36,1]]]],[15,\"onclick\",[28,[37,2],[[30,0],\"toggle\"],null]],[12],[1,\"\\n    \"],[10,1],[12],[1,[34,3]],[13],[1,\"\\n    \"],[10,\"svg\"],[15,0,[29,[\"collapsible-panel-icon \",[52,[33,5],\"is-open\"]]]],[12],[1,\"\\n      \"],[10,\"use\"],[14,\"xlink:href\",\"#arrow-down\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[33,5],[[[1,\"    \"],[10,0],[15,0,[29,[\"collapsible-panel-body \",[36,6]]]],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&default\"],false,[\"className\",\"headingClass\",\"action\",\"title\",\"if\",\"isOpen\",\"bodyClass\",\"yield\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/components/collapsible-panel.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});