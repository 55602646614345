define("huvepharma-aviapp-admin/base/enum/continents/en-ja", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AF",
    "continent": "アフリカ"
  }, {
    "code": "NA",
    "continent": "北米"
  }, {
    "code": "OC",
    "continent": "オセアニア"
  }, {
    "code": "AN",
    "continent": "南極大陸"
  }, {
    "code": "AS",
    "continent": "アジア"
  }, {
    "code": "EU",
    "continent": "ヨーロッパ"
  }, {
    "code": "SA",
    "continent": "南アメリカ"
  }];
  _exports.default = _default;
});