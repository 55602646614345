define("huvepharma-aviapp-admin/routes/programs/index", ["exports", "@ember/object", "@ember/service", "@huvepharma/huvepharma-aviapp-components/filters/programs", "@huvepharma/huvepharma-aviapp-components/utils/tree", "huvepharma-aviapp-admin/routes/protected", "huvepharma-aviapp-admin/mixins/restrict-super-user-route", "huvepharma-aviapp-admin/base/enum/other-programs"], function (_exports, _object, _service, _programs, _tree, _protected, _restrictSuperUserRoute, _otherPrograms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _protected.default.extend(_restrictSuperUserRoute.default, {
    metrics: (0, _service.inject)(),
    model: function model() {
      return this.store.query('program', {}).then(function (programs) {
        return {
          cocci: {
            active: programs.filter(function (p) {
              return (0, _object.get)(p, 'type') === 'cocci' && !(0, _object.get)(p, 'isArchived');
            }),
            isArchived: programs.filter(function (p) {
              return (0, _object.get)(p, 'type') === 'cocci' && (0, _object.get)(p, 'isArchived');
            }),
            all: programs.filter(function (p) {
              return (0, _object.get)(p, 'type') === 'cocci';
            })
          },
          other: {
            active: programs.filter(function (p) {
              return (0, _object.get)(p, 'type') === 'other' && !(0, _object.get)(p, 'isArchived');
            }),
            isArchived: programs.filter(function (p) {
              return (0, _object.get)(p, 'type') === 'other' && (0, _object.get)(p, 'isArchived');
            }),
            all: programs.filter(function (p) {
              return (0, _object.get)(p, 'type') === 'other';
            })
          }
        };
      });
    },
    afterModel: function afterModel() {
      (0, _object.get)(this, 'metrics').trackPage({
        page: '/Admin/Programs',
        title: 'Programs Overview'
      });
    },

    /**
     * Sets up the controller
     * @param controller The controller
     * @param model The model
     */
    setupController: function setupController(controller, model) {
      var _set, _set2, _set3;

      (0, _object.set)(controller, 'programs', model);
      (0, _object.set)(controller, 'trees', (_set = {}, _defineProperty(_set, _programs.COCCI_TYPE, _tree.default.create({
        data: (0, _programs.default)(_programs.COCCI_TYPE)
      })), _defineProperty(_set, _programs.OTHER_TYPE, _tree.default.create({
        data: (0, _programs.default)(_programs.OTHER_TYPE, _otherPrograms.default)
      })), _set));
      (0, _object.set)(controller, 'searchValues', (_set2 = {}, _defineProperty(_set2, _programs.COCCI_TYPE, undefined), _defineProperty(_set2, _programs.OTHER_TYPE, undefined), _set2));
      (0, _object.set)(controller, 'activeFilters', (_set3 = {}, _defineProperty(_set3, _programs.COCCI_TYPE, _toConsumableArray((0, _object.get)(controller, "filters.".concat(_programs.COCCI_TYPE)))), _defineProperty(_set3, _programs.OTHER_TYPE, _toConsumableArray((0, _object.get)(controller, "filters.".concat(_programs.OTHER_TYPE)))), _set3));
      controller.applyFilters();
    }
  });

  _exports.default = _default;
});