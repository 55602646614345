define("huvepharma-aviapp-admin/controllers/about/index", ["exports", "@ember/controller", "@ember/service", "huvepharma-aviapp-admin/config/environment"], function (_exports, _controller, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    session: (0, _service.inject)(),
    metrics: (0, _service.inject)(),
    version: _environment.default.APP.version,
    actions: {
      openLink: function openLink(target, label) {
        this.metrics.trackEvent({
          event: 'Click Link',
          event_category: 'Admin About',
          event_label: label
        });
      }
    }
  });

  _exports.default = _default;
});