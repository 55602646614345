define("huvepharma-aviapp-admin/utils/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    instance: null
  };
  _exports.default = _default;
});