define("huvepharma-aviapp-admin/base/enum/grouped-modules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "dysbacteriosis": ["gutBallooning", "undigestedFeed", "cranialInflammationBloodVesselsDilated", "cranialFlaccid", "cranialAbnormalContents", "cranialThicknessFlaccidTranslucencyFragility", "caudalInflammationBloodVesselsDilated", "caudalFlaccid", "caudalAbnormalContents", "caudalThicknessFlaccidTranslucencyFragility"],
    "ics": ["cellularSloughing", "excessiveIntestinalMucus", "excessiveIntestinalFluid", "hyperemia", "intestinalHemorrhage", "intestinalTone", "thinIntestines", "thickIntestines", "feedPassage", "necroticEnteritis"],
    "tmls": ["eimeriaAcervulina", "eimeriaTenella", "eimeriaMaxima"]
  };
  _exports.default = _default;
});