define("huvepharma-aviapp-admin/mixins/header-hidden-route", ["exports", "@ember/object/mixin", "@ember/object"], function (_exports, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var appController = this.controllerFor('application');
      (0, _object.set)(appController, 'headerHidden', true);
    }
  });

  _exports.default = _default;
});