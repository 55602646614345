define("huvepharma-aviapp-admin/routes/programs/base", ["exports", "@ember/object", "huvepharma-aviapp-admin/routes/protected", "huvepharma-aviapp-admin/mixins/restrict-super-user-route", "huvepharma-aviapp-admin/mixins/reset-controller-route", "huvepharma-aviapp-admin/helpers/time-helper"], function (_exports, _object, _protected, _restrictSuperUserRoute, _resetControllerRoute, _timeHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = _protected.default.extend(_restrictSuperUserRoute.default, _resetControllerRoute.default, {
    resetProperties: ['minEndDate', 'maxStartDate', 'selectAllFlocksTriggered'],
    setMinEndDate: function setMinEndDate(_ref) {
      var controller = _ref.controller,
          startDate = _ref.startDate;

      var minEndDate = _timeHelper.default.addToUTCDate(startDate, 1, 'day').toDate();

      (0, _object.set)(controller, 'minEndDate', minEndDate);
    },
    setMaxStartDate: function setMaxStartDate(_ref2) {
      var controller = _ref2.controller,
          endDate = _ref2.endDate;

      var maxStartDate = _timeHelper.default.subtractFromUTCDate(endDate, 1, 'day').toDate();

      (0, _object.set)(controller, 'maxStartDate', maxStartDate);
    },

    /**
     * Push the linked flocks in the store
     * they are loaded via a query param and not part of the model
     * Set the linkedFlocks on the controller
     * Set the selectedFlocks on the controller
     * @param controller the program controller
     * @param linkedFlocks the flocks that are linked to the current program
     */
    setFlocks: function setFlocks(controller, linkedFlocks) {
      var store = this.store;
      var linkedStoreFlocks = linkedFlocks.map(function (flock) {
        return store.push(store.normalize('flock', flock));
      });
      (0, _object.set)(controller, 'model.linkedFlocks', linkedStoreFlocks);
      (0, _object.set)(controller, 'selectedFlocks', linkedStoreFlocks.sortBy('calculatedAge'));
    },

    /**
     * Get all coccidiostat-programs, split them up in
     * non-vaccine and vaccine and sort them
     */
    fetchCocciPrograms: function fetchCocciPrograms() {
      var _this = this;

      return this.store.findAll('coccidiostat-programme').then(function (programs) {
        var nonVaccinePrograms = [];
        var vaccinePrograms = [];
        var noneValue = {
          id: 'none'
        };
        programs.forEach(function (program) {
          if (program.get('category') === 'vaccine') {
            vaccinePrograms.addObject(noneValue);
            vaccinePrograms.addObject(program);
          } else {
            nonVaccinePrograms.addObject(program);
          }
        });
        nonVaccinePrograms.sort(_this.sortNonVaccinePrograms.bind(_this));
        nonVaccinePrograms.unshift(noneValue);
        return {
          vaccination: vaccinePrograms.sortBy('name'),
          cocci: nonVaccinePrograms
        };
      });
    },
    getProgramName: function getProgramName(program) {
      return (_typeof((0, _object.get)(program, 'name')) === 'object' ? program.name.string : program.get('name') || '').toUpperCase();
    },

    /**
     * Sort function for non-vaccine programs
     * @param {Object} programA
     * @param {Object} programB
     * @returns {Int}
     */
    sortNonVaccinePrograms: function sortNonVaccinePrograms(programA, programB) {
      // Uppercase both strings
      var nameA = this.getProgramName(programA);
      var nameB = this.getProgramName(programB);
      var prefix = 'zzz'; // If a string starts with OTHER, add the zzz prefix to it
      // temporarily to make sure it'll be sorted at the end

      if (nameA.indexOf('OTHER') === 0) {
        nameA = prefix.concat(nameA);
      }

      if (nameB.indexOf('OTHER') === 0) {
        nameB = prefix.concat(nameB);
      } // Do the comparison


      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      } // Strings must be equal


      return 0;
    }
  });

  _exports.default = _default;
});