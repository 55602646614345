define("huvepharma-aviapp-admin/services/invitation", ["exports", "@ember/service", "huvepharma-aviapp-admin/utils/fetch"], function (_exports, _service, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    session: (0, _service.inject)('session'),

    /**
     * Send a reminder based on the token
     */
    reminder: function reminder(invitedId) {
      return (0, _fetch.default)(this.get('session'), "/invitations/".concat(invitedId, "/reminder"), 'GET', '');
    }
  });

  _exports.default = _default;
});