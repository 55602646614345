define("huvepharma-aviapp-admin/base/enum/countries/en-uk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AD",
    "country": "Андорра"
  }, {
    "code": "AE",
    "country": "Обʼєднані Арабські Емірати"
  }, {
    "code": "AF",
    "country": "Афганістан"
  }, {
    "code": "AG",
    "country": "Антигуа і Барбуда"
  }, {
    "code": "AL",
    "country": "Албанія"
  }, {
    "code": "AM",
    "country": "Вірменія"
  }, {
    "code": "AO",
    "country": "Ангола"
  }, {
    "code": "AR",
    "country": "Аргентина "
  }, {
    "code": "AT",
    "country": "Австрія"
  }, {
    "code": "AU",
    "country": "Австралія"
  }, {
    "code": "AZ",
    "country": "Азербайджан"
  }, {
    "code": "BA",
    "country": "Боснія і Герцеговина "
  }, {
    "code": "BB",
    "country": "Барбадос"
  }, {
    "code": "BD",
    "country": "Бангладеш"
  }, {
    "code": "BE",
    "country": "Бельгія"
  }, {
    "code": "BF",
    "country": "Буркіна-Фасо"
  }, {
    "code": "BG",
    "country": "Болгарія "
  }, {
    "code": "BH",
    "country": "Бахрейн"
  }, {
    "code": "BI",
    "country": "Бурунді"
  }, {
    "code": "BJ",
    "country": "Бенін"
  }, {
    "code": "BL",
    "country": "Сен-Бартельмі"
  }, {
    "code": "BN",
    "country": "Бруней"
  }, {
    "code": "BO",
    "country": "Болівія"
  }, {
    "code": "BQ",
    "country": "Нідерландські Карибські острови"
  }, {
    "code": "BR",
    "country": "Бразилія"
  }, {
    "code": "BS",
    "country": "Багамські Острови"
  }, {
    "code": "BT",
    "country": "Бутан"
  }, {
    "code": "BV",
    "country": "Острів Буве"
  }, {
    "code": "BW",
    "country": "Ботсвана"
  }, {
    "code": "BY",
    "country": "Білорусь"
  }, {
    "code": "BZ",
    "country": "Беліз"
  }, {
    "code": "CA",
    "country": "Канада"
  }, {
    "code": "CC",
    "country": "Кокосові (Кілінґ) Острови"
  }, {
    "code": "CD",
    "country": "Конго – Кіншаса"
  }, {
    "code": "CF",
    "country": "Центральноафриканська Республіка"
  }, {
    "code": "CG",
    "country": "Конго – Браззавіль"
  }, {
    "code": "CH",
    "country": "Швейцарія"
  }, {
    "code": "CI",
    "country": "Кот-дʼІвуар"
  }, {
    "code": "CK",
    "country": "Острови Кука"
  }, {
    "code": "CL",
    "country": "Чілі"
  }, {
    "code": "CM",
    "country": "Камерун"
  }, {
    "code": "CN",
    "country": "Китай"
  }, {
    "code": "CO",
    "country": "Колумбія"
  }, {
    "code": "CR",
    "country": "Коста-Ріка"
  }, {
    "code": "CU",
    "country": "Куба"
  }, {
    "code": "CV",
    "country": "Кабо-Верде"
  }, {
    "code": "CW",
    "country": "Кюрасао"
  }, {
    "code": "CX",
    "country": "Острів Різдва"
  }, {
    "code": "CY",
    "country": "Кіпр"
  }, {
    "code": "CZ",
    "country": "Чехія"
  }, {
    "code": "DE",
    "country": "Німеччина"
  }, {
    "code": "DJ",
    "country": "Джибуті"
  }, {
    "code": "DK",
    "country": "Данія"
  }, {
    "code": "DM",
    "country": "Домініка"
  }, {
    "code": "DO",
    "country": "Домініканська Республіка"
  }, {
    "code": "DZ",
    "country": "Алжир"
  }, {
    "code": "EC",
    "country": "Еквадор"
  }, {
    "code": "EE",
    "country": "Естонія"
  }, {
    "code": "EG",
    "country": "Єгипет"
  }, {
    "code": "EH",
    "country": "Західна Сахара"
  }, {
    "code": "ER",
    "country": "Еритрея"
  }, {
    "code": "ES",
    "country": "Іспанія"
  }, {
    "code": "ET",
    "country": "Ефіопія"
  }, {
    "code": "FI",
    "country": "Фінляндія"
  }, {
    "code": "FJ",
    "country": "Фіджі"
  }, {
    "code": "FK",
    "country": "Фолклендські Острови"
  }, {
    "code": "FM",
    "country": "Мікронезія"
  }, {
    "code": "FO",
    "country": "Фарерські Острови"
  }, {
    "code": "FR",
    "country": "Франція"
  }, {
    "code": "GA",
    "country": "Габон"
  }, {
    "code": "GB",
    "country": "Велика Британія"
  }, {
    "code": "GD",
    "country": "Ґренада"
  }, {
    "code": "GE",
    "country": "Грузія"
  }, {
    "code": "GF",
    "country": "Французька Ґвіана"
  }, {
    "code": "GG",
    "country": "Ґернсі"
  }, {
    "code": "GH",
    "country": "Гана"
  }, {
    "code": "GI",
    "country": "Ґібралтар"
  }, {
    "code": "GL",
    "country": "Ґренландія"
  }, {
    "code": "GM",
    "country": "Гамбія"
  }, {
    "code": "GN",
    "country": "Гвінея"
  }, {
    "code": "GP",
    "country": "Ґваделупа"
  }, {
    "code": "GQ",
    "country": "Екваторіальна Гвінея"
  }, {
    "code": "GR",
    "country": "Греція"
  }, {
    "code": "GS",
    "country": "Південна Джорджія та Південні Сандвічеві Острови"
  }, {
    "code": "GT",
    "country": "Ґватемала"
  }, {
    "code": "GU",
    "country": "Ґуам"
  }, {
    "code": "GW",
    "country": "Гвінея-Бісау"
  }, {
    "code": "GY",
    "country": "Ґайана"
  }, {
    "code": "HK",
    "country": "Гонконг, О.А.Р. Китаю"
  }, {
    "code": "HM",
    "country": "Острови Герд і Макдоналд"
  }, {
    "code": "HN",
    "country": "Гондурас"
  }, {
    "code": "HR",
    "country": "Хорватія"
  }, {
    "code": "HT",
    "country": "Гаїті"
  }, {
    "code": "HU",
    "country": "Угорщина"
  }, {
    "code": "ID",
    "country": "Індонезія"
  }, {
    "code": "IE",
    "country": "Ірландія"
  }, {
    "code": "IL",
    "country": "Ізраїль"
  }, {
    "code": "IM",
    "country": "Острів Мен"
  }, {
    "code": "IN",
    "country": "Індія"
  }, {
    "code": "IQ",
    "country": "Ірак"
  }, {
    "code": "IR",
    "country": "Іран"
  }, {
    "code": "IS",
    "country": "Ісландія"
  }, {
    "code": "IT",
    "country": "Італія"
  }, {
    "code": "JE",
    "country": "Джерсі"
  }, {
    "code": "JM",
    "country": "Ямайка"
  }, {
    "code": "JO",
    "country": "Йорданія"
  }, {
    "code": "JP",
    "country": "Японія"
  }, {
    "code": "KE",
    "country": "Кенія"
  }, {
    "code": "KG",
    "country": "Киргизстан"
  }, {
    "code": "KH",
    "country": "Камбоджа"
  }, {
    "code": "KI",
    "country": "Кірібаті"
  }, {
    "code": "KM",
    "country": "Комори"
  }, {
    "code": "KN",
    "country": "Сент-Кітс і Невіс"
  }, {
    "code": "KP",
    "country": "Північна Корея"
  }, {
    "code": "KR",
    "country": "Південна Корея"
  }, {
    "code": "KW",
    "country": "Кувейт"
  }, {
    "code": "KY",
    "country": "Кайманові Острови"
  }, {
    "code": "KZ",
    "country": "Казахстан"
  }, {
    "code": "LA",
    "country": "Лаос"
  }, {
    "code": "LB",
    "country": "Ліван"
  }, {
    "code": "LC",
    "country": "Сент-Люсія"
  }, {
    "code": "LI",
    "country": "Ліхтенштейн"
  }, {
    "code": "LK",
    "country": "Шрі-Ланка"
  }, {
    "code": "LR",
    "country": "Ліберія"
  }, {
    "code": "LS",
    "country": "Лесото"
  }, {
    "code": "LT",
    "country": "Литва"
  }, {
    "code": "LU",
    "country": "Люксембурґ"
  }, {
    "code": "LV",
    "country": "Латвія"
  }, {
    "code": "LY",
    "country": "Лівія"
  }, {
    "code": "MA",
    "country": "Марокко"
  }, {
    "code": "MC",
    "country": "Монако"
  }, {
    "code": "MD",
    "country": "Молдова"
  }, {
    "code": "ME",
    "country": "Чорногорія"
  }, {
    "code": "MF",
    "country": "Сен-Мартен"
  }, {
    "code": "MG",
    "country": "Мадагаскар"
  }, {
    "code": "MH",
    "country": "Маршаллові Острови"
  }, {
    "code": "MK",
    "country": "Північна Македонія"
  }, {
    "code": "ML",
    "country": "Малі"
  }, {
    "code": "MM",
    "country": "Мʼянма (Бірма)"
  }, {
    "code": "MN",
    "country": "Монголія"
  }, {
    "code": "MO",
    "country": "Макао, О.А.Р Китаю"
  }, {
    "code": "MP",
    "country": "Північні Маріанські Острови"
  }, {
    "code": "MQ",
    "country": "Мартініка"
  }, {
    "code": "MR",
    "country": "Мавританія"
  }, {
    "code": "MS",
    "country": "Монтсеррат"
  }, {
    "code": "MT",
    "country": "Мальта"
  }, {
    "code": "MU",
    "country": "Маврікій"
  }, {
    "code": "MV",
    "country": "Мальдіви"
  }, {
    "code": "MW",
    "country": "Малаві"
  }, {
    "code": "MX",
    "country": "Мексика"
  }, {
    "code": "MY",
    "country": "Малайзія"
  }, {
    "code": "MZ",
    "country": "Мозамбік"
  }, {
    "code": "NA",
    "country": "Намібія"
  }, {
    "code": "NC",
    "country": "Нова Каледонія"
  }, {
    "code": "NE",
    "country": "Нігер"
  }, {
    "code": "NF",
    "country": "Острів Норфолк"
  }, {
    "code": "NG",
    "country": "Нігерія"
  }, {
    "code": "NI",
    "country": "Нікараґуа"
  }, {
    "code": "NL",
    "country": "Нідерланди"
  }, {
    "code": "NO",
    "country": "Норвеґія"
  }, {
    "code": "NP",
    "country": "Непал"
  }, {
    "code": "NR",
    "country": "Науру"
  }, {
    "code": "NU",
    "country": "Ніуе"
  }, {
    "code": "NZ",
    "country": "Нова Зеландія"
  }, {
    "code": "OM",
    "country": "Оман"
  }, {
    "code": "PA",
    "country": "Панама"
  }, {
    "code": "PE",
    "country": "Перу"
  }, {
    "code": "PF",
    "country": "Французька Полінезія"
  }, {
    "code": "PG",
    "country": "Папуа-Нова Ґвінея"
  }, {
    "code": "PH",
    "country": "Філіппіни"
  }, {
    "code": "PK",
    "country": "Пакистан"
  }, {
    "code": "PL",
    "country": "Польща"
  }, {
    "code": "PM",
    "country": "Сен-Пʼєр і Мікелон"
  }, {
    "code": "PN",
    "country": "Острови Піткерн"
  }, {
    "code": "PR",
    "country": "Пуерто-Ріко"
  }, {
    "code": "PS",
    "country": "Палестинські території"
  }, {
    "code": "PT",
    "country": "Портуґалія"
  }, {
    "code": "PW",
    "country": "Палау"
  }, {
    "code": "PY",
    "country": "Параґвай"
  }, {
    "code": "QA",
    "country": "Катар"
  }, {
    "code": "RE",
    "country": "Реюньйон"
  }, {
    "code": "RO",
    "country": "Румунія"
  }, {
    "code": "RS",
    "country": "Сербія"
  }, {
    "code": "RU",
    "country": "Росія"
  }, {
    "code": "RW",
    "country": "Руанда"
  }, {
    "code": "SA",
    "country": "Саудівська Аравія"
  }, {
    "code": "SB",
    "country": "Соломонові Острови"
  }, {
    "code": "SC",
    "country": "Сейшельські Острови"
  }, {
    "code": "SD",
    "country": "Судан"
  }, {
    "code": "SE",
    "country": "Швеція"
  }, {
    "code": "SG",
    "country": "Сінгапур"
  }, {
    "code": "SH",
    "country": "Острів Святої Єлени"
  }, {
    "code": "SI",
    "country": "Словенія"
  }, {
    "code": "SJ",
    "country": "Шпіцберген та Ян-Маєн"
  }, {
    "code": "SK",
    "country": "Словаччина"
  }, {
    "code": "SL",
    "country": "Сьєрра-Леоне"
  }, {
    "code": "SM",
    "country": "Сан-Маріно"
  }, {
    "code": "SN",
    "country": "Сенегал"
  }, {
    "code": "SO",
    "country": "Сомалі"
  }, {
    "code": "SR",
    "country": "Сурінам"
  }, {
    "code": "SS",
    "country": "Південний Судан"
  }, {
    "code": "ST",
    "country": "Сан-Томе і Прінсіпі"
  }, {
    "code": "SV",
    "country": "Сальвадор"
  }, {
    "code": "SX",
    "country": "Сінт-Мартен"
  }, {
    "code": "SY",
    "country": "Сирія"
  }, {
    "code": "SZ",
    "country": "Есватіні"
  }, {
    "code": "TC",
    "country": "Острови Теркс і Кайкос"
  }, {
    "code": "TD",
    "country": "Чад"
  }, {
    "code": "TF",
    "country": "Французькі Південні Території"
  }, {
    "code": "TG",
    "country": "Того"
  }, {
    "code": "TH",
    "country": "Таїланд"
  }, {
    "code": "TJ",
    "country": "Таджикистан"
  }, {
    "code": "TK",
    "country": "Токелау"
  }, {
    "code": "TL",
    "country": "Тімор-Лешті"
  }, {
    "code": "TM",
    "country": "Туркменістан"
  }, {
    "code": "TN",
    "country": "Туніс"
  }, {
    "code": "TO",
    "country": "Тонґа"
  }, {
    "code": "TR",
    "country": "Туреччина"
  }, {
    "code": "TT",
    "country": "Трінідад і Тобаґо"
  }, {
    "code": "TV",
    "country": "Тувалу"
  }, {
    "code": "TW",
    "country": "Тайвань"
  }, {
    "code": "TZ",
    "country": "Танзанія"
  }, {
    "code": "UA",
    "country": "Україна"
  }, {
    "code": "UG",
    "country": "Уганда"
  }, {
    "code": "UM",
    "country": "Віддалені острови США"
  }, {
    "code": "US",
    "country": "Сполучені Штати"
  }, {
    "code": "UY",
    "country": "Уруґвай"
  }, {
    "code": "UZ",
    "country": "Узбекистан"
  }, {
    "code": "VA",
    "country": "Ватикан"
  }, {
    "code": "VC",
    "country": "Сент-Вінсент і Ґренадіни"
  }, {
    "code": "VE",
    "country": "Венесуела"
  }, {
    "code": "VI",
    "country": "Віргінські острови, США"
  }, {
    "code": "VN",
    "country": "Вʼєтнам"
  }, {
    "code": "VU",
    "country": "Вануату"
  }, {
    "code": "WF",
    "country": "Уолліс і Футуна"
  }, {
    "code": "WS",
    "country": "Самоа"
  }, {
    "code": "YE",
    "country": "Ємен"
  }, {
    "code": "YT",
    "country": "Майотта"
  }, {
    "code": "ZA",
    "country": "Південно-Африканська Республіка"
  }, {
    "code": "ZM",
    "country": "Замбія"
  }, {
    "code": "ZW",
    "country": "Зімбабве"
  }];
  _exports.default = _default;
});