define("huvepharma-aviapp-admin/models/me", ["exports", "huvepharma-aviapp-admin/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Me = _user.default.extend();

  var _default = Me;
  _exports.default = _default;
});