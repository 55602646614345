define("huvepharma-aviapp-admin/components/scoring/huve-scoring-toggle-input", ["exports", "@huvepharma/huvepharma-aviapp-components/components/scoring/scoring-toggle-input"], function (_exports, _scoringToggleInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scoringToggleInput.default;
    }
  });
});