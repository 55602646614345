define("huvepharma-aviapp-admin/serializers/flock-list", ["exports", "ember-data", "@ember/object/internals", "huvepharma-aviapp-admin/models/flock"], function (_exports, _emberData, _internals, _flock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  var FLOCKLIST_ATTR = 'flockList';
  var SERIALIZE_METHOD = 'serialize';
  var FLOCKS_ATTR = 'flocks';

  var _default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      flockList: {
        embedded: 'always'
      }
    },

    /**
     * Change the root JSON key when serializing
     * @param {String} attr
     * @param {String} method
     */
    keyForAttribute: function keyForAttribute(attr, method) {
      if (attr === FLOCKLIST_ATTR && method === SERIALIZE_METHOD) {
        return FLOCKS_ATTR;
      }

      return this._super(attr, method);
    },

    /**
     * Normally a save request expects a single response with an id
     * Here we generate an id based on the payload and pass the payload which is a normalized flock
     * After creating the tempory record we need to unload it
     * @param {DS.Store} store
     * @param {DS.Model} primaryModelClass
     * @param {Array} payload
     */
    normalizeSaveResponse: function normalizeSaveResponse(store, primaryModelClass, payload) {
      var documentHash = {
        data: {
          id: (0, _internals.guidFor)(payload)
        },
        included: []
      };
      var flockData = [];

      for (var i = 0, l = payload.length; i < l; i++) {
        var flock = payload[i];

        var _this$normalize2 = this.normalize(_flock.default, flock),
            data = _this$normalize2.data,
            included = _this$normalize2.included;

        data.id = flock._id;

        if (included) {
          var _documentHash$include;

          (_documentHash$include = documentHash.included).push.apply(_documentHash$include, _toConsumableArray(included));
        }

        flockData.push(data);
      }

      documentHash.included = flockData;
      return documentHash;
    }
  });

  _exports.default = _default;
});