define("huvepharma-aviapp-admin/validations/models/cocci-program", ["exports", "ember-changeset-validations/validators", "huvepharma-aviapp-admin/validations/change-description"], function (_exports, _validators, _changeDescription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _changeDescription.default)([(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 100
    })], 'label_name'),
    startDate: (0, _changeDescription.default)([(0, _validators.validatePresence)(true)], 'label_start_date'),
    endDate: (0, _changeDescription.default)([(0, _validators.validatePresence)(true)], 'label_end_date')
  };
  _exports.default = _default;
});