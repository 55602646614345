define("huvepharma-aviapp-admin/templates/users/invite", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "D7ceQU2K",
    "block": "[[[10,0],[14,0,\"container mod-content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-sm-12\"],[12],[1,\"\\n      \"],[10,\"h2\"],[14,0,\"heading\"],[12],[1,[28,[35,0],[\"invitation_invite_label_heading\"],null]],[13],[1,\"\\n      \"],[10,2],[12],[1,[28,[35,0],[\"invitation_invite_label_description\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-md-6\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"email\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"label_email\"],null]],[1,\"*\\n      \"],[13],[1,\"\\n      \"],[8,[39,1],[[24,1,\"email\"]],[[\"@value\"],[[30,0,[\"form\",\"email\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"col-md-6\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"label_role\"],null]],[1,\"*\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"field-select\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@content\",\"@selectedValue\"],[[30,0,[\"companyRoles\"]],[30,0,[\"form\",\"role\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,3],null,[[\"@company\",\"@accessRights\"],[[30,0,[\"company\"]],[30,0,[\"form\",\"accessRights\"]]]],null],[1,\"\\n  \"],[10,0],[14,0,\"row margin-top\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-sm-12\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"button\"],[24,4,\"button\"],[4,[38,4],[[30,0],\"handleSubmit\"],null],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"label_invite\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[11,\"button\"],[24,0,\"button button-red\"],[24,4,\"button\"],[4,[38,4],[[30,0],\"cancel\"],null],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"app_action_cancel\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"t\",\"input\",\"form/huve-select-box\",\"users/visibility-rights\",\"action\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/users/invite.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});