define("huvepharma-aviapp-admin/helpers/flockAgeFormat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatFlockAge = _exports.FlockAgeUnit = void 0;
  var FlockAgeUnit;
  _exports.FlockAgeUnit = FlockAgeUnit;

  (function (FlockAgeUnit) {
    FlockAgeUnit["Days"] = "Days";
    FlockAgeUnit["Weeks"] = "Weeks";
  })(FlockAgeUnit || (_exports.FlockAgeUnit = FlockAgeUnit = {}));

  var formatFlockAge = function formatFlockAge(age, unit, options) {
    if (unit === FlockAgeUnit.Weeks) {
      var weeks = Math.floor(age / 7);
      var days = age % 7;
      return "".concat(weeks, "'").concat(days);
    }

    if (options !== null && options !== void 0 && options.shouldHideDaysSuffix) {
      return "".concat(age);
    }

    return "".concat(age, "d");
  };

  _exports.formatFlockAge = formatFlockAge;
});