define("huvepharma-aviapp-admin/components/charts/base-chart-comments", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @showComments}}
    <div class="chart-comment">
      <FormBuilder::HuveBaseForm @changeset={{@changeset}} as |form changeset|>
        {{#form.field-textarea
          prop=(concat "comments." @module)
          label="base_chart_label_comment" as |field|
        }}
          {{field.input
            rows=2
            maxlength=480
            placeholder=(t "modals_withmessage_message")
          }}
          {{field.counter characterLimit=480 characterThreshold=10}}
        {{/form.field-textarea}}
      </FormBuilder::HuveBaseForm>
    </div>
  {{/if}}
  */
  {
    "id": "gNU2cmrB",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"chart-comment\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@changeset\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n\"],[6,[30,3,[\"field-textarea\"]],null,[[\"prop\",\"label\"],[[28,[37,2],[\"comments.\",[30,5]],null],\"base_chart_label_comment\"]],[[\"default\"],[[[[1,\"        \"],[1,[28,[30,6,[\"input\"]],null,[[\"rows\",\"maxlength\",\"placeholder\"],[2,480,[28,[37,3],[\"modals_withmessage_message\"],null]]]]],[1,\"\\n        \"],[1,[28,[30,6,[\"counter\"]],null,[[\"characterLimit\",\"characterThreshold\"],[480,10]]]],[1,\"\\n\"]],[6]]]]],[1,\"    \"]],[3,4]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@showComments\",\"@changeset\",\"form\",\"changeset\",\"@module\",\"field\"],false,[\"if\",\"form-builder/huve-base-form\",\"concat\",\"t\"]]",
    "moduleName": "huvepharma-aviapp-admin/components/charts/base-chart-comments.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});