define("huvepharma-aviapp-admin/helpers/id-generator", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IdGenerator = IdGenerator;
  _exports.default = void 0;

  function IdGenerator(values) {
    var id = '';
    values.forEach(function (value, index) {
      if (!value) {
        return;
      }

      if (index > 0) {
        id += '-';
      }

      id += value.toLowerCase().replace(/['"]/g, '').replace(/\s/g, '-');
    });
    return id;
  }

  var _default = (0, _helper.helper)(IdGenerator);

  _exports.default = _default;
});