define("huvepharma-aviapp-admin/base/models/client", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Client = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    clientId: _emberData.default.attr('string'),
    secret: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });

  var _default = Client;
  _exports.default = _default;
});