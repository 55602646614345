define("huvepharma-aviapp-admin/models/program", ["exports", "ember-data", "huvepharma-aviapp-admin/base/models/program"], function (_exports, _emberData, _program) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ProgramWithLinkedFlocks = _program.default.reopen({
    linkedFlocks: _emberData.default.attr('')
  });

  var _default = ProgramWithLinkedFlocks;
  _exports.default = _default;
});