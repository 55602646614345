define("huvepharma-aviapp-admin/controllers/programs/edit-cocci-program", ["exports", "@ember/object", "huvepharma-aviapp-admin/controllers/programs/add-cocci-program"], function (_exports, _object, _addCocciProgram) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _addCocciProgram.default.extend({
    programTypes: [],
    headerTitle: 'programs_edit_cocci_program',
    programType: 'cocciProgram',
    showChangeTypeConfirmation: false,
    init: function init() {
      this._super();

      var programTypes = [{
        value: 'cocci',
        label: (0, _object.get)(this, 'intl').t('label_cocci_program')
      }, {
        value: 'vaccination',
        label: (0, _object.get)(this, 'intl').t('label_vaccination')
      }];
      (0, _object.set)(this, 'programTypes', programTypes);
      (0, _object.set)(this, 'flocks', []);
    },
    actions: {
      close: function close() {
        (0, _object.get)(this, 'model.cocciProgram').rollbackAttributes();
        (0, _object.set)(this, 'selectedFlocks', []);
        this.transitionToRoute('programs');
      }
    }
  });

  _exports.default = _default;
});