define("huvepharma-aviapp-admin/base/enum/countries/en-pt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AC",
    "country": "Antigua e Barbuda"
  }, {
    "code": "AD",
    "country": "Andorra"
  }, {
    "code": "AE",
    "country": "Emirados Árabes Unidos"
  }, {
    "code": "AF",
    "country": "Afeganistão"
  }, {
    "code": "AG",
    "country": "Antígua e Barbuda"
  }, {
    "code": "AI",
    "country": "Anguila"
  }, {
    "code": "AL",
    "country": "Albânia"
  }, {
    "code": "AM",
    "country": "Armênia"
  }, {
    "code": "AO",
    "country": "Angola"
  }, {
    "code": "AQ",
    "country": "Antártida"
  }, {
    "code": "AR",
    "country": "Argentina"
  }, {
    "code": "AS",
    "country": "Samoa Americana"
  }, {
    "code": "AT",
    "country": "Áustria"
  }, {
    "code": "AU",
    "country": "Austrália"
  }, {
    "code": "AW",
    "country": "Aruba"
  }, {
    "code": "AX",
    "country": "Ilhas Aland"
  }, {
    "code": "AZ",
    "country": "Azerbaijão"
  }, {
    "code": "BA",
    "country": "Bósnia e Herzegovina"
  }, {
    "code": "BB",
    "country": "Barbados"
  }, {
    "code": "BD",
    "country": "Bangladesh"
  }, {
    "code": "BE",
    "country": "Bélgica"
  }, {
    "code": "BF",
    "country": "Burquina Faso"
  }, {
    "code": "BG",
    "country": "Bulgária"
  }, {
    "code": "BH",
    "country": "Bahrein"
  }, {
    "code": "BI",
    "country": "Burundi"
  }, {
    "code": "BJ",
    "country": "Benin"
  }, {
    "code": "BL",
    "country": "São Bartolomeu"
  }, {
    "code": "BM",
    "country": "Bermudas"
  }, {
    "code": "BN",
    "country": "Brunei"
  }, {
    "code": "BO",
    "country": "Bolívia"
  }, {
    "code": "BQ",
    "country": "Países Baixos Caribenhos"
  }, {
    "code": "BR",
    "country": "Brasil"
  }, {
    "code": "BS",
    "country": "Bahamas"
  }, {
    "code": "BT",
    "country": "Butão"
  }, {
    "code": "BV",
    "country": "Ilha Bouvet"
  }, {
    "code": "BW",
    "country": "Botsuana"
  }, {
    "code": "BY",
    "country": "Bielorrússia"
  }, {
    "code": "BZ",
    "country": "Belize"
  }, {
    "code": "CA",
    "country": "Canadá"
  }, {
    "code": "CC",
    "country": "Ilhas Cocos (Keeling)"
  }, {
    "code": "CD",
    "country": "Congo - Kinshasa"
  }, {
    "code": "CF",
    "country": "República Centro-Africana"
  }, {
    "code": "CG",
    "country": "República do Congo"
  }, {
    "code": "CH",
    "country": "Suíça"
  }, {
    "code": "CI",
    "country": "Costa do Marfim"
  }, {
    "code": "CK",
    "country": "Ilhas Cook"
  }, {
    "code": "CL",
    "country": "Chile"
  }, {
    "code": "CM",
    "country": "Camarões"
  }, {
    "code": "CN",
    "country": "China"
  }, {
    "code": "CO",
    "country": "Colômbia"
  }, {
    "code": "CR",
    "country": "Costa Rica"
  }, {
    "code": "CU",
    "country": "Cuba"
  }, {
    "code": "CV",
    "country": "Cabo Verde"
  }, {
    "code": "CW",
    "country": "Curaçao"
  }, {
    "code": "CX",
    "country": "Ilha Christmas"
  }, {
    "code": "CY",
    "country": "Chipre"
  }, {
    "code": "CZ",
    "country": "Tchéquia"
  }, {
    "code": "DE",
    "country": "Alemanha"
  }, {
    "code": "DG",
    "country": "Diego garcia"
  }, {
    "code": "DJ",
    "country": "Djibuti"
  }, {
    "code": "DK",
    "country": "Dinamarca"
  }, {
    "code": "DM",
    "country": "Dominica"
  }, {
    "code": "DO",
    "country": "República Dominicana"
  }, {
    "code": "DZ",
    "country": "Argélia"
  }, {
    "code": "EA",
    "country": "Organização de patentes da Eurásia"
  }, {
    "code": "EC",
    "country": "Equador"
  }, {
    "code": "EE",
    "country": "Estônia"
  }, {
    "code": "EG",
    "country": "Egito"
  }, {
    "code": "EH",
    "country": "Saara Ocidental"
  }, {
    "code": "ER",
    "country": "Eritreia"
  }, {
    "code": "ES",
    "country": "Espanha"
  }, {
    "code": "ET",
    "country": "Etiópia"
  }, {
    "code": "EZ",
    "country": "Zona Euro"
  }, {
    "code": "FI",
    "country": "Finlândia"
  }, {
    "code": "FJ",
    "country": "Fiji"
  }, {
    "code": "FK",
    "country": "Ilhas Malvinas"
  }, {
    "code": "FM",
    "country": "Micronésia"
  }, {
    "code": "FO",
    "country": "Ilhas Faroe"
  }, {
    "code": "FR",
    "country": "França"
  }, {
    "code": "GA",
    "country": "Gabão"
  }, {
    "code": "GB",
    "country": "Reino Unido"
  }, {
    "code": "GD",
    "country": "Granada"
  }, {
    "code": "GE",
    "country": "Geórgia"
  }, {
    "code": "GF",
    "country": "Guiana Francesa"
  }, {
    "code": "GG",
    "country": "Guernsey"
  }, {
    "code": "GH",
    "country": "Gana"
  }, {
    "code": "GI",
    "country": "Gibraltar"
  }, {
    "code": "GL",
    "country": "Groenlândia"
  }, {
    "code": "GM",
    "country": "Gâmbia"
  }, {
    "code": "GN",
    "country": "Guiné"
  }, {
    "code": "GP",
    "country": "Guadalupe"
  }, {
    "code": "GQ",
    "country": "Guiné Equatorial"
  }, {
    "code": "GR",
    "country": "Grécia"
  }, {
    "code": "GS",
    "country": "Ilhas Geórgia do Sul e Sandwich do Sul"
  }, {
    "code": "GT",
    "country": "Guatemala"
  }, {
    "code": "GU",
    "country": "Guam"
  }, {
    "code": "GW",
    "country": "Guiné-Bissau"
  }, {
    "code": "GY",
    "country": "Guiana"
  }, {
    "code": "HK",
    "country": "Hong Kong, RAE da China"
  }, {
    "code": "HM",
    "country": "Ilhas Heard e McDonald"
  }, {
    "code": "HN",
    "country": "Honduras"
  }, {
    "code": "HR",
    "country": "Croácia"
  }, {
    "code": "HT",
    "country": "Haiti"
  }, {
    "code": "HU",
    "country": "Hungria"
  }, {
    "code": "IC",
    "country": "Ilhas Canárias"
  }, {
    "code": "ID",
    "country": "Indonésia"
  }, {
    "code": "IE",
    "country": "Irlanda"
  }, {
    "code": "IL",
    "country": "Israel"
  }, {
    "code": "IM",
    "country": "Ilha de Man"
  }, {
    "code": "IN",
    "country": "Índia"
  }, {
    "code": "IO",
    "country": "Território Britânico do Oceano Índico"
  }, {
    "code": "IQ",
    "country": "Iraque"
  }, {
    "code": "IR",
    "country": "Irã"
  }, {
    "code": "IS",
    "country": "Islândia"
  }, {
    "code": "IT",
    "country": "Itália"
  }, {
    "code": "JE",
    "country": "Jersey"
  }, {
    "code": "JM",
    "country": "Jamaica"
  }, {
    "code": "JO",
    "country": "Jordânia"
  }, {
    "code": "JP",
    "country": "Japão"
  }, {
    "code": "KE",
    "country": "Quênia"
  }, {
    "code": "KG",
    "country": "Quirguistão"
  }, {
    "code": "KH",
    "country": "Camboja"
  }, {
    "code": "KI",
    "country": "Quiribati"
  }, {
    "code": "KM",
    "country": "Comores"
  }, {
    "code": "KN",
    "country": "São Cristóvão e Névis"
  }, {
    "code": "KP",
    "country": "Coreia do Norte"
  }, {
    "code": "KR",
    "country": "Coreia do Sul"
  }, {
    "code": "KW",
    "country": "Kuwait"
  }, {
    "code": "KY",
    "country": "Ilhas Cayman"
  }, {
    "code": "KZ",
    "country": "Cazaquistão"
  }, {
    "code": "LA",
    "country": "Laos"
  }, {
    "code": "LB",
    "country": "Líbano"
  }, {
    "code": "LC",
    "country": "Santa Lúcia"
  }, {
    "code": "LI",
    "country": "Liechtenstein"
  }, {
    "code": "LK",
    "country": "Sri Lanka"
  }, {
    "code": "LR",
    "country": "Libéria"
  }, {
    "code": "LS",
    "country": "Lesoto"
  }, {
    "code": "LT",
    "country": "Lituânia"
  }, {
    "code": "LU",
    "country": "Luxemburgo"
  }, {
    "code": "LV",
    "country": "Letônia"
  }, {
    "code": "LY",
    "country": "Líbia"
  }, {
    "code": "MA",
    "country": "Marrocos"
  }, {
    "code": "MC",
    "country": "Mônaco"
  }, {
    "code": "MD",
    "country": "Moldova"
  }, {
    "code": "ME",
    "country": "Montenegro"
  }, {
    "code": "MF",
    "country": "São Martinho"
  }, {
    "code": "MG",
    "country": "Madagascar"
  }, {
    "code": "MH",
    "country": "Ilhas Marshall"
  }, {
    "code": "MK",
    "country": "Macedônia do Norte"
  }, {
    "code": "ML",
    "country": "Mali"
  }, {
    "code": "MM",
    "country": "Mianmar (Birmânia)"
  }, {
    "code": "MN",
    "country": "Mongólia"
  }, {
    "code": "MO",
    "country": "Macau, RAE da China"
  }, {
    "code": "MP",
    "country": "Ilhas Marianas do Norte"
  }, {
    "code": "MQ",
    "country": "Martinica"
  }, {
    "code": "MR",
    "country": "Mauritânia"
  }, {
    "code": "MS",
    "country": "Montserrat"
  }, {
    "code": "MT",
    "country": "Malta"
  }, {
    "code": "MU",
    "country": "Maurício"
  }, {
    "code": "MV",
    "country": "Maldivas"
  }, {
    "code": "MW",
    "country": "Malaui"
  }, {
    "code": "MX",
    "country": "México"
  }, {
    "code": "MY",
    "country": "Malásia"
  }, {
    "code": "MZ",
    "country": "Moçambique"
  }, {
    "code": "NA",
    "country": "Namíbia"
  }, {
    "code": "NC",
    "country": "Nova Caledônia"
  }, {
    "code": "NE",
    "country": "Níger"
  }, {
    "code": "NF",
    "country": "Ilha Norfolk"
  }, {
    "code": "NG",
    "country": "Nigéria"
  }, {
    "code": "NI",
    "country": "Nicarágua"
  }, {
    "code": "NL",
    "country": "Países Baixos"
  }, {
    "code": "NO",
    "country": "Noruega"
  }, {
    "code": "NP",
    "country": "Nepal"
  }, {
    "code": "NR",
    "country": "Nauru"
  }, {
    "code": "NU",
    "country": "Niue"
  }, {
    "code": "NZ",
    "country": "Nova Zelândia"
  }, {
    "code": "OM",
    "country": "Omã"
  }, {
    "code": "PA",
    "country": "Panamá"
  }, {
    "code": "PE",
    "country": "Peru"
  }, {
    "code": "PF",
    "country": "Polinésia Francesa"
  }, {
    "code": "PG",
    "country": "Papua-Nova Guiné"
  }, {
    "code": "PH",
    "country": "Filipinas"
  }, {
    "code": "PK",
    "country": "Paquistão"
  }, {
    "code": "PL",
    "country": "Polônia"
  }, {
    "code": "PM",
    "country": "São Pedro e Miquelão"
  }, {
    "code": "PN",
    "country": "Ilhas Pitcairn"
  }, {
    "code": "PR",
    "country": "Porto Rico"
  }, {
    "code": "PS",
    "country": "Territórios palestinos"
  }, {
    "code": "PT",
    "country": "Portugal"
  }, {
    "code": "PW",
    "country": "Palau"
  }, {
    "code": "PY",
    "country": "Paraguai"
  }, {
    "code": "QA",
    "country": "Catar"
  }, {
    "code": "RE",
    "country": "Reunião"
  }, {
    "code": "RO",
    "country": "Romênia"
  }, {
    "code": "RS",
    "country": "Sérvia"
  }, {
    "code": "RU",
    "country": "Rússia"
  }, {
    "code": "RW",
    "country": "Ruanda"
  }, {
    "code": "SA",
    "country": "Arábia Saudita"
  }, {
    "code": "SB",
    "country": "Ilhas Salomão"
  }, {
    "code": "SC",
    "country": "Seicheles"
  }, {
    "code": "SD",
    "country": "Sudão"
  }, {
    "code": "SE",
    "country": "Suécia"
  }, {
    "code": "SG",
    "country": "Singapura"
  }, {
    "code": "SH",
    "country": "Santa Helena"
  }, {
    "code": "SI",
    "country": "Eslovênia"
  }, {
    "code": "SJ",
    "country": "Svalbard e Jan Mayen"
  }, {
    "code": "SK",
    "country": "Eslováquia"
  }, {
    "code": "SL",
    "country": "Serra Leoa"
  }, {
    "code": "SM",
    "country": "San Marino"
  }, {
    "code": "SN",
    "country": "Senegal"
  }, {
    "code": "SO",
    "country": "Somália"
  }, {
    "code": "SR",
    "country": "Suriname"
  }, {
    "code": "SS",
    "country": "Sudão do Sul"
  }, {
    "code": "ST",
    "country": "São Tomé e Príncipe"
  }, {
    "code": "SV",
    "country": "El Salvador"
  }, {
    "code": "SX",
    "country": "Sint Maarten"
  }, {
    "code": "SY",
    "country": "Síria"
  }, {
    "code": "SZ",
    "country": "Essuatíni"
  }, {
    "code": "TA",
    "country": "Tristão da cunha"
  }, {
    "code": "TC",
    "country": "Ilhas Turcas e Caicos"
  }, {
    "code": "TD",
    "country": "Chade"
  }, {
    "code": "TF",
    "country": "Territórios Franceses do Sul"
  }, {
    "code": "TG",
    "country": "Togo"
  }, {
    "code": "TH",
    "country": "Tailândia"
  }, {
    "code": "TJ",
    "country": "Tadjiquistão"
  }, {
    "code": "TK",
    "country": "Tokelau"
  }, {
    "code": "TL",
    "country": "Timor-Leste"
  }, {
    "code": "TM",
    "country": "Turcomenistão"
  }, {
    "code": "TN",
    "country": "Tunísia"
  }, {
    "code": "TO",
    "country": "Tonga"
  }, {
    "code": "TR",
    "country": "Turquia"
  }, {
    "code": "TT",
    "country": "Trinidad e Tobago"
  }, {
    "code": "TV",
    "country": "Tuvalu"
  }, {
    "code": "TW",
    "country": "Taiwan"
  }, {
    "code": "TZ",
    "country": "Tanzânia"
  }, {
    "code": "UA",
    "country": "Ucrânia"
  }, {
    "code": "UG",
    "country": "Uganda"
  }, {
    "code": "UM",
    "country": "Ilhas Menores Distantes dos EUA"
  }, {
    "code": "UN",
    "country": "Nações Unidas"
  }, {
    "code": "US",
    "country": "Estados Unidos"
  }, {
    "code": "UY",
    "country": "Uruguai"
  }, {
    "code": "UZ",
    "country": "Uzbequistão"
  }, {
    "code": "VA",
    "country": "Cidade do Vaticano"
  }, {
    "code": "VC",
    "country": "São Vicente e Granadinas"
  }, {
    "code": "VE",
    "country": "Venezuela"
  }, {
    "code": "VG",
    "country": "Ilhas Virgens Britânicas"
  }, {
    "code": "VI",
    "country": "Ilhas Virgens Americanas"
  }, {
    "code": "VN",
    "country": "Vietnã"
  }, {
    "code": "VU",
    "country": "Vanuatu"
  }, {
    "code": "WF",
    "country": "Wallis e Futuna"
  }, {
    "code": "WS",
    "country": "Samoa"
  }, {
    "code": "XK",
    "country": "Kosovo"
  }, {
    "code": "YE",
    "country": "Iêmen"
  }, {
    "code": "YT",
    "country": "Mayotte"
  }, {
    "code": "ZA",
    "country": "África do Sul"
  }, {
    "code": "ZM",
    "country": "Zâmbia"
  }, {
    "code": "ZW",
    "country": "Zimbábue"
  }];
  _exports.default = _default;
});