define("huvepharma-aviapp-admin/templates/components/search/search-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lSDcYTU9",
    "block": "[[[10,\"svg\"],[14,0,\"search-icon\"],[12],[1,\"\\n  \"],[10,\"use\"],[14,\"xlink:href\",\"#search\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[35,0],null,[[\"placeholder\",\"value\",\"key-up\",\"class\"],[[28,[37,1],[\"action_search\"],null],[33,2],[28,[37,3],[[30,0],\"search\"],null],\"search-button-input\"]]]],[1,\"\\n\"]],[],false,[\"input\",\"t\",\"search\",\"action\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/components/search/search-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});