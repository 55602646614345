define("huvepharma-aviapp-admin/base/enum/flock-sex-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "UNKNOWN": "unknown",
    "FEMALE": "female",
    "MALE": "male",
    "MIX": "mix"
  };
  _exports.default = _default;
});