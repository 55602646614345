define("huvepharma-aviapp-admin/templates/programs/add-program-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KBEn9s09",
    "block": "[[[6,[39,0],null,[[\"close\",\"headerTitle\",\"testId\"],[[28,[37,1],[[30,0],\"close\"],null],[28,[37,2],[[33,3]],null],\"program\"]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,4],null,[[\"icon\",\"label\",\"testId\",\"action\",\"isLoading\",\"noLoading\"],[\"save-as\",[28,[37,2],[\"action_save_as\"],null],\"program-save-as-button\",[28,[37,1],[[30,0],\"saveAs\"],null],[33,5,[\"isRunning\"]],true]]]],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"icon\",\"label\",\"action\",\"testId\",\"isLoading\",\"isDisabled\"],[\"save\",[28,[37,2],[\"action_save\"],null],[28,[37,1],[[30,0],\"save\"],null],\"program-save-button\",[33,6],false]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"header/huve-detail-header\",\"action\",\"t\",\"headerTitle\",\"header/huve-header-icon-button\",\"saveProgram\",\"isLoading\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/programs/add-program-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});