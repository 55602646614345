define("huvepharma-aviapp-admin/models/user", ["exports", "moment", "@ember/object", "@ember/service", "ember-data", "huvepharma-aviapp-admin/base/models/user"], function (_exports, _moment, _object, _service, _emberData, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var User = _user.default.reopen({
    session: (0, _service.inject)(),

    /**
     * Is set to true by the api if the user has an open invitation
     */
    isPending: _emberData.default.attr('boolean', {
      defaultValue: false
    }),

    /**
     * Invitations
     */
    invitations: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    /**
     * Companies
     */
    companies: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    /**
     * Notify the user (via email client in the API) that a property on this account changed
     */
    notifyUpdatedUser: _emberData.default.attr('raw', {
      defaultValue: null
    }),
    isCompanyBlocked: _emberData.default.attr('boolean', {
      defaultValue: false
    }),

    /**
     * Created at Hour
     */
    createdAtHour: (0, _object.computed)('createdAt', function () {
      return "".concat((0, _moment.default)(this.createdAt).hours(), ":").concat((0, _moment.default)(this.createdAt).minutes());
    }),

    /**
     * The full name
     */
    fullName: (0, _object.computed)('firstName', 'name', function () {
      return "".concat(this.name, ", ").concat(this.firstName);
    }),
    isHistorical: (0, _object.computed)('companies', 'session.data.loginCompany', function () {
      var currentCompanyId = (0, _object.get)(this, 'session.data.loginCompany._id');
      var company = (this.companies || []).find(function (c) {
        return c._id === currentCompanyId;
      });
      return !company;
    })
  });

  var _default = User;
  _exports.default = _default;
});