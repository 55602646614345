define("huvepharma-aviapp-admin/components/form/huve-field-number-step", ["exports", "@huvepharma/huvepharma-aviapp-components/components/form/field-number-step"], function (_exports, _fieldNumberStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fieldNumberStep.default;
    }
  });
});