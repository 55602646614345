define("huvepharma-aviapp-admin/base/enum/countries/en-tr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AC",
    "country": "Ascension Adası"
  }, {
    "code": "AD",
    "country": "Andorra"
  }, {
    "code": "AE",
    "country": "Birleşik Arap Emirlikleri"
  }, {
    "code": "AF",
    "country": "Afganistan"
  }, {
    "code": "AG",
    "country": "Antigua ve Barbuda"
  }, {
    "code": "AI",
    "country": "Anguilla"
  }, {
    "code": "AL",
    "country": "Arnavutluk"
  }, {
    "code": "AM",
    "country": "Ermenistan"
  }, {
    "code": "AO",
    "country": "Angola"
  }, {
    "code": "AQ",
    "country": "Antarktika"
  }, {
    "code": "AR",
    "country": "Arjantin"
  }, {
    "code": "AS",
    "country": "Amerikan Samoası"
  }, {
    "code": "AT",
    "country": "Avusturya"
  }, {
    "code": "AU",
    "country": "Avustralya"
  }, {
    "code": "AW",
    "country": "Aruba"
  }, {
    "code": "AX",
    "country": "Åland Adaları"
  }, {
    "code": "AZ",
    "country": "Azerbaycan"
  }, {
    "code": "BA",
    "country": "Bosna-Hersek"
  }, {
    "code": "BB",
    "country": "Barbados"
  }, {
    "code": "BD",
    "country": "Bangladeş"
  }, {
    "code": "BE",
    "country": "Belçika"
  }, {
    "code": "BF",
    "country": "Burkina Faso"
  }, {
    "code": "BG",
    "country": "Bulgaristan"
  }, {
    "code": "BH",
    "country": "Bahreyn"
  }, {
    "code": "BI",
    "country": "Burundi"
  }, {
    "code": "BJ",
    "country": "Benin"
  }, {
    "code": "BL",
    "country": "Saint Barthelemy"
  }, {
    "code": "BM",
    "country": "Bermuda"
  }, {
    "code": "BN",
    "country": "Brunei"
  }, {
    "code": "BO",
    "country": "Bolivya"
  }, {
    "code": "BQ",
    "country": "Karayip Hollanda"
  }, {
    "code": "BR",
    "country": "Brezilya"
  }, {
    "code": "BS",
    "country": "Bahamalar"
  }, {
    "code": "BT",
    "country": "Butan"
  }, {
    "code": "BW",
    "country": "Botsvana"
  }, {
    "code": "BY",
    "country": "Belarus"
  }, {
    "code": "BZ",
    "country": "Belize"
  }, {
    "code": "CA",
    "country": "Kanada"
  }, {
    "code": "CC",
    "country": "Cocos (Keeling) Adaları"
  }, {
    "code": "CD",
    "country": "Kongo - Kinşasa"
  }, {
    "code": "CF",
    "country": "Orta Afrika Cumhuriyeti"
  }, {
    "code": "CG",
    "country": "Kongo - Brazavil"
  }, {
    "code": "CH",
    "country": "İsviçre"
  }, {
    "code": "CI",
    "country": "Fildişi Sahili"
  }, {
    "code": "CK",
    "country": "Cook Adaları"
  }, {
    "code": "CL",
    "country": "Şili"
  }, {
    "code": "CM",
    "country": "Kamerun"
  }, {
    "code": "CN",
    "country": "Çin"
  }, {
    "code": "CO",
    "country": "Kolombiya"
  }, {
    "code": "CR",
    "country": "Kosta Rika"
  }, {
    "code": "CU",
    "country": "Küba"
  }, {
    "code": "CV",
    "country": "Cape Verde"
  }, {
    "code": "CW",
    "country": "Curaçao"
  }, {
    "code": "CX",
    "country": "Christmas Adası"
  }, {
    "code": "CY",
    "country": "Kıbrıs"
  }, {
    "code": "CZ",
    "country": "Çekya"
  }, {
    "code": "DE",
    "country": "Almanya"
  }, {
    "code": "DG",
    "country": "Diego Garcia"
  }, {
    "code": "DJ",
    "country": "Cibuti"
  }, {
    "code": "DK",
    "country": "Danimarka"
  }, {
    "code": "DM",
    "country": "Dominika"
  }, {
    "code": "DO",
    "country": "Dominik Cumhuriyeti"
  }, {
    "code": "DZ",
    "country": "Cezayir"
  }, {
    "code": "EA",
    "country": "Septe ve Melilla"
  }, {
    "code": "EC",
    "country": "Ekvador"
  }, {
    "code": "EE",
    "country": "Estonya"
  }, {
    "code": "EG",
    "country": "Mısır"
  }, {
    "code": "EH",
    "country": "Batı Sahra"
  }, {
    "code": "ER",
    "country": "Eritre"
  }, {
    "code": "ES",
    "country": "İspanya"
  }, {
    "code": "ET",
    "country": "Etiyopya"
  }, {
    "code": "EZ",
    "country": "Eurozone"
  }, {
    "code": "FI",
    "country": "Finlandiya"
  }, {
    "code": "FJ",
    "country": "Fiji"
  }, {
    "code": "FK",
    "country": "Falkland Adaları"
  }, {
    "code": "FM",
    "country": "Mikronezya"
  }, {
    "code": "FO",
    "country": "Faroe Adaları"
  }, {
    "code": "FR",
    "country": "Fransa"
  }, {
    "code": "GA",
    "country": "Gabon"
  }, {
    "code": "GB",
    "country": "Birleşik Krallık"
  }, {
    "code": "GD",
    "country": "Grenada"
  }, {
    "code": "GE",
    "country": "Gürcistan"
  }, {
    "code": "GF",
    "country": "Fransız Guyanası"
  }, {
    "code": "GG",
    "country": "Guernsey"
  }, {
    "code": "GH",
    "country": "Gana"
  }, {
    "code": "GI",
    "country": "Cebelitarık"
  }, {
    "code": "GL",
    "country": "Grönland"
  }, {
    "code": "GM",
    "country": "Gambiya"
  }, {
    "code": "GN",
    "country": "Gine"
  }, {
    "code": "GP",
    "country": "Guadalupe"
  }, {
    "code": "GQ",
    "country": "Ekvator Ginesi"
  }, {
    "code": "GR",
    "country": "Yunanistan"
  }, {
    "code": "GS",
    "country": "Güney Georgia ve Güney Sandwich Adaları"
  }, {
    "code": "GT",
    "country": "Guatemala"
  }, {
    "code": "GU",
    "country": "Guam"
  }, {
    "code": "GW",
    "country": "Gine-Bissau"
  }, {
    "code": "GY",
    "country": "Guyana"
  }, {
    "code": "HK",
    "country": "Çin Hong Kong ÖİB"
  }, {
    "code": "HN",
    "country": "Honduras"
  }, {
    "code": "HR",
    "country": "Hırvatistan"
  }, {
    "code": "HT",
    "country": "Haiti"
  }, {
    "code": "HU",
    "country": "Macaristan"
  }, {
    "code": "IC",
    "country": "Kanarya Adaları"
  }, {
    "code": "ID",
    "country": "Endonezya"
  }, {
    "code": "IE",
    "country": "İrlanda"
  }, {
    "code": "IL",
    "country": "İsrail"
  }, {
    "code": "IM",
    "country": "Man Adası"
  }, {
    "code": "IN",
    "country": "Hindistan"
  }, {
    "code": "IO",
    "country": "Britanya Hint Okyanusu Toprakları"
  }, {
    "code": "IQ",
    "country": "Irak"
  }, {
    "code": "IR",
    "country": "İran"
  }, {
    "code": "IS",
    "country": "İzlanda"
  }, {
    "code": "IT",
    "country": "İtalya"
  }, {
    "code": "JE",
    "country": "Jersey"
  }, {
    "code": "JM",
    "country": "Jamaika"
  }, {
    "code": "JO",
    "country": "Ürdün"
  }, {
    "code": "JP",
    "country": "Japonya"
  }, {
    "code": "KE",
    "country": "Kenya"
  }, {
    "code": "KG",
    "country": "Kırgızistan"
  }, {
    "code": "KH",
    "country": "Kamboçya"
  }, {
    "code": "KI",
    "country": "Kiribati"
  }, {
    "code": "KM",
    "country": "Komorlar"
  }, {
    "code": "KN",
    "country": "Saint Kitts ve Nevis"
  }, {
    "code": "KP",
    "country": "Kuzey Kore"
  }, {
    "code": "KR",
    "country": "Güney Kore"
  }, {
    "code": "KW",
    "country": "Kuveyt"
  }, {
    "code": "KY",
    "country": "Cayman Adaları"
  }, {
    "code": "KZ",
    "country": "Kazakistan"
  }, {
    "code": "LA",
    "country": "Laos"
  }, {
    "code": "LB",
    "country": "Lübnan"
  }, {
    "code": "LC",
    "country": "Saint Lucia"
  }, {
    "code": "LI",
    "country": "Liechtenstein"
  }, {
    "code": "LK",
    "country": "Sri Lanka"
  }, {
    "code": "LR",
    "country": "Liberya"
  }, {
    "code": "LS",
    "country": "Lesotho"
  }, {
    "code": "LT",
    "country": "Litvanya"
  }, {
    "code": "LU",
    "country": "Lüksemburg"
  }, {
    "code": "LV",
    "country": "Letonya"
  }, {
    "code": "LY",
    "country": "Libya"
  }, {
    "code": "MA",
    "country": "Fas"
  }, {
    "code": "MC",
    "country": "Monako"
  }, {
    "code": "MD",
    "country": "Moldova"
  }, {
    "code": "ME",
    "country": "Karadağ"
  }, {
    "code": "MF",
    "country": "Saint Martin"
  }, {
    "code": "MG",
    "country": "Madagaskar"
  }, {
    "code": "MH",
    "country": "Marshall Adaları"
  }, {
    "code": "MK",
    "country": "Makedonya"
  }, {
    "code": "ML",
    "country": "Mali"
  }, {
    "code": "MM",
    "country": "Myanmar (Burma)"
  }, {
    "code": "MN",
    "country": "Moğolistan"
  }, {
    "code": "MO",
    "country": "Çin Makao ÖİB"
  }, {
    "code": "MP",
    "country": "Kuzey Mariana Adaları"
  }, {
    "code": "MQ",
    "country": "Martinik"
  }, {
    "code": "MR",
    "country": "Moritanya"
  }, {
    "code": "MS",
    "country": "Montserrat"
  }, {
    "code": "MT",
    "country": "Malta"
  }, {
    "code": "MU",
    "country": "Mauritius"
  }, {
    "code": "MV",
    "country": "Maldivler"
  }, {
    "code": "MW",
    "country": "Malavi"
  }, {
    "code": "MX",
    "country": "Meksika"
  }, {
    "code": "MY",
    "country": "Malezya"
  }, {
    "code": "MZ",
    "country": "Mozambik"
  }, {
    "code": "NA",
    "country": "Namibya"
  }, {
    "code": "NC",
    "country": "Yeni Kaledonya"
  }, {
    "code": "NE",
    "country": "Nijer"
  }, {
    "code": "NF",
    "country": "Norfolk Adası"
  }, {
    "code": "NG",
    "country": "Nijerya"
  }, {
    "code": "NI",
    "country": "Nikaragua"
  }, {
    "code": "NL",
    "country": "Hollanda"
  }, {
    "code": "NO",
    "country": "Norveç"
  }, {
    "code": "NP",
    "country": "Nepal"
  }, {
    "code": "NR",
    "country": "Nauru"
  }, {
    "code": "NU",
    "country": "Niue"
  }, {
    "code": "NZ",
    "country": "Yeni Zelanda"
  }, {
    "code": "OM",
    "country": "Umman"
  }, {
    "code": "PA",
    "country": "Panama"
  }, {
    "code": "PE",
    "country": "Peru"
  }, {
    "code": "PF",
    "country": "Fransız Polinezyası"
  }, {
    "code": "PG",
    "country": "Papua Yeni Gine"
  }, {
    "code": "PH",
    "country": "Filipinler"
  }, {
    "code": "PK",
    "country": "Pakistan"
  }, {
    "code": "PL",
    "country": "Polonya"
  }, {
    "code": "PM",
    "country": "Saint Pierre ve Miquelon"
  }, {
    "code": "PN",
    "country": "Pitcairn Adaları"
  }, {
    "code": "PR",
    "country": "Porto Riko"
  }, {
    "code": "PS",
    "country": "Filistin Bölgeleri"
  }, {
    "code": "PT",
    "country": "Portekiz"
  }, {
    "code": "PW",
    "country": "Palau"
  }, {
    "code": "PY",
    "country": "Paraguay"
  }, {
    "code": "QA",
    "country": "Katar"
  }, {
    "code": "RE",
    "country": "Réunion"
  }, {
    "code": "RO",
    "country": "Romanya"
  }, {
    "code": "RS",
    "country": "Sırbistan"
  }, {
    "code": "RU",
    "country": "Rusya"
  }, {
    "code": "RW",
    "country": "Ruanda"
  }, {
    "code": "SA",
    "country": "Suudi Arabistan"
  }, {
    "code": "SB",
    "country": "Solomon Adaları"
  }, {
    "code": "SC",
    "country": "Seyşeller"
  }, {
    "code": "SD",
    "country": "Sudan"
  }, {
    "code": "SE",
    "country": "İsveç"
  }, {
    "code": "SG",
    "country": "Singapur"
  }, {
    "code": "SH",
    "country": "Saint Helena"
  }, {
    "code": "SI",
    "country": "Slovenya"
  }, {
    "code": "SJ",
    "country": "Svalbard ve Jan Mayen"
  }, {
    "code": "SK",
    "country": "Slovakya"
  }, {
    "code": "SL",
    "country": "Sierra Leone"
  }, {
    "code": "SM",
    "country": "San Marino"
  }, {
    "code": "SN",
    "country": "Senegal"
  }, {
    "code": "SO",
    "country": "Somali"
  }, {
    "code": "SR",
    "country": "Surinam"
  }, {
    "code": "SS",
    "country": "Güney Sudan"
  }, {
    "code": "ST",
    "country": "São Tomé ve Príncipe"
  }, {
    "code": "SV",
    "country": "El Salvador"
  }, {
    "code": "SX",
    "country": "Sint Maarten"
  }, {
    "code": "SY",
    "country": "Suriye"
  }, {
    "code": "SZ",
    "country": "Svaziland"
  }, {
    "code": "TA",
    "country": "Tristan da Cunha"
  }, {
    "code": "TC",
    "country": "Turks ve Caicos Adaları"
  }, {
    "code": "TD",
    "country": "Çad"
  }, {
    "code": "TF",
    "country": "Fransız Güney Toprakları"
  }, {
    "code": "TG",
    "country": "Togo"
  }, {
    "code": "TH",
    "country": "Tayland"
  }, {
    "code": "TJ",
    "country": "Tacikistan"
  }, {
    "code": "TK",
    "country": "Tokelau"
  }, {
    "code": "TL",
    "country": "Timor-Leste"
  }, {
    "code": "TM",
    "country": "Türkmenistan"
  }, {
    "code": "TN",
    "country": "Tunus"
  }, {
    "code": "TO",
    "country": "Tonga"
  }, {
    "code": "TR",
    "country": "Türkiye"
  }, {
    "code": "TT",
    "country": "Trinidad ve Tobago"
  }, {
    "code": "TV",
    "country": "Tuvalu"
  }, {
    "code": "TW",
    "country": "Tayvan"
  }, {
    "code": "TZ",
    "country": "Tanzanya"
  }, {
    "code": "UA",
    "country": "Ukrayna"
  }, {
    "code": "UG",
    "country": "Uganda"
  }, {
    "code": "UM",
    "country": "ABD Uzak Adaları"
  }, {
    "code": "UN",
    "country": "Birleşmiş Milletler"
  }, {
    "code": "US",
    "country": "Amerika Birleşik Devletleri"
  }, {
    "code": "UY",
    "country": "Uruguay"
  }, {
    "code": "UZ",
    "country": "Özbekistan"
  }, {
    "code": "VA",
    "country": "Vatikan"
  }, {
    "code": "VC",
    "country": "Saint Vincent ve Grenadinler"
  }, {
    "code": "VE",
    "country": "Venezuela"
  }, {
    "code": "VG",
    "country": "Britanya Virjin Adaları"
  }, {
    "code": "VI",
    "country": "ABD Virjin Adaları"
  }, {
    "code": "VN",
    "country": "Vietnam"
  }, {
    "code": "VU",
    "country": "Vanuatu"
  }, {
    "code": "WF",
    "country": "Wallis ve Futuna"
  }, {
    "code": "WS",
    "country": "Samoa"
  }, {
    "code": "XK",
    "country": "Kosova"
  }, {
    "code": "YE",
    "country": "Yemen"
  }, {
    "code": "YT",
    "country": "Mayotte"
  }, {
    "code": "ZA",
    "country": "Güney Afrika"
  }, {
    "code": "ZM",
    "country": "Zambiya"
  }, {
    "code": "ZW",
    "country": "Zimbabve"
  }];
  _exports.default = _default;
});