define("huvepharma-aviapp-admin/base/enum/farm-house-drink-system", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "NIPPLE": "nipple",
    "CUP": "cup",
    "BELL": "bell"
  };
  _exports.default = _default;
});