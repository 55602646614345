define("huvepharma-aviapp-admin/base/enum/daily-flock-card-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "mortality": {
      "deaths": ["morning", "afternoon", "total"],
      "size": ["morning", "afternoon", "total"],
      "skeletal": ["morning", "afternoon", "total"],
      "other": ["morning", "afternoon", "total"],
      "total": ["total", "ref"],
      "cum": ["cum"],
      "thinning": ["thinning"]
    },
    "feed_intake": {
      "feed_intake": ["daily", "daily_per_bird", "ref"]
    },
    "water_intake": {
      "water_intake": ["daily", "daily_per_bird", "ref"]
    },
    "water_feed": {
      "water_feed": ["actual", "ref"]
    },
    "body_weight": {
      "body_weight": ["weight_bird", "ref"]
    },
    "climate": {
      "temperature": ["min", "max", "ref"],
      "humidity": ["min", "max", "ref"],
      "nh3": ["max", "ref"]
    },
    "skin_color": {
      "skin_color": ["yellow", "red", "luminosity"]
    }
  };
  _exports.default = _default;
});