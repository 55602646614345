define("huvepharma-aviapp-admin/components/charts/base-chart-results-table", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Toggle::HuveToggleVisible @defaultVisible={{false}} as |toggle|>
    <Header::HuveActionableLabelHeader
      @title={{if toggle.visible @hideTitle @showTitle}}
      @onCollapse={{toggle.toggle}}
      @collapsed={{if toggle.visible false true}}
      @subheader={{true}}
    />
  
    {{#if toggle.visible}}
      <Tables::HuveQuickChartTable
        @table={{@table}}
        @module={{@module}}
        @accentedRowIndexes={{@accentedRowIndexes}}
        @accentLastRow={{true}}
      />
    {{/if}}
  </Toggle::HuveToggleVisible>
  */
  {
    "id": "bOiTTVYM",
    "block": "[[[8,[39,0],null,[[\"@defaultVisible\"],[false]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@title\",\"@onCollapse\",\"@collapsed\",\"@subheader\"],[[52,[30,1,[\"visible\"]],[30,2],[30,3]],[30,1,[\"toggle\"]],[52,[30,1,[\"visible\"]],false,true],true]],null],[1,\"\\n\\n\"],[41,[30,1,[\"visible\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@table\",\"@module\",\"@accentedRowIndexes\",\"@accentLastRow\"],[[30,4],[30,5],[30,6],true]],null],[1,\"\\n\"]],[]],null]],[1]]]]]],[\"toggle\",\"@hideTitle\",\"@showTitle\",\"@table\",\"@module\",\"@accentedRowIndexes\"],false,[\"toggle/huve-toggle-visible\",\"header/huve-actionable-label-header\",\"if\",\"tables/huve-quick-chart-table\"]]",
    "moduleName": "huvepharma-aviapp-admin/components/charts/base-chart-results-table.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});