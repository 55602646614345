define("huvepharma-aviapp-admin/base/modules/technical-modules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var technicalModules = [];
  var technical = {
    id: "technical",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "technical",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  var technical_technicalAvg = {
    id: "technicalAvg",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "technicalAvg",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  var technical_technicalAvg_mortalityAvg = {
    id: "mortalityAvg",
    abbreviation: "mortality-avg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, 15],
    info: [],
    name: "mortalityAvg",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalAvg.modules.push(technical_technicalAvg_mortalityAvg);
  var technical_technicalAvg_adgAvg = {
    id: "adgAvg",
    abbreviation: "adg-avg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, 70],
    info: [],
    name: "adgAvg",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalAvg.modules.push(technical_technicalAvg_adgAvg);
  var technical_technicalAvg_fcrAvg = {
    id: "fcrAvg",
    abbreviation: "fcr-avg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, 4],
    info: [],
    name: "fcrAvg",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalAvg.modules.push(technical_technicalAvg_fcrAvg);
  var technical_technicalAvg_epefAvg = {
    id: "epefAvg",
    abbreviation: "epef-avg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, 500],
    info: [],
    name: "epefAvg",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalAvg.modules.push(technical_technicalAvg_epefAvg);
  var technical_technicalAvg_slaughterWeightAvg = {
    id: "slaughterWeightAvg",
    abbreviation: "slaughterWeight-avg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "slaughterWeightAvg",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalAvg.modules.push(technical_technicalAvg_slaughterWeightAvg);
  var technical_technicalAvg_ageAtSlaughterAvg = {
    id: "ageAtSlaughterAvg",
    abbreviation: "ageAtSlaughter-avg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "ageAtSlaughterAvg",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalAvg.modules.push(technical_technicalAvg_ageAtSlaughterAvg);
  technical.modules.push(technical_technicalAvg);
  var technical_technicalDetail = {
    id: "technicalDetail",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "technicalDetail",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  var technical_technicalDetail_totalMortality = {
    id: "totalMortality",
    abbreviation: "mor",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "totalMortality",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalDetail.modules.push(technical_technicalDetail_totalMortality);
  var technical_technicalDetail_feedIntakeDailyPerBird = {
    id: "feedIntakeDailyPerBird",
    abbreviation: "fi",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, 500],
    info: [],
    name: "feedIntakeDailyPerBird",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalDetail.modules.push(technical_technicalDetail_feedIntakeDailyPerBird);
  var technical_technicalDetail_waterIntakeDailyPerBird = {
    id: "waterIntakeDailyPerBird",
    abbreviation: "wi",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, 500],
    info: [],
    name: "waterIntakeDailyPerBird",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalDetail.modules.push(technical_technicalDetail_waterIntakeDailyPerBird);
  var technical_technicalDetail_litterQuality = {
    id: "litterQuality",
    abbreviation: "lq",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "litterQuality",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalDetail.modules.push(technical_technicalDetail_litterQuality);
  var technical_technicalDetail_bodyWeight = {
    id: "bodyWeight",
    abbreviation: "weight-t",
    dataset: [{
      "value": 0
    }],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "bodyWeight",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: true,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoresStripped = scores.filter(scores, function (o) {
        return o !== 0;
      });
      return scoresStripped.reduce(function (sum, score) {
        return sum + score;
      }, 0) / scoresStripped.length;
    },
    modules: []
  };
  technical_technicalDetail.modules.push(technical_technicalDetail_bodyWeight);
  technical.modules.push(technical_technicalDetail);
  var technical_technicalOpg = {
    id: "technicalOpg",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "technicalOpg",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  var technical_technicalOpg_eAcerVulina = {
    id: "eAcerVulina",
    abbreviation: "e.ace-opg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "eAcerVulina",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_eAcerVulina);
  var technical_technicalOpg_eMaxima = {
    id: "eMaxima",
    abbreviation: "e.max-opg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "eMaxima",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_eMaxima);
  var technical_technicalOpg_eTenella = {
    id: "eTenella",
    abbreviation: "e.ten-opg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "eTenella",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_eTenella);
  var technical_technicalOpg_eBrunetti = {
    id: "eBrunetti",
    abbreviation: "e.bru-opg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "eBrunetti",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_eBrunetti);
  var technical_technicalOpg_eMitis = {
    id: "eMitis",
    abbreviation: "e.mit-opg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "eMitis",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_eMitis);
  var technical_technicalOpg_eNecatrixPreacox = {
    id: "eNecatrixPreacox",
    abbreviation: "e.np-opg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "eNecatrixPreacox",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_eNecatrixPreacox);
  var technical_technicalOpg_eMeleagrimitisAdenoeides = {
    id: "eMeleagrimitisAdenoeides",
    abbreviation: "e.melade-opg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "eMeleagrimitisAdenoeides",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_eMeleagrimitisAdenoeides);
  var technical_technicalOpg_eMeleagridisGallopavonis = {
    id: "eMeleagridisGallopavonis",
    abbreviation: "e.melgal-opg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "eMeleagridisGallopavonis",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_eMeleagridisGallopavonis);
  var technical_technicalOpg_eInnocua = {
    id: "eInnocua",
    abbreviation: "e.inn-opg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "eInnocua",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_eInnocua);
  var technical_technicalOpg_eDispersa = {
    id: "eDispersa",
    abbreviation: "e.dis-opg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "eDispersa",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_eDispersa);
  var technical_technicalOpg_total = {
    id: "total",
    abbreviation: "tot-opg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "total",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_total);
  var technical_technicalOpg_epgAscaridia = {
    id: "epgAscaridia",
    abbreviation: "asc-epg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "epgAscaridia",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_epgAscaridia);
  var technical_technicalOpg_epgHeterakis = {
    id: "epgHeterakis",
    abbreviation: "het-epg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "epgHeterakis",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_epgHeterakis);
  var technical_technicalOpg_epgCapillaria = {
    id: "epgCapillaria",
    abbreviation: "cap-epg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "epgCapillaria",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_epgCapillaria);
  var technical_technicalOpg_epgTotal = {
    id: "epgTotal",
    abbreviation: "tot-epg",
    dataset: [],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "epgTotal",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  technical_technicalOpg.modules.push(technical_technicalOpg_epgTotal);
  technical.modules.push(technical_technicalOpg);
  technicalModules.push(technical);
  var _default = technicalModules;
  _exports.default = _default;
});