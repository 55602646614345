define("huvepharma-aviapp-admin/utils/fingerprint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isTablet = isTablet;

  /**
   * Determines if the browser is tablet or mobile based
   * @returns {Boolean} true if the device is Android, iOS, Blackberry etc.
   */
  function isTablet() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
});