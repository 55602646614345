define("huvepharma-aviapp-admin/models/farm", ["exports", "ember-data", "huvepharma-aviapp-admin/base/models/farm"], function (_exports, _emberData, _farm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Farm = _farm.default.extend();

  Farm.reopen({
    flocks: _emberData.default.hasMany('flock', {
      async: true
    })
  });
  var _default = Farm;
  _exports.default = _default;
});