define("huvepharma-aviapp-admin/routes/clients/index", ["exports", "rsvp", "@ember/service", "huvepharma-aviapp-admin/routes/auth/authenticated-superuser"], function (_exports, _rsvp, _service, _authenticatedSuperuser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSuperuser.default.extend({
    metrics: (0, _service.inject)(),

    /**
     * Populate the model
     * @returns {Test.Promise|*|RSVP.Promise} Resolves to companies
     */
    model: function model() {
      return this.store.query('company', {});
    },

    /**
     * Fetches owner for all companies
     * @param companies
     * @return {Array} companies
     */
    afterModel: function afterModel(companies) {
      this.metrics.trackPage({
        page: '/Admin/Clients',
        title: 'Clients Screen'
      });
      return (0, _rsvp.allSettled)(companies.map(function (company) {
        return company.get('owner');
      })).then(function () {
        return companies;
      });
    },

    /**
     * Set controller variables
     * @param controller Client controller
     * @param model Companies
     */
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('approvedClients', model.filterBy('isApproved', true));
      controller.set('displayedClients', controller.approvedClients);
    }
  });

  _exports.default = _default;
});