define("huvepharma-aviapp-admin/controllers/header/navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSecondaryNavItems = _exports.getPrimaryNavItems = void 0;

  var getDashboardNavItem = function getDashboardNavItem(intl) {
    return {
      route: 'dashboard',
      icon: 'dashboard',
      label: intl.t('navigation_dashboard'),
      testId: 'dashboard-nav-button'
    };
  };

  var getClientsNavItem = function getClientsNavItem(intl) {
    return {
      route: 'clients',
      icon: 'clients',
      label: intl.t('navigation_clients'),
      testId: 'clients-nav-button'
    };
  };

  var getUsersNavItem = function getUsersNavItem(intl) {
    return {
      route: 'users',
      icon: 'users',
      label: intl.t('navigation_users'),
      testId: 'users-nav-button'
    };
  };

  var getCompanyNavItem = function getCompanyNavItem(intl) {
    return {
      route: 'company',
      icon: 'company',
      label: intl.t('navigation_company'),
      testId: 'company-nav-button'
    };
  };

  var getFeaturesNavItem = function getFeaturesNavItem(intl) {
    return {
      route: 'features',
      icon: 'features',
      label: intl.t('navigation_features')
    };
  };

  var getProgramsNavItem = function getProgramsNavItem(intl) {
    return {
      route: 'programs',
      icon: 'programs',
      label: intl.t('navigation_programs'),
      testId: 'programs-nav-button'
    };
  };

  var getSettingsNavItem = function getSettingsNavItem(intl) {
    return {
      route: 'settings',
      icon: 'settings',
      label: intl.t('label_settings'),
      testId: 'settings-nav-button',
      title: 'Settings'
    };
  };

  var getAboutNavItem = function getAboutNavItem(intl) {
    return {
      route: 'about',
      icon: 'info-filled',
      label: intl.t('app_action_about'),
      testId: 'settings-about-button',
      title: 'About'
    };
  };

  var getPrimaryNavItems = function getPrimaryNavItems(intl, isSuperUser) {
    var primaryNavItems = [];

    if (isSuperUser) {
      primaryNavItems.push(getDashboardNavItem(intl), getClientsNavItem(intl));
    }

    primaryNavItems.push(getUsersNavItem(intl));

    if (!isSuperUser) {
      primaryNavItems.push(getCompanyNavItem(intl), getFeaturesNavItem(intl), getProgramsNavItem(intl));
    }

    return primaryNavItems;
  };

  _exports.getPrimaryNavItems = getPrimaryNavItems;

  var getSecondaryNavItems = function getSecondaryNavItems(intl) {
    return [getSettingsNavItem(intl), getAboutNavItem(intl)];
  };

  _exports.getSecondaryNavItems = getSecondaryNavItems;
});