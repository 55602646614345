define("huvepharma-aviapp-admin/templates/settings/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QafBVz4B",
    "block": "[[[41,[30,0,[\"model\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@changeset\",\"@locales\",\"@model\",\"@logout\"],[[30,0,[\"changeset\"]],[30,0,[\"locales\"]],[30,0,[\"model\"]],[30,0,[\"logout\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"settings/settings-form\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/settings/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});