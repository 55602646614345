define("huvepharma-aviapp-admin/models/cocci-phase", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    startDay: 0,
    endDay: null,
    brand: '',
    dosage: ''
  });

  _exports.default = _default;
});