define("huvepharma-aviapp-admin/base/enum/countries/en-ru", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AC",
    "country": "о-в Вознесения"
  }, {
    "code": "AD",
    "country": "Андорра"
  }, {
    "code": "AE",
    "country": "ОАЭ"
  }, {
    "code": "AF",
    "country": "Афганистан"
  }, {
    "code": "AG",
    "country": "Антигуа и Барбуда"
  }, {
    "code": "AI",
    "country": "Ангилья"
  }, {
    "code": "AL",
    "country": "Албания"
  }, {
    "code": "AM",
    "country": "Армения"
  }, {
    "code": "AO",
    "country": "Ангола"
  }, {
    "code": "AQ",
    "country": "Антарктида"
  }, {
    "code": "AR",
    "country": "Аргентина"
  }, {
    "code": "AS",
    "country": "Американское Самоа"
  }, {
    "code": "AT",
    "country": "Австрия"
  }, {
    "code": "AU",
    "country": "Австралия"
  }, {
    "code": "AW",
    "country": "Аруба"
  }, {
    "code": "AX",
    "country": "Аландские о-ва"
  }, {
    "code": "AZ",
    "country": "Азербайджан"
  }, {
    "code": "BA",
    "country": "Босния и Герцеговина"
  }, {
    "code": "BB",
    "country": "Барбадос"
  }, {
    "code": "BD",
    "country": "Бангладеш"
  }, {
    "code": "BE",
    "country": "Бельгия"
  }, {
    "code": "BF",
    "country": "Буркина-Фасо"
  }, {
    "code": "BG",
    "country": "Болгария"
  }, {
    "code": "BH",
    "country": "Бахрейн"
  }, {
    "code": "BI",
    "country": "Бурунди"
  }, {
    "code": "BJ",
    "country": "Бенин"
  }, {
    "code": "BL",
    "country": "Сен-Бартелеми"
  }, {
    "code": "BM",
    "country": "Бермуды"
  }, {
    "code": "BN",
    "country": "Бруней-Даруссалам"
  }, {
    "code": "BO",
    "country": "Боливия"
  }, {
    "code": "BQ",
    "country": "Бонэйр, Синт-Эстатиус и Саба"
  }, {
    "code": "BR",
    "country": "Бразилия"
  }, {
    "code": "BS",
    "country": "Багамы"
  }, {
    "code": "BT",
    "country": "Бутан"
  }, {
    "code": "BW",
    "country": "Ботсвана"
  }, {
    "code": "BY",
    "country": "Беларусь"
  }, {
    "code": "BZ",
    "country": "Белиз"
  }, {
    "code": "CA",
    "country": "Канада"
  }, {
    "code": "CC",
    "country": "Кокосовые о-ва"
  }, {
    "code": "CD",
    "country": "Конго - Киншаса"
  }, {
    "code": "CF",
    "country": "ЦАР"
  }, {
    "code": "CG",
    "country": "Конго - Браззавиль"
  }, {
    "code": "CH",
    "country": "Швейцария"
  }, {
    "code": "CI",
    "country": "Кот-д’Ивуар"
  }, {
    "code": "CK",
    "country": "Острова Кука"
  }, {
    "code": "CL",
    "country": "Чили"
  }, {
    "code": "CM",
    "country": "Камерун"
  }, {
    "code": "CN",
    "country": "Китай"
  }, {
    "code": "CO",
    "country": "Колумбия"
  }, {
    "code": "CR",
    "country": "Коста-Рика"
  }, {
    "code": "CU",
    "country": "Куба"
  }, {
    "code": "CV",
    "country": "Кабо-Верде"
  }, {
    "code": "CW",
    "country": "Кюрасао"
  }, {
    "code": "CX",
    "country": "о-в Рождества"
  }, {
    "code": "CY",
    "country": "Кипр"
  }, {
    "code": "CZ",
    "country": "Чехия"
  }, {
    "code": "DE",
    "country": "Германия"
  }, {
    "code": "DG",
    "country": "Диего-Гарсия"
  }, {
    "code": "DJ",
    "country": "Джибути"
  }, {
    "code": "DK",
    "country": "Дания"
  }, {
    "code": "DM",
    "country": "Доминика"
  }, {
    "code": "DO",
    "country": "Доминиканская Республика"
  }, {
    "code": "DZ",
    "country": "Алжир"
  }, {
    "code": "EA",
    "country": "Сеута и Мелилья"
  }, {
    "code": "EC",
    "country": "Эквадор"
  }, {
    "code": "EE",
    "country": "Эстония"
  }, {
    "code": "EG",
    "country": "Египет"
  }, {
    "code": "EH",
    "country": "Западная Сахара"
  }, {
    "code": "ER",
    "country": "Эритрея"
  }, {
    "code": "ES",
    "country": "Испания"
  }, {
    "code": "ET",
    "country": "Эфиопия"
  }, {
    "code": "EZ",
    "country": "Eurozone"
  }, {
    "code": "FI",
    "country": "Финляндия"
  }, {
    "code": "FJ",
    "country": "Фиджи"
  }, {
    "code": "FK",
    "country": "Фолклендские о-ва"
  }, {
    "code": "FM",
    "country": "Федеративные Штаты Микронезии"
  }, {
    "code": "FO",
    "country": "Фарерские о-ва"
  }, {
    "code": "FR",
    "country": "Франция"
  }, {
    "code": "GA",
    "country": "Габон"
  }, {
    "code": "GB",
    "country": "Великобритания"
  }, {
    "code": "GD",
    "country": "Гренада"
  }, {
    "code": "GE",
    "country": "Грузия"
  }, {
    "code": "GF",
    "country": "Французская Гвиана"
  }, {
    "code": "GG",
    "country": "Гернси"
  }, {
    "code": "GH",
    "country": "Гана"
  }, {
    "code": "GI",
    "country": "Гибралтар"
  }, {
    "code": "GL",
    "country": "Гренландия"
  }, {
    "code": "GM",
    "country": "Гамбия"
  }, {
    "code": "GN",
    "country": "Гвинея"
  }, {
    "code": "GP",
    "country": "Гваделупа"
  }, {
    "code": "GQ",
    "country": "Экваториальная Гвинея"
  }, {
    "code": "GR",
    "country": "Греция"
  }, {
    "code": "GS",
    "country": "Южная Георгия и Южные Сандвичевы о-ва"
  }, {
    "code": "GT",
    "country": "Гватемала"
  }, {
    "code": "GU",
    "country": "Гуам"
  }, {
    "code": "GW",
    "country": "Гвинея-Бисау"
  }, {
    "code": "GY",
    "country": "Гайана"
  }, {
    "code": "HK",
    "country": "Гонконг (специальный административный район)"
  }, {
    "code": "HN",
    "country": "Гондурас"
  }, {
    "code": "HR",
    "country": "Хорватия"
  }, {
    "code": "HT",
    "country": "Гаити"
  }, {
    "code": "HU",
    "country": "Венгрия"
  }, {
    "code": "IC",
    "country": "Канарские о-ва"
  }, {
    "code": "ID",
    "country": "Индонезия"
  }, {
    "code": "IE",
    "country": "Ирландия"
  }, {
    "code": "IL",
    "country": "Израиль"
  }, {
    "code": "IM",
    "country": "о-в Мэн"
  }, {
    "code": "IN",
    "country": "Индия"
  }, {
    "code": "IO",
    "country": "Британская территория в Индийском океане"
  }, {
    "code": "IQ",
    "country": "Ирак"
  }, {
    "code": "IR",
    "country": "Иран"
  }, {
    "code": "IS",
    "country": "Исландия"
  }, {
    "code": "IT",
    "country": "Италия"
  }, {
    "code": "JE",
    "country": "Джерси"
  }, {
    "code": "JM",
    "country": "Ямайка"
  }, {
    "code": "JO",
    "country": "Иордания"
  }, {
    "code": "JP",
    "country": "Япония"
  }, {
    "code": "KE",
    "country": "Кения"
  }, {
    "code": "KG",
    "country": "Киргизия"
  }, {
    "code": "KH",
    "country": "Камбоджа"
  }, {
    "code": "KI",
    "country": "Кирибати"
  }, {
    "code": "KM",
    "country": "Коморы"
  }, {
    "code": "KN",
    "country": "Сент-Китс и Невис"
  }, {
    "code": "KP",
    "country": "КНДР"
  }, {
    "code": "KR",
    "country": "Республика Корея"
  }, {
    "code": "KW",
    "country": "Кувейт"
  }, {
    "code": "KY",
    "country": "Каймановы о-ва"
  }, {
    "code": "KZ",
    "country": "Казахстан"
  }, {
    "code": "LA",
    "country": "Лаос"
  }, {
    "code": "LB",
    "country": "Ливан"
  }, {
    "code": "LC",
    "country": "Сент-Люсия"
  }, {
    "code": "LI",
    "country": "Лихтенштейн"
  }, {
    "code": "LK",
    "country": "Шри-Ланка"
  }, {
    "code": "LR",
    "country": "Либерия"
  }, {
    "code": "LS",
    "country": "Лесото"
  }, {
    "code": "LT",
    "country": "Литва"
  }, {
    "code": "LU",
    "country": "Люксембург"
  }, {
    "code": "LV",
    "country": "Латвия"
  }, {
    "code": "LY",
    "country": "Ливия"
  }, {
    "code": "MA",
    "country": "Марокко"
  }, {
    "code": "MC",
    "country": "Монако"
  }, {
    "code": "MD",
    "country": "Молдова"
  }, {
    "code": "ME",
    "country": "Черногория"
  }, {
    "code": "MF",
    "country": "Сен-Мартен"
  }, {
    "code": "MG",
    "country": "Мадагаскар"
  }, {
    "code": "MH",
    "country": "Маршалловы Острова"
  }, {
    "code": "MK",
    "country": "Македония"
  }, {
    "code": "ML",
    "country": "Мали"
  }, {
    "code": "MM",
    "country": "Мьянма (Бирма)"
  }, {
    "code": "MN",
    "country": "Монголия"
  }, {
    "code": "MO",
    "country": "Макао (специальный административный район)"
  }, {
    "code": "MP",
    "country": "Северные Марианские о-ва"
  }, {
    "code": "MQ",
    "country": "Мартиника"
  }, {
    "code": "MR",
    "country": "Мавритания"
  }, {
    "code": "MS",
    "country": "Монтсеррат"
  }, {
    "code": "MT",
    "country": "Мальта"
  }, {
    "code": "MU",
    "country": "Маврикий"
  }, {
    "code": "MV",
    "country": "Мальдивы"
  }, {
    "code": "MW",
    "country": "Малави"
  }, {
    "code": "MX",
    "country": "Мексика"
  }, {
    "code": "MY",
    "country": "Малайзия"
  }, {
    "code": "MZ",
    "country": "Мозамбик"
  }, {
    "code": "NA",
    "country": "Намибия"
  }, {
    "code": "NC",
    "country": "Новая Каледония"
  }, {
    "code": "NE",
    "country": "Нигер"
  }, {
    "code": "NF",
    "country": "о-в Норфолк"
  }, {
    "code": "NG",
    "country": "Нигерия"
  }, {
    "code": "NI",
    "country": "Никарагуа"
  }, {
    "code": "NL",
    "country": "Нидерланды"
  }, {
    "code": "NO",
    "country": "Норвегия"
  }, {
    "code": "NP",
    "country": "Непал"
  }, {
    "code": "NR",
    "country": "Науру"
  }, {
    "code": "NU",
    "country": "Ниуэ"
  }, {
    "code": "NZ",
    "country": "Новая Зеландия"
  }, {
    "code": "OM",
    "country": "Оман"
  }, {
    "code": "PA",
    "country": "Панама"
  }, {
    "code": "PE",
    "country": "Перу"
  }, {
    "code": "PF",
    "country": "Французская Полинезия"
  }, {
    "code": "PG",
    "country": "Папуа – Новая Гвинея"
  }, {
    "code": "PH",
    "country": "Филиппины"
  }, {
    "code": "PK",
    "country": "Пакистан"
  }, {
    "code": "PL",
    "country": "Польша"
  }, {
    "code": "PM",
    "country": "Сен-Пьер и Микелон"
  }, {
    "code": "PN",
    "country": "острова Питкэрн"
  }, {
    "code": "PR",
    "country": "Пуэрто-Рико"
  }, {
    "code": "PS",
    "country": "Палестинские территории"
  }, {
    "code": "PT",
    "country": "Португалия"
  }, {
    "code": "PW",
    "country": "Палау"
  }, {
    "code": "PY",
    "country": "Парагвай"
  }, {
    "code": "QA",
    "country": "Катар"
  }, {
    "code": "RE",
    "country": "Реюньон"
  }, {
    "code": "RO",
    "country": "Румыния"
  }, {
    "code": "RS",
    "country": "Сербия"
  }, {
    "code": "RU",
    "country": "Россия"
  }, {
    "code": "RW",
    "country": "Руанда"
  }, {
    "code": "SA",
    "country": "Саудовская Аравия"
  }, {
    "code": "SB",
    "country": "Соломоновы Острова"
  }, {
    "code": "SC",
    "country": "Сейшельские Острова"
  }, {
    "code": "SD",
    "country": "Судан"
  }, {
    "code": "SE",
    "country": "Швеция"
  }, {
    "code": "SG",
    "country": "Сингапур"
  }, {
    "code": "SH",
    "country": "о-в Св. Елены"
  }, {
    "code": "SI",
    "country": "Словения"
  }, {
    "code": "SJ",
    "country": "Шпицберген и Ян-Майен"
  }, {
    "code": "SK",
    "country": "Словакия"
  }, {
    "code": "SL",
    "country": "Сьерра-Леоне"
  }, {
    "code": "SM",
    "country": "Сан-Марино"
  }, {
    "code": "SN",
    "country": "Сенегал"
  }, {
    "code": "SO",
    "country": "Сомали"
  }, {
    "code": "SR",
    "country": "Суринам"
  }, {
    "code": "SS",
    "country": "Южный Судан"
  }, {
    "code": "ST",
    "country": "Сан-Томе и Принсипи"
  }, {
    "code": "SV",
    "country": "Сальвадор"
  }, {
    "code": "SX",
    "country": "Синт-Мартен"
  }, {
    "code": "SY",
    "country": "Сирия"
  }, {
    "code": "SZ",
    "country": "Свазиленд"
  }, {
    "code": "TA",
    "country": "Тристан-да-Кунья"
  }, {
    "code": "TC",
    "country": "о-ва Тёркс и Кайкос"
  }, {
    "code": "TD",
    "country": "Чад"
  }, {
    "code": "TF",
    "country": "Французские Южные территории"
  }, {
    "code": "TG",
    "country": "Того"
  }, {
    "code": "TH",
    "country": "Таиланд"
  }, {
    "code": "TJ",
    "country": "Таджикистан"
  }, {
    "code": "TK",
    "country": "Токелау"
  }, {
    "code": "TL",
    "country": "Восточный Тимор"
  }, {
    "code": "TM",
    "country": "Туркменистан"
  }, {
    "code": "TN",
    "country": "Тунис"
  }, {
    "code": "TO",
    "country": "Тонга"
  }, {
    "code": "TR",
    "country": "Турция"
  }, {
    "code": "TT",
    "country": "Тринидад и Тобаго"
  }, {
    "code": "TV",
    "country": "Тувалу"
  }, {
    "code": "TW",
    "country": "Тайвань"
  }, {
    "code": "TZ",
    "country": "Танзания"
  }, {
    "code": "UA",
    "country": "Украина"
  }, {
    "code": "UG",
    "country": "Уганда"
  }, {
    "code": "UM",
    "country": "Внешние малые о-ва (США)"
  }, {
    "code": "UN",
    "country": "Организация Объединенных Наций"
  }, {
    "code": "US",
    "country": "Соединенные Штаты"
  }, {
    "code": "UY",
    "country": "Уругвай"
  }, {
    "code": "UZ",
    "country": "Узбекистан"
  }, {
    "code": "VA",
    "country": "Ватикан"
  }, {
    "code": "VC",
    "country": "Сент-Винсент и Гренадины"
  }, {
    "code": "VE",
    "country": "Венесуэла"
  }, {
    "code": "VG",
    "country": "Виргинские о-ва (Британские)"
  }, {
    "code": "VI",
    "country": "Виргинские о-ва (США)"
  }, {
    "code": "VN",
    "country": "Вьетнам"
  }, {
    "code": "VU",
    "country": "Вануату"
  }, {
    "code": "WF",
    "country": "Уоллис и Футуна"
  }, {
    "code": "WS",
    "country": "Самоа"
  }, {
    "code": "XK",
    "country": "Косово"
  }, {
    "code": "YE",
    "country": "Йемен"
  }, {
    "code": "YT",
    "country": "Майотта"
  }, {
    "code": "ZA",
    "country": "ЮАР"
  }, {
    "code": "ZM",
    "country": "Замбия"
  }, {
    "code": "ZW",
    "country": "Зимбабве"
  }];
  _exports.default = _default;
});