define("huvepharma-aviapp-admin/base/enum/continents/en-th", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AF",
    "continent": "ทวีปแอฟริกา"
  }, {
    "code": "NA",
    "continent": "ทวีปอเมริการเหนือ"
  }, {
    "code": "OC",
    "continent": "โอเชียเนีย"
  }, {
    "code": "AN",
    "continent": "ทวีปแอนตาร์กติกา"
  }, {
    "code": "AS",
    "continent": "ทวีปเอเชีย"
  }, {
    "code": "EU",
    "continent": "ทวีปยุโรป"
  }, {
    "code": "SA",
    "continent": "ทวีปอเมริกาใต้"
  }];
  _exports.default = _default;
});