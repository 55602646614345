define("huvepharma-aviapp-admin/templates/components/info-block", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lznKHdDb",
    "block": "[[[10,0],[15,0,[29,[\"info-block \",[36,0]]]],[12],[1,\"\\n  \"],[10,\"svg\"],[14,0,\"info-block-icon\"],[12],[1,\"\\n    \"],[10,\"use\"],[14,\"xlink:href\",\"#info\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"info-block-text\"],[12],[1,[34,1]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"className\",\"text\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/components/info-block.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});