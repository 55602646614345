define("huvepharma-aviapp-admin/components/form/validated-input", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    hasError: false,

    /**
     * has this input element an error?
     * @return {Boolean} True if form has been validated and the input element has an error
     */
    checkIfError: (0, _object.observer)('error', function () {
      if (this.validate) {
        this.set('hasError', this.error);
      }
    }),
    actions: {}
  });

  _exports.default = _default;
});