define("huvepharma-aviapp-admin/base/enum/default-daily-report-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "mortality": {
      "deaths": ["total"],
      "size": [],
      "skeletal": [],
      "other": ["total"],
      "thinning": ["thinning"],
      "total": ["total"],
      "cum": [],
      "ref": []
    },
    "feed_intake": {
      "feed_intake": ["daily", "daily_per_bird"]
    },
    "water_intake": {
      "water_intake": ["daily", "daily_per_bird"]
    },
    "water_feed": {
      "water_feed": []
    },
    "body_weight": {
      "body_weight": ["weight_bird", "ref"]
    },
    "climate": {
      "temperature": [],
      "humidity": [],
      "nh3": ["max"]
    },
    "skin_color": {
      "skin_color": ["yellow", "red", "luminosity"]
    }
  };
  _exports.default = _default;
});