define("huvepharma-aviapp-admin/templates/components/house-management-configuration", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Eg57huid",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@bodyClass\",\"@initialState\"],[[28,[37,1],[\"house_management_label\"],null],\"no-padding is-transparent\",true]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"features\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"features__row features__row--border-bottom\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"button-unset\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,0,[\"toggleAll\"]]],null],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"house_management_config_select_all_label\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"categories\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"features__row\"],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@name\",\"@label\",\"@checked\",\"@onChecked\"],[[30,1,[\"_id\"]],[28,[37,1],[[30,1,[\"name\"]]],null],[30,1,[\"checked\"]],[28,[37,6],[[30,0,[\"toggleCategory\"]],[30,1]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n  \"],[8,[39,7],null,[[\"@className\",\"@text\"],[\"features__info\",[28,[37,1],[\"house_management_config_info\"],null]]],null],[1,\"\\n\"]],[]]]]]],[\"category\"],false,[\"collapsible-panel\",\"t\",\"on\",\"each\",\"-track-array\",\"form/huve-check-box\",\"fn\",\"info-block\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/components/house-management-configuration.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});