define("huvepharma-aviapp-admin/services/session", ["exports", "@sentry/browser", "rsvp", "@ember/application", "@ember/object", "ember-simple-auth/services/session"], function (_exports, Sentry, _rsvp, _application, _object, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _session.default.extend({
    /**
     * Logout of the session
     */
    logout: function logout() {
      var _this = this;

      return this.invalidate().then(function () {
        _this.clear();
      });
    },

    /**
     * Clear the session store
     */
    clear: function clear() {
      this.session.get('store').clear();
    },

    /**
     * Execute
     */
    execute: function execute() {
      var _this2 = this;

      return new _rsvp.Promise(function (resolve) {
        var application = (0, _application.getOwner)(_this2).lookup('application:main'); // Not authenticated? Nothing to do

        if (!_this2.isAuthenticated) {
          return resolve(_this2);
        } // Get 'me' (current user)


        return (0, _application.getOwner)(_this2).lookup('service:store').query('me', {}).then(function (response) {
          var me = response.get('firstObject');
          var companies = me.get('companies');

          if (companies && companies.length === 1) {
            _this2.set('data.loginCompany', companies[0]);
          }

          _this2.set('me', me);

          me.set('activeUser', me.get('id'));

          if (me.get('role') === 'superuser') {
            _this2.set('isSuperUser', true);
          } else {
            _this2.set('isAdmin', true);
          } // Update locale


          var intl = (0, _application.getOwner)(_this2).lookup('service:intl');
          (0, _object.set)(intl, 'locale', me.get('locale'));
          (0, _object.set)(application, 'locale', me.get('locale'));
          me.addObserver('measurementSystem', function () {
            (0, _object.set)(application, 'measurementSystem', this.measurementSystem);
          });
          me.addObserver('measurementSystem', function () {
            (0, _object.set)(application, 'measurementSystem', this.measurementSystem);
          });
          (0, _object.set)(application, 'measurementSystem', me.get('measurementSystem'));
          (0, _object.set)(application, 'countries', // eslint-disable-next-line
          require("huvepharma-aviapp-admin/base/enum/countries/".concat(me.get('locale'))).default);
          (0, _object.set)(application, 'continents', // eslint-disable-next-line
          require("huvepharma-aviapp-admin/base/enum/continents/".concat(me.get('locale'))).default); // If the loginCompany is already set (user only has access to 1)
          // set the UserContext for Sentry

          if (_this2.get('data.loginCompany')) {
            Sentry.setUser({
              user: me.get('id'),
              company: _this2.get('data.loginCompany._id')
            });
          }

          return resolve(_this2);
        });
      });
    }
  });

  _exports.default = _default;
});