define("huvepharma-aviapp-admin/controllers/session/login", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    error: false,
    session: (0, _service.inject)(),
    flash: (0, _service.inject)(),
    isLoading: false,
    actions: {
      /**
       * Authenticate the user and redirect on success
       */
      authenticate: function authenticate() {
        var _this = this;

        this.session.get('session').get('store').clear();
        this.flash.clear();
        var data = this.getProperties('identification', 'password');
        this.session.authenticate('authenticator:api', data).catch(function (error) {
          _this.set('isLoading', false);

          var message = error && error.code && error.code >= 1000 ? "errorcodes_".concat(error.code) : 'login_error_invalid';

          _this.flash.error({
            message: message,
            sticky: true
          });
        });
      }
    }
  });

  _exports.default = _default;
});