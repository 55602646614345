define("huvepharma-aviapp-admin/helpers/user-role", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Figure out the role a user has for a given companyId
   *    usage: {{user-role user.companies session.data.loginCompany._id}}
   */
  var _default = (0, _helper.helper)(function (args) {
    var userCompanies = Array.isArray(args[0]) ? args[0] : [];
    var companyId = args[1] || '';
    var company = userCompanies.find(function (companyParam) {
      return companyParam._id === companyId;
    });

    if (company) {
      return company.role;
    }

    return 'user';
  });

  _exports.default = _default;
});