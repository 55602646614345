define("huvepharma-aviapp-admin/utils/interpolated-link-string", ["exports", "@ember/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getInterpolatedLinkString = getInterpolatedLinkString;
  var LINKED_STRING_OPEN_DELIMITER = '[';
  var LINKED_STRING_CLOSE_DELIMITER = ']';

  function getInterpolatedLinkString(linkLabel, file) {
    var baseString = linkLabel;
    var result = baseString;
    var linkedPart = '';

    if (baseString.includes(LINKED_STRING_OPEN_DELIMITER) && baseString.includes(LINKED_STRING_CLOSE_DELIMITER)) {
      linkedPart = baseString.slice(baseString.indexOf(LINKED_STRING_OPEN_DELIMITER) + 1, baseString.lastIndexOf(LINKED_STRING_CLOSE_DELIMITER));
      result = result.replace(LINKED_STRING_OPEN_DELIMITER, '').replace(LINKED_STRING_CLOSE_DELIMITER, '');
    }

    if (file && linkedPart) {
      result = result.replace(linkedPart, "<a href=\"/assets/".concat(file, ".pdf\" target=\"_blank\">").concat(linkedPart, "</a>"));
    }

    return (0, _string.htmlSafe)(result);
  }
});