define("huvepharma-aviapp-admin/base/enum/area-of-expertise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "VETERINARIAN": "veterinarian",
    "NUTRITIONIST": "nutritionist",
    "LIVESTOCK_PRODUCTION": "livestock_production",
    "IT": "it",
    "DATA_ANALYST": "data_analyst",
    "OTHER": "other"
  };
  _exports.default = _default;
});