define("huvepharma-aviapp-admin/base/models/custom-graph", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var CustomGraph = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    lines: _emberData.default.attr(),
    isAgeMode: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isAverageMode: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    chart: _emberData.default.attr(),
    chartType: _emberData.default.attr('string', {
      defaultValue: 'line'
    }),
    intervalType: _emberData.default.attr('string', {
      defaultValue: 'day'
    }),
    benchmarks: _emberData.default.attr(),
    owner: _emberData.default.belongsTo('user', {
      async: true
    }),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    isTemplate: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });

  var _default = CustomGraph;
  _exports.default = _default;
});