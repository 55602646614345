define("huvepharma-aviapp-admin/services/screen", ["exports", "ember-screen"], function (_exports, _emberScreen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberScreen.default.extend({
    isSmallAndUp: (0, _emberScreen.breakpoint)('(min-width: 34em)'),
    isMediumAndUp: (0, _emberScreen.breakpoint)('(min-width: 48em)'),
    isLargeAndUp: (0, _emberScreen.breakpoint)('(min-width: 62em)'),
    isExtraLargeAndUp: (0, _emberScreen.breakpoint)('(min-width: 75em)'),
    isExtraSmallAndDown: (0, _emberScreen.breakpoint)('(max-width: 33.9999em)'),
    isSmallAndDown: (0, _emberScreen.breakpoint)('(max-width: 47.9999em)'),
    isMediumAndDown: (0, _emberScreen.breakpoint)('(max-width: 61.9999em)'),
    isLargeAndDown: (0, _emberScreen.breakpoint)('(max-width: 74.9999em)')
  });

  _exports.default = _default;
});