define("huvepharma-aviapp-admin/components/collapsible-panel", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    isOpen: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('isOpen', this.initialState != null ? this.initialState : true);
    },
    actions: {
      toggle: function toggle() {
        var _this$onToggle;

        this.toggleProperty('isOpen');
        (_this$onToggle = this.onToggle) === null || _this$onToggle === void 0 ? void 0 : _this$onToggle.call(this, this.get('isOpen'));
      }
    }
  });

  _exports.default = _default;
});