define("huvepharma-aviapp-admin/routes/session/login", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    session: (0, _service.inject)('session'),
    setupController: function setupController() {
      if (this.get('session.isAuthenticated')) {
        if (this.get('session.isSuperUser')) {
          return this.transitionTo('dashboard');
        }

        return this.transitionTo('users');
      }

      return null;
    },

    /*
     * @summary Renders a custom header into the header outlet
     */
    renderTemplate: function renderTemplate() {
      this.render();
      this.render('session.header', {
        outlet: 'header',
        into: 'application'
      });
    }
  });

  _exports.default = _default;
});