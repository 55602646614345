define("huvepharma-aviapp-admin/mixins/restrict-super-user-route", ["exports", "@ember/object/mixin", "@ember/service", "@ember/object"], function (_exports, _mixin, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    session: (0, _service.inject)('session'),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var session = this.session;

      if ((0, _object.get)(session, 'isSuperUser')) {
        return this.transitionTo('dashboard');
      }

      return true;
    }
  });

  _exports.default = _default;
});