define("huvepharma-aviapp-admin/components/phases/lines/other-line", ["exports", "@ember/object", "@ember/array", "@ember/service", "huvepharma-aviapp-admin/components/phases/lines/base", "huvepharma-aviapp-admin/base/enum/other-programs"], function (_exports, _object, _array, _service, _base, _otherPrograms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _base.default.extend({
    attributeBindings: ['getTestId:data-test-id'],
    getTestId: (0, _object.computed)('phaseIndex', function () {
      return "other-phase-".concat(this.phaseIndex);
    }),
    poultryConfig: (0, _service.inject)(),
    brands: [],
    actives: [],
    brandDisabled: false,
    activeDisabled: false,
    dosageDisabled: false,
    displayedPrograms: (0, _object.computed)('intl.locale', function () {
      var _this = this;

      var programs = _otherPrograms.default.map(function (p) {
        return _objectSpread(_objectSpread({}, p), {}, {
          name: (0, _object.get)(p, 'id') === _base.NONE ? _this.getNoneProgramLabel() : (0, _object.get)(_this, 'intl').t("flocks_label_program_".concat((0, _object.get)(p, 'id').toLocaleLowerCase()))
        });
      });

      return programs.sortBy('name.string');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var phase = (0, _object.get)(this, 'phase');

      if (phase) {
        var selectedProgram = (0, _object.get)(phase, 'program');
        var selectedBrand = (0, _object.get)(phase, 'brand');
        var selectedActive = (0, _object.get)(phase, 'active');

        var program = _otherPrograms.default.find(function (p) {
          return p.id === selectedProgram;
        });

        var brandsAndActiveConfig = this.getBrandsAndActives(phase, program, selectedBrand);
        (0, _object.setProperties)(this, brandsAndActiveConfig); // Next, select the given brand/active, if present

        if (selectedProgram) {
          (0, _object.set)(this, 'program', selectedProgram);
        }

        if (selectedBrand) {
          (0, _object.set)(this, 'brand', selectedBrand);
        }

        if (selectedActive) {
          (0, _object.set)(this, 'active', selectedActive);
        }
      }
    },
    getBaseBrandsAndActiveConfig: function getBaseBrandsAndActiveConfig(phase) {
      return {
        brands: null,
        actives: null,
        activeDisabled: false,
        brandDisabled: false,
        dosageDisabled: false,
        dosage: (0, _object.get)(phase, 'dosage'),
        active: '',
        brand: ''
      };
    },
    configureBrandsAndActiveByProgram: function configureBrandsAndActiveByProgram(config, program) {
      var _this2 = this;

      if (!program) {
        config.brands = null;
        config.brandDisabled = true;
        config.actives = null;
        config.activeDisabled = true;
      } else if (program.inactiveFields) {
        config.activeDisabled = true;
        config.brandDisabled = true;
        config.dosageDisabled = true;
        config.dosage = ' ';
        config.brand = '-';
        config.active = '-';
      } else if (program.brands) {
        var brands = program.brands.map(function (b) {
          return _objectSpread(_objectSpread({}, b), {}, {
            name: b.name || _this2.intl.t("flocks_label_program_".concat(b.id))
          });
        });
        config.brands = brands.sortBy('name');
      } // Reset dosage to null


      if (program && !program.inactiveFields && config.dosage === _base.DISABLED_DOSAGE_VALUE) {
        config.dosage = null;
      }

      return config;
    },
    configureBrandsAndActiveByBrand: function configureBrandsAndActiveByBrand(config, selectedBrand, program) {
      var _this3 = this;

      if (config.brands) {
        var brand = config.brands.find(function (b) {
          return b.id === selectedBrand;
        });

        if (!brand) {
          config.actives = null;
          config.activeDisabled = true;
        } else if (brand.inactiveFields) {
          config.activeDisabled = true;
          config.active = '-';
        } else if (brand.freeInput) {
          config.active = '';
        } else if (!(0, _array.isArray)(brand.active)) {
          config.activeDisabled = true;
          config.actives = null;
          config.active = brand.active.name;
        } else if (brand.active) {
          var active = brand.active.map(function (b) {
            return _objectSpread(_objectSpread({}, b), {}, {
              name: b.name || _this3.intl.t("flocks_label_program_".concat(b.id))
            });
          });
          config.actives = active.sortBy('name');
        }
      } else if (program && !program.freeInput) {
        config.brands = null;
        config.brandDisabled = true;
        config.activeDisabled = true;
      }

      return config;
    },
    getBrandsAndActives: function getBrandsAndActives(phase, program) {
      var selectedBrand = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      return this.configureBrandsAndActiveByBrand(this.configureBrandsAndActiveByProgram(this.getBaseBrandsAndActiveConfig(phase), program), selectedBrand, program);
    },
    onChangeProgram: function onChangeProgram(phase, value) {
      var program = _otherPrograms.default.find(function (p) {
        return p.id === value;
      });

      var brandsAndActiveConfig = this.getBrandsAndActives(phase, program);
      this.setProperties({
        brands: brandsAndActiveConfig.brands,
        actives: brandsAndActiveConfig.actives,
        brandDisabled: brandsAndActiveConfig.brandDisabled,
        activeDisabled: brandsAndActiveConfig.activeDisabled,
        dosageDisabled: brandsAndActiveConfig.dosageDisabled
      });
      (0, _object.set)(phase, 'program', value);
      (0, _object.set)(phase, 'brand', brandsAndActiveConfig.brand);
      (0, _object.set)(phase, 'active', brandsAndActiveConfig.active);
      (0, _object.set)(phase, 'dosage', brandsAndActiveConfig.dosage);
    },
    onChangeBrand: function onChangeBrand(phase, value) {
      var program = _otherPrograms.default.find(function (p) {
        return p.id === (0, _object.get)(phase, 'program');
      });

      var brandsAndActiveConfig = this.getBrandsAndActives(phase, program, value); // update internal properties

      (0, _object.set)(this, 'actives', brandsAndActiveConfig.actives);
      (0, _object.set)(this, 'activeDisabled', brandsAndActiveConfig.activeDisabled);
      (0, _object.set)(this, 'dosageDisabled', brandsAndActiveConfig.dosageDisabled); // update the changeset

      (0, _object.set)(phase, 'brand', value);
      (0, _object.set)(phase, 'active', brandsAndActiveConfig.active);
    }
  });

  _exports.default = _default;
});