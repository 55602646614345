define("huvepharma-aviapp-admin/routes/about/index", ["exports", "@ember/service", "huvepharma-aviapp-admin/routes/protected"], function (_exports, _service, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _protected.default.extend({
    metrics: (0, _service.inject)(),
    afterModel: function afterModel() {
      this.metrics.trackPage({
        page: '/Admin/About',
        title: 'About Screen'
      });
    }
  });

  _exports.default = _default;
});