define("huvepharma-aviapp-admin/utils/format-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = format;

  /**
   * Format date
   * @param {Object|String} input
   * @param {String] dateFormat
   * @returns {number} formatted date
   * @private
   */
  function format(input, dateFormat) {
    var date = input instanceof Object || isNaN(input) ? (0, _moment.default)(input) : (0, _moment.default)(parseInt(input, 10));

    if (!date.isValid()) {
      return input;
    }

    return date.utc().format(dateFormat);
  }
});