define("huvepharma-aviapp-admin/templates/users/access-rights", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hxxeCweh",
    "block": "[[[10,0],[14,0,\"container mod-content\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"accessRights\",\"user\",\"company\",\"userNotEditable\"],[[33,1],[33,2],[33,3],[33,4]]]]],[1,\"\\n\"],[13]],[],false,[\"users/visibility-rights\",\"clientAccessRights\",\"user\",\"company\",\"userNotEditable\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/users/access-rights.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});