define("huvepharma-aviapp-admin/routes/application", ["exports", "@sentry/browser", "@ember/service", "@ember/object", "@ember/routing/route", "@ember/application", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, Sentry, _service, _object, _route, _application, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_applicationRouteMixin.default, {
    session: (0, _service.inject)(),
    metrics: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    router: (0, _service.inject)(),
    currentRouteName: '',

    /**
     * Set a bunch of stuff on the session including the current user
     * if the user is authenticated.
     * @returns {Promise<Object>} session
     */
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this.intl.setLocale(['en']); // Set the current route

      this.set('currentRouteName', transition.targetName);
      var currentRoute = (0, _application.getOwner)(this).lookup("route:".concat(transition.targetName)); // Don't setup the session if it's an unauthenticated route

      if ((0, _object.get)(currentRoute, 'skipSessionExecution')) {
        return true;
      }

      return this.session.execute().then(function (session) {
        if (!session.get('isAuthenticated')) {
          return;
        } // Set dimensions for Google Analytics


        (0, _object.set)(_this, 'metrics.context.dimension1', session.get('me.locale'));
        (0, _object.set)(_this, 'metrics.context.dimension2', session.get('me.id'));
        (0, _object.set)(_this, 'metrics.context.dimension5', session.get('me.role'));
      }).catch(function () {
        return _this.session.invalidate();
      });
    },

    /**
     * If authenticating has succeeded, set a bunch of stuff on the session
     * including the current user and check if the user has access to
     * multiple companies.
     */
    sessionAuthenticated: function sessionAuthenticated() {
      var _this2 = this;

      this.session.execute().then(this.checkCompanies.bind(this)).catch(function () {
        return _this2.session.invalidate();
      });
    },

    /**
     * Session becomes invalidated (aka logout)
     */
    sessionInvalidated: function sessionInvalidated() {
      // Clear any remaining session data
      this.session.clear(); // Clear Sentry UserContext

      Sentry.configureScope(function (scope) {
        return scope.clear();
      }); // Don't reload if the baseRoute is 'invitation'

      var baseRoute = this.currentRouteName ? this.currentRouteName.split('.')[0] : null;

      if (baseRoute !== 'invitation') {
        window.location.href = '/session/login';
      }
    },

    /**
     * Checks if the user has access to multiple companies and if so,
     * it'll show the company-switch component. If not, it'll
     * redirect to the 'dashboard'-route if the current user is a master
     * or the 'users'-route if the user is an admin.
     * @param {Object} session
     */
    checkCompanies: function checkCompanies(session) {
      if (!session.get('isAuthenticated')) {
        return null;
      }

      if (session.get('isSuperUser')) {
        return this.transitionTo('dashboard');
      }

      var me = session.get('me');
      var companies = me.get('companies'); // If there are no companies, invalidate the session

      if (!companies || companies && companies.length < 1) {
        this.session.invalidate();
      } // Set the current company (in which user is an admin)


      var adminCompanies = companies.filterBy('role', 'admin');
      var loginCompany = adminCompanies[0];
      this.session.set('data.loginCompany', loginCompany); // Show company-switch if there's more than 1

      if (adminCompanies.get('length') > 1) {
        var companySwitchController = this.controllerFor('company-switch.company-switch');
        companySwitchController.set('justLoggedIn', true);
        companySwitchController.set('showCompanySwitch', true);
      } else {
        return this.transitionTo('users');
      }

      return null;
    },

    /**
     * Render Template
     */
    renderTemplate: function renderTemplate() {
      this.render(); // Render the header application wide

      this.render('header.app', {
        outlet: 'header',
        into: 'application'
      }); // Render the company-switch application wide

      this.render('company-switch.company-switch', {
        outlet: 'company-switch',
        into: 'application'
      }); // Render the footer application wide

      /*
      this.render('footer.app', {
        outlet: 'footer',
        into: 'application'
      });
      */
    },
    actions: {
      willTransition: function willTransition() {
        var appController = this.controller;
        (0, _object.set)(appController, 'headerHidden', false);
      },

      /**
       * Set the current route
       */
      didTransition: function didTransition() {
        this.set('currentRouteName', this.router.currentRouteName);
      },

      /**
       * Refresh the route if we're currently on the users index page,
       * or transition to it if not
       */
      transitionToRoot: function transitionToRoot() {
        if (this.router.currentRouteName === 'users.index') {
          this.refresh();
        } else {
          this.transitionTo('users');
        }
      }
    }
  });

  _exports.default = _default;
});