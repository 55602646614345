define("huvepharma-aviapp-admin/utils/paged-array", ["exports", "@huvepharma/huvepharma-aviapp-components/utils/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _pagedArray.default;
    }
  });
});