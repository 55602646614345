define("huvepharma-aviapp-admin/base/enum/countries/en-nl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AC",
    "country": "Ascension"
  }, {
    "code": "AD",
    "country": "Andorra"
  }, {
    "code": "AE",
    "country": "Verenigde Arabische Emiraten"
  }, {
    "code": "AF",
    "country": "Afghanistan"
  }, {
    "code": "AG",
    "country": "Antigua en Barbuda"
  }, {
    "code": "AI",
    "country": "Anguilla"
  }, {
    "code": "AL",
    "country": "Albanië"
  }, {
    "code": "AM",
    "country": "Armenië"
  }, {
    "code": "AO",
    "country": "Angola"
  }, {
    "code": "AQ",
    "country": "Antarctica"
  }, {
    "code": "AR",
    "country": "Argentinië"
  }, {
    "code": "AS",
    "country": "Amerikaans-Samoa"
  }, {
    "code": "AT",
    "country": "Oostenrijk"
  }, {
    "code": "AU",
    "country": "Australië"
  }, {
    "code": "AW",
    "country": "Aruba"
  }, {
    "code": "AX",
    "country": "Åland"
  }, {
    "code": "AZ",
    "country": "Azerbeidzjan"
  }, {
    "code": "BA",
    "country": "Bosnië en Herzegovina"
  }, {
    "code": "BB",
    "country": "Barbados"
  }, {
    "code": "BD",
    "country": "Bangladesh"
  }, {
    "code": "BE",
    "country": "België"
  }, {
    "code": "BF",
    "country": "Burkina Faso"
  }, {
    "code": "BG",
    "country": "Bulgarije"
  }, {
    "code": "BH",
    "country": "Bahrein"
  }, {
    "code": "BI",
    "country": "Burundi"
  }, {
    "code": "BJ",
    "country": "Benin"
  }, {
    "code": "BL",
    "country": "Saint-Barthélemy"
  }, {
    "code": "BM",
    "country": "Bermuda"
  }, {
    "code": "BN",
    "country": "Brunei"
  }, {
    "code": "BO",
    "country": "Bolivia"
  }, {
    "code": "BQ",
    "country": "Caribisch Nederland"
  }, {
    "code": "BR",
    "country": "Brazilië"
  }, {
    "code": "BS",
    "country": "Bahama’s"
  }, {
    "code": "BT",
    "country": "Bhutan"
  }, {
    "code": "BW",
    "country": "Botswana"
  }, {
    "code": "BY",
    "country": "Belarus"
  }, {
    "code": "BZ",
    "country": "Belize"
  }, {
    "code": "CA",
    "country": "Canada"
  }, {
    "code": "CC",
    "country": "Cocoseilanden"
  }, {
    "code": "CD",
    "country": "Congo-Kinshasa"
  }, {
    "code": "CF",
    "country": "Centraal-Afrikaanse Republiek"
  }, {
    "code": "CG",
    "country": "Congo-Brazzaville"
  }, {
    "code": "CH",
    "country": "Zwitserland"
  }, {
    "code": "CI",
    "country": "Ivoorkust"
  }, {
    "code": "CK",
    "country": "Cookeilanden"
  }, {
    "code": "CL",
    "country": "Chili"
  }, {
    "code": "CM",
    "country": "Kameroen"
  }, {
    "code": "CN",
    "country": "China"
  }, {
    "code": "CO",
    "country": "Colombia"
  }, {
    "code": "CR",
    "country": "Costa Rica"
  }, {
    "code": "CU",
    "country": "Cuba"
  }, {
    "code": "CV",
    "country": "Kaapverdië"
  }, {
    "code": "CW",
    "country": "Curaçao"
  }, {
    "code": "CX",
    "country": "Christmaseiland"
  }, {
    "code": "CY",
    "country": "Cyprus"
  }, {
    "code": "CZ",
    "country": "Tsjechische Republiek"
  }, {
    "code": "DE",
    "country": "Duitsland"
  }, {
    "code": "DG",
    "country": "Diego Garcia"
  }, {
    "code": "DJ",
    "country": "Djibouti"
  }, {
    "code": "DK",
    "country": "Denemarken"
  }, {
    "code": "DM",
    "country": "Dominica"
  }, {
    "code": "DO",
    "country": "Dominicaanse Republiek"
  }, {
    "code": "DZ",
    "country": "Algerije"
  }, {
    "code": "EA",
    "country": "Ceuta en Melilla"
  }, {
    "code": "EC",
    "country": "Ecuador"
  }, {
    "code": "EE",
    "country": "Estland"
  }, {
    "code": "EG",
    "country": "Egypte"
  }, {
    "code": "EH",
    "country": "Westelijke Sahara"
  }, {
    "code": "ER",
    "country": "Eritrea"
  }, {
    "code": "ES",
    "country": "Spanje"
  }, {
    "code": "ET",
    "country": "Ethiopië"
  }, {
    "code": "EZ",
    "country": "Eurozone"
  }, {
    "code": "FI",
    "country": "Finland"
  }, {
    "code": "FJ",
    "country": "Fiji"
  }, {
    "code": "FK",
    "country": "Falklandeilanden"
  }, {
    "code": "FM",
    "country": "Micronesia"
  }, {
    "code": "FO",
    "country": "Faeröer"
  }, {
    "code": "FR",
    "country": "Frankrijk"
  }, {
    "code": "GA",
    "country": "Gabon"
  }, {
    "code": "GB",
    "country": "Verenigd Koninkrijk"
  }, {
    "code": "GD",
    "country": "Grenada"
  }, {
    "code": "GE",
    "country": "Georgië"
  }, {
    "code": "GF",
    "country": "Frans-Guyana"
  }, {
    "code": "GG",
    "country": "Guernsey"
  }, {
    "code": "GH",
    "country": "Ghana"
  }, {
    "code": "GI",
    "country": "Gibraltar"
  }, {
    "code": "GL",
    "country": "Groenland"
  }, {
    "code": "GM",
    "country": "Gambia"
  }, {
    "code": "GN",
    "country": "Guinee"
  }, {
    "code": "GP",
    "country": "Guadeloupe"
  }, {
    "code": "GQ",
    "country": "Equatoriaal-Guinea"
  }, {
    "code": "GR",
    "country": "Griekenland"
  }, {
    "code": "GS",
    "country": "Zuid-Georgia en Zuidelijke Sandwicheilanden"
  }, {
    "code": "GT",
    "country": "Guatemala"
  }, {
    "code": "GU",
    "country": "Guam"
  }, {
    "code": "GW",
    "country": "Guinee-Bissau"
  }, {
    "code": "GY",
    "country": "Guyana"
  }, {
    "code": "HK",
    "country": "Hongkong SAR van China"
  }, {
    "code": "HN",
    "country": "Honduras"
  }, {
    "code": "HR",
    "country": "Kroatië"
  }, {
    "code": "HT",
    "country": "Haïti"
  }, {
    "code": "HU",
    "country": "Hongarije"
  }, {
    "code": "IC",
    "country": "Canarische Eilanden"
  }, {
    "code": "ID",
    "country": "Indonesië"
  }, {
    "code": "IE",
    "country": "Ierland"
  }, {
    "code": "IL",
    "country": "Israël"
  }, {
    "code": "IM",
    "country": "Isle of Man"
  }, {
    "code": "IN",
    "country": "India"
  }, {
    "code": "IO",
    "country": "Britse Gebieden in de Indische Oceaan"
  }, {
    "code": "IQ",
    "country": "Irak"
  }, {
    "code": "IR",
    "country": "Iran"
  }, {
    "code": "IS",
    "country": "IJsland"
  }, {
    "code": "IT",
    "country": "Italië"
  }, {
    "code": "JE",
    "country": "Jersey"
  }, {
    "code": "JM",
    "country": "Jamaica"
  }, {
    "code": "JO",
    "country": "Jordanië"
  }, {
    "code": "JP",
    "country": "Japan"
  }, {
    "code": "KE",
    "country": "Kenia"
  }, {
    "code": "KG",
    "country": "Kirgizië"
  }, {
    "code": "KH",
    "country": "Cambodja"
  }, {
    "code": "KI",
    "country": "Kiribati"
  }, {
    "code": "KM",
    "country": "Comoren"
  }, {
    "code": "KN",
    "country": "Saint Kitts en Nevis"
  }, {
    "code": "KP",
    "country": "Noord-Korea"
  }, {
    "code": "KR",
    "country": "Zuid-Korea"
  }, {
    "code": "KW",
    "country": "Koeweit"
  }, {
    "code": "KY",
    "country": "Kaaimaneilanden"
  }, {
    "code": "KZ",
    "country": "Kazachstan"
  }, {
    "code": "LA",
    "country": "Laos"
  }, {
    "code": "LB",
    "country": "Libanon"
  }, {
    "code": "LC",
    "country": "Saint Lucia"
  }, {
    "code": "LI",
    "country": "Liechtenstein"
  }, {
    "code": "LK",
    "country": "Sri Lanka"
  }, {
    "code": "LR",
    "country": "Liberia"
  }, {
    "code": "LS",
    "country": "Lesotho"
  }, {
    "code": "LT",
    "country": "Litouwen"
  }, {
    "code": "LU",
    "country": "Luxemburg"
  }, {
    "code": "LV",
    "country": "Letland"
  }, {
    "code": "LY",
    "country": "Libië"
  }, {
    "code": "MA",
    "country": "Marokko"
  }, {
    "code": "MC",
    "country": "Monaco"
  }, {
    "code": "MD",
    "country": "Moldavië"
  }, {
    "code": "ME",
    "country": "Montenegro"
  }, {
    "code": "MF",
    "country": "Saint-Martin"
  }, {
    "code": "MG",
    "country": "Madagaskar"
  }, {
    "code": "MH",
    "country": "Marshalleilanden"
  }, {
    "code": "MK",
    "country": "Macedonië"
  }, {
    "code": "ML",
    "country": "Mali"
  }, {
    "code": "MM",
    "country": "Myanmar (Birma)"
  }, {
    "code": "MN",
    "country": "Mongolië"
  }, {
    "code": "MO",
    "country": "Macau SAR van China"
  }, {
    "code": "MP",
    "country": "Noordelijke Marianen"
  }, {
    "code": "MQ",
    "country": "Martinique"
  }, {
    "code": "MR",
    "country": "Mauritanië"
  }, {
    "code": "MS",
    "country": "Montserrat"
  }, {
    "code": "MT",
    "country": "Malta"
  }, {
    "code": "MU",
    "country": "Mauritius"
  }, {
    "code": "MV",
    "country": "Maldiven"
  }, {
    "code": "MW",
    "country": "Malawi"
  }, {
    "code": "MX",
    "country": "Mexico"
  }, {
    "code": "MY",
    "country": "Maleisië"
  }, {
    "code": "MZ",
    "country": "Mozambique"
  }, {
    "code": "NA",
    "country": "Namibië"
  }, {
    "code": "NC",
    "country": "Nieuw-Caledonië"
  }, {
    "code": "NE",
    "country": "Niger"
  }, {
    "code": "NF",
    "country": "Norfolk"
  }, {
    "code": "NG",
    "country": "Nigeria"
  }, {
    "code": "NI",
    "country": "Nicaragua"
  }, {
    "code": "NL",
    "country": "Nederland"
  }, {
    "code": "NO",
    "country": "Noorwegen"
  }, {
    "code": "NP",
    "country": "Nepal"
  }, {
    "code": "NR",
    "country": "Nauru"
  }, {
    "code": "NU",
    "country": "Niue"
  }, {
    "code": "NZ",
    "country": "Nieuw-Zeeland"
  }, {
    "code": "OM",
    "country": "Oman"
  }, {
    "code": "PA",
    "country": "Panama"
  }, {
    "code": "PE",
    "country": "Peru"
  }, {
    "code": "PF",
    "country": "Frans-Polynesië"
  }, {
    "code": "PG",
    "country": "Papoea-Nieuw-Guinea"
  }, {
    "code": "PH",
    "country": "Filipijnen"
  }, {
    "code": "PK",
    "country": "Pakistan"
  }, {
    "code": "PL",
    "country": "Polen"
  }, {
    "code": "PM",
    "country": "Saint-Pierre en Miquelon"
  }, {
    "code": "PN",
    "country": "Pitcairneilanden"
  }, {
    "code": "PR",
    "country": "Puerto Rico"
  }, {
    "code": "PS",
    "country": "Palestijnse gebieden"
  }, {
    "code": "PT",
    "country": "Portugal"
  }, {
    "code": "PW",
    "country": "Palau"
  }, {
    "code": "PY",
    "country": "Paraguay"
  }, {
    "code": "QA",
    "country": "Qatar"
  }, {
    "code": "RE",
    "country": "Réunion"
  }, {
    "code": "RO",
    "country": "Roemenië"
  }, {
    "code": "RS",
    "country": "Servië"
  }, {
    "code": "RU",
    "country": "Rusland"
  }, {
    "code": "RW",
    "country": "Rwanda"
  }, {
    "code": "SA",
    "country": "Saoedi-Arabië"
  }, {
    "code": "SB",
    "country": "Salomonseilanden"
  }, {
    "code": "SC",
    "country": "Seychellen"
  }, {
    "code": "SD",
    "country": "Soedan"
  }, {
    "code": "SE",
    "country": "Zweden"
  }, {
    "code": "SG",
    "country": "Singapore"
  }, {
    "code": "SH",
    "country": "Sint-Helena"
  }, {
    "code": "SI",
    "country": "Slovenië"
  }, {
    "code": "SJ",
    "country": "Spitsbergen en Jan Mayen"
  }, {
    "code": "SK",
    "country": "Slowakije"
  }, {
    "code": "SL",
    "country": "Sierra Leone"
  }, {
    "code": "SM",
    "country": "San Marino"
  }, {
    "code": "SN",
    "country": "Senegal"
  }, {
    "code": "SO",
    "country": "Somalië"
  }, {
    "code": "SR",
    "country": "Suriname"
  }, {
    "code": "SS",
    "country": "Zuid-Soedan"
  }, {
    "code": "ST",
    "country": "Sao Tomé en Principe"
  }, {
    "code": "SV",
    "country": "El Salvador"
  }, {
    "code": "SX",
    "country": "Sint-Maarten"
  }, {
    "code": "SY",
    "country": "Syrië"
  }, {
    "code": "SZ",
    "country": "Swaziland"
  }, {
    "code": "TA",
    "country": "Tristan da Cunha"
  }, {
    "code": "TC",
    "country": "Turks- en Caicoseilanden"
  }, {
    "code": "TD",
    "country": "Tsjaad"
  }, {
    "code": "TF",
    "country": "Franse Gebieden in de zuidelijke Indische Oceaan"
  }, {
    "code": "TG",
    "country": "Togo"
  }, {
    "code": "TH",
    "country": "Thailand"
  }, {
    "code": "TJ",
    "country": "Tadzjikistan"
  }, {
    "code": "TK",
    "country": "Tokelau"
  }, {
    "code": "TL",
    "country": "Oost-Timor"
  }, {
    "code": "TM",
    "country": "Turkmenistan"
  }, {
    "code": "TN",
    "country": "Tunesië"
  }, {
    "code": "TO",
    "country": "Tonga"
  }, {
    "code": "TR",
    "country": "Turkije"
  }, {
    "code": "TT",
    "country": "Trinidad en Tobago"
  }, {
    "code": "TV",
    "country": "Tuvalu"
  }, {
    "code": "TW",
    "country": "Taiwan"
  }, {
    "code": "TZ",
    "country": "Tanzania"
  }, {
    "code": "UA",
    "country": "Oekraïne"
  }, {
    "code": "UG",
    "country": "Oeganda"
  }, {
    "code": "UM",
    "country": "Kleine afgelegen eilanden van de Verenigde Staten"
  }, {
    "code": "UN",
    "country": "verenigde naties"
  }, {
    "code": "US",
    "country": "Verenigde Staten"
  }, {
    "code": "UY",
    "country": "Uruguay"
  }, {
    "code": "UZ",
    "country": "Oezbekistan"
  }, {
    "code": "VA",
    "country": "Vaticaanstad"
  }, {
    "code": "VC",
    "country": "Saint Vincent en de Grenadines"
  }, {
    "code": "VE",
    "country": "Venezuela"
  }, {
    "code": "VG",
    "country": "Britse Maagdeneilanden"
  }, {
    "code": "VI",
    "country": "Amerikaanse Maagdeneilanden"
  }, {
    "code": "VN",
    "country": "Vietnam"
  }, {
    "code": "VU",
    "country": "Vanuatu"
  }, {
    "code": "WF",
    "country": "Wallis en Futuna"
  }, {
    "code": "WS",
    "country": "Samoa"
  }, {
    "code": "XK",
    "country": "Kosovo"
  }, {
    "code": "YE",
    "country": "Jemen"
  }, {
    "code": "YT",
    "country": "Mayotte"
  }, {
    "code": "ZA",
    "country": "Zuid-Afrika"
  }, {
    "code": "ZM",
    "country": "Zambia"
  }, {
    "code": "ZW",
    "country": "Zimbabwe"
  }];
  _exports.default = _default;
});