define("huvepharma-aviapp-admin/routes/company/edit", ["exports", "@ember/service", "huvepharma-aviapp-admin/routes/protected"], function (_exports, _service, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _protected.default.extend({
    session: (0, _service.inject)(),
    metrics: (0, _service.inject)(),
    model: function model(params, transition) {
      var companyId;

      if (params && params.id && this.get('session.isSuperUser')) {
        // An ID was passed into the route, lets find the company by the route id
        this.set('routeId', params.id);
        companyId = params.id;
      } else if (!this.get('session.isSuperUser')) {
        // No ID was specified, find the users default company
        companyId = this.get('session.data.loginCompany._id');
      } else {
        transition.abort();
      }

      return this.store.findRecord('company', companyId);
    },
    afterModel: function afterModel() {
      this.metrics.trackPage({
        page: '/Admin/Companies/Edit',
        title: 'Company Edit Screen'
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('routeId', this.routeId);
      controller.set('model', model);
      controller.set('editable', {
        name: model.get('name'),
        telephone: model.get('telephone'),
        email: model.get('email'),
        street: model.get('street'),
        number: model.get('number'),
        city: model.get('city'),
        zipcode: model.get('zipcode'),
        region: model.get('region'),
        countryCode: model.get('countryCode')
      });
    },
    renderTemplate: function renderTemplate() {
      this.render();
      this.render('company.edit-header', {
        outlet: 'header',
        controller: this.controllerFor('company.edit'),
        into: 'application'
      });
    }
  });

  _exports.default = _default;
});