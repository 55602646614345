define("huvepharma-aviapp-admin/routes/auth/authenticated-superuser", ["exports", "@ember/service", "huvepharma-aviapp-admin/routes/protected"], function (_exports, _service, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _protected.default.extend({
    session: (0, _service.inject)('session'),

    /**
     * Before the module is populated
     */
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      var role = this.get('session.me.role');

      if (role === 'superuser') {
        return;
      } // Not authorized


      transition.abort();
      this.transitionTo('users');
    }
  });

  _exports.default = _default;
});