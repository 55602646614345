define("huvepharma-aviapp-admin/components/line-clamp", ["exports", "ember-line-clamp/components/line-clamp"], function (_exports, _lineClamp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _lineClamp.default;
    }
  });
});