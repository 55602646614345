define("huvepharma-aviapp-admin/constants/index", ["exports", "@huvepharma/huvepharma-aviapp-components/constants/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_index).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _index[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _index[key];
      }
    });
  });
});