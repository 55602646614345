define("huvepharma-aviapp-admin/filters/flocks", ["exports", "@ember/object", "@ember/application", "lodash-es/isEqual", "lodash-es/isObject", "lodash-es/groupBy", "huvepharma-aviapp-admin/utils/format-date", "huvepharma-aviapp-admin/helpers/region-code"], function (_exports, _object, _application, _isEqual, _isObject, _groupBy, _formatDate, _regionCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSearchFilter = getSearchFilter;
  _exports.default = getTree;
  _exports.getUserFilters = _exports.getFlockFilterParams = _exports.flockFilterParams = _exports.IS_ARCHIVED = _exports.FARM_SEARCH = _exports.NO_PROGRAMS = _exports.PROGRAMS = _exports.APPLICATION_PERIOD = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ROOT = 'root';
  var TYPE = 'flocks';
  var APPLICATION_PERIOD = 'application_period';
  _exports.APPLICATION_PERIOD = APPLICATION_PERIOD;
  var PROGRAMS = 'programs';
  _exports.PROGRAMS = PROGRAMS;
  var NO_PROGRAMS = 'no_programs';
  _exports.NO_PROGRAMS = NO_PROGRAMS;
  var FARM_SEARCH = 'farm_search';
  _exports.FARM_SEARCH = FARM_SEARCH;
  var IS_ARCHIVED = 'isArchived';
  _exports.IS_ARCHIVED = IS_ARCHIVED;

  var translationLabels = _defineProperty({
    hatchery: 'flocks_label_hatchery',
    name: 'farms_label_farm',
    feedMill: 'flocks_label_feedmill',
    continent: 'label_continent',
    country: 'label_country',
    region: 'label_region',
    company: 'label_farm_company',
    complex: 'label_complex',
    cocciProgram: 'flocks_label_coccidiostatprograms',
    otherProgram: 'flocks_label_otherprograms'
  }, IS_ARCHIVED, 'label_show_archived_flocks'); // these keys match the filterOptions in FlockFarmFilterValuesController (api-node)


  var flockFilterParams = [{
    key: 'origins.hatchery',
    value: 'hatchery'
  }, {
    key: 'feedMill',
    value: 'feedMill'
  }, {
    key: 'farms.continentCode',
    value: 'continent'
  }, {
    key: 'farms.countryCode',
    value: 'country'
  }, {
    key: 'farms.region',
    value: 'region'
  }, {
    key: 'farms.company',
    value: 'company'
  }, {
    key: 'farms.complex',
    value: 'complex'
  }, {
    key: 'cocci-programs.name',
    value: 'cocciProgram'
  }, {
    key: 'other-programs.name',
    value: 'otherProgram'
  }, {
    key: FARM_SEARCH,
    value: FARM_SEARCH
  }, {
    key: APPLICATION_PERIOD,
    value: APPLICATION_PERIOD
  }, {
    key: IS_ARCHIVED,
    value: IS_ARCHIVED
  }];
  _exports.flockFilterParams = flockFilterParams;

  function getSearchFilter(value) {
    return {
      id: value,
      filterKey: FARM_SEARCH,
      label: value,
      parentLabel: this.intl.t('action_search')
    };
  }
  /**
   * Build an array of selected filters based to query the api
   * @param {Array} selectedItems the selected filters
   * @param {String} programType either cocciProgram or otherProgram
   * @returns {Array}
   */


  var getFlockFilterParams = function getFlockFilterParams(selectedItems, programType) {
    var filterParams = selectedItems.reduce(function (acc, selectedItem) {
      if (selectedItem.id === NO_PROGRAMS) {
        var key = programType === 'otherProgram' ? 'otherProgram' : 'coccidiostatProgram';
        acc.push(_defineProperty({}, key, null));
      } else {
        var _flockFilterParams$fi2 = flockFilterParams.find(function (ffp) {
          return ffp.value === selectedItem.filterKey;
        }),
            _key = _flockFilterParams$fi2.key; // the region filter is an object but the application period filter is an array


        var value = (0, _isObject.default)(selectedItem.id) && !Array.isArray(selectedItem.id) ? selectedItem.id[selectedItem.filterKey] : selectedItem.id;
        acc.push(_defineProperty({}, _key, value));
      }

      return acc;
    }, []); // the archived filter is always present, even when not selected

    var hasArchivedFilter = filterParams.find(function (filterParam) {
      return Object.keys(filterParam)[0] === IS_ARCHIVED;
    });

    if (!hasArchivedFilter) {
      filterParams.push(_defineProperty({}, IS_ARCHIVED, false));
    }

    return filterParams;
  };
  /**
   * Create a filters object to save as preferences for the current user
   * @param {Array} selectedFilters the selected filters
   * @returns {Object}
   */


  _exports.getFlockFilterParams = getFlockFilterParams;

  var getUserFilters = function getUserFilters(selectedFilters) {
    var _defaultFilters;

    var filters = selectedFilters.map(function (f) {
      return {
        filterKey: f.filterKey,
        id: f.id
      };
    });
    var grouped = (0, _groupBy.default)(filters, 'filterKey'); // the application period and archived always need to be present in the user filters else
    // we cannot check if they are default selected or deselected by the user

    var defaultFilters = (_defaultFilters = {}, _defineProperty(_defaultFilters, APPLICATION_PERIOD, false), _defineProperty(_defaultFilters, IS_ARCHIVED, false), _defaultFilters);
    return Object.keys(grouped).reduce(function (acc, filterKey) {
      var values = grouped[filterKey]; // the value is not important here, we just need to know if is was selected

      if (filterKey === APPLICATION_PERIOD || filterKey === IS_ARCHIVED) {
        acc[filterKey] = true;
      } else {
        acc[filterKey] = values.map(function (value) {
          return value.id;
        });
      }

      return acc;
    }, defaultFilters);
  };
  /**
   * Creates the filter tree data for flocks
   * @param {Object} filtersObject filters with possible values
   * @param {Object} startDate the program start date
   * @param {Object} endDate the program end date
   * @param {Object} userFilters the filters the user has previously selected
   * @returns {Object}
   */


  _exports.getUserFilters = getUserFilters;

  function getTree(filtersObject, startDate) {
    var _this = this;

    var endDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new Date();
    var userFilters = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    var programType = arguments.length > 4 ? arguments[4] : undefined;
    var formatedStartDate = (0, _formatDate.default)(startDate, (0, _object.get)(this, 'session.me.dateFormat'));
    var formatedEndDate = (0, _formatDate.default)(endDate, (0, _object.get)(this, 'session.me.dateFormat'));
    var defaultChildren = [{
      id: [startDate.toISOString(), endDate.toISOString()],
      label: "".concat(this.intl.t('label_application_period'), " (").concat(formatedStartDate, " - ").concat(formatedEndDate, ")"),
      isSelected: userFilters[APPLICATION_PERIOD] === undefined || !!userFilters[APPLICATION_PERIOD],
      filterKey: APPLICATION_PERIOD
    }, {
      id: PROGRAMS,
      label: this.intl.t('navigation_programs'),
      filterKey: PROGRAMS,
      children: [{
        id: NO_PROGRAMS,
        label: programType === 'otherProgram' ? this.intl.t('label_no_other_programs') : this.intl.t('label_no_cocci_programs'),
        isSingleSelect: true,
        isSelected: !!userFilters[NO_PROGRAMS],
        filterKey: NO_PROGRAMS,
        parentLabel: this.intl.t('navigation_programs')
      }]
    }, {
      id: true,
      label: this.intl.t(translationLabels[IS_ARCHIVED]),
      isSelected: userFilters[IS_ARCHIVED] === undefined || !!userFilters[IS_ARCHIVED],
      filterKey: IS_ARCHIVED,
      isSingleSelect: true
    }];
    var countries = (0, _object.get)((0, _application.getOwner)(this).lookup('application:main'), 'countries');
    var continents = (0, _object.get)((0, _application.getOwner)(this).lookup('application:main'), 'continents');
    var regions = this.regions;

    var getLabel = function getLabel(filterKey, filterValue) {
      if (filterKey === 'country') {
        var _countries$find2 = countries.find(function (c) {
          return c.code.toUpperCase() === filterValue.toUpperCase();
        }),
            country = _countries$find2.country;

        return country || filterValue;
      } else if (filterKey === 'continent') {
        var _continents$find2 = continents.find(function (c) {
          return c.code.toUpperCase() === filterValue.toUpperCase();
        }),
            continent = _continents$find2.continent;

        return continent || filterValue;
      } else if (filterKey === 'region') {
        var countryRegions = regions.getRegionsForCountry(filterValue.countryCode);
        return (0, _regionCode.translate)(countryRegions, filterValue.region);
      }

      return filterValue;
    };

    var mapChildren = function mapChildren(filterKey, filterValues) {
      if (filterValues && filterValues.length) {
        return {
          id: filterKey,
          label: _this.intl.t(translationLabels[filterKey]),
          children: filterValues.map(function (filterValue) {
            return {
              filterKey: filterKey,
              id: filterValue,
              label: getLabel(filterKey, filterValue),
              isSelected: userFilters[filterKey] && !!userFilters[filterKey].find(function (userFilter) {
                return (0, _isEqual.default)(userFilter, filterValue);
              }),
              parentLabel: _this.intl.t(translationLabels[filterKey])
            };
          })
        };
      }

      return null;
    };

    var flockChildren = Object.keys(filtersObject).reduce(function (acc, filterKey) {
      var filterValues = filtersObject[filterKey];
      var children = mapChildren(filterKey, filterValues);

      if (children) {
        if (filterKey === 'cocciProgram' || filterKey === 'otherProgram') {
          if (filterKey === programType) {
            acc.find(function (c) {
              return c.id === PROGRAMS;
            }).children.unshift(children);
          }
        } else {
          acc.splice(1, 0, children);
        }
      }

      return acc;
    }, defaultChildren);
    return {
      type: TYPE,
      id: ROOT,
      children: flockChildren
    };
  }
});