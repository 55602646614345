define("huvepharma-aviapp-admin/controllers/users/edit", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    session: (0, _service.inject)(),
    flash: (0, _service.inject)(),
    actions: {
      close: function close() {
        this.model.rollbackAttributes();
        this.transitionToRoute('users');
      },

      /**
       * Save the edit user form
       */
      saveUser: function saveUser() {
        var _this = this;

        this.flash.clear(); // Notify user if his role has changed

        if (this.prevUserRole !== this.userRole) {
          this.set('model.notifyUpdatedUser', {
            property: 'role',
            keys: {
              ROLE: this.userRole,
              COMPANY: this.get('session.data.loginCompany.name')
            }
          });
        }

        this.model.save().then(function () {
          // Do nothing if the role has not been changed
          if (_this.prevUserRole !== _this.userRole) {
            // Find the user in the array of its previous role on the loginCompany
            var user = (_this.get("company.".concat(_this.prevUserRole, "s")) || []).find(function (userParam) {
              return userParam.get('id') === _this.get('model.id');
            });

            if (user) {
              // Remove the user from the array of its previous role
              _this.get("company.".concat(_this.prevUserRole, "s")).removeObject(user); // Add the user to the array of its new role


              _this.get("company.".concat(_this.userRole, "s")).pushObject(user); // Save company


              return _this.company.save();
            }
          }

          return null;
        }).then(function () {
          _this.flash.success({
            message: 'user_edit_label_updated'
          }); // If the user being saved is the current user and if the role has been changed,
          // log the current user out. Other redirect to the users page


          if (_this.get('model.id') === _this.get('session.me.id') && _this.prevUserRole !== _this.userRole) {
            _this.transitionToRoute('session.logout');
          } else {
            _this.transitionToRoute('users');
          }
        }).catch(function () {
          _this.flash.error({
            message: 'user_update_failed',
            sticky: true
          });
        });
      }
    }
  });

  _exports.default = _default;
});