define("huvepharma-aviapp-admin/helpers/case-insensitive-eq", ["exports", "@huvepharma/huvepharma-aviapp-components/helpers/case-insensitive-eq"], function (_exports, _caseInsensitiveEq) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _caseInsensitiveEq.default;
    }
  });
});