define("huvepharma-aviapp-admin/components/charts/base-chart-header", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- Header is not shown in the png export, so no need to render it --}}
  {{#unless @pngExportOptimized}}
    {{#if @showIncludeExport}}
      <Header::HuveActionableLabelHeader
        @title={{@title}}
        @onAction={{@onAction}}
        @actionLabel={{t "label_png"}}
        @actionIcon={{"download"}}
        @onCollapse={{@onCollapse}}
        @visible={{not @collapsed}}
        @collapsed={{@collapsed}}
        @checkboxName={{@checkboxName}}
        @checked={{@checked}}
        @checkboxLabel={{t "label_export"}}
        @onChecked={{@onChecked}}
      />
    {{else if @showPngExport}}
      <Header::HuveActionableLabelHeader
        @title={{@title}}
        @onAction={{@onAction}}
        @actionLabel={{t "label_png"}}
        @actionIcon={{"download"}}
        @onCollapse={{@onCollapse}}
        @collapsed={{@collapsed}}
      />
    {{else}}
      <Header::HuveActionableLabelHeader
        @title={{@title}}
        @onCollapse={{@onCollapse}}
        @collapsed={{@collapsed}}
      />
    {{/if}}
  {{/unless}}
  */
  {
    "id": "oyY2q1Ac",
    "block": "[[[41,[51,[30,1]],[[[41,[30,2],[[[1,\"    \"],[8,[39,2],null,[[\"@title\",\"@onAction\",\"@actionLabel\",\"@actionIcon\",\"@onCollapse\",\"@visible\",\"@collapsed\",\"@checkboxName\",\"@checked\",\"@checkboxLabel\",\"@onChecked\"],[[30,3],[30,4],[28,[37,3],[\"label_png\"],null],\"download\",[30,5],[28,[37,4],[[30,6]],null],[30,6],[30,7],[30,8],[28,[37,3],[\"label_export\"],null],[30,9]]],null],[1,\"\\n\"]],[]],[[[41,[30,10],[[[1,\"    \"],[8,[39,2],null,[[\"@title\",\"@onAction\",\"@actionLabel\",\"@actionIcon\",\"@onCollapse\",\"@collapsed\"],[[30,3],[30,4],[28,[37,3],[\"label_png\"],null],\"download\",[30,5],[30,6]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],null,[[\"@title\",\"@onCollapse\",\"@collapsed\"],[[30,3],[30,5],[30,6]]],null],[1,\"\\n  \"]],[]]]],[]]]],[]],null]],[\"@pngExportOptimized\",\"@showIncludeExport\",\"@title\",\"@onAction\",\"@onCollapse\",\"@collapsed\",\"@checkboxName\",\"@checked\",\"@onChecked\",\"@showPngExport\"],false,[\"unless\",\"if\",\"header/huve-actionable-label-header\",\"t\",\"not\"]]",
    "moduleName": "huvepharma-aviapp-admin/components/charts/base-chart-header.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});