define("huvepharma-aviapp-admin/controllers/programs/add-cocci-program", ["exports", "@ember/object", "ember-changeset", "ember-changeset-validations", "huvepharma-aviapp-admin/models/cocci-phase", "huvepharma-aviapp-admin/validations/models/cocci-phase", "huvepharma-aviapp-admin/controllers/programs/base-add"], function (_exports, _object, _emberChangeset, _emberChangesetValidations, _cocciPhase, _cocciPhase2, _baseAdd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseAdd.default.extend({
    programTypes: [],
    headerTitle: 'programs_add_cocci_program',
    programType: 'cocciProgram',
    showChangeTypeConfirmation: false,
    init: function init() {
      this._super();

      var programTypes = [{
        value: 'cocci',
        label: (0, _object.get)(this, 'intl').t('label_cocci_program')
      }, {
        value: 'vaccination',
        label: (0, _object.get)(this, 'intl').t('label_vaccination')
      }];
      (0, _object.set)(this, 'programTypes', programTypes);
      (0, _object.set)(this, 'selectedFlocks', []);
      (0, _object.set)(this, 'flocks', []);
    },

    /**
     * Create a phases changeset array
     * ember changeset does not support array or hasMany relations
     * we need to create separate changesets
     * @param controller The controller
     */
    setupPhases: function setupPhases() {
      var defaultPhase = _cocciPhase.default.create({
        startDay: 0
      });

      var phase = new _emberChangeset.default(defaultPhase, (0, _emberChangesetValidations.default)(_cocciPhase2.default), _cocciPhase2.default);
      (0, _object.set)(this, 'phases', [phase]);
    },
    actions: {
      close: function close() {
        var _this$model$cocciProg, _this$model$cocciProg2;

        (_this$model$cocciProg = (_this$model$cocciProg2 = this.model.cocciProgram).deleteRecord) === null || _this$model$cocciProg === void 0 ? void 0 : _this$model$cocciProg.call(_this$model$cocciProg2);
        (0, _object.set)(this, 'selectedFlocks', []);
        this.transitionToRoute('programs');
      },
      onChangeProgramType: function onChangeProgramType() {
        if ((0, _object.get)(this, 'phases.length') > 1) {
          (0, _object.set)(this, 'showChangeTypeConfirmation', true);
        } else {
          // reset phases
          this.setupPhases();
        }

        var changeset = (0, _object.get)(this, 'changeset');
        var cocciType = changeset.get('cocciType');
        changeset.set('cocciType', cocciType === 'cocci' ? 'vaccination' : 'cocci');
        (0, _object.get)(this, 'metrics').trackEvent({
          event: 'Program Type',
          event_category: 'Program Detail',
          event_label: cocciType
        });
      },
      addPhase: function addPhase(currentPhase) {
        // create new phase and set the start to end of the current phase
        var newPhase = _cocciPhase.default.create({
          startDay: Number((0, _object.get)(currentPhase, 'endDay')) + 1
        });

        var csPhase = new _emberChangeset.default(newPhase, (0, _emberChangesetValidations.default)(_cocciPhase2.default), _cocciPhase2.default);
        this.phases.addObject(csPhase);
      },
      deletePhase: function deletePhase(deletedPhase) {
        // const phases = Object.assign({}, this.phases);
        var deleteIndex = this.phases.indexOf(deletedPhase); // when deleting a disabled phase we might need to reset the disabled states

        if (deletedPhase.get('endDay') <= deletedPhase.get('startDay')) {
          this.phases.forEach(function (p) {
            (0, _object.set)(p, 'disabledStart', false);
            (0, _object.set)(p, 'disabledEnd', false);
          });
        } // set start of the next phase to the start of the deleted phase


        var nextPhase = this.phases.objectAt(deleteIndex + 1);

        if (nextPhase) {
          (0, _object.set)(nextPhase, 'startDay', (0, _object.get)(deletedPhase, 'startDay'));
        }

        this.phases.removeAt(deleteIndex);
      },
      onChangeTypeConfirmation: function onChangeTypeConfirmation() {
        this.setupPhases();
        (0, _object.set)(this, 'showChangeTypeConfirmation', false);
      },
      onChangeTypeCancel: function onChangeTypeCancel() {
        var changeset = (0, _object.get)(this, 'changeset');
        var cocciType = changeset.get('cocciType');
        changeset.set('cocciType', cocciType === 'cocci' ? 'vaccination' : 'cocci');
        (0, _object.set)(this, 'showChangeTypeConfirmation', false);
      }
    }
  });

  _exports.default = _default;
});