define("huvepharma-aviapp-admin/helpers/invoke", ["exports", "@ember/object", "@ember/component/helper"], function (_exports, _object, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.invokeFunction = invokeFunction;
  _exports.default = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function invokeFunction(_ref) {
    var _ref2 = _toArray(_ref),
        context = _ref2[0],
        method = _ref2[1],
        rest = _ref2.slice(2);

    if (typeof context[method] !== 'function') {
      throw new Error("Method '".concat(method, "' is not defined or cannot be invoked."));
    }

    return (0, _object.get)(context, method).apply(context, rest);
  }

  var _default = (0, _helper.helper)(invokeFunction);

  _exports.default = _default;
});