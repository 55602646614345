define("huvepharma-aviapp-admin/components/programs/program-form", ["exports", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    tagName: 'main',
    classNames: ['container mod-content'],
    showModal: {
      start: null,
      end: null
    },
    actions: {
      showModal: function showModal(modalId) {
        var identifier = "showModal.".concat(modalId);
        (0, _object.set)(this, identifier, !(0, _object.get)(this, identifier));
      },
      changeDate: function changeDate(modalId, date) {
        var identifier = "onChange".concat(modalId, "Date");

        if (typeof this[identifier] === 'function') {
          this[identifier](date);
        }
      }
    }
  });

  _exports.default = _default;
});