define("huvepharma-aviapp-admin/templates/features/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "coeTfrX6",
    "block": "[[[10,\"main\"],[14,0,\"container mod-content\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"heading\"],[12],[1,[28,[35,0],[\"features_title\"],null]],[13],[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"model\",\"poultry\"]],\"Layers\"],null],[[[1,\"    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@company\"],[[30,0,[\"model\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@company\"],[[30,0,[\"model\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"row margin-top\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@company\"],[[30,0,[\"model\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n        \"],[8,[39,6],null,[[\"@company\"],[[30,0,[\"model\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n        \"],[8,[39,7],null,[[\"@company\"],[[30,0,[\"model\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n        \"],[8,[39,6],null,[[\"@company\"],[[30,0,[\"model\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[],false,[\"t\",\"if\",\"eq\",\"flock-card-settings/house-data\",\"flock-card-settings/egg-production\",\"flock-card-settings/egg-packer\",\"house-management-configuration\",\"flock-card-configuration\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/features/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});