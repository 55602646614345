define("huvepharma-aviapp-admin/components/phases/lines/base", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DISABLED_DOSAGE_VALUE = _exports.NONE = void 0;
  var NONE = 'none';
  _exports.NONE = NONE;
  var DISABLED_DOSAGE_VALUE = ' ';
  _exports.DISABLED_DOSAGE_VALUE = DISABLED_DOSAGE_VALUE;

  var _default = _component.default.extend({
    tagName: 'div',
    canAddPhase: (0, _object.computed)('phaseIndex', 'phases.length', function () {
      return this.phaseIndex === (0, _object.get)(this, 'phases.length') - 1;
    }),
    canDeletePhase: (0, _computed.bool)('phaseIndex'),

    /**
     * Returns eg. - None -
     * @returns {string}
     */
    getNoneProgramLabel: function getNoneProgramLabel() {
      return "- ".concat(this.intl.t("flocks_label_program_".concat(NONE)), " -");
    },
    actions: {
      addPhase: function addPhase(changeset) {
        var _this = this;

        // check if we manually set errors on the changeset
        if ((0, _object.get)(changeset, 'errors').length) {
          return;
        }

        changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            _this.attrs.addPhase(changeset);
          }
        });
      }
    }
  });

  _exports.default = _default;
});