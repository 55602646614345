define("huvepharma-aviapp-admin/base/models/farm", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Farm = _emberData.default.Model.extend({
    address: _emberData.default.attr('string'),
    contacts: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    company: _emberData.default.attr('string'),
    complex: _emberData.default.attr('string'),
    continentCode: _emberData.default.attr('string'),
    countryCode: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    nofHouses: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    owner: _emberData.default.belongsTo('company', {
      async: true
    }),
    region: _emberData.default.attr('string', {
      defaultValue: 'UNKNOWN'
    }),
    reference: _emberData.default.attr('string'),
    lastReportAt: _emberData.default.attr('date'),
    isSoftDeleted: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    hasFlockToBeArchivedSoon: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });

  var _default = Farm;
  _exports.default = _default;
});