define("huvepharma-aviapp-admin/base/models/password-token", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var PasswordToken = _emberData.default.Model.extend({
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    expires: _emberData.default.attr('date'),
    owner: _emberData.default.belongsTo('user', {
      async: true
    }),
    token: _emberData.default.attr('string')
  });

  var _default = PasswordToken;
  _exports.default = _default;
});