define("huvepharma-aviapp-admin/validations/validators/larger-than", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateLargerThan;

  function validateLargerThan(startDay) {
    return function (key, newValue, oldValue, changes, content) {
      var startDayValue = (0, _object.get)(changes, startDay) || (0, _object.get)(content, startDay);

      if (startDayValue >= newValue) {
        return {
          message: 'validation_end_day_after_start_day'
        };
      }

      return true;
    };
  }
});