define("huvepharma-aviapp-admin/services/fetch", ["exports", "jquery", "rsvp", "@ember/service", "huvepharma-aviapp-admin/config/environment"], function (_exports, _jquery, _rsvp, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var FetchService = _service.default.extend({
    session: (0, _service.inject)(),

    /**
     * $.ajax wrapper with authentication
     * @param  {String} url
     * @param  {String} method
     * @param  {Object} data
     * @return {Promise<Object>} API Response
     */
    execute: function execute(url) {
      var _this = this;

      var method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'GET';
      var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      return new _rsvp.Promise(function (resolve, reject) {
        // eslint-disable-next-line camelcase
        var _this$get = _this.get('session.data.authenticated'),
            access_token = _this$get.access_token;

        return _jquery.default.ajax("".concat(_environment.default.APP.api.url).concat(url), {
          headers: {
            // eslint-disable-next-line camelcase
            Authorization: "Bearer ".concat(access_token),
            'X-Active-User-Id': _this.get('session.data.activeUser'),
            'X-Active-LoginCompany-Id': _this.get('session.data.loginCompany._id'),
            'Content-Type': 'application/json'
          },
          method: method,
          data: data
        }).done(function (response) {
          return resolve(response);
        }).fail(function (error) {
          return reject(error);
        });
      });
    },

    /**
     * window wrapper with authentication
     * @param {String} url The URL without any prepended forward slash
     * @param {Object} params The URL get parameters
     */
    openWindow: function openWindow(url, params) {
      // Base authentication parameters
      var parameters = {
        access_token: this.get('session.data.authenticated.access_token'),
        active_user_id: this.get('session.data.activeUser'),
        active_company_id: this.get('session.data.loginCompany._id')
      }; // Add all of the query parameters

      Object.keys(params).forEach(function (key) {
        parameters[key] = params[key];
      }); // Open the window

      window.open("".concat(_environment.default.APP.api.host, "/").concat(_environment.default.APP.api.namespace, "/").concat(url, "?").concat(_jquery.default.param(parameters)));
    },

    /**
     * Use a Form to POST data and to receive back
     * binary data which is to be downloaded by the browser.
     * Using an AJAX call for this purpose is not yet possible.
     * @param {String} url
     * @param {String} name
     * @param {*} value
     */
    downloadFromForm: function downloadFromForm(url, value) {
      var name = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'export';
      // Create a form to POST submit the data to API and download the Excel file.
      // We can't use target = _blank because of the following bug on Safari:
      // https://bugs.webkit.org/show_bug.cgi?id=28633
      var form = document.createElement('form');
      form.method = 'POST';
      form.action = "".concat(_environment.default.APP.api.url, "/").concat(url);
      form.classes = ['hide'];
      var input = document.createElement('input');
      input.name = name;
      input.value = JSON.stringify(value);
      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    }
  });

  var _default = FetchService;
  _exports.default = _default;
});