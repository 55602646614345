define("huvepharma-aviapp-admin/routes/users/edit", ["exports", "@ember/service", "huvepharma-aviapp-admin/routes/protected"], function (_exports, _service, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _protected.default.extend({
    session: (0, _service.inject)(),
    metrics: (0, _service.inject)(),

    /**
     * Get the model
     */
    model: function model(param) {
      return this.store.findRecord('user', param.id).then(function (user) {
        // TODO: Is this required?
        user.set('active', user.get('isBlocked') ? 'blocked' : 'active');
        return user;
      });
    },

    /**
     * Fetch the loginCompany and get the id of the owner, aka ADMIN0.
     * Check if the current user is the only admin in the company.
     * @param {User} model
     * @return {User} the same model
     */
    afterModel: function afterModel(model, transition) {
      var _this = this;

      this.metrics.trackPage({
        page: '/Admin/Users/Edit',
        title: 'User Edit Screen'
      });
      return this.store.findRecord('company', this.get('session.data.loginCompany._id')).then(function (company) {
        var adminCount = company.hasMany('admins').ids().get('length');

        _this.set('company', company);

        _this.set('isOnlyAdmin', adminCount === 1);

        _this.set('isCompanyOwner', company.belongsTo('owner').id() === model.get('id')); // If the user to be edited is the ADMIN0 and the current user is not that user,
        // abort the transition.


        if (_this.isCompanyOwner && !(model.get('id') === _this.get('session.me.id'))) {
          return transition.abort();
        }

        return model;
      });
    },

    /**
     * Setup the controller
     */
    setupController: function setupController(controller, model) {
      var _this2 = this;

      var currentCompany = model.get('companies').find(function (company) {
        return _this2.get('session.data.loginCompany._id') === company._id;
      });
      controller.set('isCurrentUser', model.get('id') === this.get('session.me.id'));
      controller.set('model', model);
      controller.set('prevUserRole', currentCompany ? currentCompany.role : 'user');
      controller.set('userRole', currentCompany ? currentCompany.role : 'user');
      controller.set('company', this.company);
      controller.set('isOnlyAdmin', this.isOnlyAdmin);
      controller.set('isCompanyOwner', this.isCompanyOwner);
    },

    /**
     * Renders a custom header into the header outlet
     */
    renderTemplate: function renderTemplate() {
      this.render();
      this.render('users.edit-header', {
        outlet: 'header',
        controller: this.controllerFor('users.edit'),
        into: 'application'
      });
    }
  });

  _exports.default = _default;
});