define("huvepharma-aviapp-admin/controllers/company/edit", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    flash: (0, _service.inject)(),

    /**
     * Closes the edit controller
     */
    close: function close() {
      this.transitionToRoute('company.company', this.get('model.id') || '');
    },
    actions: {
      /**
       * On close pressed
       */
      close: function close() {
        this.close();
      },

      /**
       * Saves the company
       */
      saveCompany: function saveCompany() {
        var _this = this;

        // Update the model with the editable fields
        var model = this.model;
        model.set('name', this.get('editable.name'));
        model.set('telephone', this.get('editable.telephone'));
        model.set('email', this.get('editable.email'));
        model.set('street', this.get('editable.street'));
        model.set('number', this.get('editable.number'));
        model.set('city', this.get('editable.city'));
        model.set('zipcode', this.get('editable.zipcode'));
        model.set('region', this.get('editable.region'));
        model.set('countryCode', this.get('editable.countryCode')); // Save the model

        model.save().then(function () {
          _this.flash.success({
            message: 'company_label_updated'
          });

          _this.close();
        }).catch(function (e) {
          var errorMessage = 'company_errors_validationfailed';

          if (e.errors && e.errors.length === 1 && e.errors[0].code === '0002') {
            errorMessage = 'company_errors_companynameexists';
          }

          _this.flash.error({
            message: errorMessage
          });
        });
      }
    }
  });

  _exports.default = _default;
});