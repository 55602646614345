define("huvepharma-aviapp-admin/templates/components/auto-archive-age", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vmXiDl9s",
    "block": "[[[10,0],[14,0,\"auto-archive-age\"],[12],[1,\"\\n  \"],[10,2],[14,0,\"auto-archive-age-label\"],[12],[1,[28,[35,0],[[30,0,[\"label\"]]],null]],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@min\",\"@value\",\"@update\"],[[30,0,[\"minValue\"]],[30,0,[\"value\"]],[30,0,[\"handleChange\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"t\",\"form/huve-field-number-step\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/components/auto-archive-age.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});