define("huvepharma-aviapp-admin/controllers/invitation/accept", ["exports", "@ember/controller", "@ember/service", "huvepharma-aviapp-admin/config/environment"], function (_exports, _controller, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    flash: (0, _service.inject)(),
    actions: {
      /**
       * Accepts the invitation
       */
      accept: function accept() {
        var _this = this;

        // If the user does not exist, redirect them to register
        if (!this.get('model.isUserAlreadyExist')) {
          this.transitionToRoute('invitation.register', this.get('model.token'));
          return;
        } // The user already exists, so update their account with the new roles/company.


        this.model.save().then(function () {
          // Notify the user
          _this.flash.success({
            message: 'invitation_accept_success'
          }); // Redirect them to the common frontend to login with their new account


          window.location.replace(_environment.default.APP.common.host);
        }).catch(function (e) {
          _this.flash.danger({
            message: 'invitation_accept_failure',
            interpolations: {
              error: e.errors[0].detail
            }
          });
        });
      }
    }
  });

  _exports.default = _default;
});