define("huvepharma-aviapp-admin/base/enum/countries/en-zh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AL",
    "country": "阿尔巴尼亚"
  }, {
    "code": "DZ",
    "country": "阿尔及利亚"
  }, {
    "code": "AF",
    "country": "阿富汗"
  }, {
    "code": "AR",
    "country": "阿根廷"
  }, {
    "code": "AE",
    "country": "阿拉伯联合酋长国"
  }, {
    "code": "AW",
    "country": "阿鲁巴"
  }, {
    "code": "OM",
    "country": "阿曼"
  }, {
    "code": "AZ",
    "country": "阿塞拜疆"
  }, {
    "code": "AC",
    "country": "阿森松岛"
  }, {
    "code": "EG",
    "country": "埃及"
  }, {
    "code": "ET",
    "country": "埃塞俄比亚"
  }, {
    "code": "IE",
    "country": "爱尔兰"
  }, {
    "code": "EE",
    "country": "爱沙尼亚"
  }, {
    "code": "AD",
    "country": "安道尔"
  }, {
    "code": "AO",
    "country": "安哥拉"
  }, {
    "code": "AI",
    "country": "安圭拉"
  }, {
    "code": "AG",
    "country": "安提瓜和巴布达"
  }, {
    "code": "AT",
    "country": "奥地利"
  }, {
    "code": "AX",
    "country": "奥兰群岛"
  }, {
    "code": "AU",
    "country": "澳大利亚"
  }, {
    "code": "BB",
    "country": "巴巴多斯"
  }, {
    "code": "PG",
    "country": "巴布亚新几内亚"
  }, {
    "code": "BS",
    "country": "巴哈马"
  }, {
    "code": "PK",
    "country": "巴基斯坦"
  }, {
    "code": "PY",
    "country": "巴拉圭"
  }, {
    "code": "PS",
    "country": "巴勒斯坦领土"
  }, {
    "code": "BH",
    "country": "巴林"
  }, {
    "code": "PA",
    "country": "巴拿马"
  }, {
    "code": "BR",
    "country": "巴西"
  }, {
    "code": "BY",
    "country": "白俄罗斯"
  }, {
    "code": "BM",
    "country": "百慕大"
  }, {
    "code": "BG",
    "country": "保加利亚"
  }, {
    "code": "MP",
    "country": "北马里亚纳群岛"
  }, {
    "code": "MK",
    "country": "北马其顿"
  }, {
    "code": "BJ",
    "country": "贝宁"
  }, {
    "code": "BE",
    "country": "比利时"
  }, {
    "code": "IS",
    "country": "冰岛"
  }, {
    "code": "PR",
    "country": "波多黎各"
  }, {
    "code": "PL",
    "country": "波兰"
  }, {
    "code": "BA",
    "country": "波斯尼亚和黑塞哥维那"
  }, {
    "code": "BO",
    "country": "玻利维亚"
  }, {
    "code": "BZ",
    "country": "伯利兹"
  }, {
    "code": "BW",
    "country": "博茨瓦纳"
  }, {
    "code": "BT",
    "country": "不丹"
  }, {
    "code": "BF",
    "country": "布基纳法索"
  }, {
    "code": "BI",
    "country": "布隆迪"
  }, {
    "code": "KP",
    "country": "朝鲜"
  }, {
    "code": "GQ",
    "country": "赤道几内亚"
  }, {
    "code": "DK",
    "country": "丹麦"
  }, {
    "code": "DE",
    "country": "德国"
  }, {
    "code": "DG",
    "country": "迪戈加西亚岛"
  }, {
    "code": "TL",
    "country": "东帝汶"
  }, {
    "code": "TG",
    "country": "多哥"
  }, {
    "code": "DO",
    "country": "多米尼加共和国"
  }, {
    "code": "DM",
    "country": "多米尼克"
  }, {
    "code": "RU",
    "country": "俄罗斯"
  }, {
    "code": "EC",
    "country": "厄瓜多尔"
  }, {
    "code": "ER",
    "country": "厄立特里亚"
  }, {
    "code": "FR",
    "country": "法国"
  }, {
    "code": "FO",
    "country": "法罗群岛"
  }, {
    "code": "PF",
    "country": "法属波利尼西亚"
  }, {
    "code": "GF",
    "country": "法属圭亚那"
  }, {
    "code": "TF",
    "country": "法属南部领地"
  }, {
    "code": "MF",
    "country": "法属圣马丁"
  }, {
    "code": "VA",
    "country": "梵蒂冈"
  }, {
    "code": "PH",
    "country": "菲律宾"
  }, {
    "code": "FJ",
    "country": "斐济"
  }, {
    "code": "FI",
    "country": "芬兰"
  }, {
    "code": "CV",
    "country": "佛得角"
  }, {
    "code": "FK",
    "country": "福克兰群岛"
  }, {
    "code": "GM",
    "country": "冈比亚"
  }, {
    "code": "CG",
    "country": "刚果（布）"
  }, {
    "code": "CD",
    "country": "刚果（金）"
  }, {
    "code": "CO",
    "country": "哥伦比亚"
  }, {
    "code": "CR",
    "country": "哥斯达黎加"
  }, {
    "code": "GD",
    "country": "格林纳达"
  }, {
    "code": "GL",
    "country": "格陵兰"
  }, {
    "code": "GE",
    "country": "格鲁吉亚"
  }, {
    "code": "GG",
    "country": "根西岛"
  }, {
    "code": "CU",
    "country": "古巴"
  }, {
    "code": "GP",
    "country": "瓜德罗普"
  }, {
    "code": "GU",
    "country": "关岛"
  }, {
    "code": "GY",
    "country": "圭亚那"
  }, {
    "code": "KZ",
    "country": "哈萨克斯坦"
  }, {
    "code": "HT",
    "country": "海地"
  }, {
    "code": "KR",
    "country": "韩国"
  }, {
    "code": "NL",
    "country": "荷兰"
  }, {
    "code": "BQ",
    "country": "荷属加勒比区"
  }, {
    "code": "SX",
    "country": "荷属圣马丁"
  }, {
    "code": "ME",
    "country": "黑山"
  }, {
    "code": "HN",
    "country": "洪都拉斯"
  }, {
    "code": "KI",
    "country": "基里巴斯"
  }, {
    "code": "DJ",
    "country": "吉布提"
  }, {
    "code": "KG",
    "country": "吉尔吉斯斯坦"
  }, {
    "code": "GN",
    "country": "几内亚"
  }, {
    "code": "GW",
    "country": "几内亚比绍"
  }, {
    "code": "CA",
    "country": "加拿大"
  }, {
    "code": "GH",
    "country": "加纳"
  }, {
    "code": "IC",
    "country": "加纳利群岛"
  }, {
    "code": "GA",
    "country": "加蓬"
  }, {
    "code": "KH",
    "country": "柬埔寨"
  }, {
    "code": "CZ",
    "country": "捷克"
  }, {
    "code": "ZW",
    "country": "津巴布韦"
  }, {
    "code": "CM",
    "country": "喀麦隆"
  }, {
    "code": "QA",
    "country": "卡塔尔"
  }, {
    "code": "KY",
    "country": "开曼群岛"
  }, {
    "code": "CC",
    "country": "科科斯（基林）群岛"
  }, {
    "code": "KM",
    "country": "科摩罗"
  }, {
    "code": "XK",
    "country": "科索沃"
  }, {
    "code": "CI",
    "country": "科特迪瓦"
  }, {
    "code": "KW",
    "country": "科威特"
  }, {
    "code": "HR",
    "country": "克罗地亚"
  }, {
    "code": "KE",
    "country": "肯尼亚"
  }, {
    "code": "CK",
    "country": "库克群岛"
  }, {
    "code": "CW",
    "country": "库拉索"
  }, {
    "code": "LV",
    "country": "拉脱维亚"
  }, {
    "code": "LS",
    "country": "莱索托"
  }, {
    "code": "LA",
    "country": "老挝"
  }, {
    "code": "LB",
    "country": "黎巴嫩"
  }, {
    "code": "LT",
    "country": "立陶宛"
  }, {
    "code": "LR",
    "country": "利比里亚"
  }, {
    "code": "LY",
    "country": "利比亚"
  }, {
    "code": "LI",
    "country": "列支敦士登"
  }, {
    "code": "RE",
    "country": "留尼汪"
  }, {
    "code": "LU",
    "country": "卢森堡"
  }, {
    "code": "RW",
    "country": "卢旺达"
  }, {
    "code": "RO",
    "country": "罗马尼亚"
  }, {
    "code": "MG",
    "country": "马达加斯加"
  }, {
    "code": "IM",
    "country": "马恩岛"
  }, {
    "code": "MV",
    "country": "马尔代夫"
  }, {
    "code": "MT",
    "country": "马耳他"
  }, {
    "code": "MW",
    "country": "马拉维"
  }, {
    "code": "MY",
    "country": "马来西亚"
  }, {
    "code": "ML",
    "country": "马里"
  }, {
    "code": "MH",
    "country": "马绍尔群岛"
  }, {
    "code": "MQ",
    "country": "马提尼克"
  }, {
    "code": "YT",
    "country": "马约特"
  }, {
    "code": "MU",
    "country": "毛里求斯"
  }, {
    "code": "MR",
    "country": "毛里塔尼亚"
  }, {
    "code": "US",
    "country": "美国"
  }, {
    "code": "UM",
    "country": "美国本土外小岛屿"
  }, {
    "code": "AS",
    "country": "美属萨摩亚"
  }, {
    "code": "VI",
    "country": "美属维尔京群岛"
  }, {
    "code": "MN",
    "country": "蒙古"
  }, {
    "code": "MS",
    "country": "蒙特塞拉特"
  }, {
    "code": "BD",
    "country": "孟加拉国"
  }, {
    "code": "PE",
    "country": "秘鲁"
  }, {
    "code": "FM",
    "country": "密克罗尼西亚"
  }, {
    "code": "MM",
    "country": "缅甸"
  }, {
    "code": "MD",
    "country": "摩尔多瓦"
  }, {
    "code": "MA",
    "country": "摩洛哥"
  }, {
    "code": "MC",
    "country": "摩纳哥"
  }, {
    "code": "MZ",
    "country": "莫桑比克"
  }, {
    "code": "MX",
    "country": "墨西哥"
  }, {
    "code": "NA",
    "country": "纳米比亚"
  }, {
    "code": "ZA",
    "country": "南非"
  }, {
    "code": "AQ",
    "country": "南极洲"
  }, {
    "code": "GS",
    "country": "南乔治亚和南桑威奇群岛"
  }, {
    "code": "SS",
    "country": "南苏丹"
  }, {
    "code": "NR",
    "country": "瑙鲁"
  }, {
    "code": "NI",
    "country": "尼加拉瓜"
  }, {
    "code": "NP",
    "country": "尼泊尔"
  }, {
    "code": "NE",
    "country": "尼日尔"
  }, {
    "code": "NG",
    "country": "尼日利亚"
  }, {
    "code": "NU",
    "country": "纽埃"
  }, {
    "code": "NO",
    "country": "挪威"
  }, {
    "code": "NF",
    "country": "诺福克岛"
  }, {
    "code": "PW",
    "country": "帕劳"
  }, {
    "code": "PN",
    "country": "皮特凯恩群岛"
  }, {
    "code": "PT",
    "country": "葡萄牙"
  }, {
    "code": "JP",
    "country": "日本"
  }, {
    "code": "SE",
    "country": "瑞典"
  }, {
    "code": "CH",
    "country": "瑞士"
  }, {
    "code": "SV",
    "country": "萨尔瓦多"
  }, {
    "code": "WS",
    "country": "萨摩亚"
  }, {
    "code": "RS",
    "country": "塞尔维亚"
  }, {
    "code": "SL",
    "country": "塞拉利昂"
  }, {
    "code": "SN",
    "country": "塞内加尔"
  }, {
    "code": "CY",
    "country": "塞浦路斯"
  }, {
    "code": "SC",
    "country": "塞舌尔"
  }, {
    "code": "SA",
    "country": "沙特阿拉伯"
  }, {
    "code": "BL",
    "country": "圣巴泰勒米"
  }, {
    "code": "CX",
    "country": "圣诞岛"
  }, {
    "code": "ST",
    "country": "圣多美和普林西比"
  }, {
    "code": "SH",
    "country": "圣赫勒拿"
  }, {
    "code": "KN",
    "country": "圣基茨和尼维斯"
  }, {
    "code": "LC",
    "country": "圣卢西亚"
  }, {
    "code": "SM",
    "country": "圣马力诺"
  }, {
    "code": "PM",
    "country": "圣皮埃尔和密克隆群岛"
  }, {
    "code": "VC",
    "country": "圣文森特和格林纳丁斯"
  }, {
    "code": "LK",
    "country": "斯里兰卡"
  }, {
    "code": "SK",
    "country": "斯洛伐克"
  }, {
    "code": "SI",
    "country": "斯洛文尼亚"
  }, {
    "code": "SJ",
    "country": "斯瓦尔巴和扬马延"
  }, {
    "code": "SZ",
    "country": "斯威士兰"
  }, {
    "code": "SD",
    "country": "苏丹"
  }, {
    "code": "SR",
    "country": "苏里南"
  }, {
    "code": "SB",
    "country": "所罗门群岛"
  }, {
    "code": "SO",
    "country": "索马里"
  }, {
    "code": "TJ",
    "country": "塔吉克斯坦"
  }, {
    "code": "TW",
    "country": "台湾"
  }, {
    "code": "TH",
    "country": "泰国"
  }, {
    "code": "TZ",
    "country": "坦桑尼亚"
  }, {
    "code": "TO",
    "country": "汤加"
  }, {
    "code": "TC",
    "country": "特克斯和凯科斯群岛"
  }, {
    "code": "TA",
    "country": "特里斯坦 达库尼亚群岛"
  }, {
    "code": "TT",
    "country": "特立尼达和多巴哥"
  }, {
    "code": "TN",
    "country": "突尼斯"
  }, {
    "code": "TV",
    "country": "图瓦卢"
  }, {
    "code": "TR",
    "country": "土耳其"
  }, {
    "code": "TM",
    "country": "土库曼斯坦"
  }, {
    "code": "TK",
    "country": "托克劳"
  }, {
    "code": "WF",
    "country": "瓦利斯和富图纳"
  }, {
    "code": "VU",
    "country": "瓦努阿图"
  }, {
    "code": "GT",
    "country": "危地马拉"
  }, {
    "code": "XA",
    "country": "伪地区"
  }, {
    "code": "XB",
    "country": "伪双向语言地区"
  }, {
    "code": "VE",
    "country": "委内瑞拉"
  }, {
    "code": "BN",
    "country": "文莱"
  }, {
    "code": "UG",
    "country": "乌干达"
  }, {
    "code": "UA",
    "country": "乌克兰"
  }, {
    "code": "UY",
    "country": "乌拉圭"
  }, {
    "code": "UZ",
    "country": "乌兹别克斯坦"
  }, {
    "code": "GR",
    "country": "希腊"
  }, {
    "code": "ES",
    "country": "西班牙"
  }, {
    "code": "EH",
    "country": "西撒哈拉"
  }, {
    "code": "SG",
    "country": "新加坡"
  }, {
    "code": "NC",
    "country": "新喀里多尼亚"
  }, {
    "code": "NZ",
    "country": "新西兰"
  }, {
    "code": "HU",
    "country": "匈牙利"
  }, {
    "code": "EA",
    "country": "休达及梅利利亚"
  }, {
    "code": "SY",
    "country": "叙利亚"
  }, {
    "code": "JM",
    "country": "牙买加"
  }, {
    "code": "AM",
    "country": "亚美尼亚"
  }, {
    "code": "YE",
    "country": "也门"
  }, {
    "code": "IQ",
    "country": "伊拉克"
  }, {
    "code": "IR",
    "country": "伊朗"
  }, {
    "code": "IL",
    "country": "以色列"
  }, {
    "code": "IT",
    "country": "意大利"
  }, {
    "code": "IN",
    "country": "印度"
  }, {
    "code": "ID",
    "country": "印度尼西亚"
  }, {
    "code": "GB",
    "country": "英国"
  }, {
    "code": "VG",
    "country": "英属维尔京群岛"
  }, {
    "code": "IO",
    "country": "英属印度洋领地"
  }, {
    "code": "JO",
    "country": "约旦"
  }, {
    "code": "VN",
    "country": "越南"
  }, {
    "code": "ZM",
    "country": "赞比亚"
  }, {
    "code": "JE",
    "country": "泽西岛"
  }, {
    "code": "TD",
    "country": "乍得"
  }, {
    "code": "GI",
    "country": "直布罗陀"
  }, {
    "code": "CL",
    "country": "智利"
  }, {
    "code": "CF",
    "country": "中非共和国"
  }, {
    "code": "CN",
    "country": "中国"
  }, {
    "code": "MO",
    "country": "中国澳门特别行政区"
  }, {
    "code": "HK",
    "country": "中国香港特别行政区"
  }];
  _exports.default = _default;
});