define("huvepharma-aviapp-admin/components/tables/huve-gfr-daily-table-header", ["exports", "@huvepharma/huvepharma-aviapp-components/components/tables/huve-gfr-daily-table-header"], function (_exports, _huveGfrDailyTableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _huveGfrDailyTableHeader.default;
    }
  });
});