define("huvepharma-aviapp-admin/templates/invitation/register", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OD5/QThG",
    "block": "[[[41,[30,0,[\"model\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@model\",\"@changeset\",\"@newsURL\",\"@countries\",\"@areaOfExpertise\"],[[30,0,[\"model\"]],[30,0,[\"changeset\"]],[30,0,[\"newsURL\"]],[30,0,[\"countries\"]],[30,0,[\"areaOfExpertise\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"invitation/invitation-form\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/invitation/register.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});