define("huvepharma-aviapp-admin/base/enum/other-programs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "id": "agps",
    "brands": [{
      "id": "albac",
      "name": "Albac®",
      "active": {
        "id": "bacitracin",
        "name": "Bacitracin"
      }
    }, {
      "id": "flavomycinJivetPharmastim",
      "name": "Flavomycin® / Jivet® / Pharmastim®",
      "active": {
        "id": "flavophospholipol",
        "name": "Flavophospholipol"
      }
    }, {
      "id": "bActFlavoCombi",
      "name": "B-Act+® / Flavo Combi®",
      "active": {
        "id": "flavophospholipol",
        "name": "Flavophospholipol"
      }
    }, {
      "id": "other",
      "active": [{
        "id": "bacitracin",
        "name": "Bacitracin"
      }, {
        "id": "flavophospholipol",
        "name": "Flavophospholipol"
      }, {
        "id": "enramycin",
        "name": "Enramycin"
      }, {
        "id": "virgianmycin",
        "name": "Virgianmycin"
      }, {
        "id": "avilamycin",
        "name": "Avilamycin"
      }, {
        "id": "halquinol",
        "name": "Halquinol"
      }, {
        "id": "bambermycin",
        "name": "Bambermycin"
      }, {
        "id": "other"
      }]
    }, {
      "id": "stafac",
      "name": "Stafac®",
      "active": {
        "id": "virginianmycin",
        "name": "Virginianmycin"
      }
    }, {
      "id": "inteprity",
      "name": "Inteprity®",
      "active": {
        "id": "avilamycin",
        "name": "Avilamycin"
      }
    }, {
      "id": "unknown",
      "inactiveFields": true
    }]
  }, {
    "id": "probiotic",
    "brands": [{
      "id": "miyaGold",
      "name": "Miya-Gold®",
      "active": {
        "id": "clostridiumButryricum",
        "name": "Clostridium butryricum"
      }
    }, {
      "id": "topGut",
      "name": "Top Gut®",
      "active": {
        "id": "clostridiumButryricum",
        "name": "Clostridium butryricum"
      }
    }, {
      "id": "bACT",
      "name": "B-ACT®",
      "active": {
        "id": "bacillusLicheniformis",
        "name": "Bacillus licheniformis"
      }
    }, {
      "id": "optiBacL",
      "name": "Opti-Bac L®",
      "active": {
        "id": "bacillusLicheniformis",
        "name": "Bacillus licheniformis"
      }
    }, {
      "id": "optiBacS",
      "name": "Opti-Bac S®",
      "active": {
        "id": "bacillusSubtilis",
        "name": "Bacillus subtilis"
      }
    }, {
      "id": "optiBacSL",
      "name": "Opti-Bac SL®",
      "active": {
        "id": "bacillusSubtilis",
        "name": "Bacillus subtilis"
      }
    }, {
      "id": "bActGold",
      "name": "B-Act Gold®",
      "active": {
        "id": "bacillusLicheniformis",
        "name": "Bacillus licheniformis"
      }
    }, {
      "id": "other",
      "active": [{
        "id": "bacillusSubtilis",
        "name": "Bacillus subtilis"
      }, {
        "id": "bacillusLicheniformis",
        "name": "Bacillus licheniformis"
      }, {
        "id": "clostridiumButryricum",
        "name": "Clostridium butryricum"
      }, {
        "id": "enterococcusFaecium",
        "name": "Enterococcus faecium"
      }, {
        "id": "bacillusAmyloliquefaciens",
        "name": "Bacillus amyloliquefaciens"
      }, {
        "id": "pediococcusAcidilactici",
        "name": "Pediococcus acidilactici"
      }, {
        "id": "lactobacillus",
        "name": "Lactobacillus"
      }, {
        "id": "bifidobacteria",
        "name": "Bifidobacteria"
      }, {
        "id": "yeast",
        "name": "Yeast"
      }, {
        "id": "other"
      }]
    }, {
      "id": "unknown",
      "inactiveFields": true
    }]
  }, {
    "id": "enzyme",
    "brands": [{
      "id": "hostazymP",
      "name": "Hostazym P®",
      "active": {
        "id": "phytase",
        "name": "Phytase"
      }
    }, {
      "id": "hostazymX",
      "name": "Hostazym X®",
      "active": {
        "id": "nsp",
        "name": "NSP"
      }
    }, {
      "id": "hostazymC",
      "name": "Hostazym C®",
      "active": {
        "id": "glucanase",
        "name": "Glucanase"
      }
    }, {
      "id": "optiphos",
      "name": "Optiphos®",
      "active": {
        "id": "phytase",
        "name": "Phytase"
      }
    }, {
      "id": "optiphosPlus",
      "name": "Optiphos®Plus",
      "active": {
        "id": "phytase",
        "name": "Phytase"
      }
    }, {
      "id": "axtraPhyGold",
      "name": "Axtra Phy Gold",
      "active": {
        "id": "phytase",
        "name": "Phytase"
      }
    }, {
      "id": "axtraPhyTPT",
      "name": "Axtra Phy TPT",
      "active": {
        "id": "phytase",
        "name": "Phytase"
      }
    }, {
      "id": "axtraXAP",
      "name": "Axtra XAP",
      "active": {
        "id": "NSP",
        "name": "NSP"
      }
    }, {
      "id": "natuphosE",
      "name": "Natuphos E",
      "active": {
        "id": "phytase",
        "name": "Phytase"
      }
    }, {
      "id": "quantumBlue",
      "name": "Quantum Blue",
      "active": {
        "id": "phytase",
        "name": "Phytase"
      }
    }, {
      "id": "ronozymeHiPhos",
      "name": "Ronozyme HiPhos",
      "active": {
        "id": "phytase",
        "name": "Phytase"
      }
    }, {
      "id": "ronozymeHiPhorius",
      "name": "Ronozyme HiPhorius",
      "active": {
        "id": "phytase",
        "name": "Phytase"
      }
    }, {
      "id": "rovabioPhyPlus",
      "name": "Rovabio Phy Plus",
      "active": {
        "id": "phytase",
        "name": "Phytase"
      }
    }, {
      "id": "axtraXB",
      "name": "Axtra XB",
      "active": {
        "id": "NSP",
        "name": "NSP"
      }
    }, {
      "id": "belfeedB",
      "name": "Belfeed B",
      "active": {
        "id": "NSP",
        "name": "NSP"
      }
    }, {
      "id": "econaseXT",
      "name": "Econase XT",
      "active": {
        "id": "NSP",
        "name": "NSP"
      }
    }, {
      "id": "natugrainTS",
      "name": "Natugrain TS",
      "active": {
        "id": "NSP",
        "name": "NSP"
      }
    }, {
      "id": "rovabio",
      "name": "Rovabio",
      "active": {
        "id": "NSP",
        "name": "NSP"
      }
    }, {
      "id": "ronozymeWX",
      "name": "Ronozyme WX",
      "active": {
        "id": "NSP",
        "name": "NSP"
      }
    }, {
      "id": "ronozymeG2",
      "name": "Ronozyme G2",
      "active": {
        "id": "NSP",
        "name": "NSP"
      }
    }, {
      "id": "kemzymePlus",
      "name": "Kemzyme Plus",
      "active": {
        "id": "NSP",
        "name": "NSP"
      }
    }, {
      "id": "phyzymeXP",
      "name": "Phyzyme XP",
      "active": {
        "id": "NSP",
        "name": "NSP"
      }
    }, {
      "id": "microtechVTR",
      "name": "Microtech/VTR",
      "active": {
        "id": "phytase",
        "name": "Phytase"
      }
    }, {
      "id": "other",
      "active": [{
        "id": "phytase",
        "name": "Phytase"
      }, {
        "id": "nsp",
        "name": "NSP"
      }, {
        "id": "glucanase",
        "name": "Glucanase"
      }]
    }, {
      "id": "unknown",
      "inactiveFields": true
    }]
  }, {
    "id": "prebiotic",
    "freeInput": true
  }, {
    "id": "acids",
    "freeInput": true
  }, {
    "id": "other",
    "brands": [{
      "id": "bioD",
      "name": "Bio-D®",
      "active": {
        "id": "25OH",
        "name": "25-OH"
      }
    }, {
      "id": "hyD",
      "name": "HyD",
      "active": {
        "id": "25OH",
        "name": "25-OH"
      }
    }, {
      "id": "no25OH",
      "name": "No 25-OH",
      "active": {
        "id": "No25OH",
        "name": "No 25-OH"
      }
    }, {
      "id": "clarityQ",
      "name": "Clarity Q",
      "active": {
        "id": "sapomines",
        "name": "Sapomines"
      }
    }, {
      "id": "other",
      "freeInput": true
    }]
  }, {
    "id": "none",
    "inactiveFields": true
  }, {
    "id": "Vaccine",
    "brands": [{
      "id": "avertne",
      "name": "AVERT®NE",
      "active": {
        "id": "cPerfringens",
        "name": "C. perfringens"
      }
    }, {
      "id": "other",
      "name": "Other",
      "active": [{
        "id": "cPerfringens",
        "name": "C. perfringens"
      }, {
        "id": "marek",
        "name": "Marek"
      }, {
        "id": "ibdv",
        "name": "IBDV"
      }, {
        "id": "ib",
        "name": "IB"
      }, {
        "id": "ncd",
        "name": "NCD"
      }, {
        "id": "apv",
        "name": "APV"
      }, {
        "id": "mycoplasma",
        "name": "Mycoplasma"
      }, {
        "id": "ai",
        "name": "AI"
      }, {
        "id": "ecoli",
        "name": "E. coli"
      }, {
        "id": "salmonela",
        "name": "Salmonela"
      }, {
        "id": "ilt",
        "name": "ILT"
      }, {
        "id": "combi",
        "name": "Combi"
      }, {
        "id": "tenosynovitis",
        "name": "Tenosynovitis"
      }, {
        "id": "encephalomyelitis",
        "name": "Encephalomyelitis"
      }, {
        "id": "fowlpox",
        "name": "Fowl pox"
      }, {
        "id": "chickeninfectiousanemia",
        "name": "Chicken infectious anemia"
      }, {
        "id": "laryngotracheitis",
        "name": "Laryngotracheitis"
      }, {
        "id": "fowlcholera",
        "name": "Fowl cholera"
      }, {
        "id": "mycoplasmagallisepticum",
        "name": "Mycoplasma gallisepticum"
      }, {
        "id": "eggdropSyndrom",
        "name": "Egg drop syndrom"
      }, {
        "id": "coryza",
        "name": "Coryza"
      }, {
        "id": "other",
        "name": "Other"
      }]
    }]
  }];
  _exports.default = _default;
});