define("huvepharma-aviapp-admin/components/modal/with-message", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    message: '',
    messageIsMissing: false,

    /**
     * Initialiser
     */
    init: function init() {
      this._super.apply(this, arguments);

      this.resetMessage();
    },

    /**
     * Is the message required?
     * @return {boolean} true, if the message is required
     */
    isRequired: (0, _computed.bool)('isOptional'),

    /**
     * Reset the message to default message or empty string
     */
    resetMessage: function resetMessage() {
      var defaultMessage = this.defaultMessage;
      this.set('message', defaultMessage || '');
      this.set('messageIsMissing', false);
    },
    actions: {
      /**
       * Action to be performed when the user clicks on 'Yes'
       */
      onYes: function onYes() {
        if (!this.isOptional && this.message.length === 0) {
          this.set('messageIsMissing', true);
        } else {
          this.set('messageIsMissing', false);
          this.onYes(this.message);
          this.set('showModal', false);
          this.resetMessage();
        }
      },

      /**
       * Closes the modal
       */
      onCloseModal: function onCloseModal() {
        this.set('showModal', false);
        this.resetMessage();
        var onCancel = this.onCancel;

        if (onCancel && typeof onCancel === 'function') {
          this.onCancel();
        }
      }
    }
  });

  _exports.default = _default;
});