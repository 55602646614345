define("huvepharma-aviapp-admin/base/enum/symptom-type-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "MEDICAL": ["admin", "user"],
    "NONMEDICAL": ["caretaker", "supervisor"]
  };
  _exports.default = _default;
});