define("huvepharma-aviapp-admin/utils/role", ["exports", "huvepharma-aviapp-admin/base/enum/company-roles"], function (_exports, _companyRoles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.withoutReporter = void 0;

  var withoutReporter = function withoutReporter() {
    return _companyRoles.default.filter(function (role) {
      return role !== 'reporter';
    });
  };

  _exports.withoutReporter = withoutReporter;
});