define("huvepharma-aviapp-admin/base/models/request-access", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var RequestAccess = _emberData.default.Model.extend({
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    owner: _emberData.default.belongsTo('user', {
      async: true
    }),
    extraAdminEmail: _emberData.default.attr('string'),
    acceptedUserLicenceAgreement: _emberData.default.attr('boolean'),
    acceptedPrivacyStatement: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });

  var _default = RequestAccess;
  _exports.default = _default;
});