define("huvepharma-aviapp-admin/base/enum/continents/en-pt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AF",
    "continent": "África"
  }, {
    "code": "NA",
    "continent": "América do Norte"
  }, {
    "code": "OC",
    "continent": "Oceânia"
  }, {
    "code": "AN",
    "continent": "Antártica"
  }, {
    "code": "AS",
    "continent": "Ásia"
  }, {
    "code": "EU",
    "continent": "Europa"
  }, {
    "code": "SA",
    "continent": "América do Sul"
  }];
  _exports.default = _default;
});