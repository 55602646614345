define("huvepharma-aviapp-admin/base/models/benchmark", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Benchmark = _emberData.default.Model.extend({
    moduleId: _emberData.default.attr('string'),
    birdCount: _emberData.default.attr('number'),
    countryId: _emberData.default.attr('string'),
    continentId: _emberData.default.attr('string'),
    age: _emberData.default.attr('number'),
    fromDate: _emberData.default.attr('date'),
    mean: _emberData.default.attr('number'),
    percentage: _emberData.default.attr('number'),
    weight: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });

  var _default = Benchmark;
  _exports.default = _default;
});