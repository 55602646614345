define("huvepharma-aviapp-admin/base/enum/broiler-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "id": "2kgMax",
    "name": "≤ 2 kg"
  }, {
    "id": "2To3",
    "name": "2 - 3 kg",
    "isDefault": true
  }, {
    "id": "3kgMin",
    "name": "≥ 3 kg"
  }, {
    "id": "freeRange"
  }, {
    "id": "slowGrowingBirds"
  }, {
    "id": "byProduct"
  }];
  _exports.default = _default;
});