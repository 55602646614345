define("huvepharma-aviapp-admin/base/enum/countries/en-th", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AC",
    "country": "เกาะสวรรค์"
  }, {
    "code": "AD",
    "country": "อันดอร์รา"
  }, {
    "code": "AE",
    "country": "สหรัฐอาหรับเอมิเรตส์"
  }, {
    "code": "AF",
    "country": "อัฟกานิสถาน"
  }, {
    "code": "AG",
    "country": "แอนติกาและบาร์บูดา"
  }, {
    "code": "AI",
    "country": "แองกวิลลา"
  }, {
    "code": "AL",
    "country": "แอลเบเนีย"
  }, {
    "code": "AM",
    "country": "อาร์เมเนีย"
  }, {
    "code": "AO",
    "country": "แองโกลา"
  }, {
    "code": "AQ",
    "country": "แอนตาร์กติกา"
  }, {
    "code": "AR",
    "country": "อาร์เจนตินา"
  }, {
    "code": "AS",
    "country": "อเมริกันซามัว"
  }, {
    "code": "AT",
    "country": "ออสเตรีย"
  }, {
    "code": "AU",
    "country": "ออสเตรเลีย"
  }, {
    "code": "AW",
    "country": "อารูบา"
  }, {
    "code": "AX",
    "country": "หมู่เกาะโอลันด์"
  }, {
    "code": "AZ",
    "country": "อาเซอร์ไบจาน"
  }, {
    "code": "BA",
    "country": "บอสเนียและเฮอร์เซโกวีนา"
  }, {
    "code": "BB",
    "country": "บาร์เบโดส"
  }, {
    "code": "BD",
    "country": "บังกลาเทศ"
  }, {
    "code": "BE",
    "country": "เบลเยียม"
  }, {
    "code": "BF",
    "country": "บูร์กินาฟาโซ"
  }, {
    "code": "BG",
    "country": "บัลแกเรีย"
  }, {
    "code": "BH",
    "country": "บาห์เรน"
  }, {
    "code": "BI",
    "country": "บุรุนดี"
  }, {
    "code": "BJ",
    "country": "เบนิน"
  }, {
    "code": "BL",
    "country": "เซนต์บาร์เธเลมี"
  }, {
    "code": "BM",
    "country": "เบอร์มิวดา"
  }, {
    "code": "BN",
    "country": "บรูไน"
  }, {
    "code": "BO",
    "country": "โบลิเวีย"
  }, {
    "code": "BQ",
    "country": "เนเธอร์แลนด์แคริบเบียน"
  }, {
    "code": "BR",
    "country": "บราซิล"
  }, {
    "code": "BS",
    "country": "บาฮามาส"
  }, {
    "code": "BT",
    "country": "ภูฏาน"
  }, {
    "code": "BW",
    "country": "บอตสวานา"
  }, {
    "code": "BY",
    "country": "เบลารุส"
  }, {
    "code": "BZ",
    "country": "เบลีซ"
  }, {
    "code": "CA",
    "country": "แคนาดา"
  }, {
    "code": "CC",
    "country": "หมู่เกาะโคโคส (คีลิง)"
  }, {
    "code": "CD",
    "country": "คองโก - กินชาซา"
  }, {
    "code": "CF",
    "country": "สาธารณรัฐแอฟริกากลาง"
  }, {
    "code": "CG",
    "country": "คองโก - บราซซาวิล"
  }, {
    "code": "CH",
    "country": "สวิตเซอร์แลนด์"
  }, {
    "code": "CI",
    "country": "โกตดิวัวร์"
  }, {
    "code": "CK",
    "country": "หมู่เกาะคุก"
  }, {
    "code": "CL",
    "country": "ชิลี"
  }, {
    "code": "CM",
    "country": "แคเมอรูน"
  }, {
    "code": "CN",
    "country": "จีน"
  }, {
    "code": "CO",
    "country": "โคลอมเบีย"
  }, {
    "code": "CR",
    "country": "คอสตาริกา"
  }, {
    "code": "CU",
    "country": "คิวบา"
  }, {
    "code": "CV",
    "country": "เคปเวิร์ด"
  }, {
    "code": "CW",
    "country": "คูราเซา"
  }, {
    "code": "CX",
    "country": "เกาะคริสต์มาส"
  }, {
    "code": "CY",
    "country": "ไซปรัส"
  }, {
    "code": "CZ",
    "country": "เช็ก"
  }, {
    "code": "DE",
    "country": "เยอรมนี"
  }, {
    "code": "DG",
    "country": "ดิเอโกการ์เซีย"
  }, {
    "code": "DJ",
    "country": "จิบูตี"
  }, {
    "code": "DK",
    "country": "เดนมาร์ก"
  }, {
    "code": "DM",
    "country": "โดมินิกา"
  }, {
    "code": "DO",
    "country": "สาธารณรัฐโดมินิกัน"
  }, {
    "code": "DZ",
    "country": "แอลจีเรีย"
  }, {
    "code": "EA",
    "country": "เซวตาและเมลียา"
  }, {
    "code": "EC",
    "country": "เอกวาดอร์"
  }, {
    "code": "EE",
    "country": "เอสโตเนีย"
  }, {
    "code": "EG",
    "country": "อียิปต์"
  }, {
    "code": "EH",
    "country": "ซาฮาราตะวันตก"
  }, {
    "code": "ER",
    "country": "เอริเทรีย"
  }, {
    "code": "ES",
    "country": "สเปน"
  }, {
    "code": "ET",
    "country": "เอธิโอเปีย"
  }, {
    "code": "EZ",
    "country": "ยูโรโซน"
  }, {
    "code": "FI",
    "country": "ฟินแลนด์"
  }, {
    "code": "FJ",
    "country": "ฟิจิ"
  }, {
    "code": "FK",
    "country": "หมู่เกาะฟอล์กแลนด์"
  }, {
    "code": "FM",
    "country": "ไมโครนีเซีย"
  }, {
    "code": "FO",
    "country": "หมู่เกาะแฟโร"
  }, {
    "code": "FR",
    "country": "ฝรั่งเศส"
  }, {
    "code": "GA",
    "country": "กาบอง"
  }, {
    "code": "GB",
    "country": "สหราชอาณาจักร"
  }, {
    "code": "GD",
    "country": "เกรเนดา"
  }, {
    "code": "GE",
    "country": "จอร์เจีย"
  }, {
    "code": "GF",
    "country": "เฟรนช์เกียนา"
  }, {
    "code": "GG",
    "country": "เกิร์นซีย์"
  }, {
    "code": "GH",
    "country": "กานา"
  }, {
    "code": "GI",
    "country": "ยิบรอลตาร์"
  }, {
    "code": "GL",
    "country": "กรีนแลนด์"
  }, {
    "code": "GM",
    "country": "แกมเบีย"
  }, {
    "code": "GN",
    "country": "กินี"
  }, {
    "code": "GP",
    "country": "กวาเดอลูป"
  }, {
    "code": "GQ",
    "country": "อิเควทอเรียลกินี"
  }, {
    "code": "GR",
    "country": "กรีซ"
  }, {
    "code": "GS",
    "country": "เกาะเซาท์จอร์เจียและหมู่เกาะเซาท์แซนด์วิช"
  }, {
    "code": "GT",
    "country": "กัวเตมาลา"
  }, {
    "code": "GU",
    "country": "กวม"
  }, {
    "code": "GW",
    "country": "กินี-บิสเซา"
  }, {
    "code": "GY",
    "country": "กายอานา"
  }, {
    "code": "HK",
    "country": "เขตปกครองพิเศษฮ่องกงแห่งสาธารณรัฐประชาชนจีน"
  }, {
    "code": "HN",
    "country": "ฮอนดูรัส"
  }, {
    "code": "HR",
    "country": "โครเอเชีย"
  }, {
    "code": "HT",
    "country": "เฮติ"
  }, {
    "code": "HU",
    "country": "ฮังการี"
  }, {
    "code": "IC",
    "country": "หมู่เกาะคะเนรี"
  }, {
    "code": "ID",
    "country": "อินโดนีเซีย"
  }, {
    "code": "IE",
    "country": "ไอร์แลนด์"
  }, {
    "code": "IL",
    "country": "อิสราเอล"
  }, {
    "code": "IM",
    "country": "เกาะแมน"
  }, {
    "code": "IN",
    "country": "อินเดีย"
  }, {
    "code": "IO",
    "country": "บริติชอินเดียนโอเชียนเทร์ริทอรี"
  }, {
    "code": "IQ",
    "country": "อิรัก"
  }, {
    "code": "IR",
    "country": "อิหร่าน"
  }, {
    "code": "IS",
    "country": "ไอซ์แลนด์"
  }, {
    "code": "IT",
    "country": "อิตาลี"
  }, {
    "code": "JE",
    "country": "เจอร์ซีย์"
  }, {
    "code": "JM",
    "country": "จาเมกา"
  }, {
    "code": "JO",
    "country": "จอร์แดน"
  }, {
    "code": "JP",
    "country": "ญี่ปุ่น"
  }, {
    "code": "KE",
    "country": "เคนยา"
  }, {
    "code": "KG",
    "country": "คีร์กีซสถาน"
  }, {
    "code": "KH",
    "country": "กัมพูชา"
  }, {
    "code": "KI",
    "country": "คิริบาส"
  }, {
    "code": "KM",
    "country": "คอโมโรส"
  }, {
    "code": "KN",
    "country": "เซนต์คิตส์และเนวิส"
  }, {
    "code": "KP",
    "country": "เกาหลีเหนือ"
  }, {
    "code": "KR",
    "country": "เกาหลีใต้"
  }, {
    "code": "KW",
    "country": "คูเวต"
  }, {
    "code": "KY",
    "country": "หมู่เกาะเคย์แมน"
  }, {
    "code": "KZ",
    "country": "คาซัคสถาน"
  }, {
    "code": "LA",
    "country": "ลาว"
  }, {
    "code": "LB",
    "country": "เลบานอน"
  }, {
    "code": "LC",
    "country": "เซนต์ลูเซีย"
  }, {
    "code": "LI",
    "country": "ลิกเตนสไตน์"
  }, {
    "code": "LK",
    "country": "ศรีลังกา"
  }, {
    "code": "LR",
    "country": "ไลบีเรีย"
  }, {
    "code": "LS",
    "country": "เลโซโท"
  }, {
    "code": "LT",
    "country": "ลิทัวเนีย"
  }, {
    "code": "LU",
    "country": "ลักเซมเบิร์ก"
  }, {
    "code": "LV",
    "country": "ลัตเวีย"
  }, {
    "code": "LY",
    "country": "ลิเบีย"
  }, {
    "code": "MA",
    "country": "โมร็อกโก"
  }, {
    "code": "MC",
    "country": "โมนาโก"
  }, {
    "code": "MD",
    "country": "มอลโดวา"
  }, {
    "code": "ME",
    "country": "มอนเตเนโกร"
  }, {
    "code": "MF",
    "country": "เซนต์มาร์ติน"
  }, {
    "code": "MG",
    "country": "มาดากัสการ์"
  }, {
    "code": "MH",
    "country": "หมู่เกาะมาร์แชลล์"
  }, {
    "code": "MK",
    "country": "มาซิโดเนียเหนือ"
  }, {
    "code": "ML",
    "country": "มาลี"
  }, {
    "code": "MM",
    "country": "เมียนมาร์ (พม่า)"
  }, {
    "code": "MN",
    "country": "มองโกเลีย"
  }, {
    "code": "MO",
    "country": "เขตปกครองพิเศษมาเก๊าแห่งสาธารณรัฐประชาชนจีน"
  }, {
    "code": "MP",
    "country": "หมู่เกาะนอร์เทิร์นมาเรียนา"
  }, {
    "code": "MQ",
    "country": "มาร์ตินีก"
  }, {
    "code": "MR",
    "country": "มอริเตเนีย"
  }, {
    "code": "MS",
    "country": "มอนต์เซอร์รัต"
  }, {
    "code": "MT",
    "country": "มอลตา"
  }, {
    "code": "MU",
    "country": "มอริเชียส"
  }, {
    "code": "MV",
    "country": "มัลดีฟส์"
  }, {
    "code": "MW",
    "country": "มาลาวี"
  }, {
    "code": "MX",
    "country": "เม็กซิโก"
  }, {
    "code": "MY",
    "country": "มาเลเซีย"
  }, {
    "code": "MZ",
    "country": "โมซัมบิก"
  }, {
    "code": "NA",
    "country": "นามิเบีย"
  }, {
    "code": "NC",
    "country": "นิวแคลิโดเนีย"
  }, {
    "code": "NE",
    "country": "ไนเจอร์"
  }, {
    "code": "NF",
    "country": "เกาะนอร์ฟอล์ก"
  }, {
    "code": "NG",
    "country": "ไนจีเรีย"
  }, {
    "code": "NI",
    "country": "นิการากัว"
  }, {
    "code": "NL",
    "country": "เนเธอร์แลนด์"
  }, {
    "code": "NO",
    "country": "นอร์เวย์"
  }, {
    "code": "NP",
    "country": "เนปาล"
  }, {
    "code": "NR",
    "country": "นาอูรู"
  }, {
    "code": "NU",
    "country": "นีอูเอ"
  }, {
    "code": "NZ",
    "country": "นิวซีแลนด์"
  }, {
    "code": "OM",
    "country": "โอมาน"
  }, {
    "code": "PA",
    "country": "ปานามา"
  }, {
    "code": "PE",
    "country": "เปรู"
  }, {
    "code": "PF",
    "country": "เฟรนช์โปลินีเซีย"
  }, {
    "code": "PG",
    "country": "ปาปัวนิวกินี"
  }, {
    "code": "PH",
    "country": "ฟิลิปปินส์"
  }, {
    "code": "PK",
    "country": "ปากีสถาน"
  }, {
    "code": "PL",
    "country": "โปแลนด์"
  }, {
    "code": "PM",
    "country": "แซงปีแยร์และมีเกอลง"
  }, {
    "code": "PN",
    "country": "หมู่เกาะพิตแคร์น"
  }, {
    "code": "PR",
    "country": "เปอร์โตริโก"
  }, {
    "code": "PS",
    "country": "ดินแดนปาเลสไตน์"
  }, {
    "code": "PT",
    "country": "โปรตุเกส"
  }, {
    "code": "PW",
    "country": "ปาเลา"
  }, {
    "code": "PY",
    "country": "ปารากวัย"
  }, {
    "code": "QA",
    "country": "กาตาร์"
  }, {
    "code": "RE",
    "country": "เรอูนียง"
  }, {
    "code": "RO",
    "country": "โรมาเนีย"
  }, {
    "code": "RS",
    "country": "เซอร์เบีย"
  }, {
    "code": "RU",
    "country": "รัสเซีย"
  }, {
    "code": "RW",
    "country": "รวันดา"
  }, {
    "code": "SA",
    "country": "ซาอุดีอาระเบีย"
  }, {
    "code": "SB",
    "country": "หมู่เกาะโซโลมอน"
  }, {
    "code": "SC",
    "country": "เซเชลส์"
  }, {
    "code": "SD",
    "country": "ซูดาน"
  }, {
    "code": "SE",
    "country": "สวีเดน"
  }, {
    "code": "SG",
    "country": "สิงคโปร์"
  }, {
    "code": "SH",
    "country": "เซนต์เฮเลนา"
  }, {
    "code": "SI",
    "country": "สโลวีเนีย"
  }, {
    "code": "SJ",
    "country": "สฟาลบาร์และยานไมเอน"
  }, {
    "code": "SK",
    "country": "สโลวะเกีย"
  }, {
    "code": "SL",
    "country": "เซียร์ราลีโอน"
  }, {
    "code": "SM",
    "country": "ซานมาริโน"
  }, {
    "code": "SN",
    "country": "เซเนกัล"
  }, {
    "code": "SO",
    "country": "โซมาเลีย"
  }, {
    "code": "SR",
    "country": "ซูรินาเม"
  }, {
    "code": "SS",
    "country": "ซูดานใต้"
  }, {
    "code": "ST",
    "country": "เซาตูเมและปรินซิปี"
  }, {
    "code": "SV",
    "country": "เอลซัลวาดอร์"
  }, {
    "code": "SX",
    "country": "ซินต์มาร์เทน"
  }, {
    "code": "SY",
    "country": "ซีเรีย"
  }, {
    "code": "SZ",
    "country": "เอสวาตีนี"
  }, {
    "code": "TA",
    "country": "ทริสตันดาคันฮา"
  }, {
    "code": "TC",
    "country": "หมู่เกาะเติกส์และหมู่เกาะเคคอส"
  }, {
    "code": "TD",
    "country": "ชาด"
  }, {
    "code": "TF",
    "country": "เฟรนช์เซาเทิร์นเทร์ริทอรีส์"
  }, {
    "code": "TG",
    "country": "โตโก"
  }, {
    "code": "TH",
    "country": "ไทย"
  }, {
    "code": "TJ",
    "country": "ทาจิกิสถาน"
  }, {
    "code": "TK",
    "country": "โตเกเลา"
  }, {
    "code": "TL",
    "country": "ติมอร์-เลสเต"
  }, {
    "code": "TM",
    "country": "เติร์กเมนิสถาน"
  }, {
    "code": "TN",
    "country": "ตูนิเซีย"
  }, {
    "code": "TO",
    "country": "ตองกา"
  }, {
    "code": "TR",
    "country": "ตุรกี"
  }, {
    "code": "TT",
    "country": "ตรินิแดดและโตเบโก"
  }, {
    "code": "TV",
    "country": "ตูวาลู"
  }, {
    "code": "TW",
    "country": "ไต้หวัน"
  }, {
    "code": "TZ",
    "country": "แทนซาเนีย"
  }, {
    "code": "UA",
    "country": "ยูเครน"
  }, {
    "code": "UG",
    "country": "ยูกันดา"
  }, {
    "code": "UM",
    "country": "หมู่เกาะรอบนอกของสหรัฐอเมริกา"
  }, {
    "code": "UN",
    "country": "องค์การสหประชาชาติ"
  }, {
    "code": "US",
    "country": "สหรัฐอเมริกา"
  }, {
    "code": "UY",
    "country": "อุรุกวัย"
  }, {
    "code": "UZ",
    "country": "อุซเบกิสถาน"
  }, {
    "code": "VA",
    "country": "นครวาติกัน"
  }, {
    "code": "VC",
    "country": "เซนต์วินเซนต์และเกรนาดีนส์"
  }, {
    "code": "VE",
    "country": "เวเนซุเอลา"
  }, {
    "code": "VG",
    "country": "หมู่เกาะบริติชเวอร์จิน"
  }, {
    "code": "VI",
    "country": "หมู่เกาะเวอร์จินของสหรัฐอเมริกา"
  }, {
    "code": "VN",
    "country": "เวียดนาม"
  }, {
    "code": "VU",
    "country": "วานูอาตู"
  }, {
    "code": "WF",
    "country": "วาลลิสและฟุตูนา"
  }, {
    "code": "WS",
    "country": "ซามัว"
  }, {
    "code": "XK",
    "country": "โคโซโว"
  }, {
    "code": "YE",
    "country": "เยเมน"
  }, {
    "code": "YT",
    "country": "มายอต"
  }, {
    "code": "ZA",
    "country": "แอฟริกาใต้"
  }, {
    "code": "ZM",
    "country": "แซมเบีย"
  }, {
    "code": "ZW",
    "country": "ซิมบับเว"
  }];
  _exports.default = _default;
});