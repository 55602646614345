define("huvepharma-aviapp-admin/templates/components/company-switch", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jCQRrdei",
    "block": "[[[6,[39,0],null,[[\"class\",\"show\"],[\"mod-modal-company-switch\",[33,1]]],[[\"default\"],[[[[1,\"  \"],[10,\"h2\"],[14,0,\"center\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"session_modal_multiaccounts_intro\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"multiaccounts_body\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mod-modal-content-scrollable\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,5]],null]],null],null,[[[1,\"      \"],[11,\"button\"],[24,0,\"button-bordered button-full-width\"],[24,4,\"button\"],[4,[38,6],[[30,0],\"chooseCompany\",[30,1]],null],[12],[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n\"],[41,[51,[33,8]],[[[41,[28,[37,10],[[30,1,[\"_id\"]],[33,11,[\"data\",\"loginCompany\",\"_id\"]]],null],[[[1,\"            \"],[10,\"small\"],[12],[1,\"\\n              \"],[1,[28,[35,2],[\"session_modal_multiaccounts_current\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"center\"],[12],[1,\"\\n    \"],[11,3],[24,6,\"#\"],[4,[38,6],[[30,0],\"cancel\"],null],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"app_action_cancel\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"company\"],false,[\"modal/huve-base\",\"isVisible\",\"t\",\"each\",\"-track-array\",\"companies\",\"action\",\"unless\",\"justLoggedIn\",\"if\",\"is-equal\",\"session\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/components/company-switch.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});