define("huvepharma-aviapp-admin/utils/access-rights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findAccessRightsOrDefault = _exports.hasAccessRights = _exports.emptyAccessRights = void 0;

  var defaultAccessRights = function defaultAccessRights(company) {
    return {
      client: company.id,
      clientRights: {
        entireClient: false,
        companies: [],
        complexes: [],
        farms: []
      },
      geographicRights: {
        entireWorld: false,
        countries: [],
        regions: []
      }
    };
  };

  var emptyAccessRights = function emptyAccessRights() {
    return defaultAccessRights({
      id: ''
    });
  };

  _exports.emptyAccessRights = emptyAccessRights;

  var hasAccessRights = function hasAccessRights(user, company) {
    return user.accessRights.some(function (item) {
      return item.client === company.id;
    });
  };

  _exports.hasAccessRights = hasAccessRights;

  var findAccessRightsOrDefault = function findAccessRightsOrDefault(accessRights, company) {
    return accessRights.find(function (item) {
      return item.client === company.id;
    }) || defaultAccessRights(company);
  };

  _exports.findAccessRightsOrDefault = findAccessRightsOrDefault;
});