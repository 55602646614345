define("huvepharma-aviapp-admin/base/models/chart", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Chart = _emberData.default.Model.extend({
    axes: _emberData.default.attr(),
    categories: _emberData.default.attr(),
    content: _emberData.default.attr(),
    farmIds: _emberData.default.attr(),
    module: _emberData.default.attr(),
    table: _emberData.default.attr(),
    title: _emberData.default.attr(),
    type: _emberData.default.attr(),
    visitedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });

  var _default = Chart;
  _exports.default = _default;
});