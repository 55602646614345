define("huvepharma-aviapp-admin/services/ajax", ["exports", "@ember/object", "@ember/service", "ember-ajax/services/ajax", "huvepharma-aviapp-admin/config/environment"], function (_exports, _object, _service, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HOST = _environment.default.APP.api.host;
  var NAMESPACE = _environment.default.APP.api.namespace; // Remark: Additional slash needed because library strips leading slashes.

  var BASE_URL = "/".concat(HOST, "/").concat(NAMESPACE);

  var _default = _ajax.default.extend({
    session: (0, _service.inject)('session'),
    host: BASE_URL,
    headers: (0, _object.computed)('session.data.authenticated.access_token', {
      get: function get() {
        var authToken = this.get('session.data.authenticated.access_token');
        return {
          Authorization: authToken,
          'X-Active-User-Id': this.get('session.data.activeUser'),
          'X-Active-LoginCompany-Id': this.get('session.data.loginCompany._id')
        };
      }
    })
  });

  _exports.default = _default;
});