define("huvepharma-aviapp-admin/routes/users/user", ["exports", "@ember/object", "@ember/service", "huvepharma-aviapp-admin/routes/protected"], function (_exports, _object, _service, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _protected.default.extend({
    metrics: (0, _service.inject)(),

    /**
     * Model
     * @param {Object} param The param
     */
    model: function model(param, transition) {
      var _this = this;

      // Find the user in question
      return this.store.findRecord('user', param.id).then(function (user) {
        if ((0, _object.get)(user, 'isDeletedAt')) {
          return transition.abort();
        }

        if (_this.get('session.isSuperUser')) {
          return {
            user: user
          };
        } // Find the users connections


        return _this.store.query('user-connection', {
          userConnections: user.id
        }).then(function (userConnections) {
          if (userConnections) {
            userConnections.forEach(function (connection) {
              // For each connection, find the 'other user'
              var isInvitor = connection.get('userInvitor.id') === String(user.get('id'));
              (0, _object.set)(connection, 'otherUser', connection.get(isInvitor ? 'userInvitee' : 'userInvitor'));
              (0, _object.set)(connection, 'isInvitor', isInvitor);
            });
          }

          return {
            user: user,
            userConnections: userConnections
          };
        }).catch(function () {
          return {
            user: user
          };
        });
      });
    },

    /**
     * Fetch the loginCompany and get the id of the owner, aka ADMIN0
     * @param {Array<User>} model
     * @return {Array<User>} the same model
     */
    afterModel: function afterModel(model) {
      if (this.get('session.isSuperUser')) {
        return model;
      }

      this.metrics.trackPage({
        page: '/Admin/Users/Detail',
        title: 'User Detail Screen'
      });
      return this.store.findRecord('company', this.get('session.data.loginCompany._id')).then(function (company) {
        // set isCompanyBlocked attribute for blocked users
        company.get('blocked').then(function (blockedUsers) {
          blockedUsers.forEach(function (blockedUser) {
            blockedUser.set('isCompanyBlocked', true);
          });
        });
        model.isCompanyOwner = company.belongsTo('owner').id() === model.user.get('id');
        return model;
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model.user);
      controller.set('userConnections', model.userConnections || []);
      controller.set('isEditable', model.isCompanyOwner ? model.user.get('id') === this.get('session.me.id') : true);
      controller.set('isBlockable', !model.isCompanyOwner && model.user.get('id') !== this.get('session.me.id'));
    }
  });

  _exports.default = _default;
});