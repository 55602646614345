define("huvepharma-aviapp-admin/components/form/huve-select-box", ["exports", "@huvepharma/huvepharma-aviapp-components/components/form/select-box"], function (_exports, _selectBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _selectBox.default;
    }
  });
});