define("huvepharma-aviapp-admin/routes/programs/edit-cocci-program", ["exports", "@ember/object", "@ember/service", "ember-changeset", "ember-changeset-validations", "rsvp", "huvepharma-aviapp-admin/routes/programs/base", "huvepharma-aviapp-admin/validations/models/cocci-program", "huvepharma-aviapp-admin/validations/models/cocci-phase"], function (_exports, _object, _service, _emberChangeset, _emberChangesetValidations, _rsvp, _base, _cocciProgram, _cocciPhase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    metrics: (0, _service.inject)(),

    /**
     * The model
     */
    model: function model(params) {
      return (0, _rsvp.hash)({
        cocciProgram: this.store.findRecord('program', params.id, {
          adapterOptions: {
            addLinkedFlocks: true
          }
        }),
        programs: this.fetchCocciPrograms()
      });
    },
    afterModel: function afterModel() {
      this.metrics.trackPage({
        page: '/Admin/Programs/EditProgram',
        title: 'Edit Cocci Program'
      });
    },

    /**
     * Sets up the controller
     * @param controller The controller
     * @param model The model
     */
    setupController: function setupController(controller, model) {
      var validator = (0, _emberChangesetValidations.default)(_cocciProgram.default);
      var changeset = new _emberChangeset.default(model.cocciProgram, validator, _cocciProgram.default, {
        skipValidate: true
      });
      (0, _object.set)(controller, 'changeset', changeset);
      (0, _object.set)(controller, 'model', model);
      (0, _object.set)(controller, 'programs', model.programs);
      var phases = (0, _object.get)(model, 'cocciProgram.phases');
      (0, _object.set)(controller, 'phases', phases.toArray().map(function (phase) {
        return new _emberChangeset.default(phase, (0, _emberChangesetValidations.default)(_cocciPhase.default), _cocciPhase.default, {
          skipValidate: true
        });
      }));
      this.setMinEndDate({
        controller: controller,
        startDate: (0, _object.get)(changeset, 'startDate')
      });
      this.setMaxStartDate({
        controller: controller,
        endDate: (0, _object.get)(changeset, 'endDate')
      });
      this.setFlocks(controller, (0, _object.get)(model.cocciProgram, 'linkedFlocks'));
    },

    /*
     * @summary Renders a custom header into the header outlet
     */
    renderTemplate: function renderTemplate() {
      this.render();
      this.render('programs.edit-program-header', {
        outlet: 'header',
        controller: this.controllerFor('programs.edit-cocci-program'),
        into: 'application'
      });
    }
  });

  _exports.default = _default;
});