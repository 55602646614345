define("huvepharma-aviapp-admin/utils/fetch", ["exports", "rsvp", "jquery", "huvepharma-aviapp-admin/config/environment"], function (_exports, _rsvp, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fetch;

  /**
   * $.ajax wrapper with authentication
   * @param  {Object} session
   * @param  {String} url
   * @param  {Object} data
   * @return {Promise<Object>} API Response
   */
  function fetch(session, url) {
    var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'GET';
    var data = arguments.length > 3 ? arguments[3] : undefined;
    var contentType = arguments.length > 4 ? arguments[4] : undefined;
    return new _rsvp.Promise(function (resolve, reject) {
      // eslint-disable-next-line camelcase
      var _session$get = session.get('data.authenticated'),
          access_token = _session$get.access_token;

      var settings = {
        headers: {
          // eslint-disable-next-line camelcase
          Authorization: "Bearer ".concat(access_token),
          'X-Active-User-Id': session.get('me.activeUser'),
          'X-Active-LoginCompany-Id': session.get('data.loginCompany._id')
        },
        method: method,
        data: data
      };

      if (contentType) {
        settings.contentType = contentType;
      }

      return _jquery.default.ajax("".concat(_environment.default.APP.api.url).concat(url), settings).done(function (response) {
        return resolve(response);
      }).fail(function (error) {
        return reject(error);
      });
    });
  }
});