define("huvepharma-aviapp-admin/templates/clients/add-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iek5tbjO",
    "block": "[[[1,[28,[35,0],null,[[\"close\",\"headerTitle\",\"testId\"],[[28,[37,1],[[30,0],\"close\"],null],[52,[33,3],[28,[37,4],[\"action_add_client\"],null],[28,[37,4],[\"clients_add_addedclient_header\"],null]],\"client\"]]]],[1,\"\\n\"]],[],false,[\"header/huve-detail-header\",\"action\",\"if\",\"requestSent\",\"t\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/clients/add-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});