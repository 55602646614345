define("huvepharma-aviapp-admin/components/modal/huve-closeable-info", ["exports", "@huvepharma/huvepharma-aviapp-components/components/modal/modal-closeable-info"], function (_exports, _modalCloseableInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _modalCloseableInfo.default;
    }
  });
});