define("huvepharma-aviapp-admin/routes/company/index", ["exports", "@ember/service", "huvepharma-aviapp-admin/routes/protected"], function (_exports, _service, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _protected.default.extend({
    session: (0, _service.inject)('session'),

    /**
     * Navigate to the company detail page without any id specified
     */
    afterModel: function afterModel() {
      var companyId = this.get('session.data.loginCompany._id') || '';
      this.replaceWith('company.company', companyId);
    }
  });

  _exports.default = _default;
});