define("huvepharma-aviapp-admin/components/header/huve-back-link", ["exports", "@huvepharma/huvepharma-aviapp-components/components/header/back-link"], function (_exports, _backLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _backLink.default;
    }
  });
});