define("huvepharma-aviapp-admin/base/enum/continents/en-cs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AF",
    "continent": "Afrika"
  }, {
    "code": "NA",
    "continent": "Severní Amerika"
  }, {
    "code": "OC",
    "continent": "Oceánie"
  }, {
    "code": "AN",
    "continent": "Antarktida"
  }, {
    "code": "AS",
    "continent": "Asie"
  }, {
    "code": "EU",
    "continent": "Evropa"
  }, {
    "code": "SA",
    "continent": "Jižní Amerika"
  }];
  _exports.default = _default;
});