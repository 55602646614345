define("huvepharma-aviapp-admin/mixins/translateable-attributes", ["exports", "@ember/object", "@ember/object/mixin", "@ember/application"], function (_exports, _object, _mixin, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var customDefineProperty = function customDefineProperty(object, prop, translationKey) {
    (0, _object.defineProperty)(object, prop, (0, _object.computed)(function () {
      /* return t(translationKey); */
    }).property(translationKey));
  };

  var _default = _mixin.default.create({
    init: function init() {
      var _this$_super;

      for (var _len = arguments.length, props = new Array(_len), _key = 0; _key < _len; _key++) {
        props[_key] = arguments[_key];
      }

      var result = (_this$_super = this._super).apply.apply(_this$_super, [this].concat(props));

      if ((0, _application.getOwner)(this)) {
        var t = (0, _application.getOwner)(this).lookup('utils:t');
        var isTranslateableAttribute = /(.+)Translation$/; // eslint-disable-next-line

        for (var key in this) {
          var translateableAttributeMatch = key.match(isTranslateableAttribute);

          if (translateableAttributeMatch) {
            var translationKey = this.get(key);
            customDefineProperty(this, translateableAttributeMatch[1], translationKey, t);
          }
        }
      }

      return result;
    }
  });

  _exports.default = _default;
});