define("huvepharma-aviapp-admin/base/enum/regions_translated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "subdivision_names": {
      "en": {
        "100": "district",
        "101": "autonomous community",
        "102": "city",
        "103": "province",
        "104": "department",
        "105": "province",
        "106": "region",
        "107": "province",
        "108": "municipality",
        "109": "city",
        "110": "urban community",
        "111": "department",
        "112": "council area",
        "113": "two-tier county",
        "114": "region",
        "115": "county",
        "116": "department",
        "117": "region",
        "118": "parish",
        "119": "governorate",
        "120": "district",
        "121": "municipality",
        "122": "province",
        "123": "district",
        "124": "governorate",
        "125": "department",
        "126": "province",
        "127": "region",
        "128": "region",
        "129": "state",
        "130": "region",
        "131": "city",
        "132": "region",
        "133": "province",
        "134": "metropolitan administration",
        "135": "region",
        "136": "city",
        "137": "district",
        "138": "Pakistan administered area",
        "139": "autonomous sector",
        "140": "special city",
        "141": "district",
        "142": "municipality",
        "143": "capital district",
        "145": "region",
        "147": "federal territory",
        "148": "autonomous territorial unit",
        "149": "district",
        "150": "region",
        "151": "metropolitan department",
        "152": "district",
        "153": "canton",
        "154": "region",
        "155": "province",
        "156": "state",
        "157": "region",
        "158": "overseas department",
        "159": "geographical unit",
        "160": "governorate",
        "161": "prefecture",
        "162": "chain (of islands)",
        "163": "region",
        "164": "district",
        "165": "municipality",
        "166": "district",
        "167": "municipality",
        "168": "province",
        "169": "province",
        "170": "province",
        "171": "district",
        "172": "district",
        "173": "province",
        "174": "island",
        "175": "region",
        "176": "region",
        "177": "island council",
        "178": "development region",
        "179": "municipality",
        "180": "autonomous city in North Africa",
        "181": "state",
        "182": "city",
        "183": "district",
        "184": "governorate",
        "185": "self-governed part",
        "186": "autonomous region",
        "187": "city",
        "189": "city",
        "190": "municipality",
        "191": "federal capital territory",
        "192": "region",
        "193": "state",
        "194": "economic prefecture",
        "195": "autonomous region",
        "196": "municipality",
        "197": "district",
        "198": "Land",
        "199": "emirate",
        "200": "state",
        "201": "region",
        "202": "administrative region",
        "203": "department",
        "204": "county",
        "205": "province",
        "206": "autonomous municipality",
        "207": "province",
        "208": "governorate",
        "209": "district",
        "210": "county",
        "211": "municipality",
        "212": "quarter",
        "213": "province",
        "214": "province",
        "215": "district",
        "216": "region",
        "217": "province",
        "218": "region",
        "219": "parish",
        "222": "territorial unit",
        "223": "special municipality",
        "224": "autonomous province",
        "225": "division",
        "226": "special city",
        "227": "metropolitan city",
        "229": "capital territory",
        "230": "geographical entity",
        "231": "department",
        "232": "indigenous region",
        "233": "territory",
        "234": "city",
        "235": "province",
        "237": "entity",
        "238": "district",
        "239": "state",
        "240": "metropolitan region",
        "241": "nation",
        "242": "parish",
        "243": "municipality",
        "244": "province",
        "245": "city with county rights",
        "246": "county",
        "247": "prefecture",
        "248": "republican city",
        "249": "province",
        "250": "region",
        "251": "province",
        "252": "region",
        "253": "district",
        "254": "geographical region",
        "255": "islands, groups of islands",
        "256": "state",
        "257": "county",
        "258": "zone",
        "260": "province",
        "261": "province",
        "262": "parish",
        "263": "province",
        "264": "state",
        "265": "outlying area",
        "266": "district",
        "267": "city",
        "268": "special administrative region",
        "269": "special region",
        "270": "autonomous region",
        "271": "city",
        "272": "special municipality",
        "273": "prefecture",
        "274": "division",
        "275": "province",
        "276": "department",
        "277": "district",
        "278": "province",
        "279": "department",
        "280": "local council",
        "281": "province",
        "282": "region",
        "283": "district",
        "284": "unitary authority",
        "285": "province",
        "286": "province",
        "287": "region",
        "288": "municipality",
        "289": "region",
        "290": "administrative region",
        "291": "province",
        "292": "city",
        "293": "republic",
        "294": "municipality",
        "295": "department",
        "296": "department",
        "297": "district",
        "298": "dependency",
        "299": "autonomous republic",
        "300": "region",
        "301": "province",
        "302": "region",
        "303": "region",
        "304": "district",
        "305": "dependency",
        "306": "state",
        "307": "town council",
        "308": "municipality",
        "309": "autonomous district",
        "310": "autonomous region",
        "311": "autonomous city",
        "312": "federal dependency",
        "313": "geographical region",
        "314": "city",
        "315": "autonomous region",
        "316": "governorate",
        "317": "region",
        "318": "province",
        "319": "region",
        "320": "province",
        "321": "province",
        "322": "county",
        "324": "territory",
        "325": "rayon",
        "326": "administrative atoll",
        "327": "Union territory",
        "328": "region",
        "329": "province",
        "330": "region",
        "331": "commune",
        "332": "popularate",
        "333": "province",
        "334": "district",
        "335": "state",
        "336": "territory",
        "337": "district",
        "338": "governorate",
        "339": "prefecture",
        "340": "dependency",
        "341": "federal district",
        "342": "province",
        "343": "province",
        "344": "county",
        "345": "arctic region",
        "346": "administrative region",
        "347": "city",
        "348": "special administrative city",
        "349": "governorate",
        "350": "capital",
        "351": "dependency",
        "353": "dependency",
        "354": "district",
        "355": "autonomous region",
        "356": "capital city",
        "357": "autonomous republic",
        "358": "capital district",
        "359": "area",
        "360": "capital city",
        "361": "district",
        "362": "district",
        "363": "state",
        "364": "region",
        "365": "province",
        "366": "province",
        "367": "district",
        "368": "overseas territorial collectivity",
        "369": "metropolitan district",
        "370": "London borough",
        "372": "department",
        "373": "state",
        "374": "province",
        "375": "parish",
        "376": "district",
        "377": "county",
        "378": "municipality",
        "379": "department",
        "380": "region",
        "381": "division",
        "382": "city",
        "383": "region",
        "384": "parish",
        "385": "capital district",
        "386": "governorate",
        "387": "province",
        "388": "region",
        "389": "province",
        "390": "province",
        "391": "region",
        "392": "state",
        "393": "state",
        "394": "province",
        "395": "state",
        "396": "administrative territory",
        "397": "municipality",
        "398": "country",
        "399": "governorate",
        "400": "republic",
        "401": "city",
        "402": "city corporation",
        "403": "federal district",
        "404": "city",
        "405": "province",
        "406": "capital city",
        "407": "county",
        "408": "province",
        "409": "administration",
        "410": "division",
        "411": "oblast",
        "412": "province",
        "413": "special municipality",
        "414": "country",
        "415": "prefecture",
        "416": "region",
        "417": "province",
        "418": "province",
        "419": "region",
        "420": "governorate",
        "421": "district",
        "422": "county",
        "423": "municipality",
        "424": "province",
        "425": "region",
        "426": "province",
        "427": "region",
        "428": "province",
        "429": "parish",
        "430": "parish",
        "431": "group of islands (20 inhabited islands)",
        "432": "state",
        "433": "republic",
        "435": "capital territory",
        "436": "district",
        "437": "city",
        "438": "district with special status",
        "439": "capital",
        "440": "special island authority",
        "441": "special municipality",
        "442": "city",
        "445": "city",
        "446": "commune",
        "447": "town",
        "448": "city",
        "449": "region",
        "450": "province",
        "451": "county",
        "452": "special self-governing province",
        "453": "special self-governing city",
        "454": "city municipality",
        "455": "district municipality",
        "456": "municipality",
        "457": "union territory",
        "458": "capital territory",
        "459": "city",
        "460": "autonomous district",
        "461": "district",
        "462": "canton",
        "463": "ward",
        "464": "administrative precinct",
        "465": "capital city",
        "466": "overseas region",
        "472": "districts under republic administration",
        "473": "metropolitan city",
        "TERRITORIES": "Territories"
      },
      "ca": {
        "262": "parròquia"
      },
      "fr": {
        "100": "district",
        "101": "communauté autonome",
        "102": "ville",
        "103": "province",
        "104": "département",
        "105": "province",
        "106": "région",
        "107": "province",
        "108": "municipalité",
        "109": "ville",
        "110": "communauté urbaine",
        "111": "département",
        "112": "zone de conseil",
        "113": "comté à 2 niveaux",
        "114": "région",
        "115": "comté",
        "116": "département",
        "117": "région",
        "118": "paroisse",
        "119": "gouvernorat",
        "120": "district",
        "121": "municipalité",
        "122": "province",
        "123": "district",
        "124": "gouvernorat",
        "125": "département",
        "126": "province",
        "127": "région",
        "128": "région",
        "129": "État",
        "130": "région",
        "131": "ville",
        "132": "région",
        "133": "province",
        "134": "administration métropolitaine",
        "135": "région",
        "136": "ville",
        "137": "district",
        "138": "zone administrée par le Pakistan",
        "139": "secteur autonome",
        "140": "ville spéciale",
        "141": "district fédéral",
        "142": "municipalité",
        "143": "district de la capitale",
        "145": "région",
        "147": "territoire fédéral",
        "148": "unité territoriale autonome",
        "149": "district",
        "150": "région",
        "151": "département métropolitain",
        "152": "district",
        "153": "canton",
        "154": "région",
        "155": "province",
        "156": "État",
        "157": "région",
        "158": "département d'outre-mer",
        "159": "unité géographique",
        "160": "gouvernorat",
        "161": "préfecture",
        "162": "chaîne d’îles",
        "163": "région",
        "164": "district",
        "165": "municipalité",
        "166": "district",
        "167": "municipalité",
        "168": "province",
        "169": "province",
        "170": "province",
        "171": "district",
        "172": "district",
        "173": "province",
        "174": "île",
        "175": "région",
        "176": "région",
        "177": "conseil insulaire",
        "178": "région de développement",
        "179": "municipalité",
        "180": "ville autonome en Afrique du Nord",
        "181": "État",
        "182": "ville",
        "183": "district",
        "184": "gouvernorat",
        "185": "partie auto-gouvernée",
        "186": "région autonome",
        "187": "ville",
        "189": "ville",
        "190": "municipalité",
        "191": "territoire de la capitale fédérale",
        "192": "région",
        "193": "État",
        "194": "préfecture économique",
        "195": "région autonome",
        "196": "municipalité",
        "197": "district",
        "198": "land",
        "199": "émirat",
        "200": "État",
        "201": "région",
        "202": "région administrative",
        "203": "département",
        "204": "comitat",
        "205": "province",
        "206": "municipalité autonome",
        "207": "province",
        "208": "gouvernorat",
        "209": "district",
        "210": "région",
        "211": "commune",
        "212": "quartier",
        "213": "province",
        "214": "province",
        "215": "district",
        "216": "région",
        "217": "région (voïvodie)",
        "218": "région",
        "219": "paroisse",
        "222": "unité territoriale",
        "223": "municipalité spéciale",
        "224": "province autonome",
        "225": "division",
        "226": "ville spéciale",
        "227": "ville métropolitaine",
        "229": "territoire de la capitale",
        "230": "entité géographique",
        "231": "département",
        "232": "région indigène",
        "233": "territoire",
        "234": "ville",
        "235": "province",
        "237": "entité",
        "238": "district",
        "239": "État",
        "240": "région métropolitaine",
        "241": "nation",
        "242": "paroisse",
        "243": "commune",
        "244": "province",
        "245": "ville ayant un rang de comitat",
        "246": "comté",
        "247": "département",
        "248": "ville républicaine",
        "249": "province",
        "250": "région",
        "251": "gouvernorat",
        "252": "région",
        "253": "district",
        "254": "région géographique",
        "255": "île, groupe d’îles",
        "256": "État",
        "257": "département",
        "258": "zone",
        "260": "province",
        "261": "province",
        "262": "paroisse",
        "263": "province",
        "264": "État",
        "265": "zone éloignée",
        "266": "district",
        "267": "ville",
        "268": "région administrative spéciale",
        "269": "région spéciale",
        "270": "région autonome",
        "271": "ville",
        "272": "commune speciale",
        "273": "préfecture",
        "274": "division",
        "275": "province",
        "276": "département",
        "277": "district",
        "278": "province",
        "279": "département",
        "280": "conseil local",
        "281": "province",
        "282": "région",
        "283": "district",
        "284": "autorité unitaire",
        "285": "province",
        "286": "province",
        "287": "région",
        "288": "municipalité",
        "289": "région",
        "290": "région administrative",
        "291": "province",
        "292": "ville",
        "293": "république",
        "294": "municipalité",
        "295": "département",
        "296": "département",
        "297": "district",
        "298": "dépendance",
        "299": "république autonome",
        "300": "région",
        "301": "province",
        "302": "région",
        "303": "région",
        "304": "district",
        "305": "dépendance",
        "306": "État",
        "307": "conseil urbain",
        "308": "municipalité",
        "309": "district autonome",
        "310": "région autonome",
        "311": "ville autonome",
        "312": "dépendance fédérale",
        "313": "région géographique",
        "314": "ville",
        "315": "région autonome",
        "316": "gouvernorat",
        "317": "région",
        "318": "province",
        "319": "région",
        "320": "province",
        "321": "province",
        "322": "comté",
        "324": "territoire",
        "325": "district",
        "326": "atoll administratif",
        "327": "territoire de l’Union",
        "328": "région",
        "329": "province",
        "330": "région",
        "331": "commune",
        "332": "municipalité",
        "333": "province",
        "334": "district",
        "335": "État",
        "336": "territoire",
        "337": "district",
        "338": "gouvernorat",
        "339": "préfecture",
        "340": "dépendance",
        "341": "district fédéral",
        "342": "province",
        "343": "province",
        "344": "comté",
        "345": "région arctique",
        "346": "région administrative",
        "347": "ville",
        "348": "ville administrative spéciale",
        "349": "gouvernorat",
        "350": "capitale",
        "351": "dépendance",
        "353": "dépendance",
        "354": "district",
        "355": "région autonome",
        "356": "ville capitale",
        "357": "république autonome",
        "358": "district de la capitale",
        "359": "zone",
        "360": "capitale",
        "361": "district",
        "362": "district",
        "363": "État régional",
        "364": "région",
        "365": "province",
        "366": "province",
        "367": "district",
        "368": "collectivité territoriale d'outre-mer",
        "369": "district métropolitain",
        "370": "arrondissement de Londres",
        "372": "département",
        "373": "État",
        "374": "province",
        "375": "paroisse",
        "376": "district",
        "377": "comté",
        "378": "municipalité",
        "379": "département",
        "380": "région",
        "381": "division",
        "382": "ville",
        "383": "région",
        "384": "paroisse",
        "385": "district de la capitale",
        "386": "gouvernorat",
        "387": "province",
        "388": "région",
        "389": "province",
        "390": "province",
        "391": "région",
        "392": "État",
        "393": "État",
        "394": "province",
        "395": "État",
        "396": "territoire administratif",
        "397": "municipalité",
        "399": "gouvernorat",
        "400": "république",
        "401": "ville",
        "402": "corporation urbaine",
        "403": "district fédéral",
        "404": "ville",
        "405": "province",
        "406": "ville capitale",
        "407": "département",
        "408": "province",
        "409": "administration",
        "410": "division",
        "411": "région",
        "412": "province",
        "414": "pays",
        "415": "préfecture",
        "416": "région",
        "417": "province",
        "418": "province",
        "419": "région",
        "420": "gouvernorat",
        "421": "district",
        "422": "département",
        "423": "municipalité",
        "424": "province",
        "425": "région",
        "426": "département",
        "427": "région",
        "428": "province",
        "429": "paroisse",
        "430": "paroisse",
        "431": "groupe d’îles (20 îles habitées)",
        "432": "État",
        "433": "république",
        "435": "territoire de la capitale",
        "436": "district",
        "437": "ville",
        "438": "district",
        "439": "capitale",
        "440": "territoire insulaire",
        "441": "municipalité spéciale",
        "442": "ville",
        "445": "ville",
        "446": "commune",
        "447": "chef-lieu",
        "448": "ville",
        "449": "région",
        "450": "province",
        "451": "comté",
        "452": "province autonome spéciale",
        "453": "ville autonome spéciale",
        "454": "municipalité de la ville",
        "455": "municipalité du district",
        "456": "municipalité",
        "457": "territoire de l'Union",
        "458": "territoire de la capitale",
        "459": "ville",
        "460": "district autonome",
        "461": "district",
        "462": "canton",
        "463": "circonscription",
        "464": "circonscription",
        "465": "ville capitale",
        "466": "région d'outre-mer",
        "472": "Districts sous l'administration de la République",
        "473": "ville métropolitaine"
      },
      "ar": {
        "119": "muḩāfaz̧ah",
        "124": "wilaya",
        "160": "muḩāfaz̧ah",
        "161": "‘amālah",
        "174": "jazā’ir",
        "179": "baladīyah",
        "199": "imārah",
        "208": "mouhâfazah, muḩāfaz̧ah",
        "214": "minţaqah",
        "218": "minţaqah",
        "249": "iqlīm",
        "251": "muḩāfaz̧ah",
        "287": "jihāt",
        "289": "wilaya",
        "316": "muḩāfaz̧ah",
        "317": "minţaqah",
        "332": "sha‘bīyah",
        "338": "muḩāfaz̧ah",
        "349": "muḩāfaz̧ah",
        "366": "wilaya",
        "380": "minṭaqah",
        "386": "muḩāfaz̧ah",
        "395": "wilayah",
        "399": "muḩāfaz̧ah",
        "401": "madīnah",
        "420": "muḩāfaz̧ah"
      },
      "ps": {
        "321": "wilāyat"
      },
      "fa": {
        "321": "wilāyat",
        "418": "ostān"
      },
      "sq": {
        "322": "qark"
      },
      "hy": {
        "106": "marz",
        "189": "k'aġak'"
      },
      "pt": {
        "131": "cidade",
        "133": "província",
        "139": "sector autónomo",
        "155": "província",
        "193": "estado",
        "196": "concelho",
        "244": "província",
        "253": "distrito",
        "270": "região autónoma",
        "281": "província",
        "300": "região",
        "301": "província",
        "304": "distrito",
        "313": "região geográfica",
        "403": "distrito federal",
        "416": "região"
      },
      "es": {
        "101": "comunidad autónoma",
        "104": "departamento",
        "105": "provincia",
        "107": "provincia",
        "111": "departamento",
        "125": "departamento",
        "127": "región",
        "143": "distrito capital",
        "180": "ciudad autónoma en el Norte de África",
        "182": "ciudad",
        "183": "distrito",
        "190": "municipalidad",
        "203": "departamento",
        "231": "departamento",
        "232": "comarca indígena",
        "239": "estado",
        "244": "provincia",
        "256": "estado",
        "295": "departamento",
        "300": "región",
        "310": "región autónoma",
        "312": "dependencia federal",
        "318": "provincia",
        "320": "provincia",
        "341": "distrito federal",
        "350": "capital",
        "364": "región",
        "365": "provincia",
        "372": "departamento",
        "379": "departamento",
        "385": "distrito capital",
        "389": "provincia",
        "408": "provincia",
        "441": "municipio especial",
        "449": "región"
      },
      "de": {
        "153": "Kanton",
        "198": "Land",
        "331": "Gemeinde",
        "390": "Provinz",
        "391": "Region",
        "392": "Bundesland",
        "462": "Kanton"
      },
      "az": {
        "108": "şәhәr",
        "325": "rayon",
        "357": "muxtar respublika"
      },
      "sr": {
        "224": "autonomna pokrajina",
        "237": "entitet",
        "271": "grad",
        "337": "okrug",
        "438": "distrikt"
      },
      "hr": {
        "115": "županija",
        "237": "entitet",
        "347": "grad",
        "438": "distrikt"
      },
      "bs": {
        "237": "entitet",
        "438": "distrikt"
      },
      "bn": {
        "149": "zila",
        "274": "bibhag"
      },
      "nl": {
        "123": "distrikt",
        "272": "bijzondere gemeente",
        "387": "provincie",
        "390": "provincie",
        "391": "gewest",
        "398": "land",
        "413": "bijzondere gemeente"
      },
      "bg": {
        "361": "oblast"
      },
      "rn": {
        "275": "iprovense"
      },
      "ms": {
        "147": "wilayah persekutuan",
        "156": "negeri",
        "362": "daerah"
      },
      "pap": {
        "272": "munisipio spesial",
        "398": "pais",
        "413": "municipio special"
      },
      "dz": {
        "152": "dzongkhag"
      },
      "ru": {
        "102": "gorod",
        "187": "gorod",
        "290": "oblast'",
        "309": "avtonomnyy okrug",
        "311": "avtonomnyy gorod",
        "328": "oblast'",
        "330": "oblast'",
        "355": "avtonomnaya oblast'",
        "396": "kray",
        "411": "oblast'",
        "433": "respublika",
        "437": "gorod"
      },
      "be": {
        "102": "horad",
        "411": "voblasts' / voblasc'"
      },
      "sg": {
        "194": "sêse tî kömändâ-kötä",
        "339": "sêse tî kömändâ-kötä",
        "446": "kötä gbätä"
      },
      "rm": {
        "153": "chantun"
      },
      "it": {
        "117": "regione",
        "153": "cantone",
        "165": "castello",
        "374": "provincia"
      },
      "zh": {
        "103": "sheng",
        "136": "shih",
        "195": "zizhiqu",
        "223": "chih-hsia-shih",
        "268": "tebie xingzhengqu",
        "344": "hsien",
        "397": "shi"
      },
      "tr": {
        "266": "kaza",
        "426": "il"
      },
      "el": {
        "185": "aftonomi monastiki politeia",
        "202": "periféreia",
        "266": "eparchia"
      },
      "cs": {
        "154": "kraj",
        "197": "okres",
        "465": "hlavní mešto"
      },
      "da": {
        "319": "region"
      },
      "et": {
        "407": "maakond"
      },
      "ti": {
        "317": "zoba"
      },
      "am": {
        "363": "kilil",
        "409": "āstedader"
      },
      "sv": {
        "150": "landskap",
        "422": "län"
      },
      "fi": {
        "150": "maakunta"
      },
      "ka": {
        "201": "mkhare",
        "299": "avt'onomiuri resp'ublik'a",
        "314": "kalaki"
      },
      "kl": {
        "243": "kommunia"
      },
      "ht": {
        "116": "depatman"
      },
      "hu": {
        "204": "megye",
        "245": "megyei jogú város",
        "406": "főváros"
      },
      "id": {
        "205": "provinsi",
        "269": "daerah istimewa",
        "358": "daerah khusus ibukota"
      },
      "ga": {
        "246": "contae",
        "417": "cúige"
      },
      "he": {
        "354": "meẖoz"
      },
      "is": {
        "419": "landsvæði"
      },
      "ja": {
        "247": "to, dô, hu, ken"
      },
      "ky": {
        "187": "shaar",
        "328": "oblast"
      },
      "km": {
        "342": "khet"
      },
      "ko": {
        "140": "si",
        "207": "do",
        "226": "teukbyeolsi",
        "227": " gwangyeoksi",
        "329": "do",
        "356": "chikhalsi",
        "452": "teukbyeoljachido",
        "453": "teukbyeoljachisi",
        "473": "t’ŭkpyŏlsi"
      },
      "kk": {
        "330": "oblys",
        "437": "qala"
      },
      "lo": {
        "273": "kampèng nakhon",
        "285": "khouèng"
      },
      "ta": {
        "286": "mākāṇam",
        "421": "mavaṭṭam"
      },
      "si": {
        "286": "paḷāta",
        "421": "distrikkaya"
      },
      "st": {
        "209": "setereke",
        "428": "provense"
      },
      "lt": {
        "210": "apskritis",
        "454": "miesto savivaldybė",
        "455": "rajono savivaldybė",
        "456": "savivaldybė"
      },
      "lv": {
        "211": "novads",
        "248": "republikas pilsēta"
      },
      "ro": {
        "120": "raion",
        "148": "unitatea teritorială autonomă",
        "222": "unitatea teritorială",
        "234": "municipiu",
        "296": "judeţ",
        "308": "municipiu"
      },
      "cnr": {
        "121": "opština"
      },
      "mg": {
        "213": "faritany"
      },
      "mh": {
        "378": "jukjuk"
      },
      "mk": {
        "288": "opština"
      },
      "my": {
        "163": "taing",
        "264": "pyinè"
      },
      "mn": {
        "333": "aymag",
        "360": "hot"
      },
      "mt": {
        "280": "kunsilli lokali"
      },
      "dv": {
        "263": "provins",
        "326": "atholhu",
        "439": "verirash"
      },
      "ny": {
        "135": "mchigawo",
        "367": "boma"
      },
      "nn": {
        "257": "fylke",
        "345": "arktisk område"
      },
      "nb": {
        "257": "fylke",
        "345": "arktisk område"
      },
      "ne": {
        "178": "vikas kshetra",
        "258": "anchal"
      },
      "na": {
        "172": "distric"
      },
      "mi": {
        "388": "rohe"
      },
      "ay": {
        "127": "jach'a suyu",
        "190": "hatun llaqta"
      },
      "qu": {
        "127": "suyu",
        "190": "llaqta suyu"
      },
      "tpi": {
        "137": "distrik",
        "173": "provins"
      },
      "tl": {
        "128": "rehiyon",
        "260": "lalawigan"
      },
      "ur": {
        "138": "Pakistan kay zair-i-intezam ilaqay",
        "191": "wafāqī dār al ḩikūmat ‘alāqah",
        "261": "sūbah",
        "336": "‘alāqah"
      },
      "pl": {
        "217": "województwo"
      },
      "rw": {
        "267": "umujyi",
        "343": "intara"
      },
      "crs": {
        "164": "distrik"
      },
      "sl": {
        "423": "občina"
      },
      "sk": {
        "250": "kraj"
      },
      "so": {
        "303": "gobol"
      },
      "th": {
        "291": "changwat"
      },
      "tg": {
        "145": "viloyat",
        "315": "viloyati mukhtor",
        "458": "viloyati poytakht",
        "472": "nohiyahoi tobei jumhurí"
      },
      "tk": {
        "292": "şäher",
        "425": "welaýat"
      },
      "sw": {
        "427": "mkoa"
      },
      "uk": {
        "382": "misto",
        "383": "oblast'",
        "400": "respublika"
      },
      "uz": {
        "216": "viloyat",
        "293": "respublikasi",
        "445": "shahar"
      },
      "vi": {
        "126": "tinh",
        "294": "thanh pho"
      },
      "zu": {
        "428": "fundazwe"
      },
      "xh": {
        "428": "phondo"
      },
      "ve": {
        "428": "vundu"
      },
      "tn": {
        "428": "porofense"
      },
      "ts": {
        "428": "fundzankulu"
      },
      "ss": {
        "428": "fundzeni"
      },
      "nso": {
        "428": "profense"
      },
      "nr": {
        "428": "iProvinsi"
      },
      "af": {
        "428": "provinsie"
      }
    },
    "regions": {
      "ca": {
        "AD-03": "Encamp",
        "AD-07": "Andorra la Vella",
        "AD-04": "La Massana",
        "AD-05": "Ordino",
        "AD-02": "Canillo",
        "AD-06": "Sant Julià de Lòria",
        "AD-08": "Escaldes-Engordany",
        "ES-VC": "Valenciana, Comunitat*",
        "ES-V": "València*",
        "ES-CS": "Castelló*",
        "ES-A": "Alacant*",
        "ES-CT": "Catalunya [Cataluña]",
        "ES-T": "Tarragona [Tarragona]",
        "ES-GI": "Girona [Gerona]",
        "ES-L": "Lleida [Lérida]",
        "ES-IB": "Illes Balears [Islas Baleares]",
        "ES-PM": "Balears [Baleares]"
      },
      "ar": {
        "AE-RK": "Ra’s al Khaymah",
        "AE-SH": "Ash Shāriqah",
        "AE-FU": "Al Fujayrah",
        "AE-DU": "Dubayy",
        "AE-AZ": "Abū Z̧aby",
        "AE-UQ": "Umm al Qaywayn",
        "AE-AJ": "‘Ajmān",
        "BH-14": "Al Janūbīyah",
        "BH-13": "Al ‘Āşimah",
        "BH-15": "Al Muḩarraq",
        "BH-17": "Ash Shamālīyah",
        "DJ-OB": "Awbūk",
        "DJ-AR": "‘Artā",
        "DJ-DI": "Dikhīl",
        "DJ-DJ": "Jībūtī",
        "DJ-AS": "‘Alī Şabīḩ",
        "DJ-TA": "Tājūrah",
        "DZ-06": "Béjaïa",
        "DZ-11": "Tamanrasset",
        "DZ-13": "Tlemcen",
        "DZ-27": "Mostaganem",
        "DZ-40": "Khenchela",
        "DZ-03": "Laghouat",
        "DZ-09": "Blida",
        "DZ-14": "Tiaret",
        "DZ-15": "Tizi Ouzou",
        "DZ-19": "Sétif",
        "DZ-20": "Saïda",
        "DZ-35": "Boumerdès",
        "DZ-41": "Souk Ahras",
        "DZ-42": "Tipaza",
        "DZ-44": "Aïn Defla",
        "DZ-02": "Chlef",
        "DZ-04": "Oum el Bouaghi",
        "DZ-05": "Batna",
        "DZ-23": "Annaba",
        "DZ-39": "El Oued",
        "DZ-01": "Adrar",
        "DZ-08": "Béchar",
        "DZ-16": "Alger",
        "DZ-28": "M'sila",
        "DZ-31": "Oran",
        "DZ-07": "Biskra",
        "DZ-24": "Guelma",
        "DZ-33": "Illizi",
        "DZ-36": "El Tarf",
        "DZ-38": "Tissemsilt",
        "DZ-45": "Naama",
        "DZ-10": "Bouira",
        "DZ-21": "Skikda",
        "DZ-25": "Constantine",
        "DZ-32": "El Bayadh",
        "DZ-43": "Mila",
        "DZ-46": "Aïn Témouchent",
        "DZ-48": "Relizane",
        "DZ-17": "Djelfa",
        "DZ-18": "Jijel",
        "DZ-22": "Sidi Bel Abbès",
        "DZ-30": "Ouargla",
        "DZ-47": "Ghardaïa",
        "DZ-12": "Tébessa",
        "DZ-26": "Médéa",
        "DZ-29": "Mascara",
        "DZ-34": "Bordj Bou Arréridj",
        "DZ-37": "Tindouf",
        "EG-AST": "Asyūţ",
        "EG-BNS": "Banī Suwayf",
        "EG-C": "Al Qāhirah",
        "EG-FYM": "Al Fayyūm",
        "EG-PTS": "Būr Sa‘īd",
        "EG-DT": "Dumyāţ",
        "EG-GZ": "Al Jīzah",
        "EG-IS": "Al Ismā'īlīyah",
        "EG-SHG": "Sūhāj",
        "EG-BA": "Al Baḩr al Aḩmar",
        "EG-KB": "Al Qalyūbīyah",
        "EG-SHR": "Ash Sharqīyah",
        "EG-DK": "Ad Daqahlīyah",
        "EG-LX": "Al Uqşur",
        "EG-SIN": "Shamāl Sīnā'",
        "EG-WAD": "Al Wādī al Jadīd",
        "EG-GH": "Al Gharbīyah",
        "EG-KN": "Qinā",
        "EG-MNF": "Al Minūfīyah",
        "EG-SUZ": "As Suways",
        "EG-BH": "Al Buḩayrah",
        "EG-MT": "Maţrūḩ",
        "EG-ALX": "Al Iskandarīyah",
        "EG-ASN": "Aswān",
        "EG-JS": "Janūb Sīnā'",
        "EG-KFS": "Kafr ash Shaykh",
        "EG-MN": "Al Minyā",
        "ER-GB": "Qāsh-Barkah",
        "ER-MA": "Al Awsaţ",
        "ER-SK": "Shimālī al Baḩrī al Aḩmar",
        "ER-DK": "Janūbī al Baḩrī al Aḩmar",
        "ER-DU": "Al Janūbī",
        "ER-AN": "Ansabā",
        "IL-D": "Al Janūbī",
        "IL-HA": "Ḩayfā",
        "IL-JM": "Al Quds",
        "IL-M": "Al Awsaţ",
        "IL-Z": "Ash Shamālī",
        "IL-TA": "Tall Abīb",
        "IQ-AR": "Arbīl",
        "IQ-SD": "Şalāḩ ad Dīn",
        "IQ-DI": "Diyālá",
        "IQ-AN": "Al Anbār",
        "IQ-DQ": "Dhī Qār",
        "IQ-KA": "Karbalā’",
        "IQ-NA": "An Najaf",
        "IQ-KI": "Kirkūk",
        "IQ-BA": "Al Başrah",
        "IQ-DA": "Dahūk",
        "IQ-QA": "Al Qādisīyah",
        "IQ-SU": "As Sulaymānīyah",
        "IQ-NI": "Nīnawá",
        "IQ-BG": "Baghdād",
        "IQ-MA": "Maysān",
        "IQ-BB": "Bābil",
        "IQ-MU": "Al Muthanná",
        "IQ-WA": "Wāsiţ",
        "JO-IR": "Irbid",
        "JO-MN": "Ma‘ān",
        "JO-AJ": "‘Ajlūn",
        "JO-MA": "Al Mafraq",
        "JO-AM": "Al ‘A̅şimah",
        "JO-AT": "Aţ Ţafīlah",
        "JO-JA": "Jarash",
        "JO-KA": "Al Karak",
        "JO-BA": "Al Balqā’",
        "JO-MD": "Mādabā",
        "JO-AQ": "Al ‘Aqabah",
        "JO-AZ": "Az Zarqā’",
        "KM-M": "Mūhīlī",
        "KM-A": "Anjwān",
        "KM-G": "Anjazījah",
        "KW-FA": "Al Farwānīyah",
        "KW-HA": "Ḩawallī",
        "KW-KU": "Al ‘Āşimah",
        "KW-MU": "Mubārak al Kabīr",
        "KW-AH": "Al Aḩmadī",
        "KW-JA": "Al Jahrā’",
        "LB-JL": "Jabal Lubnān",
        "LB-BA": "Beyrouth",
        "LB-BI": "Al Biqā‘",
        "LB-AS": "Liban-Nord",
        "LB-BH": "Baalbek-Hermel",
        "LB-JA": "Liban-Sud",
        "LB-AK": "‘Akkār",
        "LB-NA": "An Nabaţīyah",
        "LY-DR": "Darnah",
        "LY-JI": "Al Jafārah",
        "LY-MI": "Mişrātah",
        "LY-NL": "Nālūt",
        "LY-NQ": "An Nuqāţ al Khams",
        "LY-SR": "Surt",
        "LY-ZA": "Az Zāwiyah",
        "LY-JU": "Al Jufrah",
        "LY-MB": "Al Marqab",
        "LY-WD": "Wādī al Ḩayāt",
        "LY-GT": "Ghāt",
        "LY-TB": "Ţarābulus",
        "LY-JA": "Al Jabal al Akhḑar",
        "LY-KF": "Al Kufrah",
        "LY-MQ": "Murzuq",
        "LY-JG": "Al Jabal al Gharbī",
        "LY-WS": "Wādī ash Shāţi’",
        "LY-MJ": "Al Marj",
        "LY-SB": "Sabhā",
        "LY-WA": "Al Wāḩāt",
        "LY-BA": "Banghāzī",
        "LY-BU": "Al Buţnān",
        "MA-05": "Béni-Mellal-Khénifra",
        "MA-AZI": "Azilal",
        "MA-KHN": "Khenifra",
        "MA-KHO": "Khouribga",
        "MA-BEM": "Béni Mellal",
        "MA-FQH": "Fquih Ben Salah",
        "MA-11": "Laâyoune-Sakia El Hamra (EH-partial)",
        "MA-ESM": "Es-Semara (EH)",
        "MA-BOD": "Boujdour (EH)",
        "MA-LAA": "Laâyoune (EH)",
        "MA-TAF": "Tarfaya (EH-partial)",
        "MA-01": "Tanger-Tétouan-Al Hoceïma",
        "MA-LAR": "Larache",
        "MA-CHE": "Chefchaouen",
        "MA-HOC": "Al Hoceïma",
        "MA-TNG": "Tanger-Assilah",
        "MA-MDF": "M’diq-Fnideq",
        "MA-FAH": "Fahs-Anjra",
        "MA-OUZ": "Ouezzane",
        "MA-TET": "Tétouan",
        "MA-09": "Souss-Massa",
        "MA-INE": "Inezgane-Ait Melloul",
        "MA-TAR": "Taroudant",
        "MA-TIZ": "Tiznit",
        "MA-TAT": "Tata",
        "MA-AGD": "Agadir-Ida-Ou-Tanane",
        "MA-02": "L'Oriental",
        "MA-JRA": "Jerada",
        "MA-FIG": "Figuig",
        "MA-NAD": "Nador",
        "MA-TAI": "Taourirt",
        "MA-BER": "Berkane",
        "MA-OUJ": "Oujda-Angad",
        "MA-DRI": "Driouch",
        "MA-GUF": "Guercif",
        "MA-06": "Casablanca-Settat",
        "MA-SIK": "Sidi Kacem",
        "MA-MED": "Médiouna",
        "MA-MOH": "Mohammadia",
        "MA-CHT": "Chtouka-Ait Baha",
        "MA-JDI": "El Jadida",
        "MA-BES": "Benslimane",
        "MA-BRR": "Berrechid",
        "MA-SIB": "Sidi Bennour",
        "MA-10": "Guelmim-Oued Noun (EH-partial)",
        "MA-TNT": "Tan-Tan (EH-partial)",
        "MA-GUE": "Guelmim",
        "MA-ASZ": "Assa-Zag (EH-partial)",
        "MA-SIF": "Sidi Ifni",
        "MA-03": "Fès- Meknès",
        "MA-IFR": "Ifrane",
        "MA-SEF": "Sefrou",
        "MA-TAZ": "Taza",
        "MA-BOM": "Boulemane",
        "MA-HAJ": "El Hajeb",
        "MA-MEK": "Meknès",
        "MA-MOU": "Moulay Yacoub",
        "MA-TAO": "Taounate",
        "MA-FES": "Fès",
        "MA-08": "Drâa-Tafilalet",
        "MA-ERR": "Errachidia",
        "MA-SET": "Settat",
        "MA-CAS": "Casablanca",
        "MA-OUA": "Ouarzazate",
        "MA-ZAG": "Zagora",
        "MA-MID": "Midelt",
        "MA-TIN": "Tinghir",
        "MA-12": "Dakhla-Oued Ed-Dahab (EH)",
        "MA-OUD": "Oued Ed-Dahab (EH)",
        "MA-AOU": "Aousserd (EH)",
        "MA-04": "Rabat-Salé-Kénitra",
        "MA-RAB": "Rabat",
        "MA-SKH": "Skhirate-Témara",
        "MA-KEN": "Kénitra",
        "MA-SAL": "Salé",
        "MA-KHE": "Khemisset",
        "MA-NOU": "Nouaceur",
        "MA-SIL": "Sidi Slimane",
        "MA-07": "Marrakech-Safi",
        "MA-CHI": "Chichaoua",
        "MA-KES": "El Kelâa des Sraghna",
        "MA-ESI": "Essaouira",
        "MA-SAF": "Safi",
        "MA-HAO": "Al Haouz",
        "MA-MAR": "Marrakech",
        "MA-REH": "Rehamna",
        "MA-YUS": "Youssoufia",
        "MR-02": "Hodh el Gharbi",
        "MR-08": "Dakhlet Nouâdhibou",
        "MR-03": "Assaba",
        "MR-07": "Adrar",
        "MR-04": "Gorgol",
        "MR-12": "Inchiri",
        "MR-10": "Guidimaka",
        "MR-06": "Trarza",
        "MR-09": "Tagant",
        "MR-11": "Tiris Zemmour",
        "MR-13": "Nuwākshūţ al Gharbīyah",
        "MR-14": "Nuwākshūţ ash Shamālīyah",
        "MR-15": "Nuwākshūţ al Janūbīyah",
        "MR-01": "Hodh ech Chargui",
        "MR-05": "Brakna",
        "OM-MA": "Masqaţ",
        "OM-ZU": "Z̧ufār",
        "OM-BU": "Al Buraymī",
        "OM-MU": "Musandam",
        "OM-DA": "Ad Dākhilīyah",
        "OM-WU": "Al Wusţá",
        "OM-ZA": "Az̧ Z̧āhirah",
        "OM-BJ": "Janūb al Bāţinah",
        "OM-BS": "Shamāl al Bāţinah",
        "OM-SJ": "Janūb ash Sharqīyah",
        "OM-SS": "Shamāl ash Sharqīyah",
        "PS-DEB": "Dayr al Balaḩ",
        "PS-QQA": "Qalqīlyah",
        "PS-RFH": "Rafaḩ",
        "PS-HBN": "Al Khalīl",
        "PS-JEN": "Janīn",
        "PS-NGZ": "Shamāl Ghazzah",
        "PS-RBH": "Rām Allāh wal Bīrah",
        "PS-TBS": "Ţūbās",
        "PS-GZA": "Ghazzah",
        "PS-JRH": "Arīḩā wal Aghwār",
        "PS-NBS": "Nāblus",
        "PS-BTH": "Bayt Laḩm",
        "PS-JEM": "Al Quds",
        "PS-SLT": "Salfīt",
        "PS-TKM": "Ţūlkarm",
        "PS-KYS": "Khān Yūnis",
        "QA-US": "Umm Şalāl",
        "QA-WA": "Al Wakrah",
        "QA-ZA": "Az̧ Z̧a‘āyin",
        "QA-RA": "Ar Rayyān",
        "QA-DA": "Ad Dawḩah",
        "QA-KH": "Al Khawr wa adh Dhakhīrah",
        "QA-MS": "Ash Shamāl",
        "QA-SH": "Ash Shīḩānīyah",
        "SA-11": "Al Bāḩah",
        "SA-12": "Al Jawf",
        "SA-14": "'Asīr",
        "SA-08": "Al Ḩudūd ash Shamālīyah",
        "SA-03": "Al Madīnah al Munawwarah",
        "SA-04": "Ash Sharqīyah",
        "SA-10": "Najrān",
        "SA-02": "Makkah al Mukarramah",
        "SA-07": "Tabūk",
        "SA-01": "Ar Riyāḑ",
        "SA-05": "Al Qaşīm",
        "SA-06": "Ḩā'il",
        "SA-09": "Jāzān",
        "SD-NO": "Ash Shamālīyah",
        "SD-DS": "Janūb Dārfūr",
        "SD-SI": "Sinnār",
        "SD-DE": "Sharq Dārfūr",
        "SD-KH": "Al Kharţūm",
        "SD-DN": "Shamāl Dārfūr",
        "SD-DW": "Gharb Dārfūr",
        "SD-GZ": "Al Jazīrah",
        "SD-NW": "An Nīl al Abyaḑ",
        "SD-DC": "Wasaţ Dārfūr Zālinjay",
        "SD-KA": "Kassalā",
        "SD-KN": "Shiamāl Kurdufān",
        "SD-KS": "Janūb Kurdufān",
        "SD-NB": "An Nīl al Azraq",
        "SD-NR": "Nahr an Nīl",
        "SD-GK": "Gharb Kurdufān",
        "SD-GD": "Al Qaḑārif",
        "SD-RS": "Al Baḩr al Aḩmar",
        "SY-DY": "Dayr az Zawr",
        "SY-LA": "Al Lādhiqīyah",
        "SY-RA": "Ar Raqqah",
        "SY-QU": "Al Qunayţirah",
        "SY-SU": "As Suwaydā'",
        "SY-DR": "Dar'ā",
        "SY-HI": "Ḩimş",
        "SY-RD": "Rīf Dimashq",
        "SY-TA": "Ţarţūs",
        "SY-HA": "Al Ḩasakah",
        "SY-HL": "Ḩalab",
        "SY-HM": "Ḩamāh",
        "SY-DI": "Dimashq",
        "SY-ID": "Idlib",
        "TD-LR": "Lūqūn ash Sharqī",
        "TD-SI": "Sīlā",
        "TD-BO": "Būrkū",
        "TD-GR": "Qīrā",
        "TD-TA": "Tānjilī",
        "TD-TI": "Tibastī",
        "TD-BG": "Baḩr al Ghazāl",
        "TD-CB": "Shārī Bāqirmī",
        "TD-LO": "Lūqūn al Gharbī",
        "TD-MA": "Māndūl",
        "TD-BA": "Al Baţḩah",
        "TD-HL": "Ḩajjar Lamīs",
        "TD-MO": "Māyū Kībbī al Gharbī",
        "TD-KA": "Kānim",
        "TD-MC": "Shārī al Awsaţ",
        "TD-ND": "Madīnat Injamīnā",
        "TD-OD": "Waddāy",
        "TD-SA": "Salāmāt",
        "TD-LC": "Al Buḩayrah",
        "TD-ME": "Māyū Kībbī ash Sharqī",
        "TD-WF": "Wādī Fīrā",
        "TN-12": "L'Ariana",
        "TN-21": "Nabeul",
        "TN-72": "Tozeur",
        "TN-34": "Siliana",
        "TN-41": "Kairouan",
        "TN-52": "Monastir",
        "TN-83": "Tataouine",
        "TN-23": "Bizerte",
        "TN-51": "Sousse",
        "TN-71": "Gafsa",
        "TN-33": "Le Kef",
        "TN-13": "Ben Arous",
        "TN-42": "Kasserine",
        "TN-43": "Sidi Bouzid",
        "TN-53": "Mahdia",
        "TN-73": "Kébili",
        "TN-11": "Tunis",
        "TN-14": "La Manouba",
        "TN-31": "Béja",
        "TN-61": "Sfax",
        "TN-81": "Gabès",
        "TN-82": "Médenine",
        "TN-22": "Zaghouan",
        "TN-32": "Jendouba",
        "YE-HD": "Ḩaḑramawt",
        "YE-HJ": "Ḩajjah",
        "YE-SN": "Şanʻā’",
        "YE-IB": "Ibb",
        "YE-MR": "Al Mahrah",
        "YE-AD": "‘Adan",
        "YE-DH": "Dhamār",
        "YE-SA": "Amānat al ‘Āşimah [city]",
        "YE-MA": "Ma’rib",
        "YE-DA": "Aḑ Ḑāli‘",
        "YE-SH": "Shabwah",
        "YE-AM": "‘Amrān",
        "YE-BA": "Al Bayḑā’",
        "YE-AB": "Abyan",
        "YE-JA": "Al Jawf",
        "YE-SD": "Şāʻdah",
        "YE-HU": "Al Ḩudaydah",
        "YE-LA": "Laḩij",
        "YE-MW": "Al Maḩwīt",
        "YE-RA": "Raymah",
        "YE-TA": "Tāʻizz",
        "YE-SU": "Arkhabīl Suquţrá"
      },
      "fa": {
        "AF-BAM": "Bāmyān",
        "AF-KAB": "Kābul",
        "AF-KAP": "Kāpīsā",
        "AF-KHO": "Khōst",
        "AF-KNR": "Kunaṟ",
        "AF-KAN": "Kandahār",
        "AF-LOG": "Lōgar",
        "AF-PIA": "Paktiyā",
        "AF-SAM": "Samangān",
        "AF-URU": "Uruzgān",
        "AF-BDS": "Badakhshān",
        "AF-FYB": "Fāryāb",
        "AF-JOW": "Jowzjān",
        "AF-KDZ": "Kunduz",
        "AF-LAG": "Laghmān",
        "AF-NIM": "Nīmrōz",
        "AF-BGL": "Baghlān",
        "AF-FRA": "Farāh",
        "AF-HEL": "Helmand",
        "AF-PKA": "Paktīkā",
        "AF-SAR": "Sar-e Pul",
        "AF-BDG": "Bādghīs",
        "AF-GHA": "Ghaznī",
        "AF-PAN": "Panjshayr",
        "AF-TAK": "Takhār",
        "AF-WAR": "Wardak",
        "AF-BAL": "Balkh",
        "AF-GHO": "Ghōr",
        "AF-DAY": "Dāykundī",
        "AF-HER": "Herāt",
        "AF-NAN": "Nangarhār",
        "AF-NUR": "Nūristān",
        "AF-PAR": "Parwān",
        "AF-ZAB": "Zābul",
        "IR-01": "Āz̄ārbāyjān-e Shārqī",
        "IR-13": "Sīstān va Balūchestān",
        "IR-20": "Lorestān",
        "IR-21": "Māzandarān",
        "IR-26": "Qom",
        "IR-05": "Īlām",
        "IR-17": "Kermānshāh",
        "IR-18": "Kohgīlūyeh va Bowyer Aḩmad",
        "IR-28": "Qazvīn",
        "IR-12": "Semnān",
        "IR-14": "Fārs",
        "IR-22": "Markazī",
        "IR-24": "Hamadān",
        "IR-06": "Būshehr",
        "IR-27": "Golestān",
        "IR-30": "Khorāsān-e Raẕavī",
        "IR-32": "Alborz",
        "IR-02": "Āz̄ārbāyjān-e Ghārbī",
        "IR-04": "Eşfahān",
        "IR-07": "Tehrān",
        "IR-11": "Zanjān",
        "IR-15": "Kermān",
        "IR-23": "Hormozgān",
        "IR-29": "Khorāsān-e Jonūbī",
        "IR-31": "Khorāsān-e Shomālī",
        "IR-03": "Ardabīl",
        "IR-08": "Chahār Maḩāl va Bakhtīārī",
        "IR-10": "Khūzestān",
        "IR-16": "Kordestān",
        "IR-19": "Gīlān",
        "IR-25": "Yazd"
      },
      "ps": {
        "AF-BAM": "Bāmyān",
        "AF-KAB": "Kābul",
        "AF-KAP": "Kāpīsā",
        "AF-KHO": "Khōst",
        "AF-KNR": "Kunaṟ",
        "AF-KAN": "Kandahār",
        "AF-LOG": "Lōgar",
        "AF-PIA": "Paktiyā",
        "AF-SAM": "Samangān",
        "AF-URU": "Uruzgān",
        "AF-BDS": "Badakhshān",
        "AF-FYB": "Fāryāb",
        "AF-JOW": "Jowzjān",
        "AF-KDZ": "Kunduz",
        "AF-LAG": "Laghmān",
        "AF-NIM": "Nīmrōz",
        "AF-BGL": "Baghlān",
        "AF-FRA": "Farāh",
        "AF-HEL": "Helmand",
        "AF-PKA": "Paktīkā",
        "AF-SAR": "Sar-e Pul",
        "AF-BDG": "Bādghīs",
        "AF-GHA": "Ghaznī",
        "AF-PAN": "Panjshayr",
        "AF-TAK": "Takhār",
        "AF-WAR": "Wardak",
        "AF-BAL": "Balkh",
        "AF-GHO": "Ghōr",
        "AF-DAY": "Dāykundī",
        "AF-HER": "Herāt",
        "AF-NAN": "Nangarhār",
        "AF-NUR": "Nūristān",
        "AF-PAR": "Parwān",
        "AF-ZAB": "Zābul"
      },
      "en": {
        "1000": "Abariringa",
        "1001": "Agalega Islands",
        "1002": "Åland Islands",
        "1003": "Andaman Islands",
        "1004": "Anegada",
        "1005": "Anjouan",
        "1006": "Annobón Island",
        "1007": "Antipodes Islands",
        "1008": "Ascension Island",
        "1009": "Ashmore and Cartier Islands ",
        "1010": "Auckland Islands",
        "1012": "Alderney Island  ",
        "1013": "Austral Islands ",
        "1015": "Bird Island ",
        "1016": "Babelthuap (principal island)",
        "1017": "Baker Island",
        "1018": "Banaba",
        "1019": "Redonda Island",
        "1020": "Bassas da India",
        "1021": "Bear Island ",
        "1022": "Bequia (principal island)",
        "1023": "Bioko Island",
        "1025": "Bismarck Archipelago",
        "1026": "Bonaire",
        "1027": "Bougainville (principal island)",
        "1028": "Brecqhou Island ",
        "1030": "Burhou Island",
        "1031": "Cabinda",
        "1032": "Caicos Islands",
        "1033": "Campbell Island",
        "1034": "Cargados Carajos Shoals",
        "1035": "Caroline Islands ",
        "1036": "the west part of the Caroline Islands",
        "1039": "Carriacou",
        "1040": "Chagos Archipelago",
        "1041": "Chatham Islands",
        "1042": "Chuuk",
        "1044": "Coco Island",
        "1045": "Continental Region (Rio Muni) ",
        "1046": "Coral Sea Islands ",
        "1047": "Cosmoledo Islands",
        "1048": "Crozet Archipelago",
        "1049": "Swan Islands ",
        "1050": "la Désirade",
        "1051": "Diego Garcia",
        "1052": "Ducie Island",
        "1053": "Easter Island",
        "1054": "Efate",
        "1056": "Enderbury Island",
        "1057": "French scattered Indian Ocean Islands",
        "1058": "Europa Island",
        "1059": "Farquhar Islands",
        "1060": "Fernando de Noronha Island",
        "1063": "Funafuti (principal atoll)",
        "1064": "Futuna",
        "1065": "Galápagos Islands",
        "1066": "Gambier Islands",
        "1067": "Gilbert Islands",
        "1068": "Glorioso Islands ",
        "1070": "Gough Island",
        "1071": "Grand Cayman ",
        "1073": "Grande Comore",
        "1074": "Sark Island  ",
        "1075": "Northern Grenadine Islands",
        "1076": "Southern Grenadine Islands",
        "1079": "Guadalcanal (principal island)",
        "1080": "Henderson Island",
        "1081": "Herm Island",
        "1082": "Herzegovina *",
        "1084": "Hoorn Islands",
        "1085": "Howland Island",
        "1086": "Inaccessible Island",
        "1087": "Jaluit",
        "1088": "Jan Mayen",
        "1089": "Jarvis Island",
        "1090": "Jethou Island",
        "1091": "Johnston Atoll",
        "1092": "Jost Van Dyke",
        "1093": "Juan de Nova Island",
        "1094": "Juan Fernández Islands",
        "1095": "Kaliningrad Region",
        "1096": "Kerguelen Islands",
        "1097": "Kermadec Islands",
        "1098": "Kingman Reef",
        "1099": "Kiritimati",
        "1100": "Kosrae",
        "1101": "Laccadive Islands ",
        "1103": "part of Line Islands",
        "1104": "Lihou Island",
        "1106": "Little Sark Island ",
        "1107": "Lord Howe Island",
        "1108": "Loyalty Islands ",
        "1110": "McDonald Islands  *",
        "1111": "Macquarie Island",
        "1112": "Mahé (principal island)",
        "1113": "Kwajalein",
        "1114": "Peninsular Malaysia",
        "1116": "Malpelo Island",
        "1117": "Mariana Islands (except GUAM)",
        "1119": "Marie-Galante",
        "1120": "Marion Island",
        "1121": "Marquesas Islands ",
        "1123": "Martim Vaz Islands",
        "1127": "Midway Islands",
        "1128": "Minicoy Island",
        "1129": "Miquelon *",
        "1130": "Mohéli",
        "1131": "Mount Athos autonomous area ",
        "1133": "part of the Musandam Peninsula",
        "1135": "Navassa Island ",
        "1137": "Nevis *",
        "1138": "Nicobar Islands",
        "1139": "Nightingale Island",
        "1141": "Solomon Islands, Northern",
        "1142": "Oeno Island",
        "1143": "the exclave of Oecussi",
        "1145": "Palmyra Atoll",
        "1147": "Penghu (Pescadores) Islands",
        "1150": "Phoenix Islands",
        "1151": "Pohnpei",
        "1152": "Prince Edward Island ",
        "1154": "Principe *",
        "1155": "Rarotonga",
        "1158": "Rio Muni",
        "1159": "Rodrigues Island",
        "1160": "Rotuma Island",
        "1161": "Saba",
        "1162": "Sabah",
        "1165": "Saint Croix",
        "1166": "les Saintes",
        "1167": "Sint Eustatius ",
        "1168": "Saint Helena Island ",
        "1170": "Saint John",
        "1174": "Saint-Paul Island",
        "1175": "Saint Thomas",
        "1176": "Saint Vincent Island ",
        "1178": "Saipan (principal island)",
        "1179": "Sala y Gómez Island",
        "1181": "Southern Solomon Islands",
        "1182": "San Ambrosio Island",
        "1183": "San Andrés y Providencia Islands",
        "1184": "South Sandwich Islands  *",
        "1185": "San Félix Island",
        "1186": "Santa Cruz Islands",
        "1187": "Santo",
        "1188": "São Tiago",
        "1189": "São Vicente",
        "1190": "Sarawak",
        "1192": "Savai'i",
        "1196": "Society Archipelago ",
        "1198": "Socotra Island",
        "1204": "Stoltenhoff Island",
        "1205": "Swain's Island",
        "1207": "Tahiti",
        "1208": "Tarawa",
        "1209": "Tobago *",
        "1210": "Tongatapu (principal island)",
        "1211": "Tortola",
        "1212": "Trindade Island",
        "1213": "Tristan da Cunha Archipelago",
        "1214": "Tromelin Island",
        "1215": "Tuamotu Islands",
        "1216": "Tutuila",
        "1217": "Upolu",
        "1218": "Uvea",
        "1219": "Vanua Levu",
        "1220": "Virgin Gorda",
        "1221": "Viti Levu",
        "1222": "Wake Island",
        "1223": "Wallis Islands",
        "1224": "Yap",
        "1225": "Aldabra Islands",
        "1226": "Amindivi Islands",
        "1227": "Amirante Islands ",
        "1228": "Amsterdam Island",
        "1230": "Majuro",
        "AG-03": "Saint George",
        "AG-06": "Saint Paul",
        "AG-10": "Barbuda",
        "AG-07": "Saint Peter",
        "AG-08": "Saint Philip",
        "AG-05": "Saint Mary",
        "AG-04": "Saint John",
        "AG-11": "Redonda",
        "AU-NT": "Northern Territory",
        "AU-QLD": "Queensland",
        "AU-TAS": "Tasmania",
        "AU-ACT": "Australian Capital Territory",
        "AU-NSW": "New South Wales",
        "AU-SA": "South Australia",
        "AU-WA": "Western Australia",
        "AU-VIC": "Victoria",
        "BB-03": "Saint George",
        "BB-05": "Saint John",
        "BB-02": "Saint Andrew",
        "BB-10": "Saint Philip",
        "BB-11": "Saint Thomas",
        "BB-08": "Saint Michael",
        "BB-04": "Saint James",
        "BB-09": "Saint Peter",
        "BB-01": "Christ Church",
        "BB-06": "Saint Joseph",
        "BB-07": "Saint Lucy",
        "BN-BM": "Brunei-Muara",
        "BN-BE": "Belait",
        "BN-TE": "Temburong",
        "BN-TU": "Tutong",
        "BQ-BO": "Bonaire",
        "BQ-SA": "Saba",
        "BQ-SE": "Sint Eustatius",
        "BS-CO": "Central Abaco",
        "BS-EG": "East Grand Bahama",
        "BS-HI": "Harbour Island",
        "BS-MI": "Moore's Island",
        "BS-SS": "San Salvador",
        "BS-SW": "Spanish Wells",
        "BS-AK": "Acklins",
        "BS-LI": "Long Island",
        "BS-BY": "Berry Islands",
        "BS-FP": "City of Freeport",
        "BS-NS": "North Andros",
        "BS-RC": "Rum Cay",
        "BS-SO": "South Abaco",
        "BS-CE": "Central Eleuthera",
        "BS-CI": "Cat Island",
        "BS-CS": "Central Andros",
        "BS-EX": "Exuma",
        "BS-IN": "Inagua",
        "BS-MC": "Mangrove Cay",
        "BS-SA": "South Andros",
        "BS-GC": "Grand Cay",
        "BS-MG": "Mayaguana",
        "BS-WG": "West Grand Bahama",
        "BS-BI": "Bimini",
        "BS-BP": "Black Point",
        "BS-NE": "North Eleuthera",
        "BS-NO": "North Abaco",
        "BS-SE": "South Eleuthera",
        "BS-CK": "Crooked Island and Long Cay",
        "BS-HT": "Hope Town",
        "BS-RI": "Ragged Island",
        "BW-KL": "Kgatleng",
        "BW-GH": "Ghanzi",
        "BW-NW": "North West",
        "BW-SO": "Southern",
        "BW-KW": "Kweneng",
        "BW-CH": "Chobe",
        "BW-LO": "Lobatse",
        "BW-SP": "Selibe Phikwe",
        "BW-JW": "Jwaneng",
        "BW-ST": "Sowa Town",
        "BW-FR": "Francistown",
        "BW-GA": "Gaborone",
        "BW-KG": "Kgalagadi",
        "BW-CE": "Central",
        "BW-SE": "South East",
        "BW-NE": "North East",
        "BZ-OW": "Orange Walk",
        "BZ-CZL": "Corozal",
        "BZ-SC": "Stann Creek",
        "BZ-TOL": "Toledo",
        "BZ-BZ": "Belize",
        "BZ-CY": "Cayo",
        "CA-BC": "British Columbia",
        "CA-MB": "Manitoba",
        "CA-NB": "New Brunswick",
        "CA-SK": "Saskatchewan",
        "CA-AB": "Alberta",
        "CA-ON": "Ontario",
        "CA-YT": "Yukon",
        "CA-NT": "Northwest Territories",
        "CA-NU": "Nunavut",
        "CA-NL": "Newfoundland and Labrador",
        "CA-PE": "Prince Edward Island",
        "CA-NS": "Nova Scotia",
        "CA-QC": "Quebec",
        "CM-NW": "North-West",
        "CM-OU": "West",
        "CM-AD": "Adamaoua",
        "CM-LT": "Littoral",
        "CM-NO": "North",
        "CM-CE": "Centre",
        "CM-EN": "Far North",
        "CM-ES": "East",
        "CM-SU": "South",
        "CM-SW": "South-West",
        "CN-HK": "Hong Kong SAR ",
        "CN-MO": "Macao SAR ",
        "DM-06": "Saint Joseph",
        "DM-08": "Saint Mark",
        "DM-09": "Saint Patrick",
        "DM-03": "Saint David",
        "DM-02": "Saint Andrew",
        "DM-11": "Saint Peter",
        "DM-04": "Saint George",
        "DM-10": "Saint Paul",
        "DM-05": "Saint John",
        "DM-07": "Saint Luke",
        "ET-BE": "Benshangul-Gumaz",
        "ET-HA": "Harari People",
        "ET-SO": "Somali",
        "ET-DD": "Dire Dawa",
        "ET-GA": "Gambela Peoples",
        "ET-AM": "Amara",
        "ET-AA": "Addis Ababa",
        "ET-AF": "Afar",
        "ET-OR": "Oromia",
        "ET-TI": "Tigrai",
        "ET-SN": "Southern Nations, Nationalities and Peoples",
        "FJ-R": "Rotuma",
        "FJ-W": "Western",
        "FJ-01": "Ba",
        "FJ-08": "Nadroga and Navosa",
        "FJ-11": "Ra",
        "FJ-N": "Northern",
        "FJ-02": "Bua",
        "FJ-03": "Cakaudrove",
        "FJ-07": "Macuata",
        "FJ-E": "Eastern",
        "FJ-04": "Kadavu",
        "FJ-05": "Lau",
        "FJ-06": "Lomaiviti",
        "FJ-C": "Central",
        "FJ-09": "Naitasiri",
        "FJ-10": "Namosi",
        "FJ-12": "Rewa",
        "FJ-13": "Serua",
        "FJ-14": "Tailevu",
        "FM-TRK": "Chuuk",
        "FM-PNI": "Pohnpei",
        "FM-KSA": "Kosrae",
        "FM-YAP": "Yap",
        "GB-GBN": "Great Britain",
        "GB-SCT": "Scotland",
        "GB-NLK": "North Lanarkshire",
        "GB-RFW": "Renfrewshire",
        "GB-ANS": "Angus",
        "GB-FAL": "Falkirk",
        "GB-GLG": "Glasgow City",
        "GB-MRY": "Moray",
        "GB-DGY": "Dumfries and Galloway",
        "GB-ELN": "East Lothian",
        "GB-SAY": "South Ayrshire",
        "GB-ABD": "Aberdeenshire",
        "GB-ELS": "Eilean Siar",
        "GB-NAY": "North Ayrshire",
        "GB-WLN": "West Lothian",
        "GB-AGB": "Argyll and Bute",
        "GB-CLK": "Clackmannanshire",
        "GB-EDU": "East Dunbartonshire",
        "GB-FIF": "Fife",
        "GB-MLN": "Midlothian",
        "GB-ORK": "Orkney Islands",
        "GB-SCB": "Scottish Borders, The",
        "GB-SLK": "South Lanarkshire",
        "GB-STG": "Stirling",
        "GB-ZET": "Shetland Islands",
        "GB-WDU": "West Dunbartonshire",
        "GB-ABE": "Aberdeen City",
        "GB-DND": "Dundee City",
        "GB-EAY": "East Ayrshire",
        "GB-ERW": "East Renfrewshire",
        "GB-HLD": "Highland",
        "GB-IVC": "Inverclyde",
        "GB-PKN": "Perth and Kinross",
        "GB-EDH": "Edinburgh, City of",
        "GB-EAW": "England and Wales",
        "GB-ENG": "England",
        "GB-GRE": "Greenwich",
        "GB-HAL": "Halton",
        "GB-HRY": "Haringey",
        "GB-KHL": "Kingston upon Hull",
        "GB-NEL": "North East Lincolnshire",
        "GB-NFK": "Norfolk",
        "GB-POR": "Portsmouth",
        "GB-SGC": "South Gloucestershire",
        "GB-WBK": "West Berkshire",
        "GB-WND": "Wandsworth",
        "GB-WRT": "Warrington",
        "GB-IOS": "Isles of Scilly",
        "GB-BAS": "Bath and North East Somerset",
        "GB-BIR": "Birmingham",
        "GB-BPL": "Blackpool",
        "GB-CHW": "Cheshire West and Chester",
        "GB-HNS": "Hounslow",
        "GB-HRT": "Hertfordshire",
        "GB-KTT": "Kingston upon Thames",
        "GB-LEC": "Leicestershire",
        "GB-RCH": "Rochdale",
        "GB-ROT": "Rotherham",
        "GB-SFK": "Suffolk",
        "GB-SRY": "Surrey",
        "GB-STH": "Southampton",
        "GB-STT": "Stockton-on-Tees",
        "GB-SWD": "Swindon",
        "GB-TFW": "Telford and Wrekin",
        "GB-WNM": "Windsor and Maidenhead",
        "GB-WOR": "Worcestershire",
        "GB-WSX": "West Sussex",
        "GB-BEX": "Bexley",
        "GB-BMH": "Bournemouth",
        "GB-BRD": "Bradford",
        "GB-BUR": "Bury",
        "GB-CHE": "Cheshire East",
        "GB-CON": "Cornwall",
        "GB-HAM": "Hampshire",
        "GB-LIV": "Liverpool",
        "GB-NYK": "North Yorkshire",
        "GB-OXF": "Oxfordshire",
        "GB-RIC": "Richmond upon Thames",
        "GB-RUT": "Rutland",
        "GB-SAW": "Sandwell",
        "GB-SHF": "Sheffield",
        "GB-SLG": "Slough",
        "GB-THR": "Thurrock",
        "GB-TOB": "Torbay",
        "GB-WFT": "Waltham Forest",
        "GB-WIL": "Wiltshire",
        "GB-WKF": "Wakefield",
        "GB-YOR": "York",
        "GB-CLD": "Calderdale",
        "GB-COV": "Coventry",
        "GB-DER": "Derby",
        "GB-DEV": "Devon",
        "GB-DUD": "Dudley",
        "GB-GLS": "Gloucestershire",
        "GB-HCK": "Hackney",
        "GB-HRW": "Harrow",
        "GB-IOW": "Isle of Wight",
        "GB-ISL": "Islington",
        "GB-KEC": "Kensington and Chelsea",
        "GB-LBH": "Lambeth",
        "GB-LDS": "Leeds",
        "GB-NET": "Newcastle upon Tyne",
        "GB-NGM": "Nottingham",
        "GB-SLF": "Salford",
        "GB-SOL": "Solihull",
        "GB-STE": "Stoke-on-Trent",
        "GB-WLV": "Wolverhampton",
        "GB-WOK": "Wokingham",
        "GB-BNE": "Barnet",
        "GB-BNH": "Brighton and Hove",
        "GB-BNS": "Barnsley",
        "GB-DAL": "Darlington",
        "GB-ERY": "East Riding of Yorkshire",
        "GB-ESS": "Essex",
        "GB-HAV": "Havering",
        "GB-HIL": "Hillingdon",
        "GB-HPL": "Hartlepool",
        "GB-KEN": "Kent",
        "GB-LCE": "Leicester",
        "GB-MDB": "Middlesbrough",
        "GB-MRT": "Merton",
        "GB-RDB": "Redbridge",
        "GB-SFT": "Sefton",
        "GB-SHN": "St. Helens",
        "GB-TRF": "Trafford",
        "GB-TWH": "Tower Hamlets",
        "GB-WAR": "Warwickshire",
        "GB-WLL": "Walsall",
        "GB-DBY": "Derbyshire",
        "GB-DOR": "Dorset",
        "GB-ENF": "Enfield",
        "GB-GAT": "Gateshead",
        "GB-HMF": "Hammersmith and Fulham",
        "GB-KWL": "Knowsley",
        "GB-LAN": "Lancashire",
        "GB-LEW": "Lewisham",
        "GB-LIN": "Lincolnshire",
        "GB-NBL": "Northumberland",
        "GB-POL": "Poole",
        "GB-RCC": "Redcar and Cleveland",
        "GB-STS": "Staffordshire",
        "GB-TAM": "Tameside",
        "GB-WGN": "Wigan",
        "GB-BBD": "Blackburn with Darwen",
        "GB-BEN": "Brent",
        "GB-BOL": "Bolton",
        "GB-BRC": "Bracknell Forest",
        "GB-CBF": "Central Bedfordshire",
        "GB-CMA": "Cumbria",
        "GB-CMD": "Camden",
        "GB-CRY": "Croydon",
        "GB-DUR": "Durham County",
        "GB-ESX": "East Sussex",
        "GB-HEF": "Herefordshire",
        "GB-MDW": "Medway",
        "GB-NTH": "Northamptonshire",
        "GB-NTT": "Nottinghamshire",
        "GB-NWM": "Newham",
        "GB-OLD": "Oldham",
        "GB-PTE": "Peterborough",
        "GB-SKP": "Stockport",
        "GB-SOM": "Somerset",
        "GB-SOS": "Southend-on-Sea",
        "GB-STN": "Sutton",
        "GB-BDF": "Bedford",
        "GB-BDG": "Barking and Dagenham",
        "GB-BKM": "Buckinghamshire",
        "GB-BST": "Bristol, City of",
        "GB-CAM": "Cambridgeshire",
        "GB-DNC": "Doncaster",
        "GB-KIR": "Kirklees",
        "GB-LND": "London, City of",
        "GB-LUT": "Luton",
        "GB-MAN": "Manchester",
        "GB-MIK": "Milton Keynes",
        "GB-NLN": "North Lincolnshire",
        "GB-NSM": "North Somerset",
        "GB-NTY": "North Tyneside",
        "GB-PLY": "Plymouth",
        "GB-RDG": "Reading",
        "GB-SHR": "Shropshire",
        "GB-SND": "Sunderland",
        "GB-STY": "South Tyneside",
        "GB-SWK": "Southwark",
        "GB-WRL": "Wirral",
        "GB-WSM": "Westminster",
        "GB-BRY": "Bromley",
        "GB-EAL": "Ealing",
        "GB-WLS": "Wales [Cymru GB-CYM]",
        "GB-NTL": "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]",
        "GB-RCT": "Rhondda, Cynon, Taff [Rhondda, Cynon,Taf]",
        "GB-NWP": "Newport [Casnewydd GB-CNW]",
        "GB-BGW": "Blaenau Gwent",
        "GB-CMN": "Carmarthenshire [Sir Gaerfyrddin GB-GFY]",
        "GB-DEN": "Denbighshire [Sir Ddinbych GB-DDB]",
        "GB-FLN": "Flintshire [Sir y Fflint GB-FFL]",
        "GB-MTY": "Merthyr Tydfil [Merthyr Tudful GB-MTU]",
        "GB-POW": "Powys",
        "GB-VGL": "Vale of Glamorgan, The [Bro Morgannwg GB-BMG]",
        "GB-AGY": "Isle of Anglesey [Sir Ynys Môn GB-YNM]",
        "GB-CAY": "Caerphilly [Caerffili GB-CAF]",
        "GB-GWN": "Gwynedd",
        "GB-WRX": "Wrexham [Wrecsam GB-WRC]",
        "GB-CGN": "Ceredigion [Sir Ceredigion]",
        "GB-MON": "Monmouthshire [Sir Fynwy GB-FYN]",
        "GB-PEM": "Pembrokeshire [Sir Benfro GB-BNF]",
        "GB-TOF": "Torfaen [Tor-faen]",
        "GB-CRF": "Cardiff [Caerdydd GB-CRD]",
        "GB-SWA": "Swansea [Abertawe GB-ATA]",
        "GB-BGE": "Bridgend [Pen-y-bont ar Ogwr GB-POG]",
        "GB-CWY": "Conwy",
        "GB-NIR": "Northern Ireland",
        "GB-BFS": "Belfast",
        "GB-ANN": "Antrim and Newtownabbey",
        "GB-AND": "Ards and North Down",
        "GB-ABC": "Armagh, Banbridge and Craigavon",
        "GB-CCG": "Causeway Coast and Glens",
        "GB-DRS": "Derry and Strabane",
        "GB-FMO": "Fermanagh and Omagh",
        "GB-LBC": "Lisburn and Castlereagh",
        "GB-MEA": "Mid and East Antrim",
        "GB-MUL": "Mid Ulster",
        "GB-NMD": "Newry, Mourne and Down",
        "GB-UKM": "United Kingdom",
        "GD-10": "Southern Grenadine Islands",
        "GD-02": "Saint David",
        "GD-05": "Saint Mark",
        "GD-01": "Saint Andrew",
        "GD-03": "Saint George",
        "GD-06": "Saint Patrick",
        "GD-04": "Saint John",
        "GH-EP": "Eastern",
        "GH-CP": "Central",
        "GH-NP": "Northern",
        "GH-BA": "Brong-Ahafo",
        "GH-TV": "Volta",
        "GH-UW": "Upper West",
        "GH-AH": "Ashanti",
        "GH-AA": "Greater Accra",
        "GH-UE": "Upper East",
        "GH-WP": "Western",
        "GM-M": "Central River",
        "GM-U": "Upper River",
        "GM-W": "Western",
        "GM-L": "Lower River",
        "GM-B": "Banjul",
        "GM-N": "North Bank",
        "GY-UD": "Upper Demerara-Berbice",
        "GY-PM": "Pomeroon-Supenaam",
        "GY-UT": "Upper Takutu-Upper Essequibo",
        "GY-BA": "Barima-Waini",
        "GY-CU": "Cuyuni-Mazaruni",
        "GY-DE": "Demerara-Mahaica",
        "GY-PT": "Potaro-Siparuni",
        "GY-ES": "Essequibo Islands-West Demerara",
        "GY-MA": "Mahaica-Berbice",
        "GY-EB": "East Berbice-Corentyne",
        "IE-L": "Leinster",
        "IE-D": "Dublin",
        "IE-KE": "Kildare",
        "IE-LD": "Longford",
        "IE-CW": "Carlow",
        "IE-KK": "Kilkenny",
        "IE-LS": "Laois",
        "IE-MH": "Meath",
        "IE-WW": "Wicklow",
        "IE-WX": "Wexford",
        "IE-LH": "Louth",
        "IE-WH": "Westmeath",
        "IE-OY": "Offaly",
        "IE-U": "Ulster",
        "IE-MN": "Monaghan",
        "IE-CN": "Cavan",
        "IE-DL": "Donegal",
        "IE-C": "Connaught",
        "IE-G": "Galway",
        "IE-RN": "Roscommon",
        "IE-SO": "Sligo",
        "IE-LM": "Leitrim",
        "IE-MO": "Mayo",
        "IE-M": "Munster",
        "IE-CE": "Clare",
        "IE-CO": "Cork",
        "IE-TA": "Tipperary",
        "IE-WD": "Waterford",
        "IE-LK": "Limerick",
        "IE-KY": "Kerry",
        "IN-BR": "Bihar",
        "IN-TR": "Tripura",
        "IN-WB": "West Bengal",
        "IN-CT": "Chhattisgarh",
        "IN-HR": "Haryana",
        "IN-UP": "Uttar Pradesh",
        "IN-DD": "Daman and Diu",
        "IN-HP": "Himachal Pradesh",
        "IN-JH": "Jharkhand",
        "IN-JK": "Jammu and Kashmir",
        "IN-NL": "Nagaland",
        "IN-CH": "Chandigarh",
        "IN-GA": "Goa",
        "IN-MZ": "Mizoram",
        "IN-PY": "Puducherry",
        "IN-SK": "Sikkim",
        "IN-UT": "Uttarakhand",
        "IN-TG": "Telangana",
        "IN-GJ": "Gujarat",
        "IN-ML": "Meghalaya",
        "IN-MP": "Madhya Pradesh",
        "IN-DL": "Delhi",
        "IN-AS": "Assam",
        "IN-DN": "Dadra and Nagar Haveli",
        "IN-MN": "Manipur",
        "IN-PB": "Punjab",
        "IN-RJ": "Rajasthan",
        "IN-TN": "Tamil Nadu",
        "IN-AN": "Andaman and Nicobar Islands",
        "IN-AP": "Andhra Pradesh",
        "IN-AR": "Arunachal Pradesh",
        "IN-KA": "Karnataka",
        "IN-KL": "Kerala",
        "IN-LD": "Lakshadweep",
        "IN-MH": "Maharashtra",
        "IN-OR": "Odisha",
        "JM-13": "Clarendon",
        "JM-01": "Kingston",
        "JM-12": "Manchester",
        "JM-14": "Saint Catherine",
        "JM-10": "Westmoreland",
        "JM-11": "Saint Elizabeth",
        "JM-07": "Trelawny",
        "JM-02": "Saint Andrew",
        "JM-03": "Saint Thomas",
        "JM-08": "Saint James",
        "JM-09": "Hanover",
        "JM-04": "Portland",
        "JM-05": "Saint Mary",
        "JM-06": "Saint Ann",
        "JP-25": "Shiga",
        "JP-28": "Hyogo",
        "JP-12": "Chiba",
        "JP-39": "Kochi",
        "JP-40": "Fukuoka",
        "JP-07": "Fukushima",
        "JP-09": "Tochigi",
        "JP-18": "Fukui",
        "JP-21": "Gifu",
        "JP-34": "Hiroshima",
        "JP-35": "Yamaguchi",
        "JP-46": "Kagoshima",
        "JP-01": "Hokkaido",
        "JP-13": "Tokyo",
        "JP-23": "Aichi",
        "JP-27": "Osaka",
        "JP-17": "Ishikawa",
        "JP-19": "Yamanashi",
        "JP-22": "Shizuoka",
        "JP-26": "Kyoto",
        "JP-32": "Shimane",
        "JP-36": "Tokushima",
        "JP-44": "Oita",
        "KE-01": "Baringo",
        "KE-02": "Bomet",
        "KE-03": "Bungoma",
        "KE-04": "Busia",
        "KE-05": "Elgeyo/Marakwet",
        "KE-06": "Embu",
        "KE-07": "Garissa",
        "KE-08": "Homa Bay",
        "KE-09": "Isiolo",
        "KE-10": "Kajiado",
        "KE-11": "Kakamega",
        "KE-12": "Kericho",
        "KE-13": "Kiambu",
        "KE-14": "Kilifi",
        "KE-15": "Kirinyaga",
        "KE-16": "Kisii",
        "KE-17": "Kisumu",
        "KE-18": "Kitui",
        "KE-19": "Kwale",
        "KE-20": "Laikipia",
        "KE-21": "Lamu",
        "KE-22": "Machakos",
        "KE-23": "Makueni",
        "KE-24": "Mandera",
        "KE-25": "Marsabit",
        "KE-26": "Meru",
        "KE-27": "Migori",
        "KE-28": "Mombasa",
        "KE-29": "Murang'a",
        "KE-30": "Nairobi City",
        "KE-31": "Nakuru",
        "KE-32": "Nandi",
        "KE-33": "Narok",
        "KE-34": "Nyamira",
        "KE-35": "Nyandarua",
        "KE-36": "Nyeri",
        "KE-37": "Samburu",
        "KE-38": "Siaya",
        "KE-39": "Taita/Taveta",
        "KE-40": "Tana River",
        "KE-41": "Tharaka-Nithi",
        "KE-42": "Trans Nzoia",
        "KE-43": "Turkana",
        "KE-44": "Uasin Gishu",
        "KE-45": "Vihiga",
        "KE-46": "Wajir",
        "KE-47": "West Pokot",
        "KI-G": "Gilbert Islands",
        "KI-L": "Line Islands",
        "KI-P": "Phoenix Islands",
        "KN-K": "Saint Kitts",
        "KN-08": "Saint Mary Cayon",
        "KN-02": "Saint Anne Sandy Point",
        "KN-11": "Saint Peter Basseterre",
        "KN-01": "Christ Church Nichola Town",
        "KN-15": "Trinity Palmetto Point",
        "KN-06": "Saint John Capisterre",
        "KN-09": "Saint Paul Capisterre",
        "KN-13": "Saint Thomas Middle Island",
        "KN-03": "Saint George Basseterre",
        "KN-N": "Nevis",
        "KN-10": "Saint Paul Charlestown",
        "KN-04": "Saint George Gingerland",
        "KN-05": "Saint James Windward",
        "KN-07": "Saint John Figtree",
        "KN-12": "Saint Thomas Lowland",
        "LC-06": "Gros Islet",
        "LC-03": "Choiseul",
        "LC-02": "Castries",
        "LC-05": "Dennery",
        "LC-08": "Micoud",
        "LC-12": "Canaries",
        "LC-07": "Laborie",
        "LC-10": "Soufrière",
        "LC-11": "Vieux Fort",
        "LC-01": "Anse la Raye",
        "LK-8": "Uva Province",
        "LK-62": "Puttalam",
        "LK-91": "Ratnapura",
        "LK-9": "Sabaragamuwa Province",
        "LK-44": "Vavuniya",
        "LK-53": "Trincomalee",
        "LK-6": "North Western Province",
        "LK-45": "Mullaittivu",
        "LK-82": "Monaragala",
        "LK-7": "North Central Province",
        "LK-23": "Nuwara Eliya",
        "LK-72": "Polonnaruwa",
        "LK-1": "Western Province",
        "LK-52": "Ampara",
        "LK-81": "Badulla",
        "LK-71": "Anuradhapura",
        "LK-4": "Northern Province",
        "LK-42": "Kilinochchi",
        "LK-92": "Kegalla",
        "LK-13": "Kalutara",
        "LK-21": "Kandy",
        "LK-61": "Kurunegala",
        "LK-5": "Eastern Province",
        "LK-43": "Mannar",
        "LK-32": "Matara",
        "LK-22": "Matale",
        "LK-2": "Central Province",
        "LK-11": "Colombo",
        "LK-51": "Batticaloa",
        "LK-31": "Galle",
        "LK-3": "Southern Province",
        "LK-12": "Gampaha",
        "LK-33": "Hambantota",
        "LK-41": "Jaffna",
        "LR-CM": "Grand Cape Mount",
        "LR-GP": "Gbarpolu",
        "LR-MG": "Margibi",
        "LR-BG": "Bong",
        "LR-MY": "Maryland",
        "LR-NI": "Nimba",
        "LR-SI": "Sinoe",
        "LR-RG": "River Gee",
        "LR-MO": "Montserrado",
        "LR-GK": "Grand Kru",
        "LR-BM": "Bomi",
        "LR-GB": "Grand Bassa",
        "LR-GG": "Grand Gedeh",
        "LR-LO": "Lofa",
        "LR-RI": "River Cess",
        "LS-D": "Berea",
        "LS-E": "Mafeteng",
        "LS-K": "Thaba-Tseka",
        "LS-A": "Maseru",
        "LS-F": "Mohale's Hoek",
        "LS-J": "Mokhotlong",
        "LS-C": "Leribe",
        "LS-H": "Qacha's Nek",
        "LS-B": "Butha-Buthe",
        "LS-G": "Quthing",
        "MH-T": "Ratak chain",
        "MH-LIK": "Likiep",
        "MH-MAJ": "Majuro",
        "MH-ARN": "Arno",
        "MH-AUR": "Aur",
        "MH-MAL": "Maloelap",
        "MH-MEJ": "Mejit",
        "MH-MIL": "Mili",
        "MH-WTJ": "Wotje",
        "MH-ALK": "Ailuk",
        "MH-UTI": "Utrik",
        "MH-L": "Ralik chain",
        "MH-NMK": "Namdrik",
        "MH-RON": "Rongelap",
        "MH-KWA": "Kwajalein",
        "MH-ALL": "Ailinglaplap",
        "MH-KIL": "Bikini & Kili",
        "MH-LAE": "Lae",
        "MH-NMU": "Namu",
        "MH-JAB": "Jabat",
        "MH-JAL": "Jaluit",
        "MH-LIB": "Lib",
        "MH-UJA": "Ujae",
        "MH-EBO": "Ebon",
        "MH-ENI": "Enewetak & Ujelang",
        "MH-WTH": "Wotho",
        "MT-34": "Msida",
        "MT-35": "Mtarfa",
        "MT-37": "Nadur",
        "MT-46": "Rabat Malta",
        "MT-13": "Għajnsielem",
        "MT-15": "Għargħur",
        "MT-20": "Isla",
        "MT-27": "Marsaskala",
        "MT-36": "Munxar",
        "MT-40": "Pembroke",
        "MT-44": "Qrendi",
        "MT-49": "Saint John",
        "MT-50": "Saint Lawrence",
        "MT-51": "Saint Paul's Bay",
        "MT-61": "Xagħra",
        "MT-63": "Xgħajra",
        "MT-67": "Żejtun",
        "MT-05": "Birżebbuġa",
        "MT-06": "Bormla",
        "MT-12": "Gżira",
        "MT-64": "Żabbar",
        "MT-02": "Balzan",
        "MT-03": "Birgu",
        "MT-19": "Iklin",
        "MT-24": "Lija",
        "MT-25": "Luqa",
        "MT-39": "Paola",
        "MT-52": "Sannat",
        "MT-55": "Siġġiewi",
        "MT-56": "Sliema",
        "MT-66": "Żebbuġ Malta",
        "MT-68": "Żurrieq",
        "MT-38": "Naxxar",
        "MT-41": "Pietà",
        "MT-54": "Santa Venera",
        "MT-58": "Ta' Xbiex",
        "MT-60": "Valletta",
        "MT-07": "Dingli",
        "MT-11": "Gudja",
        "MT-21": "Kalkara",
        "MT-31": "Mġarr",
        "MT-04": "Birkirkara",
        "MT-14": "Għarb",
        "MT-16": "Għasri",
        "MT-18": "Ħamrun",
        "MT-22": "Kerċem",
        "MT-26": "Marsa",
        "MT-47": "Safi",
        "MT-59": "Tarxien",
        "MT-62": "Xewkija",
        "MT-65": "Żebbuġ Gozo",
        "MT-09": "Floriana",
        "MT-30": "Mellieħa",
        "MT-42": "Qala",
        "MT-45": "Rabat Gozo",
        "MT-53": "Saint Lucia's",
        "MT-57": "Swieqi",
        "MT-01": "Attard",
        "MT-10": "Fontana",
        "MT-17": "Għaxaq",
        "MT-23": "Kirkop",
        "MT-28": "Marsaxlokk",
        "MT-29": "Mdina",
        "MT-32": "Mosta",
        "MT-33": "Mqabba",
        "MT-43": "Qormi",
        "MT-48": "Saint Julian's",
        "MT-08": "Fgura",
        "MU-BR": "Beau Bassin-Rose Hill",
        "MU-RO": "Rodrigues Island",
        "MU-SA": "Savanne",
        "MU-PW": "Plaines Wilhems",
        "MU-CC": "Cargados Carajos Shoals",
        "MU-GP": "Grand Port",
        "MU-CU": "Curepipe",
        "MU-PA": "Pamplemousses",
        "MU-PL": "Port Louis",
        "MU-BL": "Black River",
        "MU-FL": "Flacq",
        "MU-QB": "Quatre Bornes",
        "MU-MO": "Moka",
        "MU-PU": "Port Louis",
        "MU-RR": "Rivière du Rempart",
        "MU-VP": "Vacoas-Phoenix",
        "MU-AG": "Agalega Islands",
        "MV-SU": "South",
        "MV-29": "Gnaviyani",
        "MV-01": "Seenu",
        "MV-SC": "South Central",
        "MV-27": "Gaafu Alifu",
        "MV-28": "Gaafu Dhaalu",
        "MV-MLE": "Male",
        "MV-NO": "North",
        "MV-13": "Raa",
        "MV-03": "Lhaviyani",
        "MV-20": "Baa",
        "MV-25": "Noonu",
        "MV-UN": "Upper North",
        "MV-07": "Haa Alifu",
        "MV-23": "Haa Dhaalu",
        "MV-24": "Shaviyani",
        "MV-US": "Upper South",
        "MV-05": "Laamu",
        "MV-08": "Thaa",
        "MV-CE": "Central",
        "MV-14": "Faafu",
        "MV-12": "Meemu",
        "MV-17": "Dhaalu",
        "MV-NC": "North Central",
        "MV-04": "Vaavu",
        "MV-26": "Kaafu",
        "MV-00": "Alifu Dhaalu",
        "MV-02": "Alifu Alifu",
        "MW-C": "Central Region",
        "MW-KS": "Kasungu",
        "MW-NK": "Nkhotakota",
        "MW-MC": "Mchinji",
        "MW-NI": "Ntchisi",
        "MW-SA": "Salima",
        "MW-DE": "Dedza",
        "MW-DO": "Dowa",
        "MW-NU": "Ntcheu",
        "MW-LI": "Lilongwe",
        "MW-N": "Northern Region",
        "MW-CT": "Chitipa",
        "MW-KR": "Karonga",
        "MW-LK": "Likoma",
        "MW-NB": "Nkhata Bay",
        "MW-MZ": "Mzimba",
        "MW-RU": "Rumphi",
        "MW-S": "Southern Region",
        "MW-BA": "Balaka",
        "MW-CK": "Chikwawa",
        "MW-MG": "Mangochi",
        "MW-MW": "Mwanza",
        "MW-TH": "Thyolo",
        "MW-BL": "Blantyre",
        "MW-MH": "Machinga",
        "MW-NE": "Neno",
        "MW-CR": "Chiradzulu",
        "MW-MU": "Mulanje",
        "MW-NS": "Nsanje",
        "MW-PH": "Phalombe",
        "MW-ZO": "Zomba",
        "NA-HA": "Hardap",
        "NA-KH": "Khomas",
        "NA-CA": "Zambezi",
        "NA-ER": "Erongo",
        "NA-KU": "Kunene",
        "NA-OS": "Omusati",
        "NA-OW": "Ohangwena",
        "NA-OD": "Otjozondjupa",
        "NA-KE": "Kavango East",
        "NA-KW": "Kavango West",
        "NA-KA": "Karas",
        "NA-OH": "Omaheke",
        "NA-OT": "Oshikoto",
        "NA-ON": "Oshana",
        "NG-BO": "Borno",
        "NG-EB": "Ebonyi",
        "NG-FC": "Abuja Federal Capital Territory",
        "NG-KE": "Kebbi",
        "NG-NA": "Nasarawa",
        "NG-NI": "Niger",
        "NG-ON": "Ondo",
        "NG-OS": "Osun",
        "NG-OY": "Oyo",
        "NG-DE": "Delta",
        "NG-EK": "Ekiti",
        "NG-KO": "Kogi",
        "NG-LA": "Lagos",
        "NG-TA": "Taraba",
        "NG-KD": "Kaduna",
        "NG-OG": "Ogun",
        "NG-SO": "Sokoto",
        "NG-YO": "Yobe",
        "NG-ZA": "Zamfara",
        "NG-AK": "Akwa Ibom",
        "NG-EN": "Enugu",
        "NG-IM": "Imo",
        "NG-BE": "Benue",
        "NG-CR": "Cross River",
        "NG-JI": "Jigawa",
        "NG-AD": "Adamawa",
        "NG-AN": "Anambra",
        "NG-AB": "Abia",
        "NG-BA": "Bauchi",
        "NG-ED": "Edo",
        "NG-GO": "Gombe",
        "NG-KW": "Kwara",
        "NG-PL": "Plateau",
        "NG-RI": "Rivers",
        "NG-BY": "Bayelsa",
        "NG-KN": "Kano",
        "NG-KT": "Katsina",
        "NP-2": "Mid Western",
        "NP-3": "Western",
        "NP-4": "Eastern",
        "NP-5": "Far Western",
        "NP-1": "Central",
        "NR-02": "Anabar",
        "NR-04": "Anibare",
        "NR-01": "Aiwo",
        "NR-03": "Anetan",
        "NR-08": "Denigomodu",
        "NR-10": "Ijuw",
        "NR-09": "Ewa",
        "NR-12": "Nibok",
        "NR-14": "Yaren",
        "NR-07": "Buada",
        "NR-13": "Uaboe",
        "NR-05": "Baitsi",
        "NR-06": "Boe",
        "NR-11": "Meneng",
        "NZ-CIT": "Chatham Islands Territory",
        "NZ-AUK": "Auckland",
        "NZ-GIS": "Gisborne",
        "NZ-HKB": "Hawke's Bay",
        "NZ-MWT": "Manawatu-Wanganui",
        "NZ-BOP": "Bay of Plenty",
        "NZ-CAN": "Canterbury",
        "NZ-MBH": "Marlborough",
        "NZ-NSN": "Nelson",
        "NZ-NTL": "Northland",
        "NZ-TAS": "Tasman",
        "NZ-OTA": "Otago",
        "NZ-STL": "Southland",
        "NZ-TKI": "Taranaki",
        "NZ-WGN": "Wellington",
        "NZ-WTC": "West Coast",
        "PG-WHM": "Western Highlands",
        "PG-NCD": "National Capital District (Port Moresby)",
        "PG-WPD": "Western",
        "PG-ESW": "East Sepik",
        "PG-GPK": "Gulf",
        "PG-NSB": "Bougainville",
        "PG-EBR": "East New Britain",
        "PG-NIK": "New Ireland",
        "PG-SAN": "West Sepik",
        "PG-HLA": "Hela",
        "PG-JWK": "Jiwaka",
        "PG-EHG": "Eastern Highlands",
        "PG-EPW": "Enga",
        "PG-MRL": "Manus",
        "PG-NPP": "Northern",
        "PG-WBK": "West New Britain",
        "PG-MBA": "Milne Bay",
        "PG-MPL": "Morobe",
        "PG-SHM": "Southern Highlands",
        "PG-CPK": "Chimbu",
        "PG-CPM": "Central",
        "PG-MPM": "Madang",
        "PH-06": "Western Visayas (Region VI)",
        "PH-GUI": "Guimaras",
        "PH-ILI": "Iloilo",
        "PH-NEC": "Negros Occidental",
        "PH-AKL": "Aklan",
        "PH-ANT": "Antique",
        "PH-CAP": "Capiz",
        "PH-11": "Davao (Region XI)",
        "PH-SAR": "Sarangani",
        "PH-DAO": "Davao Oriental",
        "PH-DAS": "Davao del Sur",
        "PH-COM": "Compostela Valley",
        "PH-DAV": "Davao del Norte",
        "PH-SCO": "South Cotabato",
        "PH-DVO": "Davao Occidental",
        "PH-13": "Caraga (Region XIII)",
        "PH-SUN": "Surigao del Norte",
        "PH-SUR": "Surigao del Sur",
        "PH-AGS": "Agusan del Sur",
        "PH-AGN": "Agusan del Norte",
        "PH-DIN": "Dinagat Islands",
        "PH-01": "Ilocos (Region I)",
        "PH-ILN": "Ilocos Norte",
        "PH-LUN": "La Union",
        "PH-PAN": "Pangasinan",
        "PH-ILS": "Ilocos Sur",
        "PH-03": "Central Luzon (Region III)",
        "PH-BAN": "Bataan",
        "PH-PAM": "Pampanga",
        "PH-BUL": "Bulacan",
        "PH-NUE": "Nueva Ecija",
        "PH-TAR": "Tarlac",
        "PH-ZMB": "Zambales",
        "PH-AUR": "Aurora",
        "PH-08": "Eastern Visayas (Region VIII)",
        "PH-LEY": "Leyte",
        "PH-SLE": "Southern Leyte",
        "PH-BIL": "Biliran",
        "PH-EAS": "Eastern Samar",
        "PH-NSA": "Northern Samar",
        "PH-WSA": "Samar",
        "PH-10": "Northern Mindanao (Region X)",
        "PH-MSR": "Misamis Oriental",
        "PH-MSC": "Misamis Occidental",
        "PH-CAM": "Camiguin",
        "PH-BUK": "Bukidnon",
        "PH-00": "National Capital Region",
        "PH-02": "Cagayan Valley (Region II)",
        "PH-CAG": "Cagayan",
        "PH-NUV": "Nueva Vizcaya",
        "PH-QUI": "Quirino",
        "PH-ISA": "Isabela",
        "PH-BTN": "Batanes",
        "PH-07": "Central Visayas (Region VII)",
        "PH-CEB": "Cebu",
        "PH-BOH": "Bohol",
        "PH-NER": "Negros Oriental",
        "PH-SIG": "Siquijor",
        "PH-05": "Bicol (Region V)",
        "PH-CAT": "Catanduanes",
        "PH-CAN": "Camarines Norte",
        "PH-SOR": "Sorsogon",
        "PH-ALB": "Albay",
        "PH-CAS": "Camarines Sur",
        "PH-MAS": "Masbate",
        "PH-09": "Zamboanga Peninsula (Region IX)",
        "PH-ZAS": "Zamboanga del Sur",
        "PH-ZAN": "Zamboanga del Norte",
        "PH-ZSI": "Zamboanga Sibugay",
        "PH-BAS": "Basilan",
        "PH-12": "Soccsksargen (Region XII)",
        "PH-LAN": "Lanao del Norte",
        "PH-SUK": "Sultan Kudarat",
        "PH-NCO": "Cotabato",
        "PH-14": "Autonomous Region in Muslim Mindanao (ARMM)",
        "PH-LAS": "Lanao del Sur",
        "PH-MAG": "Maguindanao",
        "PH-TAW": "Tawi-Tawi",
        "PH-SLU": "Sulu",
        "PH-15": "Cordillera Administrative Region (CAR)",
        "PH-ABR": "Abra",
        "PH-BEN": "Benguet",
        "PH-MOU": "Mountain Province",
        "PH-APA": "Apayao",
        "PH-IFU": "Ifugao",
        "PH-KAL": "Kalinga",
        "PH-40": "Calabarzon (Region IV-A)",
        "PH-RIZ": "Rizal",
        "PH-BTG": "Batangas",
        "PH-CAV": "Cavite",
        "PH-LAG": "Laguna",
        "PH-QUE": "Quezon",
        "PH-41": "Mimaropa (Region IV-B)",
        "PH-MDR": "Mindoro Oriental",
        "PH-PLW": "Palawan",
        "PH-ROM": "Romblon",
        "PH-MAD": "Marinduque",
        "PH-MDC": "Mindoro Occidental",
        "PK-GB": "Gilgit-Baltistan",
        "PK-SD": "Sindh",
        "PK-KP": "Khyber Pakhtunkhwa",
        "PK-TA": "Federally Administered Tribal Areas",
        "PK-PB": "Punjab",
        "PK-JK": "Azad Jammu and Kashmir",
        "PK-BA": "Balochistan",
        "PK-IS": "Islamabad",
        "PS-DEB": "Deir El Balah",
        "PS-QQA": "Qalqilya",
        "PS-RFH": "Rafah",
        "PS-HBN": "Hebron",
        "PS-JEN": "Jenin",
        "PS-NGZ": "North Gaza",
        "PS-RBH": "Ramallah",
        "PS-TBS": "Tubas",
        "PS-GZA": "Gaza",
        "PS-JRH": "Jericho and Al Aghwar",
        "PS-NBS": "Nablus",
        "PS-BTH": "Bethlehem",
        "PS-JEM": "Jerusalem",
        "PS-SLT": "Salfit",
        "PS-TKM": "Tulkarm",
        "PS-KYS": "Khan Yunis",
        "PW-212": "Melekeok",
        "PW-214": "Ngaraard",
        "PW-224": "Ngatpang",
        "PW-228": "Ngiwal",
        "PW-218": "Ngarchelong",
        "PW-004": "Airai",
        "PW-227": "Ngeremlengui",
        "PW-350": "Peleliu",
        "PW-002": "Aimeliik",
        "PW-010": "Angaur",
        "PW-150": "Koror",
        "PW-222": "Ngardmau",
        "PW-226": "Ngchesar",
        "PW-370": "Sonsorol",
        "PW-050": "Hatohobei",
        "PW-100": "Kayangel",
        "RW-01": "City of Kigali",
        "RW-02": "Eastern",
        "RW-04": "Western",
        "RW-05": "Southern",
        "RW-03": "Northern",
        "SB-CE": "Central",
        "SB-CT": "Capital Territory (Honiara)",
        "SB-ML": "Malaita",
        "SB-MK": "Makira-Ulawa",
        "SB-RB": "Rennell and Bellona",
        "SB-GU": "Guadalcanal",
        "SB-TE": "Temotu",
        "SB-IS": "Isabel",
        "SB-CH": "Choiseul",
        "SB-WE": "Western",
        "SC-08": "Beau Vallon",
        "SC-19": "Plaisance",
        "SC-12": "Glacis",
        "SC-13": "Grand Anse Mahe",
        "SC-04": "Au Cap",
        "SC-17": "Mont Buxton",
        "SC-15": "La Digue",
        "SC-20": "Pointe Larue",
        "SC-21": "Port Glaud",
        "SC-23": "Takamaka",
        "SC-05": "Anse Royale",
        "SC-07": "Baie Sainte Anne",
        "SC-10": "Bel Ombre",
        "SC-11": "Cascade",
        "SC-16": "English River",
        "SC-18": "Mont Fleuri",
        "SC-25": "Roche Caiman",
        "SC-01": "Anse aux Pins",
        "SC-02": "Anse Boileau",
        "SC-09": "Bel Air",
        "SC-14": "Grand Anse Praslin",
        "SC-22": "Saint Louis",
        "SC-03": "Anse Etoile",
        "SC-06": "Baie Lazare",
        "SC-24": "Les Mamelles",
        "SD-NO": "Northern",
        "SD-DS": "South Darfur",
        "SD-SI": "Sennar",
        "SD-DE": "East Darfur",
        "SD-KH": "Khartoum",
        "SD-DN": "North Darfur",
        "SD-DW": "West Darfur",
        "SD-GZ": "Gezira",
        "SD-NW": "White Nile",
        "SD-DC": "Central Darfur",
        "SD-KA": "Kassala",
        "SD-KN": "North Kordofan",
        "SD-KS": "South Kordofan",
        "SD-NB": "Blue Nile",
        "SD-NR": "River Nile",
        "SD-GK": "West Kordofan",
        "SD-GD": "Gedaref",
        "SD-RS": "Red Sea",
        "SG-03": "North West",
        "SG-04": "South East",
        "SG-01": "Central Singapore",
        "SG-05": "South West",
        "SG-02": "North East",
        "SH-TA": "Tristan da Cunha",
        "SH-HL": "Saint Helena",
        "SH-AC": "Ascension",
        "SL-S": "Southern",
        "SL-N": "Northern",
        "SL-E": "Eastern",
        "SL-W": "Western Area (Freetown)",
        "SS-BW": "Western Bahr el  Ghazal",
        "SS-NU": "Upper Nile",
        "SS-UY": "Unity",
        "SS-BN": "Northern Bahr el Ghazal",
        "SS-WR": "Warrap",
        "SS-EE": "Eastern Equatoria",
        "SS-EC": "Central Equatoria",
        "SS-EW": "Western Equatoria",
        "SS-JG": "Jonglei",
        "SS-LK": "Lakes",
        "SZ-MA": "Manzini",
        "SZ-SH": "Shiselweni",
        "SZ-LU": "Lubombo",
        "SZ-HH": "Hhohho",
        "TO-02": "Ha'apai",
        "TO-03": "Niuas",
        "TO-04": "Tongatapu",
        "TO-01": "'Eua",
        "TO-05": "Vava'u",
        "TT-ARI": "Arima",
        "TT-DMN": "Diego Martin",
        "TT-PTF": "Point Fortin",
        "TT-TUP": "Tunapuna-Piarco",
        "TT-CTT": "Couva-Tabaquite-Talparo",
        "TT-PRT": "Princes Town",
        "TT-SJL": "San Juan-Laventille",
        "TT-CHA": "Chaguanas",
        "TT-SGE": "Sangre Grande",
        "TT-POS": "Port of Spain",
        "TT-SFO": "San Fernando",
        "TT-TOB": "Tobago",
        "TT-MRC": "Mayaro-Rio Claro",
        "TT-PED": "Penal-Debe",
        "TT-SIP": "Siparia",
        "TV-FUN": "Funafuti",
        "TV-NMA": "Nanumea",
        "TV-NUI": "Nui",
        "TV-NIT": "Niutao",
        "TV-VAI": "Vaitupu",
        "TV-NKL": "Nukulaelae",
        "TV-NKF": "Nukufetau",
        "TV-NMG": "Nanumaga",
        "TZ-15": "Zanzibar West",
        "TZ-19": "Coast",
        "TZ-10": "Pemba South",
        "TZ-11": "Zanzibar South",
        "TZ-06": "Pemba North",
        "TZ-07": "Zanzibar North",
        "UG-W": "Western",
        "UG-401": "Bundibugyo",
        "UG-403": "Hoima",
        "UG-415": "Kyenjojo",
        "UG-419": "Kiruhura",
        "UG-411": "Ntungamo",
        "UG-413": "Kamwenge",
        "UG-416": "Buliisa",
        "UG-414": "Kanungu",
        "UG-417": "Ibanda",
        "UG-418": "Isingiro",
        "UG-405": "Kabarole",
        "UG-407": "Kibaale",
        "UG-409": "Masindi",
        "UG-412": "Rukungiri",
        "UG-402": "Bushenyi",
        "UG-406": "Kasese",
        "UG-408": "Kisoro",
        "UG-410": "Mbarara",
        "UG-404": "Kabale",
        "UG-427": "Kagadi",
        "UG-428": "Kakumiro",
        "UG-429": "Rubanda",
        "UG-430": "Bunyangabu",
        "UG-431": "Rukiga",
        "UG-420": "Buhweju",
        "UG-421": "Kiryandongo",
        "UG-422": "Kyegegwa",
        "UG-423": "Mitooma",
        "UG-424": "Ntoroko",
        "UG-425": "Rubirizi",
        "UG-426": "Sheema",
        "UG-C": "Central",
        "UG-104": "Luwero",
        "UG-107": "Mubende",
        "UG-102": "Kampala",
        "UG-109": "Nakasongola",
        "UG-110": "Rakai",
        "UG-112": "Kayunga",
        "UG-113": "Wakiso",
        "UG-105": "Masaka",
        "UG-115": "Mityana",
        "UG-108": "Mukono",
        "UG-111": "Sembabule",
        "UG-114": "Lyantonde",
        "UG-103": "Kiboga",
        "UG-116": "Nakaseke",
        "UG-117": "Buikwe",
        "UG-118": "Bukomansibi",
        "UG-119": "Butambala",
        "UG-120": "Buvuma",
        "UG-121": "Gomba",
        "UG-101": "Kalangala",
        "UG-106": "Mpigi",
        "UG-122": "Kalungu",
        "UG-125": "Kyotera",
        "UG-123": "Kyankwanzi",
        "UG-124": "Lwengo",
        "UG-E": "Eastern",
        "UG-210": "Pallisa",
        "UG-202": "Busia",
        "UG-207": "Katakwi",
        "UG-205": "Kamuli",
        "UG-208": "Kumi",
        "UG-222": "Kaliro",
        "UG-203": "Iganga",
        "UG-206": "Kapchorwa",
        "UG-213": "Kaberamaido",
        "UG-214": "Mayuge",
        "UG-215": "Sironko",
        "UG-218": "Bududa",
        "UG-219": "Bukedea",
        "UG-221": "Butaleja",
        "UG-216": "Amuria",
        "UG-220": "Bukwa",
        "UG-201": "Bugiri",
        "UG-211": "Soroti",
        "UG-217": "Budaka",
        "UG-204": "Jinja",
        "UG-209": "Mbale",
        "UG-212": "Tororo",
        "UG-233": "Butebo",
        "UG-234": "Namisindwa",
        "UG-223": "Manafwa",
        "UG-224": "Namutumba",
        "UG-225": "Bulambuli",
        "UG-226": "Buyende",
        "UG-227": "Kibuku",
        "UG-228": "Kween",
        "UG-229": "Luuka",
        "UG-230": "Namayingo",
        "UG-231": "Ngora",
        "UG-232": "Serere",
        "UG-N": "Northern",
        "UG-306": "Kotido",
        "UG-309": "Moyo",
        "UG-310": "Nebbi",
        "UG-313": "Yumbe",
        "UG-318": "Kaabong",
        "UG-331": "Omoro",
        "UG-301": "Adjumani",
        "UG-308": "Moroto",
        "UG-314": "Abim",
        "UG-315": "Amolatar",
        "UG-317": "Dokolo",
        "UG-316": "Amuru",
        "UG-320": "Maracha",
        "UG-305": "Kitgum",
        "UG-307": "Lira",
        "UG-321": "Oyam",
        "UG-302": "Apac",
        "UG-303": "Arua",
        "UG-311": "Nakapiripirit",
        "UG-304": "Gulu",
        "UG-312": "Pader",
        "UG-319": "Koboko",
        "UG-332": "Pakwach",
        "UG-322": "Agago",
        "UG-323": "Alebtong",
        "UG-324": "Amudat",
        "UG-325": "Kole",
        "UG-326": "Lamwo",
        "UG-327": "Napak",
        "UG-328": "Nwoya",
        "UG-329": "Otuke",
        "UG-330": "Zombo",
        "UM-71": "Midway Islands",
        "UM-84": "Howland Island",
        "UM-86": "Jarvis Island",
        "UM-79": "Wake Island",
        "UM-89": "Kingman Reef",
        "UM-76": "Navassa Island",
        "UM-67": "Johnston Atoll",
        "UM-81": "Baker Island",
        "UM-95": "Palmyra Atoll",
        "US-AS": "American Samoa ",
        "US-GA": "Georgia",
        "US-KS": "Kansas",
        "US-MN": "Minnesota",
        "US-TX": "Texas",
        "US-UM": "United States Minor Outlying Islands ",
        "US-AK": "Alaska",
        "US-AL": "Alabama",
        "US-CO": "Colorado",
        "US-KY": "Kentucky",
        "US-MP": "Northern Mariana Islands ",
        "US-NY": "New York",
        "US-RI": "Rhode Island",
        "US-SC": "South Carolina",
        "US-SD": "South Dakota",
        "US-WA": "Washington",
        "US-DC": "District of Columbia",
        "US-GU": "Guam ",
        "US-IA": "Iowa",
        "US-OH": "Ohio",
        "US-OR": "Oregon",
        "US-CA": "California",
        "US-DE": "Delaware",
        "US-HI": "Hawaii",
        "US-MA": "Massachusetts",
        "US-MT": "Montana",
        "US-NC": "North Carolina",
        "US-NE": "Nebraska",
        "US-NJ": "New Jersey",
        "US-VI": "Virgin Islands, U.S. ",
        "US-CT": "Connecticut",
        "US-FL": "Florida",
        "US-IN": "Indiana",
        "US-OK": "Oklahoma",
        "US-UT": "Utah",
        "US-WI": "Wisconsin",
        "US-WV": "West Virginia",
        "US-ID": "Idaho",
        "US-IL": "Illinois",
        "US-MD": "Maryland",
        "US-ME": "Maine",
        "US-MO": "Missouri",
        "US-MS": "Mississippi",
        "US-NM": "New Mexico",
        "US-PA": "Pennsylvania",
        "US-VT": "Vermont",
        "US-WY": "Wyoming",
        "US-AZ": "Arizona",
        "US-LA": "Louisiana",
        "US-ND": "North Dakota",
        "US-NH": "New Hampshire",
        "US-NV": "Nevada",
        "US-VA": "Virginia",
        "US-AR": "Arkansas",
        "US-MI": "Michigan",
        "US-PR": "Puerto Rico ",
        "US-TN": "Tennessee",
        "VC-01": "Charlotte",
        "VC-06": "Grenadines",
        "VC-02": "Saint Andrew",
        "VC-05": "Saint Patrick",
        "VC-03": "Saint David",
        "VC-04": "Saint George",
        "VU-SEE": "Shéfa",
        "VU-MAP": "Malampa",
        "VU-PAM": "Pénama",
        "VU-SAM": "Sanma",
        "VU-TOB": "Torba",
        "VU-TAE": "Taféa",
        "WS-SA": "Satupa'itea",
        "WS-AA": "A'ana",
        "WS-FA": "Fa'asaleleaga",
        "WS-GE": "Gaga'emauga",
        "WS-AT": "Atua",
        "WS-PA": "Palauli",
        "WS-TU": "Tuamasaga",
        "WS-VF": "Va'a-o-Fonoti",
        "WS-GI": "Gagaifomauga",
        "WS-AL": "Aiga-i-le-Tai",
        "WS-VS": "Vaisigano",
        "ZA-NL": "Kwazulu-Natal",
        "ZA-WC": "Western Cape",
        "ZA-NC": "Northern Cape",
        "ZA-GT": "Gauteng",
        "ZA-EC": "Eastern Cape",
        "ZA-MP": "Mpumalanga",
        "ZA-FS": "Free State",
        "ZA-LP": "Limpopo",
        "ZA-NW": "North-West",
        "ZM-01": "Western",
        "ZM-02": "Central",
        "ZM-06": "North-Western",
        "ZM-07": "Southern",
        "ZM-04": "Luapula",
        "ZM-10": "Muchinga",
        "ZM-03": "Eastern",
        "ZM-08": "Copperbelt",
        "ZM-09": "Lusaka",
        "ZM-05": "Northern",
        "ZW-MS": "Matabeleland South",
        "ZW-BU": "Bulawayo",
        "ZW-HA": "Harare",
        "ZW-MI": "Midlands",
        "ZW-MW": "Mashonaland West",
        "ZW-ME": "Mashonaland East",
        "ZW-MN": "Matabeleland North",
        "ZW-MV": "Masvingo",
        "ZW-MA": "Manicaland",
        "ZW-MC": "Mashonaland Central"
      },
      "sq": {
        "AL-11": "Tiranë",
        "AL-12": "Vlorë",
        "AL-03": "Elbasan",
        "AL-09": "Dibër",
        "AL-01": "Berat",
        "AL-02": "Durrës",
        "AL-08": "Lezhë",
        "AL-10": "Shkodër",
        "AL-05": "Gjirokastër",
        "AL-06": "Korçë",
        "AL-07": "Kukës",
        "AL-04": "Fier"
      },
      "hy": {
        "AM-AG": "Aragac̣otn",
        "AM-AV": "Armavir",
        "AM-KT": "Kotayk'",
        "AM-GR": "Geġark'unik'",
        "AM-SH": "Širak",
        "AM-SU": "Syunik'",
        "AM-TV": "Tavuš",
        "AM-LO": "Loṙi",
        "AM-ER": "Erevan",
        "AM-VD": "Vayoć Jor",
        "AM-AR": "Ararat"
      },
      "pt": {
        "AO-CAB": "Cabinda",
        "AO-CCU": "Kuando Kubango",
        "AO-ZAI": "Zaire",
        "AO-LNO": "Lunda Norte",
        "AO-LSU": "Lunda Sul",
        "AO-MOX": "Moxico",
        "AO-NAM": "Namibe",
        "AO-UIG": "Uíge",
        "AO-CNO": "Kwanza Norte",
        "AO-HUI": "Huíla",
        "AO-LUA": "Luanda",
        "AO-MAL": "Malange",
        "AO-BGO": "Bengo",
        "AO-BGU": "Benguela",
        "AO-CNN": "Cunene",
        "AO-CUS": "Kwanza Sul",
        "AO-HUA": "Huambo",
        "AO-BIE": "Bié",
        "BR-AC": "Acre",
        "BR-AM": "Amazonas",
        "BR-CE": "Ceará",
        "BR-RR": "Roraima",
        "BR-GO": "Goiás",
        "BR-MG": "Minas Gerais",
        "BR-ES": "Espírito Santo",
        "BR-AP": "Amapá",
        "BR-BA": "Bahia",
        "BR-MS": "Mato Grosso do Sul",
        "BR-PR": "Paraná",
        "BR-RN": "Rio Grande do Norte",
        "BR-MT": "Mato Grosso",
        "BR-PB": "Paraíba",
        "BR-PE": "Pernambuco",
        "BR-RJ": "Rio de Janeiro",
        "BR-RO": "Rondônia",
        "BR-SC": "Santa Catarina",
        "BR-SP": "São Paulo",
        "BR-AL": "Alagoas",
        "BR-DF": "Distrito Federal",
        "BR-MA": "Maranhão",
        "BR-PA": "Pará",
        "BR-PI": "Piauí",
        "BR-RS": "Rio Grande do Sul",
        "BR-SE": "Sergipe",
        "BR-TO": "Tocantins",
        "CN-MO": "Macau SAR ",
        "CV-B": "Ilhas de Barlavento",
        "CV-SV": "São Vicente",
        "CV-BV": "Boa Vista",
        "CV-SL": "Sal",
        "CV-TS": "Tarrafal de São Nicolau",
        "CV-PA": "Paul",
        "CV-RB": "Ribeira Brava",
        "CV-PN": "Porto Novo",
        "CV-RG": "Ribeira Grande",
        "CV-S": "Ilhas de Sotavento",
        "CV-MA": "Maio",
        "CV-PR": "Praia",
        "CV-RS": "Ribeira Grande de Santiago",
        "CV-SS": "São Salvador do Mundo",
        "CV-TA": "Tarrafal",
        "CV-CR": "Santa Cruz",
        "CV-SD": "São Domingos",
        "CV-SF": "São Filipe",
        "CV-CA": "Santa Catarina",
        "CV-MO": "Mosteiros",
        "CV-SM": "São Miguel",
        "CV-SO": "São Lourenço dos Órgãos",
        "CV-CF": "Santa Catarina do Fogo",
        "CV-BR": "Brava",
        "GQ-I": "Região Insular",
        "GQ-AN": "Ano Bom",
        "GQ-BN": "Bioko Norte",
        "GQ-BS": "Bioko Sul",
        "GQ-C": "Região Continental",
        "GQ-KN": "Kié-Ntem",
        "GQ-CS": "Centro Sul",
        "GQ-LI": "Litoral",
        "GQ-WN": "Wele-Nzas",
        "GW-BS": "Bissau",
        "GW-N": "Norte",
        "GW-OI": "Oio",
        "GW-CA": "Cacheu",
        "GW-BM": "Biombo",
        "GW-S": "Sul",
        "GW-TO": "Tombali",
        "GW-BL": "Bolama",
        "GW-QU": "Quinara",
        "GW-L": "Leste",
        "GW-GA": "Gabú",
        "GW-BA": "Bafatá",
        "MZ-A": "Niassa",
        "MZ-L": "Maputo",
        "MZ-Q": "Zambézia",
        "MZ-S": "Sofala",
        "MZ-B": "Manica",
        "MZ-T": "Tete",
        "MZ-P": "Cabo Delgado",
        "MZ-N": "Nampula",
        "MZ-G": "Gaza",
        "MZ-I": "Inhambane",
        "MZ-MPM": "Maputo",
        "PT-01": "Aveiro",
        "PT-02": "Beja",
        "PT-05": "Castelo Branco",
        "PT-09": "Guarda",
        "PT-12": "Portalegre",
        "PT-04": "Bragança",
        "PT-06": "Coimbra",
        "PT-10": "Leiria",
        "PT-11": "Lisboa",
        "PT-15": "Setúbal",
        "PT-16": "Viana do Castelo",
        "PT-30": "Região Autónoma da Madeira",
        "PT-03": "Braga",
        "PT-17": "Vila Real",
        "PT-07": "Évora",
        "PT-13": "Porto",
        "PT-14": "Santarém",
        "PT-08": "Faro",
        "PT-18": "Viseu",
        "PT-20": "Região Autónoma dos Açores",
        "ST-P": "Príncipe",
        "ST-S": "São Tomé",
        "TL-ER": "Ermera",
        "TL-BO": "Bobonaro",
        "TL-CO": "Cova Lima",
        "TL-MF": "Manufahi",
        "TL-AN": "Ainaro",
        "TL-BA": "Baucau",
        "TL-LA": "Lautem",
        "TL-VI": "Viqueque",
        "TL-DI": "Díli",
        "TL-LI": "Liquiça",
        "TL-MT": "Manatuto",
        "TL-AL": "Aileu",
        "TL-OE": "Oecussi"
      },
      "es": {
        "AR-N": "Misiones",
        "AR-S": "Santa Fe",
        "AR-Z": "Santa Cruz",
        "AR-A": "Salta",
        "AR-C": "Ciudad Autónoma de Buenos Aires",
        "AR-F": "La Rioja",
        "AR-B": "Buenos Aires",
        "AR-G": "Santiago del Estero",
        "AR-H": "Chaco",
        "AR-P": "Formosa",
        "AR-X": "Córdoba",
        "AR-J": "San Juan",
        "AR-T": "Tucumán",
        "AR-E": "Entre Ríos",
        "AR-K": "Catamarca",
        "AR-V": "Tierra del Fuego",
        "AR-W": "Corrientes",
        "AR-U": "Chubut",
        "AR-D": "San Luis",
        "AR-L": "La Pampa",
        "AR-M": "Mendoza",
        "AR-Q": "Neuquén",
        "AR-R": "Río Negro",
        "AR-Y": "Jujuy",
        "BO-C": "Cochabamba",
        "BO-L": "La Paz",
        "BO-B": "El Beni",
        "BO-O": "Oruro",
        "BO-S": "Santa Cruz",
        "BO-P": "Potosí",
        "BO-T": "Tarija",
        "BO-H": "Chuquisaca",
        "BO-N": "Pando",
        "CL-AN": "Antofagasta",
        "CL-RM": "Región Metropolitana de Santiago",
        "CL-TA": "Tarapacá",
        "CL-BI": "Biobío",
        "CL-AP": "Arica y Parinacota",
        "CL-AT": "Atacama",
        "CL-LL": "Los Lagos",
        "CL-LR": "Los Ríos",
        "CL-ML": "Maule",
        "CL-AR": "La Araucanía",
        "CL-LI": "Libertador General Bernardo O'Higgins",
        "CL-AI": "Aisén del General Carlos Ibañez del Campo",
        "CL-VS": "Valparaíso",
        "CL-CO": "Coquimbo",
        "CL-MA": "Magallanes",
        "CO-BOL": "Bolívar",
        "CO-MAG": "Magdalena",
        "CO-MET": "Meta",
        "CO-PUT": "Putumayo",
        "CO-VID": "Vichada",
        "CO-AMA": "Amazonas",
        "CO-CAL": "Caldas",
        "CO-CAU": "Cauca",
        "CO-CES": "Cesar",
        "CO-DC": "Distrito Capital de Bogotá",
        "CO-LAG": "La Guajira",
        "CO-ARA": "Arauca",
        "CO-NSA": "Norte de Santander",
        "CO-TOL": "Tolima",
        "CO-VAC": "Valle del Cauca",
        "CO-GUV": "Guaviare",
        "CO-HUI": "Huila",
        "CO-SUC": "Sucre",
        "CO-CAS": "Casanare",
        "CO-CHO": "Chocó",
        "CO-GUA": "Guainía",
        "CO-QUI": "Quindío",
        "CO-RIS": "Risaralda",
        "CO-SAN": "Santander",
        "CO-VAU": "Vaupés",
        "CO-ANT": "Antioquia",
        "CO-ATL": "Atlántico",
        "CO-COR": "Córdoba",
        "CO-SAP": "San Andrés, Providencia y Santa Catalina",
        "CO-BOY": "Boyacá",
        "CO-CAQ": "Caquetá",
        "CO-CUN": "Cundinamarca",
        "CO-NAR": "Nariño",
        "CR-H": "Heredia",
        "CR-L": "Limón",
        "CR-G": "Guanacaste",
        "CR-P": "Puntarenas",
        "CR-A": "Alajuela",
        "CR-C": "Cartago",
        "CR-SJ": "San José",
        "CU-03": "La Habana",
        "CU-07": "Sancti Spíritus",
        "CU-12": "Granma",
        "CU-05": "Villa Clara",
        "CU-11": "Holguín",
        "CU-08": "Ciego de Ávila",
        "CU-09": "Camagüey",
        "CU-10": "Las Tunas",
        "CU-13": "Santiago de Cuba",
        "CU-15": "Artemisa",
        "CU-16": "Mayabeque",
        "CU-01": "Pinar del Río",
        "CU-14": "Guantánamo",
        "CU-99": "Isla de la Juventud",
        "CU-04": "Matanzas",
        "CU-06": "Cienfuegos",
        "DO-33": "Cibao Nordeste",
        "DO-14": "María Trinidad Sánchez",
        "DO-06": "Duarte",
        "DO-19": "Hermanas Mirabal",
        "DO-20": "Samaná",
        "DO-34": "Cibao Noroeste",
        "DO-26": "Santiago Rodríguez",
        "DO-27": "Valverde",
        "DO-05": "Dajabón",
        "DO-15": "Monte Cristi",
        "DO-35": "Cibao Norte",
        "DO-09": "Espaillat",
        "DO-18": "Puerto Plata",
        "DO-25": "Santiago",
        "DO-36": "Cibao Sur",
        "DO-24": "Sánchez Ramírez",
        "DO-13": "La Vega",
        "DO-28": "Monseñor Nouel",
        "DO-37": "El Valle",
        "DO-07": "Elías Piña",
        "DO-22": "San Juan",
        "DO-38": "Enriquillo",
        "DO-03": "Baoruco",
        "DO-16": "Pedernales",
        "DO-04": "Barahona",
        "DO-10": "Independencia",
        "DO-39": "Higuamo",
        "DO-23": "San Pedro de Macorís",
        "DO-30": "Hato Mayor",
        "DO-29": "Monte Plata",
        "DO-40": "Ozama",
        "DO-01": "Distrito Nacional (Santo Domingo)",
        "DO-32": "Santo Domingo",
        "DO-41": "Valdesia",
        "DO-21": "San Cristóbal",
        "DO-31": "San José de Ocoa",
        "DO-02": "Azua",
        "DO-17": "Peravia",
        "DO-42": "Yuma",
        "DO-08": "El Seibo",
        "DO-12": "La Romana",
        "DO-11": "La Altagracia",
        "EC-E": "Esmeraldas",
        "EC-G": "Guayas",
        "EC-X": "Cotopaxi",
        "EC-Y": "Pastaza",
        "EC-A": "Azuay",
        "EC-D": "Orellana",
        "EC-L": "Loja",
        "EC-O": "El Oro",
        "EC-SE": "Santa Elena",
        "EC-Z": "Zamora Chinchipe",
        "EC-H": "Chimborazo",
        "EC-SD": "Santo Domingo de los Tsáchilas",
        "EC-I": "Imbabura",
        "EC-M": "Manabí",
        "EC-P": "Pichincha",
        "EC-W": "Galápagos",
        "EC-C": "Carchi",
        "EC-F": "Cañar",
        "EC-S": "Morona Santiago",
        "EC-U": "Sucumbíos",
        "EC-N": "Napo",
        "EC-T": "Tungurahua",
        "EC-B": "Bolívar",
        "EC-R": "Los Ríos",
        "ES-CB": "Cantabria",
        "ES-S": "Cantabria",
        "ES-MC": "Murcia, Región de",
        "ES-MU": "Murcia",
        "ES-AR": "Aragón",
        "ES-Z": "Zaragoza",
        "ES-HU": "Huesca",
        "ES-TE": "Teruel",
        "ES-AS": "Asturias, Principado de",
        "ES-O": "Asturias",
        "ES-CE": "Ceuta",
        "ES-RI": "La Rioja",
        "ES-LO": "La Rioja",
        "ES-VC": "Valenciana, Comunidad",
        "ES-V": "Valencia",
        "ES-CS": "Castellón",
        "ES-A": "Alicante",
        "ES-B": "Barcelona [Barcelona]",
        "ES-CM": "Castilla-La Mancha",
        "ES-AB": "Albacete",
        "ES-CU": "Cuenca",
        "ES-TO": "Toledo",
        "ES-CR": "Ciudad Real",
        "ES-GU": "Guadalajara",
        "ES-EX": "Extremadura",
        "ES-CC": "Cáceres",
        "ES-BA": "Badajoz",
        "ES-MD": "Madrid, Comunidad de",
        "ES-M": "Madrid",
        "ES-ML": "Melilla",
        "ES-CL": "Castilla y León",
        "ES-AV": "Ávila",
        "ES-SO": "Soria",
        "ES-P": "Palencia",
        "ES-VA": "Valladolid",
        "ES-BU": "Burgos",
        "ES-SG": "Segovia",
        "ES-ZA": "Zamora",
        "ES-LE": "León",
        "ES-SA": "Salamanca",
        "ES-CN": "Canarias",
        "ES-TF": "Santa Cruz de Tenerife",
        "ES-GC": "Las Palmas",
        "ES-NC": "Navarra, Comunidad Foral de",
        "ES-NA": "Navarra",
        "ES-AN": "Andalucía",
        "ES-CA": "Cádiz",
        "ES-GR": "Granada",
        "ES-H": "Huelva",
        "ES-MA": "Málaga",
        "ES-CO": "Córdoba",
        "ES-AL": "Almería",
        "ES-J": "Jaén",
        "ES-SE": "Sevilla",
        "ES-PV": "País Vasco",
        "ES-VI": "Álava",
        "GQ-I": "Región Insular",
        "GQ-AN": "Annobón",
        "GQ-BN": "Bioko Norte",
        "GQ-BS": "Bioko Sur",
        "GQ-C": "Región Continental",
        "GQ-KN": "Kié-Ntem",
        "GQ-CS": "Centro Sur",
        "GQ-LI": "Litoral",
        "GQ-WN": "Wele-Nzas",
        "GT-GU": "Guatemala",
        "GT-ZA": "Zacapa",
        "GT-QZ": "Quetzaltenango",
        "GT-RE": "Retalhuleu",
        "GT-HU": "Huehuetenango",
        "GT-SO": "Sololá",
        "GT-SR": "Santa Rosa",
        "GT-SU": "Suchitepéquez",
        "GT-PR": "El Progreso",
        "GT-JA": "Jalapa",
        "GT-JU": "Jutiapa",
        "GT-SA": "Sacatepéquez",
        "GT-SM": "San Marcos",
        "GT-CQ": "Chiquimula",
        "GT-QC": "Quiché",
        "GT-AV": "Alta Verapaz",
        "GT-BV": "Baja Verapaz",
        "GT-CM": "Chimaltenango",
        "GT-ES": "Escuintla",
        "GT-IZ": "Izabal",
        "GT-PE": "Petén",
        "GT-TO": "Totonicapán",
        "HN-CL": "Colón",
        "HN-YO": "Yoro",
        "HN-VA": "Valle",
        "HN-CM": "Comayagua",
        "HN-LE": "Lempira",
        "HN-CH": "Choluteca",
        "HN-GD": "Gracias a Dios",
        "HN-IB": "Islas de la Bahía",
        "HN-OL": "Olancho",
        "HN-EP": "El Paraíso",
        "HN-AT": "Atlántida",
        "HN-CR": "Cortés",
        "HN-FM": "Francisco Morazán",
        "HN-IN": "Intibucá",
        "HN-OC": "Ocotepeque",
        "HN-CP": "Copán",
        "HN-LP": "La Paz",
        "HN-SB": "Santa Bárbara",
        "MX-BCS": "Baja California Sur",
        "MX-GRO": "Guerrero",
        "MX-HID": "Hidalgo",
        "MX-SON": "Sonora",
        "MX-ZAC": "Zacatecas",
        "MX-COA": "Coahuila de Zaragoza",
        "MX-COL": "Colima",
        "MX-DUR": "Durango",
        "MX-QUE": "Querétaro",
        "MX-SIN": "Sinaloa",
        "MX-VER": "Veracruz de Ignacio de la Llave",
        "MX-CHP": "Chiapas",
        "MX-JAL": "Jalisco",
        "MX-PUE": "Puebla",
        "MX-ROO": "Quintana Roo",
        "MX-TLA": "Tlaxcala",
        "MX-MEX": "México",
        "MX-NAY": "Nayarit",
        "MX-OAX": "Oaxaca",
        "MX-SLP": "San Luis Potosí",
        "MX-TAB": "Tabasco",
        "MX-CHH": "Chihuahua",
        "MX-NLE": "Nuevo León",
        "MX-TAM": "Tamaulipas",
        "MX-YUC": "Yucatán",
        "MX-MIC": "Michoacán de Ocampo",
        "MX-CMX": "Ciudad de México",
        "MX-AGU": "Aguascalientes",
        "MX-BCN": "Baja California",
        "MX-CAM": "Campeche",
        "MX-GUA": "Guanajuato",
        "MX-MOR": "Morelos",
        "NI-AN": "Costa Caribe Norte",
        "NI-RI": "Rivas",
        "NI-SJ": "Río San Juan",
        "NI-AS": "Costa Caribe Sur",
        "NI-GR": "Granada",
        "NI-MD": "Madriz",
        "NI-LE": "León",
        "NI-NS": "Nueva Segovia",
        "NI-CI": "Chinandega",
        "NI-JI": "Jinotega",
        "NI-CO": "Chontales",
        "NI-BO": "Boaco",
        "NI-MT": "Matagalpa",
        "NI-CA": "Carazo",
        "NI-ES": "Estelí",
        "NI-MN": "Managua",
        "NI-MS": "Masaya",
        "PA-1": "Bocas del Toro",
        "PA-2": "Coclé",
        "PA-5": "Darién",
        "PA-NB": "Ngöbe-Buglé",
        "PA-4": "Chiriquí",
        "PA-7": "Los Santos",
        "PA-8": "Panamá",
        "PA-6": "Herrera",
        "PA-10": "Panamá Oeste",
        "PA-3": "Colón",
        "PA-KY": "Guna Yala",
        "PA-EM": "Emberá",
        "PA-9": "Veraguas",
        "PE-ICA": "Ica",
        "PE-LIM": "Lima",
        "PE-MDD": "Madre de Dios",
        "PE-AYA": "Ayacucho",
        "PE-HUC": "Huánuco",
        "PE-ANC": "Ancash",
        "PE-HUV": "Huancavelica",
        "PE-LAM": "Lambayeque",
        "PE-TAC": "Tacna",
        "PE-APU": "Apurímac",
        "PE-LAL": "La Libertad",
        "PE-PAS": "Pasco",
        "PE-PIU": "Piura",
        "PE-CUS": "Cusco",
        "PE-MOQ": "Moquegua",
        "PE-PUN": "Puno",
        "PE-SAM": "San Martín",
        "PE-CAL": "El Callao",
        "PE-AMA": "Amazonas",
        "PE-ARE": "Arequipa",
        "PE-TUM": "Tumbes",
        "PE-CAJ": "Cajamarca",
        "PE-JUN": "Junín",
        "PE-LMA": "Municipalidad Metropolitana de Lima",
        "PE-LOR": "Loreto",
        "PE-UCA": "Ucayali",
        "PY-12": "Ñeembucú",
        "PY-15": "Presidente Hayes",
        "PY-3": "Cordillera",
        "PY-8": "Misiones",
        "PY-9": "Paraguarí",
        "PY-19": "Boquerón",
        "PY-6": "Caazapá",
        "PY-7": "Itapúa",
        "PY-ASU": "Asunción",
        "PY-4": "Guairá",
        "PY-1": "Concepción",
        "PY-5": "Caaguazú",
        "PY-13": "Amambay",
        "PY-16": "Alto Paraguay",
        "PY-2": "San Pedro",
        "PY-10": "Alto Paraná",
        "PY-11": "Central",
        "PY-14": "Canindeyú",
        "SV-AH": "Ahuachapán",
        "SV-SA": "Santa Ana",
        "SV-SO": "Sonsonate",
        "SV-PA": "La Paz",
        "SV-SM": "San Miguel",
        "SV-CA": "Cabañas",
        "SV-UN": "La Unión",
        "SV-CH": "Chalatenango",
        "SV-LI": "La Libertad",
        "SV-SV": "San Vicente",
        "SV-MO": "Morazán",
        "SV-US": "Usulután",
        "SV-CU": "Cuscatlán",
        "SV-SS": "San Salvador",
        "UY-CO": "Colonia",
        "UY-LA": "Lavalleja",
        "UY-RN": "Río Negro",
        "UY-SA": "Salto",
        "UY-DU": "Durazno",
        "UY-FD": "Florida",
        "UY-FS": "Flores",
        "UY-RV": "Rivera",
        "UY-CL": "Cerro Largo",
        "UY-MA": "Maldonado",
        "UY-RO": "Rocha",
        "UY-TA": "Tacuarembó",
        "UY-SJ": "San José",
        "UY-AR": "Artigas",
        "UY-PA": "Paysandú",
        "UY-SO": "Soriano",
        "UY-CA": "Canelones",
        "UY-MO": "Montevideo",
        "UY-TT": "Treinta y Tres",
        "VE-D": "Aragua",
        "VE-P": "Portuguesa",
        "VE-S": "Táchira",
        "VE-F": "Bolívar",
        "VE-H": "Cojedes",
        "VE-I": "Falcón",
        "VE-Z": "Amazonas",
        "VE-J": "Guárico",
        "VE-K": "Lara",
        "VE-O": "Nueva Esparta",
        "VE-U": "Yaracuy",
        "VE-C": "Apure",
        "VE-Y": "Delta Amacuro",
        "VE-A": "Distrito Capital",
        "VE-B": "Anzoátegui",
        "VE-L": "Mérida",
        "VE-N": "Monagas",
        "VE-R": "Sucre",
        "VE-T": "Trujillo",
        "VE-W": "Dependencias Federales",
        "VE-X": "Vargas",
        "VE-E": "Barinas",
        "VE-M": "Miranda",
        "VE-G": "Carabobo",
        "VE-V": "Zulia"
      },
      "de": {
        "AT-3": "Niederösterreich",
        "AT-4": "Oberösterreich",
        "AT-5": "Salzburg",
        "AT-8": "Vorarlberg",
        "AT-1": "Burgenland",
        "AT-7": "Tirol",
        "AT-2": "Kärnten",
        "AT-6": "Steiermark",
        "AT-9": "Wien",
        "CH-LU": "Luzern",
        "CH-SO": "Solothurn",
        "CH-TG": "Thurgau",
        "CH-BS": "Basel-Stadt",
        "CH-SZ": "Schwyz",
        "CH-ZG": "Zug",
        "CH-AG": "Aargau",
        "CH-BE": "Bern",
        "CH-FR": "Freiburg",
        "CH-OW": "Obwalden",
        "CH-AR": "Appenzell Ausserrhoden",
        "CH-GL": "Glarus",
        "CH-SG": "Sankt Gallen",
        "CH-GR": "Graubünden",
        "CH-NW": "Nidwalden",
        "CH-SH": "Schaffhausen",
        "CH-ZH": "Zürich",
        "CH-AI": "Appenzell Innerrhoden",
        "CH-VS": "Wallis",
        "CH-BL": "Basel-Landschaft",
        "CH-UR": "Uri",
        "DE-BW": "Baden-Württemberg",
        "DE-MV": "Mecklenburg-Vorpommern",
        "DE-NW": "Nordrhein-Westfalen",
        "DE-SL": "Saarland",
        "DE-TH": "Thüringen",
        "DE-ST": "Sachsen-Anhalt",
        "DE-HH": "Hamburg",
        "DE-BB": "Brandenburg",
        "DE-BE": "Berlin",
        "DE-BY": "Bayern",
        "DE-HB": "Bremen",
        "DE-HE": "Hessen",
        "DE-NI": "Niedersachsen",
        "DE-SH": "Schleswig-Holstein",
        "DE-RP": "Rheinland-Pfalz",
        "DE-SN": "Sachsen",
        "IT-32": "Trentino-Südtirol",
        "IT-BZ": "Bozen",
        "LI-04": "Mauren",
        "LI-03": "Gamprin",
        "LI-05": "Planken",
        "LI-06": "Ruggell",
        "LI-01": "Balzers",
        "LI-09": "Triesen",
        "LI-07": "Schaan",
        "LI-10": "Triesenberg",
        "LI-02": "Eschen",
        "LI-08": "Schellenberg",
        "LI-11": "Vaduz",
        "LU-CA": "Capellen",
        "LU-CL": "Clerf",
        "LU-DI": "Diekirch",
        "LU-EC": "Echternach",
        "LU-ES": "Esch an der Alzette",
        "LU-GR": "Grevenmacher",
        "LU-LU": "Luxemburg",
        "LU-ME": "Mersch",
        "LU-RD": "Redingen",
        "LU-RM": "Remich",
        "LU-VD": "Vianden",
        "LU-WI": "Wiltz"
      },
      "az": {
        "AZ-ABS": "Abşeron",
        "AZ-BA": "Bakı",
        "AZ-BAL": "Balakən",
        "AZ-BAR": "Bərdə",
        "AZ-GOY": "Göyçay",
        "AZ-MAS": "Masallı",
        "AZ-SKR": "Şəmkir",
        "AZ-SR": "Şirvan",
        "AZ-XA": "Xankəndi",
        "AZ-XIZ": "Xızı",
        "AZ-AGC": "Ağcabədi",
        "AZ-AGU": "Ağsu",
        "AZ-BIL": "Biləsuvar",
        "AZ-GA": "Gəncə",
        "AZ-QAB": "Qəbələ",
        "AZ-SMX": "Samux",
        "AZ-XAC": "Xaçmaz",
        "AZ-YAR": "Yardımlı",
        "AZ-ZAN": "Zəngilan",
        "AZ-AGA": "Ağstafa",
        "AZ-FUZ": "Füzuli",
        "AZ-GAD": "Gədəbəy",
        "AZ-LAC": "Laçın",
        "AZ-QAX": "Qax",
        "AZ-AGM": "Ağdam",
        "AZ-DAS": "Daşkəsən",
        "AZ-GYG": "Göygöl",
        "AZ-LA": "Lənkəran",
        "AZ-LAN": "Lənkəran",
        "AZ-OGU": "Oğuz",
        "AZ-QBA": "Quba",
        "AZ-QUS": "Qusar",
        "AZ-SAB": "Sabirabad",
        "AZ-CAB": "Cəbrayıl",
        "AZ-KUR": "Kürdəmir",
        "AZ-SAL": "Salyan",
        "AZ-SBN": "Şabran",
        "AZ-SM": "Sumqayıt",
        "AZ-UCA": "Ucar",
        "AZ-XCI": "Xocalı",
        "AZ-YEV": "Yevlax",
        "AZ-AGS": "Ağdaş",
        "AZ-AST": "Astara",
        "AZ-BEY": "Beyləqan",
        "AZ-HAC": "Hacıqabul",
        "AZ-IMI": "İmişli",
        "AZ-ISM": "İsmayıllı",
        "AZ-LER": "Lerik",
        "AZ-MI": "Mingəçevir",
        "AZ-NEF": "Neftçala",
        "AZ-NX": "Naxçıvan",
        "AZ-KAN": "Kǝngǝrli",
        "AZ-CUL": "Culfa",
        "AZ-ORD": "Ordubad",
        "AZ-SAD": "Sədərək",
        "AZ-SAH": "Şahbuz",
        "AZ-BAB": "Babək",
        "AZ-SAR": "Şərur",
        "AZ-NV": "Naxçıvan",
        "AZ-QAZ": "Qazax",
        "AZ-SAK": "Şəki",
        "AZ-SAT": "Saatlı",
        "AZ-TAR": "Tərtər",
        "AZ-XVD": "Xocavənd",
        "AZ-CAL": "Cəlilabad",
        "AZ-KAL": "Kəlbəcər",
        "AZ-QOB": "Qobustan",
        "AZ-SIY": "Siyəzən",
        "AZ-SUS": "Şuşa",
        "AZ-ZAQ": "Zaqatala",
        "AZ-ZAR": "Zərdab",
        "AZ-GOR": "Goranboy",
        "AZ-NA": "Naftalan",
        "AZ-QBI": "Qubadlı",
        "AZ-SA": "Şəki",
        "AZ-SMI": "Şamaxı",
        "AZ-TOV": "Tovuz",
        "AZ-YE": "Yevlax"
      },
      "sr": {
        "BA-BRC": "Brčko distrikt",
        "BA-BIH": "Federacija Bosne i Hercegovine",
        "BA-SRP": "Republika Srpska",
        "RS-11": "Braničevski okrug",
        "RS-13": "Pomoravski okrug",
        "RS-16": "Zlatiborski okrug",
        "RS-KM": "Kosovo-Metohija",
        "RS-27": "Prizrenski okrug",
        "RS-25": "Kosovski okrug",
        "RS-26": "Pećki okrug",
        "RS-28": "Kosovsko-Mitrovački okrug",
        "RS-29": "Kosovsko-Pomoravski okrug",
        "RS-VO": "Vojvodina",
        "RS-03": "Severnobanatski okrug",
        "RS-04": "Južnobanatski okrug",
        "RS-02": "Srednjebanatski okrug",
        "RS-07": "Sremski okrug",
        "RS-06": "Južnobački okrug",
        "RS-01": "Severnobački okrug",
        "RS-05": "Zapadnobački okrug",
        "RS-20": "Nišavski okrug",
        "RS-21": "Toplički okrug",
        "RS-22": "Pirotski okrug",
        "RS-24": "Pčinjski okrug",
        "RS-09": "Kolubarski okrug",
        "RS-10": "Podunavski okrug",
        "RS-19": "Rasinski okrug",
        "RS-00": "Beograd",
        "RS-12": "Šumadijski okrug",
        "RS-14": "Borski okrug",
        "RS-15": "Zaječarski okrug",
        "RS-23": "Jablanički okrug",
        "RS-08": "Mačvanski okrug",
        "RS-18": "Raški okrug",
        "RS-17": "Moravički okrug"
      },
      "hr": {
        "BA-BRC": "Brčko distrikt",
        "BA-BIH": "Federacija Bosne i Hercegovine",
        "BA-SRP": "Republika Srpska",
        "HR-01": "Zagrebačka županija",
        "HR-06": "Koprivničko-križevačka županija",
        "HR-17": "Splitsko-dalmatinska županija",
        "HR-21": "Grad Zagreb",
        "HR-04": "Karlovačka županija",
        "HR-19": "Dubrovačko-neretvanska županija",
        "HR-10": "Virovitičko-podravska županija",
        "HR-14": "Osječko-baranjska županija",
        "HR-16": "Vukovarsko-srijemska županija",
        "HR-02": "Krapinsko-zagorska županija",
        "HR-09": "Ličko-senjska županija",
        "HR-12": "Brodsko-posavska županija",
        "HR-18": "Istarska županija",
        "HR-03": "Sisačko-moslavačka županija",
        "HR-05": "Varaždinska županija",
        "HR-08": "Primorsko-goranska županija",
        "HR-15": "Šibensko-kninska županija",
        "HR-07": "Bjelovarsko-bilogorska županija",
        "HR-20": "Međimurska županija",
        "HR-11": "Požeško-slavonska županija",
        "HR-13": "Zadarska županija"
      },
      "bs": {
        "BA-BRC": "Brčko distrikt",
        "BA-BIH": "Federacija Bosne i Hercegovine",
        "BA-SRP": "Republika Srpska"
      },
      "bn": {
        "BD-B": "Chittagong",
        "BD-10": "Chittagong",
        "BD-11": "Cox's Bazar",
        "BD-56": "Rangamati",
        "BD-16": "Feni",
        "BD-31": "Lakshmipur",
        "BD-09": "Chandpur",
        "BD-08": "Comilla",
        "BD-29": "Khagrachhari",
        "BD-01": "Bandarban",
        "BD-04": "Brahmanbaria",
        "BD-47": "Noakhali",
        "BD-H": "Mymensingh",
        "BD-34": "Mymensingh",
        "BD-57": "Sherpur",
        "BD-21": "Jamalpur",
        "BD-41": "Netrakona",
        "BD-A": "Barisal",
        "BD-06": "Barisal",
        "BD-07": "Bhola",
        "BD-51": "Patuakhali",
        "BD-02": "Barguna",
        "BD-25": "Jhalakathi",
        "BD-50": "Pirojpur",
        "BD-C": "Dhaka",
        "BD-26": "Kishoreganj",
        "BD-42": "Narsingdi",
        "BD-13": "Dhaka",
        "BD-15": "Faridpur",
        "BD-17": "Gopalganj",
        "BD-53": "Rajbari",
        "BD-18": "Gazipur",
        "BD-33": "Manikganj",
        "BD-35": "Munshiganj",
        "BD-40": "Narayanganj",
        "BD-36": "Madaripur",
        "BD-62": "Shariatpur",
        "BD-63": "Tangail",
        "BD-D": "Khulna",
        "BD-30": "Kushtia",
        "BD-39": "Meherpur",
        "BD-12": "Chuadanga",
        "BD-22": "Jessore",
        "BD-58": "Satkhira",
        "BD-37": "Magura",
        "BD-05": "Bagerhat",
        "BD-23": "Jhenaidah",
        "BD-27": "Khulna",
        "BD-43": "Narail",
        "BD-E": "Rajshahi",
        "BD-49": "Pabna",
        "BD-54": "Rajshahi",
        "BD-24": "Joypurhat",
        "BD-59": "Sirajganj",
        "BD-03": "Bogra",
        "BD-44": "Natore",
        "BD-45": "Chapai Nawabganj",
        "BD-48": "Naogaon",
        "BD-F": "Rangpur",
        "BD-19": "Gaibandha",
        "BD-64": "Thakurgaon",
        "BD-28": "Kurigram",
        "BD-52": "Panchagarh",
        "BD-14": "Dinajpur",
        "BD-32": "Lalmonirhat",
        "BD-46": "Nilphamari",
        "BD-55": "Rangpur",
        "BD-G": "Sylhet",
        "BD-38": "Moulvibazar",
        "BD-60": "Sylhet",
        "BD-61": "Sunamganj",
        "BD-20": "Habiganj"
      },
      "fr": {
        "1000": "Abariringa",
        "1001": "les Îles Agalega",
        "1002": "Îles Åland",
        "1003": "les Îles Andaman",
        "1004": "Anegada",
        "1005": "Anjouan",
        "1006": "l'Île d'Annobón",
        "1007": "les Îles Antipodes",
        "1008": "l'Île de l'Ascension",
        "1009": "Îles Ashmore-et-Cartier",
        "1010": "les Îles Auckland",
        "1012": "Île d'Aurigny",
        "1013": "les Îles Australes",
        "1015": "l'Île d'Aves",
        "1016": "Babelthuap (île principale)",
        "1017": "l'Île Baker",
        "1018": "Banaba",
        "1019": "l'Île Redonda",
        "1020": "Bassas da India",
        "1021": "l'Île aux Ours",
        "1022": "Bequia (Île principale)",
        "1023": "l'Île de Bioko",
        "1025": "l'Archipel des Bismarck",
        "1026": "Bonaire",
        "1027": "Bougainville (Île principale)",
        "1028": "l'Île Brechou",
        "1030": "l'Île Burhou",
        "1031": "Cabinda",
        "1032": "Îles Caicos",
        "1033": "l'Île Campbell",
        "1034": "Cargados Carajos Shoals",
        "1035": "les Îles Carolines",
        "1036": "la partie ouest des Îles Carolines",
        "1039": "Carriacou",
        "1040": "l'Archipel des Chagos",
        "1041": "les Îles Chatham",
        "1042": "Chuuk",
        "1043": "l'Île Clipperton",
        "1044": "l'Île du Coco",
        "1045": "Région Continentale (Rio Muni) ",
        "1046": "Îles de la Mer de Corail",
        "1047": "les Îles Cosmoledo",
        "1048": "l'Archipel Crozet",
        "1049": "les Îles du Cygne",
        "1050": "la Désirade",
        "1051": "Diego Garcia",
        "1052": " l'Île Ducie",
        "1053": "Île de Pâques",
        "1054": "Éfaté ",
        "1056": "l'Île Enderbury",
        "1057": "les Îles Éparses de l'Océan Indien",
        "1058": "l'Île Europa",
        "1059": "les Îles Farquhar",
        "1060": "l'Île Fernando de Noronha",
        "1061": "France métropolitaine ",
        "1063": "Funafuti (atoll principal)",
        "1064": "Futuna",
        "1065": "les Îles Galápagos",
        "1066": "les Îles Gambier",
        "1067": " les Îles Gilbert",
        "1068": "les Îles Glorieuses",
        "1070": "Île de Gough",
        "1071": "Grand Caïman ",
        "1073": "la Grande Comore",
        "1074": " l'Île de la Grande Sercq",
        "1075": "les Îles Grenadines du Nord",
        "1076": "les Îles Grenadines du Sud",
        "1079": "Guadalcanal (île principale)",
        "1080": "l'Île Henderson",
        "1081": "l'Île Herm",
        "1082": "Herzégovine *",
        "1084": " Îles de Hoorn",
        "1085": "l'Île Howland",
        "1086": "Île Inaccessible",
        "1087": "Jaluit",
        "1088": "Île Jan Mayen",
        "1089": "l'Île Jarvis",
        "1090": "l'Île Jethou",
        "1091": "l'Atoll Johnston",
        "1092": "Jost Van Dyke",
        "1093": "l'Île Juan de Nova",
        "1094": " les Îles Juan Fernández",
        "1095": "la Région de Kaliningrad",
        "1096": " les Îles Kerguelen",
        "1097": "les Îles Kermadec",
        "1098": "le Récif Kingman",
        "1099": "Kiritimati",
        "1100": "Kosrae",
        "1101": "les Îles Laquedives",
        "1103": " une partie des Îles de la Ligne",
        "1104": "l'Île Lihou",
        "1106": "l'Île de la Petite Sercq",
        "1107": "l'Île Lord Howe",
        "1108": "les Îles Loyauté",
        "1110": "MacDonald, Îles  *",
        "1111": "l'Île Macquarie",
        "1112": "Mahé (île principale)",
        "1113": "Kwajalein",
        "1114": "la Malaisie péninsulaire",
        "1116": "l'Île de Malpelo",
        "1117": "les Îles Mariannes (sauf GUAM)",
        "1119": "Marie-Galante",
        "1120": "l'Île Marion",
        "1121": "les Îles Marquises",
        "1123": "les Îles Martim Vaz",
        "1127": "les Îles Midway",
        "1128": " l'Île Minicoy",
        "1129": "Miquelon *",
        "1130": "Mohéli",
        "1131": "le territoire autonome du Mont Athos",
        "1133": "partie de la Presqu'île de Musandam",
        "1135": "l'Île de la Navasse",
        "1137": "Niévès*",
        "1138": "les Îles Nicobar",
        "1139": " Île Nightingale",
        "1141": "les Îles Salomon du Nord",
        "1142": "l'Île Oeno",
        "1143": "l'exclave d'Oecussi",
        "1145": "l'Atoll Palmyra",
        "1147": "les Îles Penghu (Pescadores)",
        "1150": "les Îles Phoenix",
        "1151": "Pohnpei",
        "1152": "l'Île du Prince-Édouard",
        "1154": " Príncipe*",
        "1155": "Rarotonga",
        "1158": "Rio Muni",
        "1159": "l'Île Rodrigues",
        "1160": "l'Île de Rotuma",
        "1161": "Saba",
        "1162": "Sabah",
        "1165": "Saint Croix",
        "1166": "les Saintes",
        "1167": "Saint-Eustache ",
        "1168": "l'Île Sainte-Hélène",
        "1170": "Saint John",
        "1171": "Saint-Martin, partie nord de",
        "1174": "l'Île Saint-Paul",
        "1175": "Saint Thomas",
        "1176": " l'Île Saint-Vincent",
        "1178": "Saipan ( île principale)",
        "1179": " l'Île Sala y Gómez",
        "1181": "les Îles Salomon du Sud",
        "1182": "l'Île San Ambrosio",
        "1183": "les Îles San Andrés et Providencia",
        "1184": "Sandwich du Sud, Îles  *",
        "1185": " l'Île San Félix",
        "1186": "les Îles Santa Cruz",
        "1187": "Santo",
        "1188": "São Tiago",
        "1189": "São Vicente",
        "1190": "Sarawak",
        "1192": "Savai'i",
        "1196": "Archipel de la Société",
        "1198": "l'Île de Socotra",
        "1204": "Île Stoltenhoff",
        "1205": "l'Île Swains",
        "1207": "Tahiti",
        "1208": "Tarawa",
        "1209": "Tobago *",
        "1210": "Tongatapu (île principale)",
        "1211": "Tortola",
        "1212": "l'Île de Trindade",
        "1213": "l'Archipel Tristan da Cunha",
        "1214": "l'Île Tromelin",
        "1215": "les Îles Tuamotu",
        "1216": "Tutuila",
        "1217": "Upolu",
        "1218": "Uvea",
        "1219": "Vanua Levu",
        "1220": "Virgin Gorda",
        "1221": "Viti Levu",
        "1222": "l'Île Wake",
        "1223": "les Îles Wallis",
        "1224": "Yap",
        "1225": "les Îles Aldabra",
        "1226": "les Îles Amindivi",
        "1227": "les Îles Amirantes",
        "1228": "l'Île Amsterdam",
        "1230": "Majuro",
        "BE-WAL": "wallonne, Région",
        "BE-WLX": "Luxembourg",
        "BE-WNA": "Namur",
        "BE-WHT": "Hainaut",
        "BE-WBR": "Brabant wallon",
        "BE-WLG": "Liège",
        "BE-BRU": "Bruxelles-Capitale, Région de",
        "BF-05": "Centre-Nord",
        "BF-NAM": "Namentenga",
        "BF-BAM": "Bam",
        "BF-SMT": "Sanmatenga",
        "BF-01": "Boucle du Mouhoun",
        "BF-BAN": "Banwa",
        "BF-MOU": "Mouhoun",
        "BF-NAY": "Nayala",
        "BF-SOR": "Sourou",
        "BF-BAL": "Balé",
        "BF-KOS": "Kossi",
        "BF-10": "Nord",
        "BF-YAT": "Yatenga",
        "BF-LOR": "Loroum",
        "BF-PAS": "Passoré",
        "BF-ZON": "Zondoma",
        "BF-02": "Cascades",
        "BF-LER": "Léraba",
        "BF-COM": "Comoé",
        "BF-03": "Centre",
        "BF-KAD": "Kadiogo",
        "BF-04": "Centre-Est",
        "BF-BLG": "Boulgou",
        "BF-KOT": "Kouritenga",
        "BF-KOP": "Koulpélogo",
        "BF-06": "Centre-Ouest",
        "BF-SIS": "Sissili",
        "BF-ZIR": "Ziro",
        "BF-BLK": "Boulkiemdé",
        "BF-SNG": "Sanguié",
        "BF-07": "Centre-Sud",
        "BF-ZOU": "Zoundwéogo",
        "BF-NAO": "Nahouri",
        "BF-BAZ": "Bazèga",
        "BF-08": "Est",
        "BF-GOU": "Gourma",
        "BF-GNA": "Gnagna",
        "BF-KMD": "Komondjari",
        "BF-KMP": "Kompienga",
        "BF-TAP": "Tapoa",
        "BF-09": "Hauts-Bassins",
        "BF-HOU": "Houet",
        "BF-TUI": "Tuy",
        "BF-KEN": "Kénédougou",
        "BF-11": "Plateau-Central",
        "BF-GAN": "Ganzourgou",
        "BF-KOW": "Kourwéogo",
        "BF-OUB": "Oubritenga",
        "BF-12": "Sahel",
        "BF-OUD": "Oudalan",
        "BF-SOM": "Soum",
        "BF-YAG": "Yagha",
        "BF-SEN": "Séno",
        "BF-13": "Sud-Ouest",
        "BF-PON": "Poni",
        "BF-BGR": "Bougouriba",
        "BF-NOU": "Noumbiel",
        "BF-IOB": "Ioba",
        "BI-BM": "Bujumbura Mairie",
        "BI-KR": "Karuzi",
        "BI-MY": "Muyinga",
        "BI-KI": "Kirundo",
        "BI-BR": "Bururi",
        "BI-CI": "Cibitoke",
        "BI-GI": "Gitega",
        "BI-MA": "Makamba",
        "BI-MU": "Muramvya",
        "BI-NG": "Ngozi",
        "BI-RY": "Ruyigi",
        "BI-KY": "Kayanza",
        "BI-MW": "Mwaro",
        "BI-CA": "Cankuzo",
        "BI-BL": "Bujumbura Rural",
        "BI-RT": "Rutana",
        "BI-RM": "Rumonge",
        "BI-BB": "Bubanza",
        "BJ-AK": "Atacora",
        "BJ-CO": "Collines",
        "BJ-BO": "Borgou",
        "BJ-OU": "Ouémé",
        "BJ-LI": "Littoral",
        "BJ-AQ": "Atlantique",
        "BJ-MO": "Mono",
        "BJ-AL": "Alibori",
        "BJ-DO": "Donga",
        "BJ-KO": "Couffo",
        "BJ-PL": "Plateau",
        "BJ-ZO": "Zou",
        "CA-BC": "Colombie-Britannique",
        "CA-MB": "Manitoba",
        "CA-NB": "Nouveau-Brunswick",
        "CA-SK": "Saskatchewan",
        "CA-AB": "Alberta",
        "CA-ON": "Ontario",
        "CA-YT": "Yukon",
        "CA-NT": "Territoires du Nord-Ouest",
        "CA-NU": "Nunavut",
        "CA-NL": "Terre-Neuve-et-Labrador",
        "CA-PE": "Île-du-Prince-Édouard",
        "CA-NS": "Nouvelle-Écosse",
        "CA-QC": "Québec",
        "CD-EQ": "Équateur",
        "CD-BC": "Kongo Central",
        "CD-SK": "Sud-Kivu",
        "CD-NK": "Nord-Kivu",
        "CD-KE": "Kasaï Oriental",
        "CD-KN": "Kinshasa",
        "CD-BU": "Bas-Uélé",
        "CD-HK": "Haut-Katanga",
        "CD-HL": "Haut-Lomami",
        "CD-HU": "Haut-Uélé",
        "CD-IT": "Ituri",
        "CD-KS": "Kasaï",
        "CD-KC": "Kasaï Central",
        "CD-KG": "Kwango",
        "CD-KL": "Kwilu",
        "CD-LO": "Lomami",
        "CD-LU": "Lualaba",
        "CD-MN": "Mai-Ndombe",
        "CD-MO": "Mongala",
        "CD-NU": "Nord-Ubangi",
        "CD-SA": "Sankuru",
        "CD-SU": "Sud-Ubangi",
        "CD-TA": "Tanganyika",
        "CD-TO": "Tshopo",
        "CD-TU": "Tshuapa",
        "CD-MA": "Maniema",
        "CF-HM": "Haut-Mbomou",
        "CF-HS": "Haute-Sangha / Mambéré-Kadéï",
        "CF-LB": "Lobaye",
        "CF-AC": "Ouham",
        "CF-KB": "Gribingui",
        "CF-BB": "Bamingui-Bangoran",
        "CF-MP": "Ombella-Mpoko",
        "CF-HK": "Haute-Kotto",
        "CF-KG": "Kémo-Gribingui",
        "CF-MB": "Mbomou",
        "CF-NM": "Nana-Mambéré",
        "CF-SE": "Sangha",
        "CF-VK": "Vakaga",
        "CF-BGF": "Bangui",
        "CF-OP": "Ouham-Pendé",
        "CF-UK": "Ouaka",
        "CF-BK": "Basse-Kotto",
        "CG-9": "Niari",
        "CG-15": "Cuvette-Ouest",
        "CG-5": "Kouilou",
        "CG-11": "Bouenza",
        "CG-BZV": "Brazzaville",
        "CG-16": "Pointe-Noire",
        "CG-12": "Pool",
        "CG-13": "Sangha",
        "CG-7": "Likouala",
        "CG-14": "Plateaux",
        "CG-2": "Lékoumou",
        "CG-8": "Cuvette",
        "CH-JU": "Jura",
        "CH-BE": "Berne",
        "CH-FR": "Fribourg",
        "CH-NE": "Neuchâtel",
        "CH-VD": "Vaud",
        "CH-GE": "Genève",
        "CH-GR": "Grisons",
        "CH-VS": "Valais",
        "CI-AB": "Abidjan",
        "CI-YM": "Yamoussoukro",
        "CI-BS": "Bas-Sassandra",
        "CI-CM": "Comoé",
        "CI-DN": "Denguélé",
        "CI-GD": "Gôh-Djiboua",
        "CI-LC": "Lacs",
        "CI-LG": "Lagunes",
        "CI-MG": "Montagnes",
        "CI-SM": "Sassandra-Marahoué",
        "CI-SV": "Savanes",
        "CI-VB": "Vallée du Bandama",
        "CI-WR": "Woroba",
        "CI-ZZ": "Zanzan",
        "CM-NW": "Nord-Ouest",
        "CM-OU": "Ouest",
        "CM-AD": "Adamaoua",
        "CM-LT": "Littoral",
        "CM-NO": "Nord",
        "CM-CE": "Centre",
        "CM-EN": "Extrême-Nord",
        "CM-ES": "Est",
        "CM-SU": "Sud",
        "CM-SW": "Sud-Ouest",
        "DJ-OB": "Obock",
        "DJ-AR": "Arta",
        "DJ-DI": "Dikhil",
        "DJ-DJ": "Djibouti",
        "DJ-AS": "Ali Sabieh",
        "DJ-TA": "Tadjourah",
        "FR-CP": "Clipperton",
        "FR-MF": "Saint-Martin ",
        "FR-NC": "Nouvelle-Calédonie ",
        "FR-PM": "Saint-Pierre-et-Miquelon ",
        "FR-TF": "Terres australes françaises ",
        "FR-WF": "Wallis-et-Futuna ",
        "FR-ARA": "Auvergne-Rhône-Alpes",
        "FR-03": "Allier",
        "FR-26": "Drôme",
        "FR-15": "Cantal",
        "FR-07": "Ardèche",
        "FR-01": "Ain",
        "FR-38": "Isère",
        "FR-43": "Haute-Loire",
        "FR-74": "Haute-Savoie",
        "FR-69": "Rhône",
        "FR-42": "Loire",
        "FR-63": "Puy-de-Dôme",
        "FR-73": "Savoie",
        "FR-BFC": "Bourgogne-Franche-Comté",
        "FR-70": "Haute-Saône",
        "FR-71": "Saône-et-Loire",
        "FR-21": "Côte-d'Or",
        "FR-89": "Yonne",
        "FR-90": "Territoire de Belfort",
        "FR-39": "Jura",
        "FR-58": "Nièvre",
        "FR-25": "Doubs",
        "FR-BRE": "Bretagne",
        "FR-22": "Côtes-d'Armor",
        "FR-29": "Finistère",
        "FR-56": "Morbihan",
        "FR-35": "Ille-et-Vilaine",
        "FR-CVL": "Centre-Val de Loire",
        "FR-41": "Loir-et-Cher",
        "FR-45": "Loiret",
        "FR-37": "Indre-et-Loire",
        "FR-36": "Indre",
        "FR-28": "Eure-et-Loir",
        "FR-18": "Cher",
        "FR-COR": "Corse",
        "FR-2A": "Corse-du-Sud",
        "FR-2B": "Haute-Corse",
        "FR-GES": "Grand-Est",
        "FR-51": "Marne",
        "FR-55": "Meuse",
        "FR-57": "Moselle",
        "FR-67": "Bas-Rhin",
        "FR-68": "Haut-Rhin",
        "FR-10": "Aube",
        "FR-52": "Haute-Marne",
        "FR-54": "Meurthe-et-Moselle",
        "FR-08": "Ardennes",
        "FR-88": "Vosges",
        "FR-HDF": "Hauts-de-France",
        "FR-62": "Pas-de-Calais",
        "FR-02": "Aisne",
        "FR-80": "Somme",
        "FR-59": "Nord",
        "FR-60": "Oise",
        "FR-IDF": "Île-de-France",
        "FR-77": "Seine-et-Marne",
        "FR-78": "Yvelines",
        "FR-95": "Val-d'Oise",
        "FR-92": "Hauts-de-Seine",
        "FR-93": "Seine-Saint-Denis",
        "FR-75": "Paris",
        "FR-91": "Essonne",
        "FR-94": "Val-de-Marne",
        "FR-NOR": "Normandie",
        "FR-14": "Calvados",
        "FR-76": "Seine-Maritime",
        "FR-27": "Eure",
        "FR-50": "Manche",
        "FR-61": "Orne",
        "FR-NAQ": "Nouvelle-Aquitaine",
        "FR-16": "Charente",
        "FR-24": "Dordogne",
        "FR-79": "Deux-Sèvres",
        "FR-19": "Corrèze",
        "FR-47": "Lot-et-Garonne",
        "FR-17": "Charente-Maritime",
        "FR-86": "Vienne",
        "FR-33": "Gironde",
        "FR-64": "Pyrénées-Atlantiques",
        "FR-23": "Creuse",
        "FR-40": "Landes",
        "FR-87": "Haute-Vienne",
        "FR-OCC": "Occitanie",
        "FR-30": "Gard",
        "FR-11": "Aude",
        "FR-48": "Lozère",
        "FR-09": "Ariège",
        "FR-65": "Hautes-Pyrénées",
        "FR-34": "Hérault",
        "FR-46": "Lot",
        "FR-82": "Tarn-et-Garonne",
        "FR-66": "Pyrénées-Orientales",
        "FR-12": "Aveyron",
        "FR-31": "Haute-Garonne",
        "FR-32": "Gers",
        "FR-81": "Tarn",
        "FR-PDL": "Pays-de-la-Loire",
        "FR-53": "Mayenne",
        "FR-49": "Maine-et-Loire",
        "FR-72": "Sarthe",
        "FR-44": "Loire-Atlantique",
        "FR-85": "Vendée",
        "FR-PAC": "Provence-Alpes-Côte-d’Azur",
        "FR-04": "Alpes-de-Haute-Provence",
        "FR-05": "Hautes-Alpes",
        "FR-83": "Var",
        "FR-06": "Alpes-Maritimes",
        "FR-84": "Vaucluse",
        "FR-13": "Bouches-du-Rhône",
        "FR-GUA": "Guadeloupe",
        "FR-GP": "Guadeloupe ",
        "FR-LRE": "La Réunion",
        "FR-RE": "La Réunion ",
        "FR-MAY": "Mayotte",
        "FR-YT": "Mayotte ",
        "FR-GF": "Guyane (française) ",
        "FR-MQ": "Martinique ",
        "FR-BL": "Saint-Barthélemy ",
        "FR-PF": "Polynésie française ",
        "GA-1": "Estuaire",
        "GA-6": "Ogooué-Ivindo",
        "GA-7": "Ogooué-Lolo",
        "GA-8": "Ogooué-Maritime",
        "GA-2": "Haut-Ogooué",
        "GA-4": "Ngounié",
        "GA-9": "Woleu-Ntem",
        "GA-3": "Moyen-Ogooué",
        "GA-5": "Nyanga",
        "GN-M": "Mamou",
        "GN-DL": "Dalaba",
        "GN-PI": "Pita",
        "GN-MM": "Mamou",
        "GN-C": "Conakry",
        "GN-K": "Kankan",
        "GN-SI": "Siguiri",
        "GN-MD": "Mandiana",
        "GN-KA": "Kankan",
        "GN-KE": "Kérouané",
        "GN-KO": "Kouroussa",
        "GN-L": "Labé",
        "GN-KB": "Koubia",
        "GN-ML": "Mali",
        "GN-TO": "Tougué",
        "GN-LE": "Lélouma",
        "GN-LA": "Labé",
        "GN-B": "Boké",
        "GN-BK": "Boké",
        "GN-BF": "Boffa",
        "GN-GA": "Gaoual",
        "GN-FR": "Fria",
        "GN-KN": "Koundara",
        "GN-F": "Faranah",
        "GN-FA": "Faranah",
        "GN-DI": "Dinguiraye",
        "GN-DB": "Dabola",
        "GN-KS": "Kissidougou",
        "GN-D": "Kindia",
        "GN-KD": "Kindia",
        "GN-DU": "Dubréka",
        "GN-CO": "Coyah",
        "GN-FO": "Forécariah",
        "GN-TE": "Télimélé",
        "GN-N": "Nzérékoré",
        "GN-MC": "Macenta",
        "GN-LO": "Lola",
        "GN-YO": "Yomou",
        "GN-BE": "Beyla",
        "GN-GU": "Guékédou",
        "GN-NZ": "Nzérékoré",
        "GQ-I": "Région Insulaire",
        "GQ-AN": "Annobon",
        "GQ-BN": "Bioko Nord",
        "GQ-BS": "Bioko Sud",
        "GQ-C": "Région Continentale",
        "GQ-KN": "Kié-Ntem",
        "GQ-CS": "Centro Sud",
        "GQ-LI": "Littoral",
        "GQ-WN": "Wele-Nzas",
        "HT-NE": "Nord-Est",
        "HT-OU": "Ouest",
        "HT-GA": "Grande’Anse",
        "HT-ND": "Nord",
        "HT-SD": "Sud",
        "HT-SE": "Sud-Est",
        "HT-NI": "Nippes",
        "HT-AR": "Artibonite",
        "HT-CE": "Centre",
        "HT-NO": "Nord-Ouest",
        "IT-23": "Val d'Aoste",
        "IT-AO": "Aoste",
        "KM-M": "Mohéli",
        "KM-A": "Anjouan",
        "KM-G": "Grande Comore",
        "LU-CA": "Capellen",
        "LU-CL": "Clervaux",
        "LU-DI": "Diekirch",
        "LU-EC": "Echternach",
        "LU-ES": "Esch-sur-Alzette",
        "LU-GR": "Grevenmacher",
        "LU-LU": "Luxembourg",
        "LU-ME": "Mersch",
        "LU-RD": "Redange",
        "LU-RM": "Remich",
        "LU-VD": "Vianden",
        "LU-WI": "Wiltz",
        "MC-CL": "La Colle",
        "MC-GA": "La Gare",
        "MC-JE": "Jardin Exotique",
        "MC-MA": "Malbousquet",
        "MC-SP": "Spélugues",
        "MC-MC": "Monte-Carlo",
        "MC-MG": "Moneghetti",
        "MC-LA": "Larvotto",
        "MC-SD": "Sainte-Dévote",
        "MC-SO": "La Source",
        "MC-CO": "La Condamine",
        "MC-FO": "Fontvieille",
        "MC-MU": "Moulins",
        "MC-MO": "Monaco-Ville",
        "MC-PH": "Port-Hercule",
        "MC-SR": "Saint-Roman",
        "MC-VR": "Vallon de la Rousse",
        "ML-BKO": "Bamako",
        "ML-6": "Tombouctou",
        "ML-2": "Koulikoro",
        "ML-5": "Mopti",
        "ML-3": "Sikasso",
        "ML-8": "Kidal",
        "ML-1": "Kayes",
        "ML-7": "Gao",
        "ML-9": "Ménaka",
        "ML-10": "Taoudénit",
        "ML-4": "Ségou",
        "MR-13": "Nouakchott Ouest",
        "MR-14": "Nouakchott Nord",
        "MR-15": "Nouakchott Sud",
        "NE-5": "Tahoua",
        "NE-2": "Diffa",
        "NE-7": "Zinder",
        "NE-3": "Dosso",
        "NE-4": "Maradi",
        "NE-8": "Niamey",
        "NE-1": "Agadez",
        "NE-6": "Tillabéri",
        "RW-01": "Ville de Kigali",
        "RW-02": "Est",
        "RW-04": "Ouest",
        "RW-05": "Sud",
        "RW-03": "Nord",
        "SC-08": "Beau Vallon",
        "SC-19": "Plaisance",
        "SC-12": "Glacis",
        "SC-13": "Grand'Anse Mahé",
        "SC-04": "Au Cap",
        "SC-17": "Mont Buxton",
        "SC-15": "La Digue",
        "SC-20": "Pointe La Rue",
        "SC-21": "Port Glaud",
        "SC-23": "Takamaka",
        "SC-05": "Anse Royale",
        "SC-07": "Baie Sainte-Anne",
        "SC-10": "Bel Ombre",
        "SC-11": "Cascade",
        "SC-16": "La Rivière Anglaise",
        "SC-18": "Mont Fleuri",
        "SC-25": "Roche Caïman",
        "SC-01": "Anse aux Pins",
        "SC-02": "Anse Boileau",
        "SC-09": "Bel Air",
        "SC-14": "Grand'Anse Praslin",
        "SC-22": "Saint-Louis",
        "SC-03": "Anse Étoile",
        "SC-06": "Baie Lazare",
        "SC-24": "Les Mamelles",
        "SN-SE": "Sédhiou",
        "SN-DK": "Dakar",
        "SN-KD": "Kolda",
        "SN-SL": "Saint-Louis",
        "SN-FK": "Fatick",
        "SN-KA": "Kaffrine",
        "SN-ZG": "Ziguinchor",
        "SN-KL": "Kaolack",
        "SN-KE": "Kédougou",
        "SN-LG": "Louga",
        "SN-DB": "Diourbel",
        "SN-MT": "Matam",
        "SN-TC": "Tambacounda",
        "SN-TH": "Thiès",
        "TD-LR": "Logone-Oriental",
        "TD-SI": "Sila",
        "TD-BO": "Borkou",
        "TD-GR": "Guéra",
        "TD-TA": "Tandjilé",
        "TD-TI": "Tibesti",
        "TD-BG": "Bahr el Ghazal",
        "TD-CB": "Chari-Baguirmi",
        "TD-LO": "Logone-Occidental",
        "TD-MA": "Mandoul",
        "TD-BA": "Batha",
        "TD-HL": "Hadjer Lamis",
        "TD-MO": "Mayo-Kebbi-Ouest",
        "TD-EE": "Ennedi-Est",
        "TD-EO": "Ennedi-Ouest",
        "TD-KA": "Kanem",
        "TD-MC": "Moyen-Chari",
        "TD-ND": "Ville de Ndjamena",
        "TD-OD": "Ouaddaï",
        "TD-SA": "Salamat",
        "TD-LC": "Lac",
        "TD-ME": "Mayo-Kebbi-Est",
        "TD-WF": "Wadi Fira",
        "TG-C": "Centrale",
        "TG-P": "Plateaux",
        "TG-S": "Savanes",
        "TG-M": "Maritime (Région)",
        "TG-K": "Kara",
        "VU-SEE": "Shéfa",
        "VU-MAP": "Malampa",
        "VU-PAM": "Pénama",
        "VU-SAM": "Sanma",
        "VU-TOB": "Torba",
        "VU-TAE": "Taféa",
        "WF-AL": "Alo",
        "WF-SG": "Sigave",
        "WF-UV": "Uvea"
      },
      "nl": {
        "BE-BRU": "Brussels Hoofdstedelijk Gewest",
        "BE-VLG": "Vlaams Gewest",
        "BE-VBR": "Vlaams-Brabant",
        "BE-VWV": "West-Vlaanderen",
        "BE-VAN": "Antwerpen",
        "BE-VLI": "Limburg",
        "BE-VOV": "Oost-Vlaanderen",
        "BQ-BO": "Bonaire",
        "BQ-SA": "Saba",
        "BQ-SE": "Sint Eustatius",
        "NL-NH": "Noord-Holland",
        "NL-ZH": "Zuid-Holland",
        "NL-CW": "Curaçao ",
        "NL-GR": "Groningen",
        "NL-LI": "Limburg",
        "NL-OV": "Overijssel",
        "NL-AW": "Aruba ",
        "NL-DR": "Drenthe",
        "NL-BQ3": "Sint Eustatius ",
        "NL-GE": "Gelderland",
        "NL-UT": "Utrecht",
        "NL-FL": "Flevoland",
        "NL-BQ1": "Bonaire ",
        "NL-ZE": "Zeeland",
        "NL-SX": "Sint Maarten ",
        "NL-BQ2": "Saba ",
        "NL-NB": "Noord-Brabant",
        "SR-WA": "Wanica",
        "SR-CM": "Commewijne",
        "SR-MA": "Marowijne",
        "SR-SA": "Saramacca",
        "SR-CR": "Coronie",
        "SR-PM": "Paramaribo",
        "SR-BR": "Brokopondo",
        "SR-NI": "Nickerie",
        "SR-PR": "Para",
        "SR-SI": "Sipaliwini"
      },
      "bg": {
        "BG-04": "Veliko Tarnovo",
        "BG-09": "Kardzhali",
        "BG-15": "Pleven",
        "BG-16": "Plovdiv",
        "BG-28": "Yambol",
        "BG-13": "Pazardzhik",
        "BG-18": "Ruse",
        "BG-21": "Smolyan",
        "BG-22": "Sofia (stolitsa)",
        "BG-27": "Shumen",
        "BG-02": "Burgas",
        "BG-14": "Pernik",
        "BG-19": "Silistra",
        "BG-25": "Targovishte",
        "BG-03": "Varna",
        "BG-24": "Stara Zagora",
        "BG-01": "Blagoevgrad",
        "BG-10": "Kyustendil",
        "BG-12": "Montana",
        "BG-20": "Sliven",
        "BG-23": "Sofia",
        "BG-11": "Lovech",
        "BG-17": "Razgrad",
        "BG-05": "Vidin",
        "BG-06": "Vratsa",
        "BG-07": "Gabrovo",
        "BG-08": "Dobrich",
        "BG-26": "Haskovo"
      },
      "rn": {
        "BI-BM": "Bujumbura Mairie",
        "BI-KR": "Karuzi",
        "BI-MY": "Muyinga",
        "BI-KI": "Kirundo",
        "BI-BR": "Bururi",
        "BI-CI": "Cibitoke",
        "BI-GI": "Gitega",
        "BI-MA": "Makamba",
        "BI-MU": "Muramvya",
        "BI-NG": "Ngozi",
        "BI-RY": "Ruyigi",
        "BI-KY": "Kayanza",
        "BI-MW": "Mwaro",
        "BI-CA": "Cankuzo",
        "BI-BL": "Bujumbura Rural",
        "BI-RT": "Rutana",
        "BI-RM": "Rumonge",
        "BI-BB": "Bubanza"
      },
      "ms": {
        "BN-BM": "Brunei-Muara",
        "BN-BE": "Belait",
        "BN-TE": "Temburong",
        "BN-TU": "Tutong",
        "MY-13": "Sarawak",
        "MY-05": "Negeri Sembilan",
        "MY-09": "Perlis",
        "MY-07": "Pulau Pinang",
        "MY-01": "Johor",
        "MY-08": "Perak",
        "MY-10": "Selangor",
        "MY-14": "Wilayah Persekutuan Kuala Lumpur",
        "MY-16": "Wilayah Persekutuan Putrajaya",
        "MY-03": "Kelantan",
        "MY-04": "Melaka",
        "MY-02": "Kedah",
        "MY-11": "Terengganu",
        "MY-06": "Pahang",
        "MY-15": "Wilayah Persekutuan Labuan",
        "MY-12": "Sabah"
      },
      "pap": {
        "BQ-BO": " Boneiru",
        "BQ-SA": "Saba",
        "BQ-SE": "Sint Eustatius"
      },
      "dz": {
        "BT-42": "Monggar",
        "BT-45": "Samdrup Jongkhar",
        "BT-12": "Chhukha",
        "BT-23": "Punakha",
        "BT-24": "Wangdue Phodrang",
        "BT-21": "Tsirang",
        "BT-33": "Bumthang",
        "BT-34": "Zhemgang",
        "BT-31": "Sarpang",
        "BT-14": "Samtse",
        "BT-15": "Thimphu",
        "BT-44": "Lhuentse",
        "BT-TY": "Trashi Yangtse",
        "BT-13": "Haa",
        "BT-41": "Trashigang",
        "BT-GA": "Gasa",
        "BT-22": "Dagana",
        "BT-32": "Trongsa",
        "BT-43": "Pemagatshel",
        "BT-11": "Paro"
      },
      "be": {
        "BY-VI": "Vitsyebskaya voblasts'",
        "BY-BR": "Brestskaya voblasts'",
        "BY-HM": "Horad Minsk",
        "BY-HO": "Homyel'skaya voblasts'",
        "BY-HR": "Hrodzienskaja voblasć",
        "BY-MI": "Minskaya voblasts'",
        "BY-MA": "Mahilyowskaya voblasts'"
      },
      "ru": {
        "BY-VI": "Vitebskaya oblast'",
        "BY-BR": "Brestskaja oblast'",
        "BY-HM": "Gorod Minsk",
        "BY-HO": "Gomel'skaya oblast'",
        "BY-HR": "Grodnenskaya oblast'",
        "BY-MI": "Minskaja oblast'",
        "BY-MA": "Mogilevskaya oblast'",
        "KG-Y": "Issyk-Kul'skaja oblast'",
        "KG-O": "Oshskaya oblast'",
        "KG-T": "Talasskaya oblast'",
        "KG-B": "Batkenskaja oblast'",
        "KG-GO": "Gorod Oš",
        "KG-GB": "Gorod Bishkek",
        "KG-J": "Dzhalal-Abadskaya oblast'",
        "KG-C": "Chuyskaya oblast'",
        "KG-N": "Narynskaya oblast'",
        "KZ-ALM": "Almatinskaya oblast'",
        "KZ-SEV": "Severo-Kazahstanskaja oblast'",
        "KZ-YUZ": "Yuzhno-Kazakhstankaya oblast'",
        "KZ-ZAP": "Zapadno-Kazakhstanskaya oblast'",
        "KZ-ALA": "Almaty",
        "KZ-KUS": "Kostanajskaja oblast'",
        "KZ-KAR": "Karagandinskaya oblast'",
        "KZ-PAV": "Pavlodarskaya oblast'",
        "KZ-AST": "Astana",
        "KZ-AKT": "Aktyubinskaya oblast'",
        "KZ-ATY": "Atyrauskaya oblast'",
        "KZ-MAN": "Mangystauskaja oblast'",
        "KZ-ZHA": "Zhambylskaya oblast'",
        "KZ-BAY": "Baykonyr",
        "KZ-AKM": "Akmolinskaya oblast'",
        "KZ-KZY": "Kyzylordinskaya oblast'",
        "KZ-VOS": "Vostochno-Kazakhstanskaya oblast'",
        "RU-CU": "Chuvashskaya Respublika",
        "RU-DA": "Dagestan, Respublika",
        "RU-IRK": "Irkutskaja oblast'",
        "RU-KAM": "Kamčatskij kraj",
        "RU-KHA": "Habarovskij kraj",
        "RU-MAG": "Magadanskaja oblast'",
        "RU-NGR": "Novgorodskaya oblast'",
        "RU-NIZ": "Nizhegorodskaya oblast'",
        "RU-ORE": "Orenburgskaya oblast'",
        "RU-ORL": "Orlovskaya oblast'",
        "RU-PSK": "Pskovskaya oblast'",
        "RU-SVE": "Sverdlovskaja oblast'",
        "RU-TYU": "Tjumenskaja oblast'",
        "RU-ALT": "Altayskiy kray",
        "RU-IVA": "Ivanovskaja oblast'",
        "RU-KDA": "Krasnodarskij kraj",
        "RU-KEM": "Kemerovskaja oblast'",
        "RU-KHM": "Hanty-Mansijskij avtonomnyj okrug",
        "RU-KK": "Khakasiya, Respublika",
        "RU-LIP": "Lipetskaya oblast'",
        "RU-ME": "Marij Èl, Respublika",
        "RU-MO": "Mordovija, Respublika",
        "RU-PNZ": "Penzenskaja oblast'",
        "RU-SA": "Sakha, Respublika",
        "RU-SMO": "Smolenskaya oblast'",
        "RU-VLG": "Vologodskaja oblast'",
        "RU-AST": "Astrahanskaja oblast'",
        "RU-BU": "Burjatija, Respublika",
        "RU-CHU": "Čukotskij avtonomnyj okrug",
        "RU-STA": "Stavropol'skij kraj",
        "RU-YAN": "Yamalo-Nenetskiy avtonomnyy okrug",
        "RU-YEV": "Evrejskaja avtonomnaja oblast'",
        "RU-BEL": "Belgorodskaja oblast'",
        "RU-IN": "Ingushetiya, Respublika",
        "RU-KR": "Karelija, Respublika",
        "RU-KRS": "Kurskaya oblast'",
        "RU-MOW": "Moskva",
        "RU-RYA": "Ryazanskaya oblast'",
        "RU-SAK": "Sakhalinskaya oblast'",
        "RU-TA": "Tatarstan, Respublika",
        "RU-TOM": "Tomskaja oblast'",
        "RU-TUL": "Tul'skaya oblast'",
        "RU-ULY": "Ul'yanovskaya oblast'",
        "RU-VLA": "Vladimirskaja oblast'",
        "RU-VOR": "Voronezhskaya oblast'",
        "RU-BRY": "Brjanskaja oblast'",
        "RU-CHE": "Chelyabinskaya oblast'",
        "RU-KGN": "Kurganskaya oblast'",
        "RU-KIR": "Kirovskaya oblast'",
        "RU-MOS": "Moskovskaja oblast'",
        "RU-SAM": "Samarskaja oblast'",
        "RU-TAM": "Tambovskaja oblast'",
        "RU-YAR": "Yaroslavskaya oblast'",
        "RU-ZAB": "Zabajkal'skij kraj",
        "RU-AMU": "Amurskaja oblast'",
        "RU-KL": "Kalmykiya, Respublika",
        "RU-KOS": "Kostromskaja oblast'",
        "RU-MUR": "Murmanskaja oblast'",
        "RU-OMS": "Omskaja oblast'",
        "RU-TY": "Tyva, Respublika",
        "RU-AD": "Adygeja, Respublika",
        "RU-BA": "Bashkortostan, Respublika",
        "RU-KC": "Karačaevo-Čerkesskaja Respublika",
        "RU-KLU": "Kaluzhskaya oblast'",
        "RU-LEN": "Leningradskaya oblast'",
        "RU-NVS": "Novosibirskaja oblast'",
        "RU-PER": "Permskij kraj",
        "RU-TVE": "Tverskaya oblast'",
        "RU-VGG": "Volgogradskaya oblast'",
        "RU-AL": "Altay, Respublika",
        "RU-ARK": "Arhangel'skaja oblast'",
        "RU-CE": "Chechenskaya Respublika",
        "RU-KB": "Kabardino-Balkarskaja Respublika",
        "RU-KGD": "Kaliningradskaja oblast'",
        "RU-KO": "Komi, Respublika",
        "RU-KYA": "Krasnoyarskiy kray",
        "RU-NEN": "Neneckij avtonomnyj okrug",
        "RU-PRI": "Primorskiy kray",
        "RU-ROS": "Rostovskaya oblast'",
        "RU-SAR": "Saratovskaja oblast'",
        "RU-SE": "Severnaja Osetija, Respublika",
        "RU-SPE": "Sankt-Peterburg",
        "RU-UD": "Udmurtskaja Respublika"
      },
      "sg": {
        "CF-HM": "Tö-Mbömü",
        "CF-HS": "Tö-Sangä / Mbaere-Kadeï",
        "CF-LB": "Lobâye",
        "CF-AC": "Wâmo",
        "CF-KB": "Gïrïbïngï",
        "CF-BB": "Bamïngï-Bangoran",
        "CF-MP": "Ömbëlä-Pökö",
        "CF-HK": "Tö-Kötö",
        "CF-KG": "Kemö-Gïrïbïngï",
        "CF-MB": "Mbömü",
        "CF-NM": "Nanä-Mbaere",
        "CF-SE": "Sangä",
        "CF-VK": "Vakaga",
        "CF-BGF": "Bangî",
        "CF-OP": "Wâmo-Pendë",
        "CF-UK": "Wäkä",
        "CF-BK": "Do-Kötö"
      },
      "it": {
        "CH-TI": "Ticino",
        "CH-GR": "Grigioni",
        "IT-23": "Valle d'Aosta",
        "IT-AO": "Aosta",
        "IT-25": "Lombardia",
        "IT-CR": "Cremona",
        "IT-LC": "Lecco",
        "IT-BS": "Brescia",
        "IT-SO": "Sondrio",
        "IT-MN": "Mantova",
        "IT-BG": "Bergamo",
        "IT-VA": "Varese",
        "IT-CO": "Como",
        "IT-LO": "Lodi",
        "IT-MI": "Milano",
        "IT-PV": "Pavia",
        "IT-MB": "Monza e Brianza",
        "IT-36": "Friuli-Venezia Giulia",
        "IT-TS": "Trieste",
        "IT-UD": "Udine",
        "IT-PN": "Pordenone",
        "IT-GO": "Gorizia",
        "IT-34": "Veneto",
        "IT-PD": "Padova",
        "IT-RO": "Rovigo",
        "IT-BL": "Belluno",
        "IT-TV": "Treviso",
        "IT-VR": "Verona",
        "IT-VE": "Venezia",
        "IT-VI": "Vicenza",
        "IT-42": "Liguria",
        "IT-SP": "La Spezia",
        "IT-SV": "Savona",
        "IT-GE": "Genova",
        "IT-IM": "Imperia",
        "IT-45": "Emilia-Romagna",
        "IT-FC": "Forlì-Cesena",
        "IT-FE": "Ferrara",
        "IT-PC": "Piacenza",
        "IT-RA": "Ravenna",
        "IT-BO": "Bologna",
        "IT-MO": "Modena",
        "IT-RN": "Rimini",
        "IT-PR": "Parma",
        "IT-RE": "Reggio Emilia",
        "IT-77": "Basilicata",
        "IT-MT": "Matera",
        "IT-PZ": "Potenza",
        "IT-82": "Sicilia",
        "IT-AG": "Agrigento",
        "IT-PA": "Palermo",
        "IT-TP": "Trapani",
        "IT-CL": "Caltanissetta",
        "IT-RG": "Ragusa",
        "IT-SR": "Siracusa",
        "IT-EN": "Enna",
        "IT-ME": "Messina",
        "IT-CT": "Catania",
        "IT-88": "Sardegna",
        "IT-CA": "Cagliari",
        "IT-CI": "Carbonia-Iglesias",
        "IT-OG": "Ogliastra",
        "IT-OR": "Oristano",
        "IT-OT": "Olbia-Tempio",
        "IT-VS": "Medio Campidano",
        "IT-SS": "Sassari",
        "IT-NU": "Nuoro",
        "IT-55": "Umbria",
        "IT-PG": "Perugia",
        "IT-TR": "Terni",
        "IT-72": "Campania",
        "IT-NA": "Napoli",
        "IT-AV": "Avellino",
        "IT-BN": "Benevento",
        "IT-SA": "Salerno",
        "IT-CE": "Caserta",
        "IT-32": "Trentino-Alto Adige",
        "IT-TN": "Trento",
        "IT-BZ": "Bolzano",
        "IT-62": "Lazio",
        "IT-FR": "Frosinone",
        "IT-RI": "Rieti",
        "IT-RM": "Roma",
        "IT-VT": "Viterbo",
        "IT-LT": "Latina",
        "IT-52": "Toscana",
        "IT-PO": "Prato",
        "IT-PT": "Pistoia",
        "IT-LU": "Lucca",
        "IT-SI": "Siena",
        "IT-MS": "Massa-Carrara",
        "IT-PI": "Pisa",
        "IT-FI": "Firenze",
        "IT-AR": "Arezzo",
        "IT-LI": "Livorno",
        "IT-GR": "Grosseto",
        "IT-75": "Puglia",
        "IT-BT": "Barletta-Andria-Trani",
        "IT-FG": "Foggia",
        "IT-LE": "Lecce",
        "IT-BA": "Bari",
        "IT-BR": "Brindisi",
        "IT-TA": "Taranto",
        "IT-78": "Calabria",
        "IT-RC": "Reggio Calabria",
        "IT-VV": "Vibo Valentia",
        "IT-CS": "Cosenza",
        "IT-CZ": "Catanzaro",
        "IT-KR": "Crotone",
        "IT-21": "Piemonte",
        "IT-VC": "Vercelli",
        "IT-BI": "Biella",
        "IT-NO": "Novara",
        "IT-VB": "Verbano-Cusio-Ossola",
        "IT-TO": "Torino",
        "IT-AL": "Alessandria",
        "IT-AT": "Asti",
        "IT-CN": "Cuneo",
        "IT-57": "Marche",
        "IT-FM": "Fermo",
        "IT-AP": "Ascoli Piceno",
        "IT-AN": "Ancona",
        "IT-MC": "Macerata",
        "IT-PU": "Pesaro e Urbino",
        "IT-65": "Abruzzo",
        "IT-PE": "Pescara",
        "IT-AQ": "L'Aquila",
        "IT-CH": "Chieti",
        "IT-TE": "Teramo",
        "IT-67": "Molise",
        "IT-IS": "Isernia",
        "IT-CB": "Campobasso",
        "SM-03": "Domagnano",
        "SM-06": "Borgo Maggiore",
        "SM-08": "Montegiardino",
        "SM-02": "Chiesanuova",
        "SM-04": "Faetano",
        "SM-09": "Serravalle",
        "SM-07": "San Marino",
        "SM-01": "Acquaviva",
        "SM-05": "Fiorentino"
      },
      "rm": {
        "CH-GR": "Grischun"
      },
      "mn": {
        "CN-NM": "Nei Mongol Zizhiqu",
        "MN-041": "Hövsgöl",
        "MN-053": "Ömnögovĭ",
        "MN-061": "Dornod",
        "MN-067": "Bulgan",
        "MN-055": "Övörhangay",
        "MN-069": "Bayanhongor",
        "MN-039": "Hentiy",
        "MN-047": "Töv",
        "MN-049": "Selenge",
        "MN-057": "Dzavhan",
        "MN-059": "Dundgovĭ",
        "MN-043": "Hovd",
        "MN-071": "Bayan-Ölgiy",
        "MN-035": "Orhon",
        "MN-037": "Darhan uul",
        "MN-064": "Govĭ-Sümber",
        "MN-051": "Sühbaatar",
        "MN-063": "Dornogovĭ",
        "MN-073": "Arhangay",
        "MN-1": "Ulaanbaatar",
        "MN-046": "Uvs",
        "MN-065": "Govĭ-Altay"
      },
      "zh": {
        "CN-GX": "Guangxi Zhuangzu Zizhiqu",
        "CN-XZ": "Xizang Zizhiqu",
        "CN-NX": "Ningxia Huizi Zizhiqu",
        "CN-XJ": "Xinjiang Uygur Zizhiqu",
        "CN-BJ": "Beijing Shi",
        "CN-TJ": "Tianjin Shi",
        "CN-SH": "Shanghai Shi",
        "CN-CQ": "Chongqing Shi",
        "CN-HE": "Hebei Sheng",
        "CN-SX": "Shanxi Sheng",
        "CN-LN": "Liaoning Sheng",
        "CN-JL": "Jilin Sheng",
        "CN-HL": "Heilongjiang Sheng",
        "CN-ZJ": "Zhejiang Sheng",
        "CN-AH": "Anhui Sheng",
        "CN-FJ": "Fujian Sheng",
        "CN-JX": "Jiangxi Sheng",
        "CN-SD": "Shandong Sheng",
        "CN-HA": "Henan Sheng",
        "CN-HB": "Hubei Sheng",
        "CN-HN": "Hunan Sheng",
        "CN-GD": "Guangdong Sheng",
        "CN-HI": "Hainan Sheng",
        "CN-SC": "Sichuan Sheng",
        "CN-GZ": "Guizhou Sheng",
        "CN-YN": "Yunnan Sheng",
        "CN-SN": "Shaanxi Sheng",
        "CN-GS": "Gansu Sheng",
        "CN-QH": "Qinghai Sheng",
        "CN-TW": "Taiwan Sheng ",
        "CN-HK": "Xianggang Tebiexingzhengqu ",
        "CN-MO": "Aomen Tebiexingzhengqu ",
        "CN-JS": "Jiangsu Sheng",
        "TW-CYI": "Chiayi",
        "TW-CYQ": "Chiayi",
        "TW-HUA": "Hualien",
        "TW-HSZ": "Hsinchu",
        "TW-PEN": "Penghu",
        "TW-PIF": "Pingtung",
        "TW-TPE": "Taipei",
        "TW-CHA": "Changhua",
        "TW-ILA": "Yilan",
        "TW-YUN": "Yunlin",
        "TW-NAN": "Nantou",
        "TW-HSQ": "Hsinchu",
        "TW-MIA": "Miaoli",
        "TW-KEE": "Keelung",
        "TW-KIN": "Kinmen",
        "TW-LIE": "Lienchiang",
        "TW-TXG": "Taichung",
        "TW-TNN": "Tainan",
        "TW-TAO": "Taoyuan",
        "TW-NWT": "New Taipei",
        "TW-KHH": "Kaohsiung",
        "TW-TTT": "Taitung"
      },
      "el": {
        "CY-03": "Larnaka",
        "CY-06": "Keryneia",
        "CY-01": "Lefkosia",
        "CY-04": "Ammochostos",
        "CY-05": "Pafos",
        "CY-02": "Lemesos",
        "GR-H": "Stereá Elláda",
        "GR-A": "Anatolikí Makedonía kai Thráki",
        "GR-B": "Kentrikí Makedonía",
        "GR-F": "Ionía Nísia",
        "GR-69": "Ágion Óros",
        "GR-E": "Thessalía",
        "GR-K": "Voreío Aigaío",
        "GR-I": "Attikí",
        "GR-L": "Notío Aigaío",
        "GR-D": "Ípeiros",
        "GR-G": "Dytikí Elláda",
        "GR-J": "Peloponnísos",
        "GR-M": "Kríti",
        "GR-C": "Dytikí Makedonía"
      },
      "tr": {
        "CY-03": "Larnaka",
        "CY-06": "Girne",
        "CY-01": "Lefkoşa",
        "CY-04": "Mağusa",
        "CY-05": "Baf",
        "CY-02": "Leymasun",
        "TR-34": "İstanbul",
        "TR-47": "Mardin",
        "TR-49": "Muş",
        "TR-57": "Sinop",
        "TR-66": "Yozgat",
        "TR-69": "Bayburt",
        "TR-75": "Ardahan",
        "TR-77": "Yalova",
        "TR-78": "Karabük",
        "TR-03": "Afyonkarahisar",
        "TR-08": "Artvin",
        "TR-15": "Burdur",
        "TR-22": "Edirne",
        "TR-25": "Erzurum",
        "TR-27": "Gaziantep",
        "TR-29": "Gümüşhane",
        "TR-40": "Kırşehir",
        "TR-45": "Manisa",
        "TR-56": "Siirt",
        "TR-67": "Zonguldak",
        "TR-70": "Karaman",
        "TR-02": "Adıyaman",
        "TR-05": "Amasya",
        "TR-11": "Bilecik",
        "TR-13": "Bitlis",
        "TR-17": "Çanakkale",
        "TR-43": "Kütahya",
        "TR-52": "Ordu",
        "TR-60": "Tokat",
        "TR-61": "Trabzon",
        "TR-62": "Tunceli",
        "TR-80": "Osmaniye",
        "TR-10": "Balıkesir",
        "TR-18": "Çankırı",
        "TR-30": "Hakkâri",
        "TR-31": "Hatay",
        "TR-32": "Isparta",
        "TR-35": "İzmir",
        "TR-37": "Kastamonu",
        "TR-74": "Bartın",
        "TR-01": "Adana",
        "TR-16": "Bursa",
        "TR-19": "Çorum",
        "TR-20": "Denizli",
        "TR-21": "Diyarbakır",
        "TR-24": "Erzincan",
        "TR-28": "Giresun",
        "TR-33": "Mersin",
        "TR-46": "Kahramanmaraş",
        "TR-54": "Sakarya",
        "TR-65": "Van",
        "TR-72": "Batman",
        "TR-39": "Kırklareli",
        "TR-50": "Nevşehir",
        "TR-53": "Rize",
        "TR-55": "Samsun",
        "TR-63": "Şanlıurfa",
        "TR-64": "Uşak",
        "TR-76": "Iğdır",
        "TR-81": "Düzce",
        "TR-04": "Ağrı",
        "TR-06": "Ankara",
        "TR-07": "Antalya",
        "TR-09": "Aydın",
        "TR-12": "Bingöl",
        "TR-23": "Elazığ",
        "TR-26": "Eskişehir",
        "TR-36": "Kars",
        "TR-42": "Konya",
        "TR-48": "Muğla",
        "TR-51": "Niğde",
        "TR-59": "Tekirdağ",
        "TR-79": "Kilis",
        "TR-14": "Bolu",
        "TR-38": "Kayseri",
        "TR-41": "Kocaeli",
        "TR-44": "Malatya",
        "TR-58": "Sivas",
        "TR-68": "Aksaray",
        "TR-71": "Kırıkkale",
        "TR-73": "Şırnak"
      },
      "cs": {
        "CZ-42": "Ústecký kraj",
        "CZ-423": "Litoměřice",
        "CZ-421": "Děčín",
        "CZ-422": "Chomutov",
        "CZ-424": "Louny",
        "CZ-426": "Teplice",
        "CZ-427": "Ústí nad Labem",
        "CZ-425": "Most",
        "CZ-63": "Kraj Vysočina",
        "CZ-631": "Havlíčkův Brod",
        "CZ-632": "Jihlava",
        "CZ-633": "Pelhřimov",
        "CZ-634": "Třebíč",
        "CZ-635": "Žďár nad Sázavou",
        "CZ-72": "Zlínský kraj",
        "CZ-721": "Kroměříž",
        "CZ-722": "Uherské Hradiště",
        "CZ-723": "Vsetín",
        "CZ-724": "Zlín",
        "CZ-31": "Jihočeský kraj",
        "CZ-312": "Český Krumlov",
        "CZ-316": "Strakonice",
        "CZ-317": "Tábor",
        "CZ-313": "Jindřichův Hradec",
        "CZ-311": "České Budějovice",
        "CZ-314": "Písek",
        "CZ-315": "Prachatice",
        "CZ-64": "Jihomoravský kraj",
        "CZ-641": "Blansko",
        "CZ-644": "Břeclav",
        "CZ-642": "Brno-město",
        "CZ-643": "Brno-venkov",
        "CZ-645": "Hodonín",
        "CZ-646": "Vyškov",
        "CZ-647": "Znojmo",
        "CZ-41": "Karlovarský kraj",
        "CZ-411": "Cheb",
        "CZ-412": "Karlovy Vary",
        "CZ-413": "Sokolov",
        "CZ-52": "Královéhradecký kraj",
        "CZ-521": "Hradec Králové",
        "CZ-524": "Rychnov nad Kněžnou",
        "CZ-522": "Jičín",
        "CZ-525": "Trutnov",
        "CZ-523": "Náchod",
        "CZ-51": "Liberecký kraj",
        "CZ-512": "Jablonec nad Nisou",
        "CZ-513": "Liberec",
        "CZ-511": "Česká Lípa",
        "CZ-514": "Semily",
        "CZ-80": "Moravskoslezský kraj",
        "CZ-802": "Frýdek Místek",
        "CZ-803": "Karviná",
        "CZ-806": "Ostrava-město",
        "CZ-804": "Nový Jičín",
        "CZ-805": "Opava",
        "CZ-801": "Bruntál",
        "CZ-71": "Olomoucký kraj",
        "CZ-712": "Olomouc",
        "CZ-711": "Jeseník",
        "CZ-715": "Šumperk",
        "CZ-713": "Prostějov",
        "CZ-714": "Přerov",
        "CZ-53": "Pardubický kraj",
        "CZ-534": "Ústí nad Orlicí",
        "CZ-533": "Svitavy",
        "CZ-531": "Chrudim",
        "CZ-532": "Pardubice",
        "CZ-32": "Plzeňský kraj",
        "CZ-321": "Domažlice",
        "CZ-327": "Tachov",
        "CZ-325": "Plzeň-sever",
        "CZ-324": "Plzeň-jih",
        "CZ-323": "Plzeň-město",
        "CZ-326": "Rokycany",
        "CZ-322": "Klatovy",
        "CZ-20": "Středočeský kraj",
        "CZ-205": "Kutná Hora",
        "CZ-20C": "Rakovník",
        "CZ-203": "Kladno",
        "CZ-207": "Mladá Boleslav",
        "CZ-206": "Mělník",
        "CZ-20A": "Praha-západ",
        "CZ-20B": "Příbram",
        "CZ-201": "Benešov",
        "CZ-202": "Beroun",
        "CZ-204": "Kolín",
        "CZ-209": "Praha-východ",
        "CZ-208": "Nymburk",
        "CZ-10": "Praha, Hlavní mešto",
        "CZ-101": "Praha 1",
        "CZ-110": "Praha 10",
        "CZ-111": "Praha 11",
        "CZ-112": "Praha 12",
        "CZ-113": "Praha 13",
        "CZ-114": "Praha 14",
        "CZ-115": "Praha 15",
        "CZ-102": "Praha 2",
        "CZ-103": "Praha 3",
        "CZ-104": "Praha 4",
        "CZ-105": "Praha 5",
        "CZ-106": "Praha 6",
        "CZ-107": "Praha 7",
        "CZ-108": "Praha 8",
        "CZ-109": "Praha 9",
        "CZ-116": "Praha 16",
        "CZ-117": "Praha 17",
        "CZ-118": "Praha 18",
        "CZ-119": "Praha 19",
        "CZ-120": "Praha 20",
        "CZ-122": "Praha 22",
        "CZ-121": "Praha 21"
      },
      "da": {
        "DK-82": "Midtjylland",
        "DK-85": "Sjælland",
        "DK-81": "Nordjylland",
        "DK-84": "Hovedstaden",
        "DK-83": "Syddanmark"
      },
      "et": {
        "EE-78": "Tartumaa",
        "EE-49": "Jõgevamaa",
        "EE-51": "Järvamaa",
        "EE-70": "Raplamaa",
        "EE-84": "Viljandimaa",
        "EE-37": "Harjumaa",
        "EE-44": "Ida-Virumaa",
        "EE-57": "Läänemaa",
        "EE-59": "Lääne-Virumaa",
        "EE-67": "Pärnumaa",
        "EE-74": "Saaremaa",
        "EE-82": "Valgamaa",
        "EE-86": "Võrumaa",
        "EE-65": "Põlvamaa",
        "EE-39": "Hiiumaa"
      },
      "ti": {
        "ER-GB": "Gash-Barka",
        "ER-MA": "Ma’ĭkel",
        "ER-SK": "Semienawi K’eyyĭḥ Baḥri",
        "ER-DK": "Debubawi K’eyyĭḥ Baḥri",
        "ER-DU": "Debub",
        "ER-AN": "‘Anseba"
      },
      "gl": {
        "ES-GA": "Galicia [Galicia]",
        "ES-LU": "Lugo [Lugo]",
        "ES-OR": "Ourense [Orense]",
        "ES-PO": "Pontevedra [Pontevedra]",
        "ES-C": "A Coruña [La Coruña]"
      },
      "eu": {
        "ES-NC": "Nafarroako Foru Komunitatea*",
        "ES-NA": "Nafarroa*",
        "ES-PV": "Euskal Herria",
        "ES-BI": "Bizkaia",
        "ES-SS": "Gipuzkoa",
        "ES-VI": "Araba*"
      },
      "am": {
        "ET-BE": "Bīnshangul Gumuz",
        "ET-HA": "Hārerī Hizb",
        "ET-SO": "Sumalē",
        "ET-DD": "Dirē Dawa",
        "ET-GA": "Gambēla Hizboch",
        "ET-AM": "Āmara",
        "ET-AA": "Ādīs Ābeba",
        "ET-AF": "Āfar",
        "ET-OR": "Oromīya",
        "ET-TI": "Tigray",
        "ET-SN": "YeDebub Bihēroch Bihēreseboch na Hizboch"
      },
      "sv": {
        "FI-06": "Egentliga Tavastland",
        "FI-08": "Mellersta Finland",
        "FI-13": "Norra Karelen",
        "FI-18": "Nyland",
        "FI-01": "Landskapet Åland",
        "FI-15": "Norra Savolax",
        "FI-14": "Norra Österbotten",
        "FI-04": "Södra Savolax",
        "FI-07": "Mellersta Österbotten",
        "FI-09": "Kymmenedalen",
        "FI-11": "Birkaland",
        "FI-12": "Österbotten",
        "FI-19": "Egentliga Finland",
        "FI-17": "Satakunda",
        "FI-03": "Södra Österbotten",
        "FI-05": "Kajanaland",
        "FI-10": "Lappland",
        "FI-02": "Södra Karelen",
        "FI-16": "Päijänne-Tavastland",
        "SE-O": "Västra Götalands län [SE-14]",
        "SE-X": "Gävleborgs län [SE-21]",
        "SE-K": "Blekinge län [SE-10]",
        "SE-U": "Västmanlands län [SE-19]",
        "SE-W": "Dalarnas län [SE-20]",
        "SE-Y": "Västernorrlands län [SE-22]",
        "SE-E": "Östergötlands län [SE-05]",
        "SE-AB": "Stockholms län [SE-01]",
        "SE-D": "Södermanlands län [SE-04]",
        "SE-G": "Kronobergs län [SE-07]",
        "SE-Z": "Jämtlands län [SE-23]",
        "SE-I": "Gotlands län [SE-09]",
        "SE-N": "Hallands län [SE-13]",
        "SE-S": "Värmlands län [SE-17]",
        "SE-T": "Örebro län [SE-18]",
        "SE-BD": "Norrbottens län [SE-25]",
        "SE-F": "Jönköpings län [SE-06]",
        "SE-AC": "Västerbottens län [SE-24]",
        "SE-C": "Uppsala län [SE-03]",
        "SE-H": "Kalmar län [SE-08]",
        "SE-M": "Skåne län [SE-12]"
      },
      "fi": {
        "FI-06": "Kanta-Häme",
        "FI-08": "Keski-Suomi",
        "FI-13": "Pohjois-Karjala",
        "FI-18": "Uusimaa",
        "FI-01": "Ahvenanmaan maakunta",
        "FI-15": "Pohjois-Savo",
        "FI-14": "Pohjois-Pohjanmaa",
        "FI-04": "Etelä-Savo",
        "FI-07": "Keski-Pohjanmaa",
        "FI-09": "Kymenlaakso",
        "FI-11": "Pirkanmaa",
        "FI-12": "Pohjanmaa",
        "FI-19": "Varsinais-Suomi",
        "FI-17": "Satakunta",
        "FI-03": "Etelä-Pohjanmaa",
        "FI-05": "Kainuu",
        "FI-10": "Lappi",
        "FI-02": "Etelä-Karjala",
        "FI-16": "Päijät-Häme"
      },
      "ka": {
        "GE-SK": "Shida Kartli",
        "GE-IM": "Imereti",
        "GE-SJ": "Samtskhe-Javakheti",
        "GE-GU": "Guria",
        "GE-RL": "Rach'a-Lechkhumi-Kvemo Svaneti",
        "GE-TB": "Tbilisi",
        "GE-KA": "K'akheti",
        "GE-KK": "Kvemo Kartli",
        "GE-AB": "Abkhazia",
        "GE-AJ": "Ajaria",
        "GE-MM": "Mtskheta-Mtianeti",
        "GE-SZ": "Samegrelo-Zemo Svaneti"
      },
      "kl": {
        "GL-KU": "Kommune Kujalleq",
        "GL-SM": "Kommuneqarfik Sermersooq",
        "GL-QA": "Qaasuitsup Kommunia",
        "GL-QE": "Qeqqata Kommunia"
      },
      "ht": {
        "HT-NE": "Nòdès",
        "HT-OU": "Lwès",
        "HT-GA": "Grandans",
        "HT-ND": "Nò",
        "HT-SD": "Sid",
        "HT-SE": "Sidès",
        "HT-NI": "Nip",
        "HT-AR": "Latibonit",
        "HT-CE": "Sant",
        "HT-NO": "Nòdwès"
      },
      "hu": {
        "HU-FE": "Fejér",
        "HU-GS": "Győr-Moson-Sopron",
        "HU-MI": "Miskolc",
        "HU-PS": "Pécs",
        "HU-ST": "Salgótarján",
        "HU-VA": "Vas",
        "HU-BA": "Baranya",
        "HU-BC": "Békéscsaba",
        "HU-CS": "Csongrád",
        "HU-DE": "Debrecen",
        "HU-HB": "Hajdú-Bihar",
        "HU-PE": "Pest",
        "HU-SN": "Sopron",
        "HU-SO": "Somogy",
        "HU-BE": "Békés",
        "HU-JN": "Jász-Nagykun-Szolnok",
        "HU-SD": "Szeged",
        "HU-VE": "Veszprém",
        "HU-ZA": "Zala",
        "HU-ZE": "Zalaegerszeg",
        "HU-DU": "Dunaújváros",
        "HU-KE": "Komárom-Esztergom",
        "HU-SF": "Székesfehérvár",
        "HU-SS": "Szekszárd",
        "HU-BZ": "Borsod-Abaúj-Zemplén",
        "HU-ER": "Érd",
        "HU-NK": "Nagykanizsa",
        "HU-NO": "Nógrád",
        "HU-BK": "Bács-Kiskun",
        "HU-EG": "Eger",
        "HU-GY": "Győr",
        "HU-HE": "Heves",
        "HU-KM": "Kecskemét",
        "HU-SZ": "Szabolcs-Szatmár-Bereg",
        "HU-TO": "Tolna",
        "HU-BU": "Budapest",
        "HU-HV": "Hódmezővásárhely",
        "HU-KV": "Kaposvár",
        "HU-SH": "Szombathely",
        "HU-SK": "Szolnok",
        "HU-VM": "Veszprém",
        "HU-NY": "Nyíregyháza",
        "HU-TB": "Tatabánya"
      },
      "id": {
        "ID-KA": "Kalimantan",
        "ID-KU": "Kalimantan Utara",
        "ID-KT": "Kalimantan Tengah",
        "ID-KB": "Kalimantan Barat",
        "ID-KS": "Kalimantan Selatan",
        "ID-KI": "Kalimantan Timur",
        "ID-JW": "Jawa",
        "ID-JB": "Jawa Barat",
        "ID-JT": "Jawa Tengah",
        "ID-JI": "Jawa Timur",
        "ID-BT": "Banten",
        "ID-YO": "Yogyakarta",
        "ID-JK": "Jakarta Raya",
        "ID-NU": "Nusa Tenggara",
        "ID-NT": "Nusa Tenggara Timur",
        "ID-NB": "Nusa Tenggara Barat",
        "ID-BA": "Bali",
        "ID-SM": "Sumatera",
        "ID-BB": "Kepulauan Bangka Belitung",
        "ID-JA": "Jambi",
        "ID-BE": "Bengkulu",
        "ID-SB": "Sumatera Barat",
        "ID-SU": "Sumatera Utara",
        "ID-LA": "Lampung",
        "ID-KR": "Kepulauan Riau",
        "ID-RI": "Riau",
        "ID-SS": "Sumatera Selatan",
        "ID-AC": "Aceh",
        "ID-PP": "Papua",
        "ID-PA": "Papua",
        "ID-PB": "Papua Barat",
        "ID-ML": "Maluku",
        "ID-MA": "Maluku",
        "ID-MU": "Maluku Utara",
        "ID-SL": "Sulawesi",
        "ID-SA": "Sulawesi Utara",
        "ID-SR": "Sulawesi Barat",
        "ID-ST": "Sulawesi Tengah",
        "ID-SG": "Sulawesi Tenggara",
        "ID-SN": "Sulawesi Selatan",
        "ID-GO": "Gorontalo"
      },
      "ga": {
        "IE-L": "Laighin",
        "IE-D": "Baile Átha Cliath",
        "IE-KE": "Cill Dara",
        "IE-LD": "An Longfort",
        "IE-CW": "Ceatharlach",
        "IE-KK": "Cill Chainnigh",
        "IE-LS": "Laois",
        "IE-MH": "An Mhí",
        "IE-WW": "Cill Mhantáin",
        "IE-WX": "Loch Garman",
        "IE-LH": "Lú",
        "IE-WH": "An Iarmhí",
        "IE-OY": "Uíbh Fhailí",
        "IE-U": "Ulaidh",
        "IE-MN": "Muineachán",
        "IE-CN": "An Cabhán",
        "IE-DL": "Dún na nGall",
        "IE-C": "Connacht",
        "IE-G": "Gaillimh",
        "IE-RN": "Ros Comáin",
        "IE-SO": "Sligeach",
        "IE-LM": "Liatroim",
        "IE-MO": "Maigh Eo",
        "IE-M": "An Mhumhain",
        "IE-CE": "An Clár",
        "IE-CO": "Corcaigh",
        "IE-TA": "Tiobraid Árann",
        "IE-WD": "Port Láirge",
        "IE-LK": "Luimneach",
        "IE-KY": "Ciarraí"
      },
      "he": {
        "IL-D": "HaDarom",
        "IL-HA": "H̱efa",
        "IL-JM": "Yerushalayim",
        "IL-M": "HaMerkaz",
        "IL-Z": "HaTsafon",
        "IL-TA": "Tel Aviv"
      },
      "ku": {
        "IQ-AR": "Hewlêr",
        "IQ-DA": "Dihok",
        "IQ-SU": "Slêmanî"
      },
      "is": {
        "IS-2": "Suðurnes",
        "IS-7": "Austurland",
        "IS-1": "Höfuðborgarsvæði",
        "IS-5": "Norðurland vestra",
        "IS-6": "Norðurland eystra",
        "IS-3": "Vesturland",
        "IS-8": "Suðurland",
        "IS-4": "Vestfirðir"
      },
      "ja": {
        "JP-03": "Iwate",
        "JP-25": "Siga",
        "JP-28": "Hyôgo",
        "JP-41": "Saga",
        "JP-10": "Gunma",
        "JP-12": "Tiba",
        "JP-14": "Kanagawa",
        "JP-39": "Kôti",
        "JP-45": "Miyazaki",
        "JP-20": "Nagano",
        "JP-31": "Tottori",
        "JP-38": "Ehime",
        "JP-40": "Hukuoka",
        "JP-42": "Nagasaki",
        "JP-02": "Aomori",
        "JP-07": "Hukusima",
        "JP-09": "Totigi",
        "JP-18": "Hukui",
        "JP-21": "Gihu",
        "JP-34": "Hirosima",
        "JP-35": "Yamaguti",
        "JP-43": "Kumamoto",
        "JP-46": "Kagosima",
        "JP-47": "Okinawa",
        "JP-01": "Hokkaidô",
        "JP-05": "Akita",
        "JP-13": "Tôkyô",
        "JP-23": "Aiti",
        "JP-29": "Nara",
        "JP-30": "Wakayama",
        "JP-33": "Okayama",
        "JP-37": "Kagawa",
        "JP-04": "Miyagi",
        "JP-06": "Yamagata",
        "JP-08": "Ibaraki",
        "JP-15": "Niigata",
        "JP-27": "Ôsaka",
        "JP-11": "Saitama",
        "JP-16": "Toyama",
        "JP-17": "Isikawa",
        "JP-19": "Yamanasi",
        "JP-22": "Sizuoka",
        "JP-24": "Mie",
        "JP-26": "Kyôto",
        "JP-32": "Simane",
        "JP-36": "Tokusima",
        "JP-44": "Ôita"
      },
      "ky": {
        "KG-Y": "Ysyk-Köl",
        "KG-O": "Osh",
        "KG-T": "Talas",
        "KG-B": "Batken",
        "KG-GO": "Osh",
        "KG-GB": "Bishkek",
        "KG-J": "Jalal-Abad",
        "KG-C": "Chüy",
        "KG-N": "Naryn"
      },
      "km": {
        "KH-16": "Rôtânôkiri",
        "KH-17": "Siĕmréab",
        "KH-20": "Svaay Rieng",
        "KH-11": "Mondol Kiri",
        "KH-4": "Kâmpóng Chhnăng",
        "KH-6": "Kampong Thum",
        "KH-2": "Bătdâmbâng",
        "KH-5": "Kâmpóng Spœ",
        "KH-9": "Kaôh Kŏng",
        "KH-15": "Pousaat",
        "KH-3": "Kâmpóng Cham",
        "KH-8": "Kândal",
        "KH-19": "Stueng Traeng",
        "KH-22": "Otdar Mean Chey",
        "KH-7": "Kâmpôt",
        "KH-12": "Phnom Penh",
        "KH-13": "Preah Vihear",
        "KH-14": "Prey Veaeng",
        "KH-24": "Krong Pailin",
        "KH-10": "Kracheh",
        "KH-1": "Bântéay Méanchey",
        "KH-18": "Krong Preah Sihanouk",
        "KH-23": "Krŏng Kêb",
        "KH-25": "Tbong Khmŭm",
        "KH-21": "Takêv"
      },
      "002": {
        "KM-M": "Mwali",
        "KM-A": "Ndzuwani",
        "KM-G": "Ngazidja"
      },
      "ko": {
        "KP-02": "P'yǒngan-namdo",
        "KP-03": "Phyeonganpukto",
        "KP-04": "Jakangto",
        "KP-09": "Hamgyǒng-bukto",
        "KP-13": "Rasǒn",
        "KP-07": "Kangwǒn-do",
        "KP-01": "Phyeongyang",
        "KP-08": "Hamkyeongnamto",
        "KP-14": "Nampho",
        "KP-05": "Hwanghainamto",
        "KP-06": "Hwanghaipukto",
        "KP-10": "Ryanggang-do",
        "KR-50": "Sejong",
        "KR-26": "Busan-gwangyeoksi",
        "KR-27": "Daegu-gwangyeoksi",
        "KR-30": "Daejeon-gwangyeoksi",
        "KR-29": "Gwangju-gwangyeoksi",
        "KR-28": "Incheon-gwangyeoksi",
        "KR-31": "Ulsan-gwangyeoksi",
        "KR-43": "Chungcheongbuk-do",
        "KR-44": "Chungcheongnam-do",
        "KR-42": "Gangwon-do",
        "KR-41": "Gyeonggi-do",
        "KR-47": "Gyeongsangbuk-do",
        "KR-48": "Gyeongsangnam-do",
        "KR-45": "Jeollabuk-do",
        "KR-46": "Jeollanam-do",
        "KR-49": "Jeju-teukbyeoljachido",
        "KR-11": "Seoul-teukbyeolsi"
      },
      "kk": {
        "KZ-ALM": "Almaty oblysy",
        "KZ-SEV": "Soltüstik Qazaqstan oblysy",
        "KZ-YUZ": "Ongtüstik Qazaqstan oblysy",
        "KZ-ZAP": "Batys Qazaqstan oblysy",
        "KZ-ALA": "Almaty",
        "KZ-KUS": "Qostanay oblysy",
        "KZ-KAR": "Qaraghandy oblysy",
        "KZ-PAV": "Pavlodar oblysy",
        "KZ-AST": "Astana",
        "KZ-AKT": "Aqtöbe oblysy",
        "KZ-ATY": "Atyraū oblysy",
        "KZ-MAN": "Mangghystaū oblysy",
        "KZ-ZHA": "Zhambyl oblysy",
        "KZ-BAY": "Bayqongyr",
        "KZ-AKM": "Aqmola oblysy",
        "KZ-KZY": "Qyzylorda oblysy",
        "KZ-VOS": "Shyghys Qazaqstan oblysy"
      },
      "lo": {
        "LA-BK": "Bokèo",
        "LA-SV": "Savannakhét",
        "LA-HO": "Houaphan",
        "LA-KH": "Khammouan",
        "LA-VI": "Viangchan",
        "LA-LM": "Louang Namtha",
        "LA-VT": "Viangchan",
        "LA-XS": "Xaisômboun",
        "LA-AT": "Attapu",
        "LA-BL": "Bolikhamxai",
        "LA-CH": "Champasak",
        "LA-LP": "Louangphabang",
        "LA-OU": "Oudômxai",
        "LA-PH": "Phôngsali",
        "LA-SL": "Salavan",
        "LA-XA": "Xaignabouli",
        "LA-XE": "Xékong",
        "LA-XI": "Xiangkhouang"
      },
      "ta": {
        "LK-8": "Ūvā mākāṇam",
        "LK-62": "Puttaḷam",
        "LK-91": "Irattiṉapuri",
        "LK-9": "Chappirakamuva mākāṇam",
        "LK-44": "Vavuṉiyā",
        "LK-53": "Tirukŏṇamalai",
        "LK-6": "Vaṭamel mākāṇam",
        "LK-45": "Mullaittīvu",
        "LK-82": "Mŏṉarākalai",
        "LK-7": "Vaṭamattiya mākāṇam",
        "LK-23": "Nuvarĕliyā",
        "LK-72": "Pŏlaṉṉaṛuvai",
        "LK-1": "Mel mākāṇam",
        "LK-52": "Ampāṟai",
        "LK-81": "Patuḷai",
        "LK-71": "Anurātapuram",
        "LK-4": "Vaṭakku mākāṇam",
        "LK-42": "Kiḷinochchi",
        "LK-92": "Kekālai",
        "LK-13": "Kaḷuttuṟai",
        "LK-21": "Kaṇṭi",
        "LK-61": "Kurunākal",
        "LK-5": "Kil̮akku mākāṇam",
        "LK-43": "Maṉṉār",
        "LK-32": "Māttaṛai",
        "LK-22": "Māttaḷai",
        "LK-2": "Mattiya mākāṇam",
        "LK-11": "Kŏl̮umpu",
        "LK-51": "Maṭṭakkaḷappu",
        "LK-31": "Kāli",
        "LK-3": "Tĕṉ mākāṇam",
        "LK-12": "Kampahā",
        "LK-33": "Ampāntōṭṭai",
        "LK-41": "Yāl̮ppāṇam"
      },
      "si": {
        "LK-8": "Ūva paḷāta",
        "LK-62": "Puttalama",
        "LK-91": "Ratnapura",
        "LK-9": "Sabaragamuva paḷāta",
        "LK-44": "Vavuniyāva",
        "LK-53": "Trikuṇāmalaya",
        "LK-6": "Vayamba paḷāta",
        "LK-45": "Mulativ",
        "LK-82": "Mŏṇarāgala",
        "LK-7": "Uturumæ̆da paḷāta",
        "LK-23": "Nuvara Ĕliya",
        "LK-72": "Pŏḷŏnnaruva",
        "LK-1": "Basnāhira paḷāta",
        "LK-52": "Ampāra",
        "LK-81": "Badulla",
        "LK-71": "Anurādhapura",
        "LK-4": "Uturu paḷāta",
        "LK-42": "Kilinŏchchi",
        "LK-92": "Kægalla",
        "LK-13": "Kaḷutara",
        "LK-21": "Mahanuvara",
        "LK-61": "Kuruṇægala",
        "LK-5": "Næ̆gĕnahira paḷāta",
        "LK-43": "Mannārama",
        "LK-32": "Mātara",
        "LK-22": "Mātale",
        "LK-2": "Madhyama paḷāta",
        "LK-11": "Kŏḷamba",
        "LK-51": "Maḍakalapuva",
        "LK-31": "Gālla",
        "LK-3": "Dakuṇu paḷāta",
        "LK-12": "Gampaha",
        "LK-33": "Hambantŏṭa",
        "LK-41": "Yāpanaya"
      },
      "st": {
        "LS-D": "Berea",
        "LS-E": "Mafeteng",
        "LS-K": "Thaba-Tseka",
        "LS-A": "Maseru",
        "LS-F": "Mohale's Hoek",
        "LS-J": "Mokhotlong",
        "LS-C": "Leribe",
        "LS-H": "Qacha's Nek",
        "LS-B": "Butha-Buthe",
        "LS-G": "Quthing",
        "ZA-NL": "Hazolo-Natala",
        "ZA-WC": "Kapa Bophirimela",
        "ZA-NC": "Kapa Leboya",
        "ZA-GT": "Kgauteng",
        "ZA-EC": "Kapa Botjhabela",
        "ZA-MP": "Mpumalanga",
        "ZA-FS": "Freistata",
        "ZA-LP": "Limpopo",
        "ZA-NW": "Leboya (le) Bophirima"
      },
      "lt": {
        "LT-SA": "Šiaulių apskritis",
        "LT-AL": "Alytaus apskritis",
        "LT-KU": "Kauno apskritis",
        "LT-UT": "Utenos apskritis",
        "LT-02": "Alytaus miestas",
        "LT-15": "Kauno miestas",
        "LT-20": "Klaipėdos miestas",
        "LT-31": "Palangos miestas",
        "LT-43": "Šiaulių miestas",
        "LT-03": "Alytus",
        "LT-57": "Vilniaus miestas",
        "LT-32": "Panevėžio miestas",
        "LT-01": "Akmenė",
        "LT-04": "Anykščiai",
        "LT-06": "Biržai",
        "LT-09": "Ignalina",
        "LT-10": "Jonava",
        "LT-11": "Joniškis",
        "LT-12": "Jurbarkas",
        "LT-13": "Kaišiadorys",
        "LT-16": "Kaunas",
        "LT-18": "Kėdainiai",
        "LT-19": "Kelmė",
        "LT-21": "Klaipėda",
        "LT-22": "Kretinga",
        "LT-23": "Kupiškis",
        "LT-24": "Lazdijai",
        "LT-25": "Marijampolė",
        "LT-26": "Mažeikiai",
        "LT-27": "Molėtai",
        "LT-30": "Pakruojis",
        "LT-33": "Panevėžys",
        "LT-34": "Pasvalys",
        "LT-35": "Plungė",
        "LT-36": "Prienai",
        "LT-37": "Radviliškis",
        "LT-38": "Raseiniai",
        "LT-40": "Rokiškis",
        "LT-41": "Šakiai",
        "LT-42": "Šalčininkai",
        "LT-44": "Šiauliai",
        "LT-45": "Šilalė",
        "LT-46": "Šilutė",
        "LT-47": "Širvintos",
        "LT-48": "Skuodas",
        "LT-49": "Švenčionys",
        "LT-50": "Tauragė",
        "LT-51": "Telšiai",
        "LT-52": "Trakai",
        "LT-53": "Ukmergė",
        "LT-54": "Utena",
        "LT-55": "Varėna",
        "LT-56": "Vilkaviškis",
        "LT-58": "Vilnius",
        "LT-60": "Zarasai",
        "LT-05": "Birštono",
        "LT-07": "Druskininkai",
        "LT-08": "Elektrėnai",
        "LT-14": "Kalvarijos",
        "LT-17": "Kazlų Rūdos",
        "LT-28": "Neringa",
        "LT-29": "Pagėgiai",
        "LT-39": "Rietavo",
        "LT-59": "Visaginas",
        "LT-TE": "Telšių apskritis",
        "LT-KL": "Klaipėdos apskritis",
        "LT-TA": "Tauragės apskritis",
        "LT-VL": "Vilniaus apskritis",
        "LT-MR": "Marijampolės apskritis",
        "LT-PN": "Panevėžio apskritis"
      },
      "lb": {
        "LU-CA": "Kapellen",
        "LU-CL": "Klierf",
        "LU-DI": "Diekrech",
        "LU-EC": "Iechternach",
        "LU-ES": "Esch-Uelzecht",
        "LU-GR": "Gréivemaacher",
        "LU-LU": "Lëtzebuerg",
        "LU-ME": "Miersch",
        "LU-RD": "Réiden-Atert",
        "LU-RM": "Réimech",
        "LU-VD": "Veianen",
        "LU-WI": "Wolz"
      },
      "lv": {
        "LV-010": "Auces novads",
        "LV-017": "Beverīnas novads",
        "LV-035": "Ikšķiles novads",
        "LV-068": "Olaines novads",
        "LV-075": "Priekuļu novads",
        "LV-076": "Raunas novads",
        "LV-088": "Saldus novads",
        "LV-097": "Talsu novads",
        "LV-107": "Viesītes novads",
        "LV-JEL": "Jelgava",
        "LV-VMR": "Valmiera",
        "LV-006": "Alsungas novads",
        "LV-030": "Ērgļu novads",
        "LV-037": "Inčukalna novads",
        "LV-039": "Jaunpiebalgas novads",
        "LV-043": "Kandavas novads",
        "LV-050": "Kuldīgas novads",
        "LV-055": "Līgatnes novads",
        "LV-065": "Neretas novads",
        "LV-072": "Pļaviņu novads",
        "LV-077": "Rēzeknes novads",
        "LV-079": "Rojas novads",
        "LV-087": "Salaspils novads",
        "LV-089": "Saulkrastu novads",
        "LV-091": "Siguldas novads",
        "LV-094": "Smiltenes novads",
        "LV-100": "Vaiņodes novads",
        "LV-104": "Vecpiebalgas novads",
        "LV-REZ": "Rēzekne",
        "LV-013": "Baldones novads",
        "LV-023": "Ciblas novads",
        "LV-025": "Daugavpils novads",
        "LV-026": "Dobeles novads",
        "LV-029": "Engures novads",
        "LV-045": "Kocēnu novads",
        "LV-059": "Madonas novads",
        "LV-069": "Ozolnieku novads",
        "LV-082": "Rugāju novads",
        "LV-085": "Salas novads",
        "LV-092": "Skrīveru novads",
        "LV-103": "Vārkavas novads",
        "LV-JUR": "Jūrmala",
        "LV-004": "Aknīstes novads",
        "LV-011": "Ādažu novads",
        "LV-018": "Brocēnu novads",
        "LV-028": "Durbes novads",
        "LV-064": "Naukšēnu novads",
        "LV-101": "Valkas novads",
        "LV-105": "Vecumnieku novads",
        "LV-108": "Viļakas novads",
        "LV-DGV": "Daugavpils",
        "LV-LPX": "Liepāja",
        "LV-RIX": "Rīga",
        "LV-012": "Babītes novads",
        "LV-014": "Baltinavas novads",
        "LV-019": "Burtnieku novads",
        "LV-020": "Carnikavas novads",
        "LV-022": "Cēsu novads",
        "LV-031": "Garkalnes novads",
        "LV-051": "Ķeguma novads",
        "LV-058": "Ludzas novads",
        "LV-063": "Mērsraga novads",
        "LV-066": "Nīcas novads",
        "LV-067": "Ogres novads",
        "LV-070": "Pārgaujas novads",
        "LV-093": "Skrundas novads",
        "LV-095": "Stopiņu novads",
        "LV-096": "Strenču novads",
        "LV-099": "Tukuma novads",
        "LV-102": "Varakļānu novads",
        "LV-002": "Aizkraukles novads",
        "LV-005": "Alojas novads",
        "LV-007": "Alūksnes novads",
        "LV-008": "Amatas novads",
        "LV-015": "Balvu novads",
        "LV-024": "Dagdas novads",
        "LV-027": "Dundagas novads",
        "LV-036": "Ilūkstes novads",
        "LV-047": "Krāslavas novads",
        "LV-048": "Krimuldas novads",
        "LV-054": "Limbažu novads",
        "LV-056": "Līvānu novads",
        "LV-060": "Mazsalacas novads",
        "LV-078": "Riebiņu novads",
        "LV-080": "Ropažu novads",
        "LV-083": "Rundāles novads",
        "LV-086": "Salacgrīvas novads",
        "LV-110": "Zilupes novads",
        "LV-003": "Aizputes novads",
        "LV-009": "Apes novads",
        "LV-016": "Bauskas novads",
        "LV-021": "Cesvaines novads",
        "LV-034": "Iecavas novads",
        "LV-038": "Jaunjelgavas novads",
        "LV-040": "Jaunpils novads",
        "LV-041": "Jelgavas novads",
        "LV-042": "Jēkabpils novads",
        "LV-044": "Kārsavas novads",
        "LV-052": "Ķekavas novads",
        "LV-057": "Lubānas novads",
        "LV-062": "Mārupes novads",
        "LV-074": "Priekules novads",
        "LV-084": "Rūjienas novads",
        "LV-106": "Ventspils novads",
        "LV-109": "Viļānu novads",
        "LV-001": "Aglonas novads",
        "LV-032": "Grobiņas novads",
        "LV-033": "Gulbenes novads",
        "LV-046": "Kokneses novads",
        "LV-049": "Krustpils novads",
        "LV-053": "Lielvārdes novads",
        "LV-061": "Mālpils novads",
        "LV-071": "Pāvilostas novads",
        "LV-073": "Preiļu novads",
        "LV-081": "Rucavas novads",
        "LV-090": "Sējas novads",
        "LV-098": "Tērvetes novads",
        "LV-JKB": "Jēkabpils",
        "LV-VEN": "Ventspils"
      },
      "ro": {
        "MD-BR": "Briceni",
        "MD-FA": "Fălești",
        "MD-CM": "Cimișlia",
        "MD-FL": "Florești",
        "MD-LE": "Leova",
        "MD-OR": "Orhei",
        "MD-AN": "Anenii Noi",
        "MD-CR": "Criuleni",
        "MD-CT": "Cantemir",
        "MD-GL": "Glodeni",
        "MD-RI": "Rîșcani",
        "MD-ST": "Strășeni",
        "MD-BA": "Bălți",
        "MD-BS": "Basarabeasca",
        "MD-CA": "Cahul",
        "MD-SD": "Șoldănești",
        "MD-SI": "Sîngerei",
        "MD-CL": "Călărași",
        "MD-DR": "Drochia",
        "MD-GA": "Găgăuzia, Unitatea teritorială autonomă (UTAG)",
        "MD-OC": "Ocnița",
        "MD-SO": "Soroca",
        "MD-TA": "Taraclia",
        "MD-TE": "Telenești",
        "MD-BD": "Bender [Tighina]",
        "MD-CS": "Căușeni",
        "MD-CU": "Chișinău",
        "MD-DO": "Dondușeni",
        "MD-DU": "Dubăsari",
        "MD-IA": "Ialoveni",
        "MD-UN": "Ungheni",
        "MD-ED": "Edineț",
        "MD-HI": "Hîncești",
        "MD-NI": "Nisporeni",
        "MD-RE": "Rezina",
        "MD-SN": "Stînga Nistrului, unitatea teritorială din",
        "MD-SV": "Ștefan Vodă",
        "RO-BH": "Bihor",
        "RO-IF": "Ilfov",
        "RO-IS": "Iași",
        "RO-VS": "Vaslui",
        "RO-B": "București",
        "RO-GL": "Galați",
        "RO-SM": "Satu Mare",
        "RO-SV": "Suceava",
        "RO-TR": "Teleorman",
        "RO-VL": "Vâlcea",
        "RO-VN": "Vrancea",
        "RO-BR": "Brăila",
        "RO-CT": "Constanța",
        "RO-IL": "Ialomița",
        "RO-MH": "Mehedinți",
        "RO-MM": "Maramureș",
        "RO-OT": "Olt",
        "RO-PH": "Prahova",
        "RO-BZ": "Buzău",
        "RO-DJ": "Dolj",
        "RO-HD": "Hunedoara",
        "RO-HR": "Harghita",
        "RO-TM": "Timiș",
        "RO-AG": "Argeș",
        "RO-BC": "Bacău",
        "RO-GJ": "Gorj",
        "RO-SJ": "Sălaj",
        "RO-BV": "Brașov",
        "RO-GR": "Giurgiu",
        "RO-NT": "Neamț",
        "RO-TL": "Tulcea",
        "RO-BN": "Bistrița-Năsăud",
        "RO-CL": "Călărași",
        "RO-CS": "Caraș-Severin",
        "RO-CV": "Covasna",
        "RO-DB": "Dâmbovița",
        "RO-MS": "Mureș",
        "RO-AB": "Alba",
        "RO-AR": "Arad",
        "RO-BT": "Botoșani",
        "RO-CJ": "Cluj",
        "RO-SB": "Sibiu"
      },
      "cnr": {
        "ME-06": "Cetinje",
        "ME-09": "Kolašin",
        "ME-14": "Pljevlja",
        "ME-18": "Šavnik",
        "ME-21": "Žabljak",
        "ME-02": "Bar",
        "ME-04": "Bijelo Polje",
        "ME-08": "Herceg-Novi",
        "ME-11": "Mojkovac",
        "ME-03": "Berane",
        "ME-16": "Podgorica",
        "ME-20": "Ulcinj",
        "ME-22": "Gusinje",
        "ME-23": "Petnjica",
        "ME-05": "Budva",
        "ME-15": "Plužine",
        "ME-19": "Tivat",
        "ME-13": "Plav",
        "ME-12": "Nikšić",
        "ME-01": "Andrijevica",
        "ME-07": "Danilovgrad",
        "ME-10": "Kotor",
        "ME-17": "Rožaje"
      },
      "mg": {
        "MG-U": "Toliara",
        "MG-T": "Antananarivo",
        "MG-A": "Toamasina",
        "MG-F": "Fianarantsoa",
        "MG-M": "Mahajanga",
        "MG-D": "Antsiranana"
      },
      "mh": {
        "MH-T": "Ratak chain",
        "MH-LIK": "Likiep",
        "MH-MAJ": "Mājro",
        "MH-ARN": "Arṇo",
        "MH-AUR": "Aur",
        "MH-MAL": "Ṃaḷoeḷap",
        "MH-MEJ": "Mājej",
        "MH-MIL": "Mile",
        "MH-WTJ": "Wōjjā",
        "MH-ALK": "Aelok",
        "MH-UTI": "Utrōk",
        "MH-L": "Ralik chain",
        "MH-NMK": "Naṃdik",
        "MH-RON": "Ron̄ḷap",
        "MH-KWA": "Kuwajleen",
        "MH-ALL": "Aelōn̄ḷapḷap",
        "MH-KIL": "Pikinni & Kōle",
        "MH-LAE": "Lae",
        "MH-NMU": "Naṃo",
        "MH-JAB": "Jebat",
        "MH-JAL": "Jālwōj",
        "MH-LIB": "Ellep",
        "MH-UJA": "Ujae",
        "MH-EBO": "Epoon",
        "MH-ENI": "Ānewetak & Wūjlan̄",
        "MH-WTH": "Wōtto"
      },
      "mk": {
        "MK-03": "Berovo",
        "MK-10": "Valandovo",
        "MK-11": "Vasilevo",
        "MK-26": "Dojran",
        "MK-44": "Kriva Palanka",
        "MK-54": "Negotino",
        "MK-56": "Novo Selo",
        "MK-60": "Pehčevo",
        "MK-78": "Centar Župa",
        "MK-80": "Čaška",
        "MK-81": "Češinovo-Obleševo",
        "MK-06": "Bogovinje",
        "MK-16": "Vrapčište",
        "MK-25": "Demir Hisar",
        "MK-35": "Jegunovce",
        "MK-41": "Konče",
        "MK-58": "Ohrid",
        "MK-62": "Prilep",
        "MK-73": "Strumica",
        "MK-18": "Gevgelija",
        "MK-22": "Debarca",
        "MK-24": "Demir Kapija",
        "MK-36": "Kavadarci",
        "MK-43": "Kratovo",
        "MK-50": "Mavrovo i Rostuša",
        "MK-61": "Plasnica",
        "MK-69": "Sveti Nikole",
        "MK-34": "Ilinden",
        "MK-42": "Kočani",
        "MK-45": "Krivogaštani",
        "MK-46": "Kruševo",
        "MK-48": "Lipkovo",
        "MK-63": "Probištip",
        "MK-74": "Studeničani",
        "MK-76": "Tetovo",
        "MK-02": "Aračinovo",
        "MK-04": "Bitola",
        "MK-07": "Bosilovo",
        "MK-19": "Gostivar",
        "MK-30": "Želino",
        "MK-40": "Kičevo",
        "MK-51": "Makedonska Kamenica",
        "MK-53": "Mogila",
        "MK-66": "Resen",
        "MK-67": "Rosoman",
        "MK-75": "Tearce",
        "MK-12": "Vevčani",
        "MK-20": "Gradsko",
        "MK-47": "Kumanovo",
        "MK-65": "Rankovce",
        "MK-72": "Struga",
        "MK-83": "Štip",
        "MK-13": "Veles",
        "MK-33": "Zrnovci",
        "MK-49": "Lozovo",
        "MK-52": "Makedonski Brod",
        "MK-59": "Petrovec",
        "MK-70": "Sopište",
        "MK-82": "Čučer Sandevo",
        "MK-85": "Skopje",
        "MK-05": "Bogdanci",
        "MK-08": "Brvenica",
        "MK-14": "Vinica",
        "MK-21": "Debar",
        "MK-23": "Delčevo",
        "MK-27": "Dolneni",
        "MK-32": "Zelenikovo",
        "MK-37": "Karbinci",
        "MK-55": "Novaci",
        "MK-64": "Radoviš",
        "MK-71": "Staro Nagoričane"
      },
      "my": {
        "MM-05": "Tanintharyi",
        "MM-11": "Kachin",
        "MM-13": "Kayin",
        "MM-15": "Mon",
        "MM-01": "Sagaing",
        "MM-04": "Mandalay",
        "MM-06": "Yangon",
        "MM-17": "Shan",
        "MM-03": "Magway",
        "MM-18": "Nay Pyi Taw",
        "MM-02": "Bago",
        "MM-12": "Kayah",
        "MM-14": "Chin",
        "MM-07": "Ayeyarwady",
        "MM-16": "Rakhine"
      },
      "mt": {
        "MT-34": "Msida",
        "MT-35": "Mtarfa",
        "MT-37": "Nadur",
        "MT-46": "Rabat Malta",
        "MT-13": "Għajnsielem",
        "MT-15": "Għargħur",
        "MT-20": "Isla",
        "MT-27": "Marsaskala",
        "MT-36": "Munxar",
        "MT-40": "Pembroke",
        "MT-44": "Qrendi",
        "MT-49": "San Ġwann",
        "MT-50": "San Lawrenz",
        "MT-51": "San Pawl il-Baħar",
        "MT-61": "Xagħra",
        "MT-63": "Xgħajra",
        "MT-67": "Żejtun",
        "MT-05": "Birżebbuġa",
        "MT-06": "Bormla",
        "MT-12": "Gżira",
        "MT-64": "Żabbar",
        "MT-02": "Balzan",
        "MT-03": "Birgu",
        "MT-19": "Iklin",
        "MT-24": "Lija",
        "MT-25": "Luqa",
        "MT-39": "Paola",
        "MT-52": "Sannat",
        "MT-55": "Siġġiewi",
        "MT-56": "Sliema",
        "MT-66": "Żebbuġ Malta",
        "MT-68": "Żurrieq",
        "MT-38": "Naxxar",
        "MT-41": "Pietà",
        "MT-54": "Santa Venera",
        "MT-58": "Ta' Xbiex",
        "MT-60": "Valletta",
        "MT-07": "Dingli",
        "MT-11": "Gudja",
        "MT-21": "Kalkara",
        "MT-31": "Mġarr",
        "MT-04": "Birkirkara",
        "MT-14": "Għarb",
        "MT-16": "Għasri",
        "MT-18": "Ħamrun",
        "MT-22": "Kerċem",
        "MT-26": "Marsa",
        "MT-47": "Safi",
        "MT-59": "Tarxien",
        "MT-62": "Xewkija",
        "MT-65": "Żebbuġ Għawdex",
        "MT-09": "Floriana",
        "MT-30": "Mellieħa",
        "MT-42": "Qala",
        "MT-45": "Rabat Għawdex",
        "MT-53": "Santa Luċija",
        "MT-57": "Swieqi",
        "MT-01": "Attard",
        "MT-10": "Fontana",
        "MT-17": "Għaxaq",
        "MT-23": "Kirkop",
        "MT-28": "Marsaxlokk",
        "MT-29": "Mdina",
        "MT-32": "Mosta",
        "MT-33": "Mqabba",
        "MT-43": "Qormi",
        "MT-48": "San Ġiljan",
        "MT-08": "Fgura"
      },
      "dv": {
        "MV-SU": "Dhekunu",
        "MV-29": "Fuvammulah",
        "MV-01": "Addu Atholhu",
        "MV-SC": "Medhu-Dhekunu",
        "MV-27": "Huvadhu Atholhu Uthuruburi",
        "MV-28": "Huvadhu Atholhu Dhekunuburi",
        "MV-MLE": "Maale",
        "MV-NO": "Uthuru",
        "MV-13": "Maalhosmadulu Uthuruburi",
        "MV-03": "Faadhippolhu",
        "MV-20": "Maalhosmadulu Dhekunuburi",
        "MV-25": "Miladhunmadulu Dhekunuburi",
        "MV-UN": "Mathi-Uthuru",
        "MV-07": "Thiladhunmathee Uthuruburi",
        "MV-23": "Thiladhunmathee Dhekunuburi",
        "MV-24": "Miladhunmadulu Uthuruburi",
        "MV-US": "Mathi-Dhekunu",
        "MV-05": "Hahdhunmathi",
        "MV-08": "Kolhumadulu",
        "MV-CE": "Medhu",
        "MV-14": "Nilandhe Atholhu Uthuruburi",
        "MV-12": "Mulakatholhu",
        "MV-17": "Nilandhe Atholhu Dhekunuburi",
        "MV-NC": "Medhu-Uthuru",
        "MV-04": "Felidhu Atholhu",
        "MV-26": "Maale Atholhu",
        "MV-00": "Ari Atholhu Dhekunuburi",
        "MV-02": "Ari Atholhu Uthuruburi"
      },
      "ny": {
        "MW-C": "Chapakati",
        "MW-KS": "Kasungu",
        "MW-NK": "Nkhotakota",
        "MW-MC": "Mchinji",
        "MW-NI": "Ntchisi",
        "MW-SA": "Salima",
        "MW-DE": "Dedza",
        "MW-DO": "Dowa",
        "MW-NU": "Ntcheu",
        "MW-LI": "Lilongwe",
        "MW-N": "Chakumpoto",
        "MW-CT": "Chitipa",
        "MW-KR": "Karonga",
        "MW-LK": "Likoma",
        "MW-NB": "Nkhata Bay",
        "MW-MZ": "Mzimba",
        "MW-RU": "Rumphi",
        "MW-S": "Chakumwera",
        "MW-BA": "Balaka",
        "MW-CK": "Chikwawa",
        "MW-MG": "Mangochi",
        "MW-MW": "Mwanza",
        "MW-TH": "Thyolo",
        "MW-BL": "Blantyre",
        "MW-MH": "Machinga",
        "MW-NE": "Neno",
        "MW-CR": "Chiradzulu",
        "MW-MU": "Mulanje",
        "MW-NS": "Nsanje",
        "MW-PH": "Phalombe",
        "MW-ZO": "Zomba"
      },
      "fy": {
        "NL-FR": "Fryslân"
      },
      "nn": {
        "NO-04": "Hedmark",
        "NO-12": "Hordaland",
        "NO-14": "Sogn og Fjordane",
        "NO-22": "Jan Mayen (Arctic Region) ",
        "NO-02": "Akershus",
        "NO-03": "Oslo",
        "NO-05": "Oppland",
        "NO-07": "Vestfold",
        "NO-20": "Finnmark",
        "NO-01": "Østfold",
        "NO-10": "Vest-Agder",
        "NO-19": "Troms",
        "NO-08": "Telemark",
        "NO-06": "Buskerud",
        "NO-09": "Aust-Agder",
        "NO-16": "Sør-Trøndelag",
        "NO-17": "Nord-Trøndelag",
        "NO-21": "Svalbard (Arctic Region) ",
        "NO-18": "Nordland",
        "NO-11": "Rogaland",
        "NO-15": "Møre og Romsdal"
      },
      "nb": {
        "NO-04": "Hedmark",
        "NO-12": "Hordaland",
        "NO-14": "Sogn og Fjordane",
        "NO-22": "Jan Mayen (Arctic Region) ",
        "NO-02": "Akershus",
        "NO-03": "Oslo",
        "NO-05": "Oppland",
        "NO-07": "Vestfold",
        "NO-20": "Finnmark",
        "NO-01": "Østfold",
        "NO-10": "Vest-Agder",
        "NO-19": "Troms",
        "NO-08": "Telemark",
        "NO-06": "Buskerud",
        "NO-09": "Aust-Agder",
        "NO-16": "Sør-Trøndelag",
        "NO-17": "Nord-Trøndelag",
        "NO-21": "Svalbard (Arctic Region) ",
        "NO-18": "Nordland",
        "NO-11": "Rogaland",
        "NO-15": "Møre og Romsdal"
      },
      "se": {
        "NO-20": "Finnmárku",
        "NO-19": "Romsa"
      },
      "ne": {
        "NP-2": "Madhya Pashchimanchal",
        "NP-BH": "Bheri",
        "NP-KA": "Karnali",
        "NP-RA": "Rapti",
        "NP-3": "Pashchimanchal",
        "NP-GA": "Gandaki",
        "NP-LU": "Lumbini",
        "NP-DH": "Dhawalagiri",
        "NP-4": "Purwanchal",
        "NP-ME": "Mechi",
        "NP-KO": "Kosi",
        "NP-SA": "Sagarmatha",
        "NP-5": "Sudur Pashchimanchal",
        "NP-SE": "Seti",
        "NP-MA": "Mahakali",
        "NP-1": "Madhyamanchal",
        "NP-JA": "Janakpur",
        "NP-NA": "Narayani",
        "NP-BA": "Bagmati"
      },
      "na": {
        "NR-02": "Anabar",
        "NR-04": "Anibare",
        "NR-01": "Aiwo",
        "NR-03": "Anetan",
        "NR-08": "Denigomodu",
        "NR-10": "Ijuw",
        "NR-09": "Ewa",
        "NR-12": "Nibok",
        "NR-14": "Yaren",
        "NR-07": "Buada",
        "NR-13": "Uaboe",
        "NR-05": "Baitsi",
        "NR-06": "Boe",
        "NR-11": "Meneng"
      },
      "mi": {
        "NZ-CIT": "Wharekauri",
        "NZ-AUK": "Tāmaki-makau-rau",
        "NZ-GIS": "Tūranga nui a Kiwa",
        "NZ-HKB": "Te Matau a Māui",
        "NZ-MWT": "Manawatu Whanganui",
        "NZ-BOP": "Te Moana a Toi Te Huatahi",
        "NZ-CAN": "Waitaha",
        "NZ-NSN": "Whakatū",
        "NZ-NTL": "Te Tai tokerau",
        "NZ-OTA": "Ō Tākou",
        "NZ-STL": "Murihiku",
        "NZ-TKI": "Taranaki",
        "NZ-WKO": "Waikato",
        "NZ-WGN": "Te Whanga-nui-a-Tara",
        "NZ-WTC": "Te Taihau ā uru"
      },
      "qu": {
        "PE-ICA": "Ika",
        "PE-LIM": "Lima",
        "PE-MDD": "Mayutata",
        "PE-AYA": "Ayakuchu",
        "PE-HUC": "Wanuku",
        "PE-ANC": "Anqash",
        "PE-HUV": "Wankawillka",
        "PE-LAM": "Lampalliqi",
        "PE-TAC": "Taqna",
        "PE-APU": "Apurimaq",
        "PE-LAL": "Qispi kay",
        "PE-PAS": "Pasqu",
        "PE-PIU": "Piwra",
        "PE-CUS": "Qusqu",
        "PE-MOQ": "Muqiwa",
        "PE-PUN": "Punu",
        "PE-SAM": "San Martin",
        "PE-CAL": "Qallaw",
        "PE-AMA": "Amarumayu",
        "PE-ARE": "Ariqipa",
        "PE-TUM": "Tumpis",
        "PE-CAJ": "Kashamarka",
        "PE-JUN": "Hunin",
        "PE-LMA": "Lima llaqta suyu",
        "PE-LOR": "Luritu",
        "PE-UCA": "Ukayali"
      },
      "ay": {
        "PE-ICA": "Ika",
        "PE-LIM": "Lima",
        "PE-MDD": "Madre de Dios",
        "PE-AYA": "Ayaquchu",
        "PE-HUC": "Wanuku",
        "PE-ANC": "Ankashu",
        "PE-HUV": "Wankawelika",
        "PE-LAM": "Lambayeque",
        "PE-TAC": "Takna",
        "PE-APU": "Apurimaq",
        "PE-LAL": "La Libertad",
        "PE-PAS": "Pasqu",
        "PE-PIU": "Piura",
        "PE-CUS": "Kusku",
        "PE-MOQ": "Moqwegwa",
        "PE-PUN": "Puno",
        "PE-SAM": "San Martín",
        "PE-CAL": "Kallao",
        "PE-AMA": "Amasunu",
        "PE-ARE": "Arikipa",
        "PE-TUM": "Tumbes",
        "PE-CAJ": "Qajamarka",
        "PE-JUN": "Junin",
        "PE-LMA": "Lima hatun llaqta",
        "PE-LOR": "Luritu",
        "PE-UCA": "Ukayali"
      },
      "tl": {
        "PH-06": "Rehiyon ng Kanlurang Bisaya",
        "PH-GUI": "Gimaras",
        "PH-ILI": "Iloilo",
        "PH-NEC": "Kanlurang Negros",
        "PH-AKL": "Aklan",
        "PH-ANT": "Antike",
        "PH-CAP": "Kapis",
        "PH-11": "Rehiyon ng Dabaw",
        "PH-SAR": "Sarangani",
        "PH-DAO": "Silangang Dabaw",
        "PH-DAS": "Timog Dabaw",
        "PH-COM": "Lambak ng Kompostela",
        "PH-DAV": "Hilagang Dabaw",
        "PH-SCO": "Timog Kotabato",
        "PH-DVO": "Kanlurang Dabaw",
        "PH-13": "Rehiyon ng Karaga",
        "PH-SUN": "Hilagang Surigaw",
        "PH-SUR": "Timog Surigaw",
        "PH-AGS": "Timog Agusan",
        "PH-AGN": "Hilagang Agusan",
        "PH-DIN": "Pulo ng Dinagat",
        "PH-01": "Rehiyon ng Iloko",
        "PH-ILN": "Hilagang Iloko",
        "PH-LUN": "La Unyon",
        "PH-PAN": "Pangasinan",
        "PH-ILS": "Timog Iloko",
        "PH-03": "Rehiyon ng Gitnang Luson",
        "PH-BAN": "Bataan",
        "PH-PAM": "Pampanga",
        "PH-BUL": "Bulakan",
        "PH-NUE": "Nuweva Esiha",
        "PH-TAR": "Tarlak",
        "PH-ZMB": "Sambales",
        "PH-AUR": "Aurora",
        "PH-08": "Rehiyon ng Silangang Bisaya",
        "PH-LEY": "Leyte",
        "PH-SLE": "Katimogang Leyte",
        "PH-BIL": "Biliran",
        "PH-EAS": "Silangang Samar",
        "PH-NSA": "Hilagang Samar",
        "PH-WSA": "Samar",
        "PH-10": "Rehiyon ng Hilagang Mindanaw",
        "PH-MSR": "Silangang Misamis",
        "PH-MSC": "Kanlurang Misamis",
        "PH-CAM": "Kamigin",
        "PH-BUK": "Bukidnon",
        "PH-00": "Pambansang Punong Rehiyon",
        "PH-02": "Rehiyon ng Lambak ng Kagayan",
        "PH-CAG": "Kagayan",
        "PH-NUV": "Nuweva Biskaya",
        "PH-QUI": "Kirino",
        "PH-ISA": "Isabela",
        "PH-BTN": "Batanes",
        "PH-07": "Rehiyon ng Gitnang Bisaya",
        "PH-CEB": "Sebu",
        "PH-BOH": "Bohol",
        "PH-NER": "Silangang Negros",
        "PH-SIG": "Sikihor",
        "PH-05": "Rehiyon ng Bikol",
        "PH-CAT": "Katanduwanes",
        "PH-CAN": "Hilagang Kamarines",
        "PH-SOR": "Sorsogon",
        "PH-ALB": "Albay",
        "PH-CAS": "Timog Kamarines",
        "PH-MAS": "Masbate",
        "PH-09": "Rehiyon ng Tangway ng Sambuwangga",
        "PH-ZAS": "Timog Sambuwangga",
        "PH-ZAN": "Hilagang Sambuwangga",
        "PH-ZSI": "Sambuwangga Sibugay",
        "PH-BAS": "Basilan",
        "PH-12": "Rehiyon ng Soccsksargen",
        "PH-LAN": "Hilagang Lanaw",
        "PH-SUK": "Sultan Kudarat",
        "PH-NCO": "Kotabato",
        "PH-14": "Nagsasariling Rehiyon ng Muslim sa Mindanaw",
        "PH-LAS": "Timog Lanaw",
        "PH-MAG": "Magindanaw",
        "PH-TAW": "Tawi-Tawi",
        "PH-SLU": "Sulu",
        "PH-15": "Rehiyon ng Administratibo ng Kordilyera",
        "PH-ABR": "Abra",
        "PH-BEN": "Benget",
        "PH-MOU": "Lalawigang Bulubundukin",
        "PH-APA": "Apayaw",
        "PH-IFU": "Ipugaw",
        "PH-KAL": "Kalinga",
        "PH-40": "Rehiyon ng Calabarzon",
        "PH-RIZ": "Risal",
        "PH-BTG": "Batangas",
        "PH-CAV": "Kabite",
        "PH-LAG": "Laguna",
        "PH-QUE": "Keson",
        "PH-41": "Rehiyon ng Mimaropa",
        "PH-MDR": "Silangang Mindoro",
        "PH-PLW": "Palawan",
        "PH-ROM": "Romblon",
        "PH-MAD": "Marinduke",
        "PH-MDC": "Kanlurang Mindoro"
      },
      "ur": {
        "PK-GB": "Gilgit-Baltistān",
        "PK-SD": "Sindh",
        "PK-KP": "Khaībar Pakhtūnkhwā",
        "PK-PB": "Panjāb",
        "PK-JK": "Āzād Jammūñ o Kashmīr",
        "PK-BA": "Balōchistān",
        "PK-IS": "Islāmābād",
        "RU-PSK": "Pskovskaja oblast'",
        "RU-PRI": "Primorskij kraj",
        "RU-ROS": "Rostovskaja oblast'"
      },
      "pl": {
        "PL-DS": "Dolnośląskie",
        "PL-PD": "Podlaskie",
        "PL-SK": "Świętokrzyskie",
        "PL-SL": "Śląskie",
        "PL-WN": "Warmińsko-mazurskie",
        "PL-ZP": "Zachodniopomorskie",
        "PL-LU": "Lubelskie",
        "PL-MA": "Małopolskie",
        "PL-MZ": "Mazowieckie",
        "PL-LB": "Lubuskie",
        "PL-PK": "Podkarpackie",
        "PL-PM": "Pomorskie",
        "PL-KP": "Kujawsko-pomorskie",
        "PL-OP": "Opolskie",
        "PL-WP": "Wielkopolskie",
        "PL-LD": "Łódzkie"
      },
      "pau": {
        "PW-212": "Melekeok",
        "PW-214": "Ngaraard",
        "PW-224": "Ngatpang",
        "PW-228": "Ngiwal",
        "PW-218": "Ngarchelong",
        "PW-004": "Airai",
        "PW-227": "Ngeremlengui",
        "PW-350": "Peleliu",
        "PW-002": "Aimeliik",
        "PW-010": "Angaur",
        "PW-150": "Koror",
        "PW-222": "Ngardmau",
        "PW-226": "Ngchesar",
        "PW-370": "Sonsorol",
        "PW-050": "Hatohobei",
        "PW-100": "Kayangel"
      },
      "rw": {
        "RW-01": "Umujyi wa Kigali",
        "RW-02": "Iburasirazuba",
        "RW-04": "Iburengerazuba",
        "RW-05": "Amajyepfo",
        "RW-03": "Amajyaruguru"
      },
      "crs": {
        "SC-08": "Bovalon",
        "SC-19": "Plezans",
        "SC-12": "Glasi",
        "SC-13": "Grand Ans Mae",
        "SC-04": "O Kap",
        "SC-17": "Mon Bikston",
        "SC-15": "Ladig",
        "SC-20": "Pwent Lari",
        "SC-21": "Porglo",
        "SC-23": "Takamaka",
        "SC-05": "Ans Royal",
        "SC-07": "Be Sent Ann",
        "SC-10": "Belonm",
        "SC-11": "Kaskad",
        "SC-16": "Larivyer Anglez",
        "SC-18": "Mon Fleri",
        "SC-25": "Ros Kaiman",
        "SC-01": "Ans o Pen",
        "SC-02": "Ans Bwalo",
        "SC-09": "Beler",
        "SC-14": "Grand Ans Pralen",
        "SC-22": "Sen Lwi",
        "SC-03": "Ans Etwal",
        "SC-06": "Be Lazar",
        "SC-24": "Lemamel"
      },
      "sl": {
        "SI-036": "Idrija",
        "SI-047": "Kobilje",
        "SI-061": "Ljubljana",
        "SI-086": "Odranci",
        "SI-092": "Podčetrtek",
        "SI-093": "Podvelka",
        "SI-102": "Radovljica",
        "SI-107": "Rogatec",
        "SI-129": "Trbovlje",
        "SI-137": "Vitanje",
        "SI-149": "Bistrica ob Sotli",
        "SI-150": "Bloke",
        "SI-152": "Cankova",
        "SI-154": "Dobje",
        "SI-163": "Jezersko",
        "SI-172": "Podlehnik",
        "SI-178": "Selnica ob Dravi",
        "SI-201": "Renče-Vogrsko",
        "SI-001": "Ajdovščina",
        "SI-019": "Divača",
        "SI-025": "Dravograd",
        "SI-027": "Gorenja vas-Poljane",
        "SI-032": "Grosuplje",
        "SI-034": "Hrastnik",
        "SI-039": "Ivančna Gorica",
        "SI-040": "Izola",
        "SI-053": "Kranjska Gora",
        "SI-058": "Lenart",
        "SI-060": "Litija",
        "SI-084": "Nova Gorica",
        "SI-091": "Pivka",
        "SI-099": "Radeče",
        "SI-104": "Ribnica",
        "SI-121": "Škocjan",
        "SI-135": "Videm",
        "SI-157": "Dolenjske Toplice",
        "SI-170": "Mirna Peč",
        "SI-171": "Oplotnica",
        "SI-177": "Ribnica na Pohorju",
        "SI-179": "Sodražica",
        "SI-181": "Sveta Ana",
        "SI-184": "Tabor",
        "SI-186": "Trzin",
        "SI-198": "Makole",
        "SI-200": "Poljčane",
        "SI-203": "Straža",
        "SI-211": "Šentrupert",
        "SI-004": "Bohinj",
        "SI-006": "Bovec",
        "SI-009": "Brežice",
        "SI-015": "Črenšovci",
        "SI-031": "Gornji Petrovci",
        "SI-041": "Jesenice",
        "SI-046": "Kobarid",
        "SI-048": "Kočevje",
        "SI-066": "Loški Potok",
        "SI-069": "Majšperk",
        "SI-071": "Medvode",
        "SI-072": "Mengeš",
        "SI-076": "Mislinja",
        "SI-078": "Moravske Toplice",
        "SI-090": "Piran",
        "SI-097": "Puconci",
        "SI-100": "Radenci",
        "SI-103": "Ravne na Koroškem",
        "SI-120": "Šentjur",
        "SI-136": "Vipava",
        "SI-156": "Dobrovnik",
        "SI-161": "Hodoš",
        "SI-173": "Polzela",
        "SI-182": "Sveti Andraž v Slovenskih Goricah",
        "SI-190": "Žalec",
        "SI-192": "Žirovnica",
        "SI-010": "Tišina",
        "SI-011": "Celje",
        "SI-013": "Cerknica",
        "SI-020": "Dobrepolje",
        "SI-021": "Dobrova-Polhov Gradec",
        "SI-023": "Domžale",
        "SI-029": "Gornja Radgona",
        "SI-038": "Ilirska Bistrica",
        "SI-049": "Komen",
        "SI-052": "Kranj",
        "SI-063": "Ljutomer",
        "SI-073": "Metlika",
        "SI-077": "Moravče",
        "SI-083": "Nazarje",
        "SI-089": "Pesnica",
        "SI-096": "Ptuj",
        "SI-110": "Sevnica",
        "SI-115": "Starše",
        "SI-118": "Šentilj",
        "SI-123": "Škofljica",
        "SI-124": "Šmarje pri Jelšah",
        "SI-130": "Trebnje",
        "SI-131": "Tržič",
        "SI-132": "Turnišče",
        "SI-133": "Velenje",
        "SI-146": "Železniki",
        "SI-165": "Kostel",
        "SI-176": "Razkrižje",
        "SI-185": "Trnovska Vas",
        "SI-188": "Veržej",
        "SI-189": "Vransko",
        "SI-191": "Žetale",
        "SI-194": "Šmartno pri Litiji",
        "SI-196": "Cirkulane",
        "SI-204": "Sveta Trojica v Slovenskih Goricah",
        "SI-205": "Sveti Tomaž",
        "SI-208": "Log-Dragomer",
        "SI-212": "Mirna",
        "SI-007": "Brda",
        "SI-016": "Črna na Koroškem",
        "SI-022": "Dol pri Ljubljani",
        "SI-028": "Gorišnica",
        "SI-045": "Kidričevo",
        "SI-051": "Kozje",
        "SI-065": "Loška Dolina",
        "SI-067": "Luče",
        "SI-068": "Lukovica",
        "SI-074": "Mežica",
        "SI-079": "Mozirje",
        "SI-080": "Murska Sobota",
        "SI-082": "Naklo",
        "SI-085": "Novo Mesto",
        "SI-088": "Osilnica",
        "SI-109": "Semič",
        "SI-116": "Sveti Jurij",
        "SI-125": "Šmartno ob Paki",
        "SI-126": "Šoštanj",
        "SI-142": "Zagorje ob Savi",
        "SI-148": "Benedikt",
        "SI-153": "Cerkvenjak",
        "SI-159": "Hajdina",
        "SI-162": "Horjul",
        "SI-166": "Križevci",
        "SI-174": "Prebold",
        "SI-180": "Solčava",
        "SI-199": "Mokronog-Trebelno",
        "SI-012": "Cerklje na Gorenjskem",
        "SI-014": "Cerkno",
        "SI-017": "Črnomelj",
        "SI-030": "Gornji Grad",
        "SI-059": "Lendava",
        "SI-087": "Ormož",
        "SI-098": "Rače-Fram",
        "SI-101": "Radlje ob Dravi",
        "SI-106": "Rogaška Slatina",
        "SI-108": "Ruše",
        "SI-117": "Šenčur",
        "SI-119": "Šentjernej",
        "SI-127": "Štore",
        "SI-138": "Vodice",
        "SI-139": "Vojnik",
        "SI-140": "Vrhnika",
        "SI-155": "Dobrna",
        "SI-158": "Grad",
        "SI-164": "Komenda",
        "SI-183": "Šempeter-Vrtojba",
        "SI-197": "Kosanjevica na Krki",
        "SI-206": "Šmarješke Toplice",
        "SI-207": "Gorje",
        "SI-209": "Rečica ob Savinji",
        "SI-002": "Beltinci",
        "SI-026": "Duplek",
        "SI-035": "Hrpelje-Kozina",
        "SI-043": "Kamnik",
        "SI-050": "Koper",
        "SI-054": "Krško",
        "SI-055": "Kungota",
        "SI-056": "Kuzma",
        "SI-062": "Ljubno",
        "SI-064": "Logatec",
        "SI-105": "Rogašovci",
        "SI-112": "Slovenj Gradec",
        "SI-122": "Škofja Loka",
        "SI-128": "Tolmin",
        "SI-141": "Vuzenica",
        "SI-144": "Zreče",
        "SI-167": "Lovrenc na Pohorju",
        "SI-169": "Miklavž na Dravskem Polju",
        "SI-193": "Žužemberk",
        "SI-202": "Središče ob Dravi",
        "SI-213": "Ankaran",
        "SI-003": "Bled",
        "SI-005": "Borovnica",
        "SI-008": "Brezovica",
        "SI-018": "Destrnik",
        "SI-024": "Dornava",
        "SI-033": "Šalovci",
        "SI-037": "Ig",
        "SI-042": "Juršinci",
        "SI-044": "Kanal",
        "SI-057": "Laško",
        "SI-070": "Maribor",
        "SI-075": "Miren-Kostanjevica",
        "SI-081": "Muta",
        "SI-094": "Postojna",
        "SI-095": "Preddvor",
        "SI-111": "Sežana",
        "SI-113": "Slovenska Bistrica",
        "SI-114": "Slovenske Konjice",
        "SI-134": "Velike Lašče",
        "SI-143": "Zavrč",
        "SI-147": "Žiri",
        "SI-151": "Braslovče",
        "SI-160": "Hoče-Slivnica",
        "SI-168": "Markovci",
        "SI-175": "Prevalje",
        "SI-187": "Velika Polana",
        "SI-195": "Apače",
        "SI-210": "Sveti Jurij v Slovenskih Goricah"
      },
      "sk": {
        "SK-PV": "Prešovský kraj",
        "SK-TA": "Trnavský kraj",
        "SK-KI": "Košický kraj",
        "SK-BC": "Banskobystrický kraj",
        "SK-NI": "Nitriansky kraj",
        "SK-ZI": "Žilinský kraj",
        "SK-BL": "Bratislavský kraj",
        "SK-TC": "Trenčiansky kraj"
      },
      "so": {
        "SO-BY": "Bay",
        "SO-JD": "Jubbada Dhexe",
        "SO-MU": "Mudug",
        "SO-SA": "Sanaag",
        "SO-SD": "Shabeellaha Dhexe",
        "SO-SO": "Sool",
        "SO-WO": "Woqooyi Galbeed",
        "SO-BK": "Bakool",
        "SO-BR": "Bari",
        "SO-HI": "Hiiraan",
        "SO-SH": "Shabeellaha Hoose",
        "SO-BN": "Banaadir",
        "SO-AW": "Awdal",
        "SO-GE": "Gedo",
        "SO-JH": "Jubbada Hoose",
        "SO-NU": "Nugaal",
        "SO-TO": "Togdheer",
        "SO-GA": "Galguduud"
      },
      "ss": {
        "SZ-MA": "Manzini",
        "SZ-SH": "Shiselweni",
        "SZ-LU": "Lubombo",
        "SZ-HH": "Hhohho",
        "ZA-NL": "KwaZulu-Natali",
        "ZA-GT": "Gauteng",
        "ZA-MP": "Mpumalanga",
        "ZA-LP": "Limpopo"
      },
      "th": {
        "TH-16": "Lop Buri",
        "TH-24": "Chachoengsao",
        "TH-35": "Yasothon",
        "TH-39": "Nong Bua Lam Phu",
        "TH-46": "Kalasin",
        "TH-48": "Nakhon Phanom",
        "TH-54": "Phrae",
        "TH-66": "Phichit",
        "TH-80": "Nakhon Si Thammarat",
        "TH-S": "Phatthaya",
        "TH-14": "Phra Nakhon Si Ayutthaya",
        "TH-20": "Chon Buri",
        "TH-47": "Sakon Nakhon",
        "TH-49": "Mukdahan",
        "TH-52": "Lampang",
        "TH-58": "Mae Hong Son",
        "TH-61": "Uthai Thani",
        "TH-85": "Ranong",
        "TH-23": "Trat",
        "TH-27": "Sa Kaeo",
        "TH-37": "Amnat Charoen",
        "TH-42": "Loei",
        "TH-45": "Roi Et",
        "TH-72": "Suphan Buri",
        "TH-74": "Samut Sakhon",
        "TH-84": "Surat Thani",
        "TH-13": "Pathum Thani",
        "TH-15": "Ang Thong",
        "TH-18": "Chai Nat",
        "TH-30": "Nakhon Ratchasima",
        "TH-32": "Surin",
        "TH-71": "Kanchanaburi",
        "TH-73": "Nakhon Pathom",
        "TH-83": "Phuket",
        "TH-86": "Chumphon",
        "TH-91": "Satun",
        "TH-38": "Bueng Kan",
        "TH-21": "Rayong",
        "TH-22": "Chanthaburi",
        "TH-55": "Nan",
        "TH-56": "Phayao",
        "TH-60": "Nakhon Sawan",
        "TH-65": "Phitsanulok",
        "TH-67": "Phetchabun",
        "TH-94": "Pattani",
        "TH-95": "Yala",
        "TH-11": "Samut Prakan",
        "TH-17": "Sing Buri",
        "TH-19": "Saraburi",
        "TH-34": "Ubon Ratchathani",
        "TH-36": "Chaiyaphum",
        "TH-41": "Udon Thani",
        "TH-44": "Maha Sarakham",
        "TH-75": "Samut Songkhram",
        "TH-90": "Songkhla",
        "TH-96": "Narathiwat",
        "TH-12": "Nonthaburi",
        "TH-25": "Prachin Buri",
        "TH-26": "Nakhon Nayok",
        "TH-31": "Buri Ram",
        "TH-33": "Si Sa Ket",
        "TH-40": "Khon Kaen",
        "TH-50": "Chiang Mai",
        "TH-53": "Uttaradit",
        "TH-62": "Kamphaeng Phet",
        "TH-64": "Sukhothai",
        "TH-76": "Phetchaburi",
        "TH-81": "Krabi",
        "TH-10": "Krung Thep Maha Nakhon",
        "TH-43": "Nong Khai",
        "TH-51": "Lamphun",
        "TH-57": "Chiang Rai",
        "TH-63": "Tak",
        "TH-70": "Ratchaburi",
        "TH-77": "Prachuap Khiri Khan",
        "TH-82": "Phangnga",
        "TH-92": "Trang",
        "TH-93": "Phatthalung"
      },
      "tg": {
        "TJ-KT": "Khatlon",
        "TJ-SU": "Sughd",
        "TJ-DU": "Dushanbe",
        "TJ-GB": "Kŭhistoni Badakhshon",
        "TJ-RA": "nohiyahoi tobei jumhurí"
      },
      "tet": {
        "TL-ER": "Ermera",
        "TL-BO": "Bobonaru",
        "TL-CO": "Kovalima",
        "TL-MF": "Manufahi",
        "TL-AN": "Ainaru",
        "TL-BA": "Baukau",
        "TL-LA": "Lautém",
        "TL-VI": "Vikeke",
        "TL-DI": "Díli",
        "TL-LI": "Likisá",
        "TL-MT": "Manatutu",
        "TL-AL": "Aileu",
        "TL-OE": "Oekusi-Ambenu"
      },
      "tk": {
        "TM-B": "Balkan",
        "TM-A": "Ahal",
        "TM-S": "Aşgabat",
        "TM-D": "Daşoguz",
        "TM-L": "Lebap",
        "TM-M": "Mary"
      },
      "to": {
        "TO-02": "Ha'apai",
        "TO-03": "Niuas",
        "TO-04": "Tongatapu",
        "TO-01": "'Eua",
        "TO-05": "Vava'u"
      },
      "sw": {
        "TZ-01": "Arusha",
        "TZ-12": "Lindi",
        "TZ-15": "Mjini Magharibi",
        "TZ-23": "Singida",
        "TZ-19": "Pwani",
        "TZ-25": "Tanga",
        "TZ-10": "Kusini Pemba",
        "TZ-17": "Mtwara",
        "TZ-11": "Kusini Unguja",
        "TZ-21": "Ruvuma",
        "TZ-24": "Tabora",
        "TZ-27": "Geita",
        "TZ-28": "Katavi",
        "TZ-29": "Njombe",
        "TZ-30": "Simiyu",
        "TZ-03": "Dodoma",
        "TZ-08": "Kigoma",
        "TZ-09": "Kilimanjaro",
        "TZ-16": "Morogoro",
        "TZ-18": "Mwanza",
        "TZ-13": "Mara",
        "TZ-22": "Shinyanga",
        "TZ-06": "Kaskazini Pemba",
        "TZ-14": "Mbeya",
        "TZ-20": "Rukwa",
        "TZ-26": "Manyara",
        "TZ-02": "Dar es Salaam",
        "TZ-04": "Iringa",
        "TZ-05": "Kagera",
        "TZ-07": "Kaskazini Unguja"
      },
      "uk": {
        "UA-05": "Vinnytska oblast",
        "UA-63": "Kharkivska oblast",
        "UA-74": "Chernihivska oblast",
        "UA-12": "Dnipropetrovska oblast",
        "UA-48": "Mykolaivska oblast",
        "UA-56": "Rivnenska oblast",
        "UA-71": "Cherkaska oblast",
        "UA-26": "Ivano-Frankivska oblast",
        "UA-51": "Odeska oblast",
        "UA-77": "Chernivetska oblast",
        "UA-40": "Sevastopol",
        "UA-53": "Poltavska oblast",
        "UA-14": "Donetska oblast",
        "UA-43": "Avtonomna Respublika Krym",
        "UA-46": "Lvivska oblast",
        "UA-18": "Zhytomyrska oblast",
        "UA-30": "Kyiv",
        "UA-68": "Khmelnytska oblast",
        "UA-07": "Volynska oblast",
        "UA-61": "Ternopilska oblast",
        "UA-65": "Khersonska oblast",
        "UA-09": "Luhanska oblast",
        "UA-21": "Zakarpatska oblast",
        "UA-23": "Zaporizka oblast",
        "UA-32": "Kyivska oblast",
        "UA-35": "Kirovohradska oblast",
        "UA-59": "Sumska oblast"
      },
      "uz": {
        "UZ-JI": "Jizzax",
        "UZ-NG": "Namangan",
        "UZ-FA": "Farg‘ona",
        "UZ-QA": "Qashqadaryo",
        "UZ-AN": "Andijon",
        "UZ-QR": "Qoraqalpog‘iston Respublikasi",
        "UZ-SA": "Samarqand",
        "UZ-SI": "Sirdaryo",
        "UZ-SU": "Surxondaryo",
        "UZ-XO": "Xorazm",
        "UZ-TK": "Toshkent",
        "UZ-TO": "Toshkent",
        "UZ-BU": "Buxoro",
        "UZ-NW": "Navoiy"
      },
      "vi": {
        "VN-06": "Yên Bái",
        "VN-18": "Ninh Bình",
        "VN-22": "Nghệ An",
        "VN-51": "Trà Vinh",
        "VN-63": "Hà Nam",
        "VN-69": "Thái Nguyên",
        "VN-32": "Phú Yên",
        "VN-36": "Ninh Thuận",
        "VN-52": "Sóc Trăng",
        "VN-54": "Bắc Giang",
        "VN-56": "Bắc Ninh",
        "VN-58": "Bình Phước",
        "VN-73": "Hậu Giang",
        "VN-02": "Lào Cai",
        "VN-13": "Quảng Ninh",
        "VN-20": "Thái Bình",
        "VN-29": "Quảng Ngãi",
        "VN-66": "Hưng Yên",
        "VN-68": "Phú Thọ",
        "VN-23": "Hà Tĩnh",
        "VN-26": "Thừa Thiên-Huế",
        "VN-33": "Đắk Lắk",
        "VN-34": "Khánh Hòa",
        "VN-44": "An Giang",
        "VN-45": "Đồng Tháp",
        "VN-05": "Sơn La",
        "VN-14": "Hòa Bình",
        "VN-25": "Quảng Trị",
        "VN-47": "Kiến Giang",
        "VN-61": "Hải Dương",
        "VN-67": "Nam Định",
        "VN-HP": "Hai Phong",
        "VN-SG": "Ho Chi Minh",
        "VN-09": "Lạng Sơn",
        "VN-21": "Thanh Hóa",
        "VN-24": "Quảng Bình",
        "VN-40": "Bình Thuận",
        "VN-43": "Bà Rịa - Vũng Tàu",
        "VN-46": "Tiền Giang",
        "VN-50": "Bến Tre",
        "VN-55": "Bạc Liêu",
        "VN-59": "Cà Mau",
        "VN-70": "Vĩnh Phúc",
        "VN-07": "Tuyên Quang",
        "VN-30": "Gia Lai",
        "VN-35": "Lâm Đồng",
        "VN-37": "Tây Ninh",
        "VN-39": "Đồng Nai",
        "VN-57": "Bình Dương",
        "VN-72": "Đắk Nông",
        "VN-CT": "Can Tho",
        "VN-HN": "Ha Noi",
        "VN-01": "Lai Châu",
        "VN-03": "Hà Giang",
        "VN-04": "Cao Bằng",
        "VN-27": "Quảng Nam",
        "VN-28": "Kon Tum",
        "VN-31": "Bình Định",
        "VN-41": "Long An",
        "VN-49": "Vĩnh Long",
        "VN-53": "Bắc Kạn",
        "VN-71": "Điện Biên",
        "VN-DN": "Da Nang"
      },
      "sm": {
        "WS-SA": "Satupa'itea",
        "WS-AA": "A'ana",
        "WS-FA": "Fa'asaleleaga",
        "WS-GE": "Gaga'emauga",
        "WS-AT": "Atua",
        "WS-PA": "Palauli",
        "WS-TU": "Tuamasaga",
        "WS-VF": "Va'a-o-Fonoti",
        "WS-GI": "Gagaifomauga",
        "WS-AL": "Aiga-i-le-Tai",
        "WS-VS": "Vaisigano"
      },
      "zu": {
        "ZA-NL": "KwaZulu-Natali",
        "ZA-WC": "Ntshonalanga-Kapa",
        "ZA-NC": "Nyakatho-Kapa",
        "ZA-GT": "Gauteng",
        "ZA-EC": "Mpumalanga-Kapa",
        "ZA-MP": "Mpumalanga",
        "ZA-FS": "Fuleyisitata",
        "ZA-LP": "Limpopo",
        "ZA-NW": "Nyakatho-Ntshonalanga"
      },
      "nr": {
        "ZA-NL": "iKwaZulu-Natal",
        "ZA-WC": "iTjhingalanga-Kapa",
        "ZA-NC": "iTlhagwini-Kapa",
        "ZA-GT": "iGauteng",
        "ZA-EC": "iPumalanga-Kapa",
        "ZA-MP": "iMpumalanga",
        "ZA-FS": "iFreyistata",
        "ZA-LP": "Limpopo",
        "ZA-NW": "iTlhagwini-Tjhingalanga"
      },
      "ts": {
        "ZA-NL": "Kwazulu-Natal",
        "ZA-WC": "Kapa-Vupeladyambu",
        "ZA-NC": "Kapa-N'walungu",
        "ZA-GT": "Gauteng",
        "ZA-EC": "Kapa-Vuxa",
        "ZA-MP": "Mpumalanga",
        "ZA-FS": "Free State",
        "ZA-LP": "Limpopo",
        "ZA-NW": "N'walungu-Vupeladyambu"
      },
      "ve": {
        "ZA-NL": "HaZulu-Natal",
        "ZA-WC": "Kapa Vhukovhela",
        "ZA-NC": "Kapa Devhula",
        "ZA-GT": "Gauteng",
        "ZA-EC": "Kapa Vhubvaḓuvha",
        "ZA-MP": "Mpumalanga",
        "ZA-FS": "Fureisitata",
        "ZA-LP": "Vhembe"
      },
      "tn": {
        "ZA-NL": "KwaZulu-Natal",
        "ZA-WC": "Kapa Bophirima",
        "ZA-NC": "Kapa Bokone",
        "ZA-GT": "Gauteng",
        "ZA-EC": "Kapa Botlhaba",
        "ZA-MP": "Mpumalanga",
        "ZA-FS": "Foreisetata",
        "ZA-LP": "Limpopo",
        "ZA-NW": "Bokone Bophirima"
      },
      "xh": {
        "ZA-NL": "KwaZulu-Natala",
        "ZA-WC": "Ntshona-Koloni",
        "ZA-NC": "Mntla-Koloni",
        "ZA-GT": "Rhawuti",
        "ZA-EC": "Mpuma-Koloni",
        "ZA-MP": "Mpumalanga",
        "ZA-FS": "Freyistata",
        "ZA-LP": "Limpopo",
        "ZA-NW": "Mntla-Ntshona"
      },
      "af": {
        "ZA-NL": "KwaZulu-Natal",
        "ZA-WC": "Wes-Kaap",
        "ZA-NC": "Noord-Kaap",
        "ZA-GT": "Gauteng",
        "ZA-EC": "Oos-Kaap",
        "ZA-MP": "Mpumalanga",
        "ZA-FS": "Vrystaat",
        "ZA-LP": "Limpopo",
        "ZA-NW": "Noordwes"
      },
      "nso": {
        "ZA-NL": "GaZulu-Natala",
        "ZA-WC": "Kapa Bodikela",
        "ZA-NC": "Kapa Leboya",
        "ZA-GT": "Gauteng",
        "ZA-EC": "Kapa Bohlabela",
        "ZA-MP": "Mpumalanga",
        "ZA-FS": "Freistata",
        "ZA-LP": "Limpopo",
        "ZA-NW": "Lebowa Bodikela"
      }
    }
  };
  _exports.default = _default;
});