define("huvepharma-aviapp-admin/templates/components/form/validated-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3w6yucn0",
    "block": "[[[41,[28,[37,1],[[33,2],\"checkbox\"],null],[[[1,\"  \"],[10,\"label\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"type\",\"id\",\"checked\"],[\"checkbox\",[33,4],[33,5]]]]],[1,\"\\n    \"],[10,\"svg\"],[15,0,[29,[\"state \",[52,[33,6],\"error\"]]]],[12],[10,\"use\"],[14,\"xlink:href\",\"#check\",\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"label\"],[15,\"for\",[29,[[36,4]]]],[12],[1,\"\\n    \"],[1,[34,7]],[1,\" \"],[10,3],[15,6,[29,[\"assets/\",[36,8],\".pdf\"]]],[14,\"target\",\"_blank\"],[12],[1,[34,9]],[13],[1,\"\\n    \"],[10,2],[14,0,\"info\"],[12],[1,[34,10]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"label\"],[15,\"for\",[29,[[36,4]]]],[12],[1,[34,9]],[41,[33,11],[[[1,\" \"],[10,\"sup\"],[12],[1,\"*\"],[13]],[]],null],[13],[1,\"\\n  \"],[41,[33,6],[[[10,0],[14,0,\"error-description\"],[12],[1,[34,12]],[13]],[]],null],[1,\"\\n\\n\"],[41,[51,[33,2]],[[[1,\"    \"],[1,[28,[35,3],null,[[\"id\",\"value\",\"class\"],[[33,4],[33,5],[28,[37,14],[[52,[33,6],\"error-border\"],\" \"],null]]]]],[1,\"\\n\"]],[]],null],[41,[28,[37,1],[[33,2],\"country-select\"],null],[[[1,\"    \"],[1,[28,[35,15],null,[[\"countryCode\",\"selectedValue\",\"id\",\"class\"],[[33,5],[33,5],[33,4],[28,[37,14],[[52,[33,6],\"error-border\"],\" \"],null]]]]],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"if\",\"is-equal\",\"type\",\"input\",\"uniqueId\",\"value\",\"hasError\",\"prefix\",\"file\",\"label\",\"description\",\"required\",\"error\",\"unless\",\"concat\",\"form/huve-country-select\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/components/form/validated-input.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});