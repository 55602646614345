define("huvepharma-aviapp-admin/components/company-switch", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    session: (0, _service.inject)(),
    actions: {
      /**
       * Choose the company
       */
      chooseCompany: function chooseCompany(companyId) {
        if (companyId) {
          this.switchToCompany(companyId);
        }

        this.hideCompanySwitch();
      },

      /**
       * Cancel button pressed
       */
      cancel: function cancel() {
        if (this.justLoggedIn) {
          this.logout();
        } else {
          this.set('justLoggedIn', false);
          this.hideCompanySwitch();
        }
      }
    }
  });

  _exports.default = _default;
});