define("huvepharma-aviapp-admin/controllers/programs/edit-other-program", ["exports", "@ember/object", "huvepharma-aviapp-admin/controllers/programs/add-other-program"], function (_exports, _object, _addOtherProgram) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _addOtherProgram.default.extend({
    headerTitle: 'programs_edit_other_program',
    programType: 'otherProgram',
    init: function init() {
      this._super();

      (0, _object.set)(this, 'flocks', []);
    },
    actions: {
      close: function close() {
        (0, _object.get)(this, 'model.otherProgram').rollbackAttributes();
        (0, _object.set)(this, 'selectedFlocks', []);
        this.transitionToRoute('programs');
      }
    }
  });

  _exports.default = _default;
});