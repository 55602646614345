define("huvepharma-aviapp-admin/utils/i18n/missing-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function hasUpperCase(str) {
    return /[A-Z]/.test(str);
  }

  function _default(locale, key) {
    if (hasUpperCase(key)) {
      return this.t(key.toLowerCase());
    }

    return key;
  }
});