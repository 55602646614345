define("huvepharma-aviapp-admin/models/request-access", ["exports", "ember-data", "huvepharma-aviapp-admin/base/models/request-access"], function (_exports, _emberData, _requestAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RequestAccess = _requestAccess.default;
  RequestAccess.reopen({
    isCreatedByMaster: _emberData.default.attr('boolean', {
      defaultValue: false
    })
  });
  var _default = RequestAccess;
  _exports.default = _default;
});