define("huvepharma-aviapp-admin/base/enum/continents/en-pl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AF",
    "continent": "Afryka"
  }, {
    "code": "NA",
    "continent": "Ameryka Północna"
  }, {
    "code": "OC",
    "continent": "Oceania"
  }, {
    "code": "AN",
    "continent": "Antarktyda"
  }, {
    "code": "AS",
    "continent": "Azja"
  }, {
    "code": "EU",
    "continent": "Europa"
  }, {
    "code": "SA",
    "continent": "Ameryka Południowa"
  }];
  _exports.default = _default;
});