define("huvepharma-aviapp-admin/templates/components/button-spinner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "51E+4Ouw",
    "block": "[[[1,\"  \"],[10,1],[14,0,\"label\"],[12],[1,[34,0]],[13],[1,\"\\n  \"],[10,0],[14,0,\"spinner-icon\"],[12],[13],[1,\"\\n\"]],[],false,[\"label\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/components/button-spinner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});