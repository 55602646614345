define("huvepharma-aviapp-admin/controllers/application", ["exports", "@ember/controller", "@ember/object", "@ember/service", "rsvp", "jquery", "ember-data", "semver", "huvepharma-aviapp-admin/utils/fingerprint", "huvepharma-aviapp-admin/config/environment"], function (_exports, _controller, _object, _service, _rsvp, _jquery, _emberData, _semver, _fingerprint, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    metrics: (0, _service.inject)(),
    showLogoutWarning: false,
    headerHidden: false,

    /**
     * Initialisation
     */
    init: function init() {
      this._super.apply(this, arguments); // Set dimension for Google Analytics


      (0, _object.set)(this, 'metrics.context.dimension4', (0, _fingerprint.isTablet)() ? 'tablet' : 'desktop');
    },

    /**
     * On Route Changed, check if the version number of the API and the APP differ,
     * if so, an update is available!
     */
    showUpdateAvailable: (0, _object.computed)('target.currentRouteName', function () {
      return _emberData.default.PromiseObject.create({
        promise: new _rsvp.Promise(function (resolve) {
          // Stupid jQuery non Promise A+ compliant promises
          _jquery.default.get(_environment.default.APP.api.baseUrl).done(function (info) {
            return resolve({
              status: _semver.default.diff(_environment.default.APP.version, info.release) !== 'patch' && _semver.default.diff(_environment.default.APP.version, info.release) !== null
            });
          }).fail(function () {
            return resolve({
              status: false
            });
          });
        })
      });
    }),
    actions: {
      /**
       * Logout
       */
      logout: function logout() {
        this.transitionToRoute('session.logout');
      },

      /**
       * Show the logout warning if a user is about to update the app
       */
      toggleLogoutWarning: function toggleLogoutWarning() {
        this.toggleProperty('showLogoutWarning');
      },

      /**
       * Reload and fetch from server
       */
      updateApp: function updateApp() {
        window.location.reload(true);
      }
    }
  });

  _exports.default = _default;
});