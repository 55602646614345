define("huvepharma-aviapp-admin/components/form/huve-measurement-based-number-input", ["exports", "@huvepharma/huvepharma-aviapp-components/components/form/measurement-based-number-input"], function (_exports, _measurementBasedNumberInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _measurementBasedNumberInput.default;
    }
  });
});