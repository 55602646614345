define("huvepharma-aviapp-admin/base/models/user-connection", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var UserConnection = _emberData.default.Model.extend({
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    userInvitor: _emberData.default.belongsTo('user', {
      async: true
    }),
    userInvitee: _emberData.default.belongsTo('user', {
      async: true
    }),
    isApproved: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isDeclined: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isBlockedByCompany: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isSoftDeleted: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });

  var _default = UserConnection;
  _exports.default = _default;
});