define("huvepharma-aviapp-admin/base/enum/countries/en-cs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AF",
    "country": "Afghánistán"
  }, {
    "code": "AX",
    "country": "Ålandy"
  }, {
    "code": "AL",
    "country": "Albánie"
  }, {
    "code": "DZ",
    "country": "Alžírsko"
  }, {
    "code": "AS",
    "country": "Americká Samoa"
  }, {
    "code": "VI",
    "country": "Americké Panenské ostrovy"
  }, {
    "code": "AD",
    "country": "Andorra"
  }, {
    "code": "AO",
    "country": "Angola"
  }, {
    "code": "AI",
    "country": "Anguilla"
  }, {
    "code": "AQ",
    "country": "Antarktida"
  }, {
    "code": "AG",
    "country": "Antigua a Barbuda"
  }, {
    "code": "AR",
    "country": "Argentina"
  }, {
    "code": "AM",
    "country": "Arménie"
  }, {
    "code": "AW",
    "country": "Aruba"
  }, {
    "code": "AU",
    "country": "Austrálie"
  }, {
    "code": "AZ",
    "country": "Ázerbájdžán"
  }, {
    "code": "BS",
    "country": "Bahamy"
  }, {
    "code": "BH",
    "country": "Bahrajn"
  }, {
    "code": "BD",
    "country": "Bangladéš"
  }, {
    "code": "BB",
    "country": "Barbados"
  }, {
    "code": "BE",
    "country": "Belgie"
  }, {
    "code": "BZ",
    "country": "Belize"
  }, {
    "code": "BY",
    "country": "Bělorusko"
  }, {
    "code": "BJ",
    "country": "Benin"
  }, {
    "code": "BM",
    "country": "Bermudy"
  }, {
    "code": "BT",
    "country": "Bhútán"
  }, {
    "code": "BO",
    "country": "Bolívie"
  }, {
    "code": "BA",
    "country": "Bosna a Hercegovina"
  }, {
    "code": "BW",
    "country": "Botswana"
  }, {
    "code": "BV",
    "country": "Bouvetův ostrov"
  }, {
    "code": "BR",
    "country": "Brazílie"
  }, {
    "code": "IO",
    "country": "Britské indickooceánské území"
  }, {
    "code": "VG",
    "country": "Britské Panenské ostrovy"
  }, {
    "code": "BN",
    "country": "Brunej"
  }, {
    "code": "BG",
    "country": "Bulharsko"
  }, {
    "code": "BF",
    "country": "Burkina Faso"
  }, {
    "code": "BI",
    "country": "Burundi"
  }, {
    "code": "CK",
    "country": "Cookovy ostrovy"
  }, {
    "code": "CW",
    "country": "Curaçao"
  }, {
    "code": "TD",
    "country": "Čad"
  }, {
    "code": "ME",
    "country": "Černá Hora"
  }, {
    "code": "CZ",
    "country": "Česko"
  }, {
    "code": "CN",
    "country": "Čína"
  }, {
    "code": "DK",
    "country": "Dánsko"
  }, {
    "code": "DM",
    "country": "Dominika"
  }, {
    "code": "DO",
    "country": "Dominikánská republika"
  }, {
    "code": "DJ",
    "country": "Džibutsko"
  }, {
    "code": "EG",
    "country": "Egypt"
  }, {
    "code": "EC",
    "country": "Ekvádor"
  }, {
    "code": "ER",
    "country": "Eritrea"
  }, {
    "code": "EE",
    "country": "Estonsko"
  }, {
    "code": "ET",
    "country": "Etiopie"
  }, {
    "code": "FO",
    "country": "Faerské ostrovy"
  }, {
    "code": "FK",
    "country": "Falklandské ostrovy"
  }, {
    "code": "FJ",
    "country": "Fidži"
  }, {
    "code": "PH",
    "country": "Filipíny"
  }, {
    "code": "FI",
    "country": "Finsko"
  }, {
    "code": "FR",
    "country": "Francie"
  }, {
    "code": "GF",
    "country": "Francouzská Guyana"
  }, {
    "code": "TF",
    "country": "Francouzská jižní území"
  }, {
    "code": "PF",
    "country": "Francouzská Polynésie"
  }, {
    "code": "GA",
    "country": "Gabon"
  }, {
    "code": "GM",
    "country": "Gambie"
  }, {
    "code": "GH",
    "country": "Ghana"
  }, {
    "code": "GI",
    "country": "Gibraltar"
  }, {
    "code": "GD",
    "country": "Grenada"
  }, {
    "code": "GL",
    "country": "Grónsko"
  }, {
    "code": "GE",
    "country": "Gruzie"
  }, {
    "code": "GP",
    "country": "Guadeloupe"
  }, {
    "code": "GU",
    "country": "Guam"
  }, {
    "code": "GT",
    "country": "Guatemala"
  }, {
    "code": "GG",
    "country": "Guernsey"
  }, {
    "code": "GN",
    "country": "Guinea"
  }, {
    "code": "GW",
    "country": "Guinea-Bissau"
  }, {
    "code": "GY",
    "country": "Guyana"
  }, {
    "code": "HT",
    "country": "Haiti"
  }, {
    "code": "HM",
    "country": "Heardův ostrov a McDonaldovy ostrovy"
  }, {
    "code": "HN",
    "country": "Honduras"
  }, {
    "code": "HK",
    "country": "Hongkong – ZAO Číny"
  }, {
    "code": "CL",
    "country": "Chile"
  }, {
    "code": "HR",
    "country": "Chorvatsko"
  }, {
    "code": "IN",
    "country": "Indie"
  }, {
    "code": "ID",
    "country": "Indonésie"
  }, {
    "code": "IQ",
    "country": "Irák"
  }, {
    "code": "IR",
    "country": "Írán"
  }, {
    "code": "IE",
    "country": "Irsko"
  }, {
    "code": "IS",
    "country": "Island"
  }, {
    "code": "IT",
    "country": "Itálie"
  }, {
    "code": "IL",
    "country": "Izrael"
  }, {
    "code": "JM",
    "country": "Jamajka"
  }, {
    "code": "JP",
    "country": "Japonsko"
  }, {
    "code": "YE",
    "country": "Jemen"
  }, {
    "code": "JE",
    "country": "Jersey"
  }, {
    "code": "ZA",
    "country": "Jihoafrická republika"
  }, {
    "code": "GS",
    "country": "Jižní Georgie a Jižní Sandwichovy ostrovy"
  }, {
    "code": "KR",
    "country": "Jižní Korea"
  }, {
    "code": "SS",
    "country": "Jižní Súdán"
  }, {
    "code": "JO",
    "country": "Jordánsko"
  }, {
    "code": "KY",
    "country": "Kajmanské ostrovy"
  }, {
    "code": "KH",
    "country": "Kambodža"
  }, {
    "code": "CM",
    "country": "Kamerun"
  }, {
    "code": "CA",
    "country": "Kanada"
  }, {
    "code": "CV",
    "country": "Kapverdy"
  }, {
    "code": "BQ",
    "country": "Karibské Nizozemsko"
  }, {
    "code": "QA",
    "country": "Katar"
  }, {
    "code": "KZ",
    "country": "Kazachstán"
  }, {
    "code": "KE",
    "country": "Keňa"
  }, {
    "code": "KI",
    "country": "Kiribati"
  }, {
    "code": "CC",
    "country": "Kokosové ostrovy"
  }, {
    "code": "CO",
    "country": "Kolumbie"
  }, {
    "code": "KM",
    "country": "Komory"
  }, {
    "code": "CG",
    "country": "Kongo – Brazzaville"
  }, {
    "code": "CD",
    "country": "Kongo – Kinshasa"
  }, {
    "code": "CR",
    "country": "Kostarika"
  }, {
    "code": "CU",
    "country": "Kuba"
  }, {
    "code": "KW",
    "country": "Kuvajt"
  }, {
    "code": "CY",
    "country": "Kypr"
  }, {
    "code": "KG",
    "country": "Kyrgyzstán"
  }, {
    "code": "LA",
    "country": "Laos"
  }, {
    "code": "LS",
    "country": "Lesotho"
  }, {
    "code": "LB",
    "country": "Libanon"
  }, {
    "code": "LR",
    "country": "Libérie"
  }, {
    "code": "LY",
    "country": "Libye"
  }, {
    "code": "LI",
    "country": "Lichtenštejnsko"
  }, {
    "code": "LT",
    "country": "Litva"
  }, {
    "code": "LV",
    "country": "Lotyšsko"
  }, {
    "code": "LU",
    "country": "Lucembursko"
  }, {
    "code": "MO",
    "country": "Macao – ZAO Číny"
  }, {
    "code": "MG",
    "country": "Madagaskar"
  }, {
    "code": "HU",
    "country": "Maďarsko"
  }, {
    "code": "MY",
    "country": "Malajsie"
  }, {
    "code": "MW",
    "country": "Malawi"
  }, {
    "code": "MV",
    "country": "Maledivy"
  }, {
    "code": "ML",
    "country": "Mali"
  }, {
    "code": "MT",
    "country": "Malta"
  }, {
    "code": "MA",
    "country": "Maroko"
  }, {
    "code": "MH",
    "country": "Marshallovy ostrovy"
  }, {
    "code": "MQ",
    "country": "Martinik"
  }, {
    "code": "MU",
    "country": "Mauricius"
  }, {
    "code": "MR",
    "country": "Mauritánie"
  }, {
    "code": "YT",
    "country": "Mayotte"
  }, {
    "code": "UM",
    "country": "Menší odlehlé ostrovy USA"
  }, {
    "code": "MX",
    "country": "Mexiko"
  }, {
    "code": "FM",
    "country": "Mikronésie"
  }, {
    "code": "MD",
    "country": "Moldavsko"
  }, {
    "code": "MC",
    "country": "Monako"
  }, {
    "code": "MN",
    "country": "Mongolsko"
  }, {
    "code": "MS",
    "country": "Montserrat"
  }, {
    "code": "MZ",
    "country": "Mosambik"
  }, {
    "code": "MM",
    "country": "Myanmar (Barma)"
  }, {
    "code": "NA",
    "country": "Namibie"
  }, {
    "code": "NR",
    "country": "Nauru"
  }, {
    "code": "DE",
    "country": "Německo"
  }, {
    "code": "NP",
    "country": "Nepál"
  }, {
    "code": "NE",
    "country": "Niger"
  }, {
    "code": "NG",
    "country": "Nigérie"
  }, {
    "code": "NI",
    "country": "Nikaragua"
  }, {
    "code": "NU",
    "country": "Niue"
  }, {
    "code": "NL",
    "country": "Nizozemsko"
  }, {
    "code": "NF",
    "country": "Norfolk"
  }, {
    "code": "NO",
    "country": "Norsko"
  }, {
    "code": "NC",
    "country": "Nová Kaledonie"
  }, {
    "code": "NZ",
    "country": "Nový Zéland"
  }, {
    "code": "OM",
    "country": "Omán"
  }, {
    "code": "IM",
    "country": "Ostrov Man"
  }, {
    "code": "PK",
    "country": "Pákistán"
  }, {
    "code": "PW",
    "country": "Palau"
  }, {
    "code": "PS",
    "country": "Palestinská území"
  }, {
    "code": "PA",
    "country": "Panama"
  }, {
    "code": "PG",
    "country": "Papua-Nová Guinea"
  }, {
    "code": "PY",
    "country": "Paraguay"
  }, {
    "code": "PE",
    "country": "Peru"
  }, {
    "code": "PN",
    "country": "Pitcairnovy ostrovy"
  }, {
    "code": "CI",
    "country": "Pobřeží slonoviny"
  }, {
    "code": "PL",
    "country": "Polsko"
  }, {
    "code": "PR",
    "country": "Portoriko"
  }, {
    "code": "PT",
    "country": "Portugalsko"
  }, {
    "code": "AT",
    "country": "Rakousko"
  }, {
    "code": "RE",
    "country": "Réunion"
  }, {
    "code": "GQ",
    "country": "Rovníková Guinea"
  }, {
    "code": "RO",
    "country": "Rumunsko"
  }, {
    "code": "RU",
    "country": "Rusko"
  }, {
    "code": "RW",
    "country": "Rwanda"
  }, {
    "code": "GR",
    "country": "Řecko"
  }, {
    "code": "PM",
    "country": "Saint-Pierre a Miquelon"
  }, {
    "code": "SV",
    "country": "Salvador"
  }, {
    "code": "WS",
    "country": "Samoa"
  }, {
    "code": "SM",
    "country": "San Marino"
  }, {
    "code": "SA",
    "country": "Saúdská Arábie"
  }, {
    "code": "SN",
    "country": "Senegal"
  }, {
    "code": "KP",
    "country": "Severní Korea"
  }, {
    "code": "MK",
    "country": "Severní Makedonie"
  }, {
    "code": "MP",
    "country": "Severní Mariany"
  }, {
    "code": "SC",
    "country": "Seychely"
  }, {
    "code": "SL",
    "country": "Sierra Leone"
  }, {
    "code": "SG",
    "country": "Singapur"
  }, {
    "code": "SK",
    "country": "Slovensko"
  }, {
    "code": "SI",
    "country": "Slovinsko"
  }, {
    "code": "SO",
    "country": "Somálsko"
  }, {
    "code": "AE",
    "country": "Spojené arabské emiráty"
  }, {
    "code": "GB",
    "country": "Spojené království"
  }, {
    "code": "US",
    "country": "Spojené státy"
  }, {
    "code": "RS",
    "country": "Srbsko"
  }, {
    "code": "LK",
    "country": "Srí Lanka"
  }, {
    "code": "CF",
    "country": "Středoafrická republika"
  }, {
    "code": "SD",
    "country": "Súdán"
  }, {
    "code": "SR",
    "country": "Surinam"
  }, {
    "code": "SH",
    "country": "Svatá Helena"
  }, {
    "code": "LC",
    "country": "Svatá Lucie"
  }, {
    "code": "BL",
    "country": "Svatý Bartoloměj"
  }, {
    "code": "KN",
    "country": "Svatý Kryštof a Nevis"
  }, {
    "code": "MF",
    "country": "Svatý Martin (Francie)"
  }, {
    "code": "SX",
    "country": "Svatý Martin (Nizozemsko)"
  }, {
    "code": "ST",
    "country": "Svatý Tomáš a Princův ostrov"
  }, {
    "code": "VC",
    "country": "Svatý Vincenc a Grenadiny"
  }, {
    "code": "SZ",
    "country": "Svazijsko"
  }, {
    "code": "SY",
    "country": "Sýrie"
  }, {
    "code": "SB",
    "country": "Šalamounovy ostrovy"
  }, {
    "code": "ES",
    "country": "Španělsko"
  }, {
    "code": "SJ",
    "country": "Špicberky a Jan Mayen"
  }, {
    "code": "SE",
    "country": "Švédsko"
  }, {
    "code": "CH",
    "country": "Švýcarsko"
  }, {
    "code": "TJ",
    "country": "Tádžikistán"
  }, {
    "code": "TZ",
    "country": "Tanzanie"
  }, {
    "code": "TH",
    "country": "Thajsko"
  }, {
    "code": "TW",
    "country": "Tchaj-wan"
  }, {
    "code": "TG",
    "country": "Togo"
  }, {
    "code": "TK",
    "country": "Tokelau"
  }, {
    "code": "TO",
    "country": "Tonga"
  }, {
    "code": "TT",
    "country": "Trinidad a Tobago"
  }, {
    "code": "TN",
    "country": "Tunisko"
  }, {
    "code": "TR",
    "country": "Turecko"
  }, {
    "code": "TM",
    "country": "Turkmenistán"
  }, {
    "code": "TC",
    "country": "Turks a Caicos"
  }, {
    "code": "TV",
    "country": "Tuvalu"
  }, {
    "code": "UG",
    "country": "Uganda"
  }, {
    "code": "UA",
    "country": "Ukrajina"
  }, {
    "code": "UY",
    "country": "Uruguay"
  }, {
    "code": "UZ",
    "country": "Uzbekistán"
  }, {
    "code": "CX",
    "country": "Vánoční ostrov"
  }, {
    "code": "VU",
    "country": "Vanuatu"
  }, {
    "code": "VA",
    "country": "Vatikán"
  }, {
    "code": "VE",
    "country": "Venezuela"
  }, {
    "code": "VN",
    "country": "Vietnam"
  }, {
    "code": "TL",
    "country": "Východní Timor"
  }, {
    "code": "WF",
    "country": "Wallis a Futuna"
  }, {
    "code": "ZM",
    "country": "Zambie"
  }, {
    "code": "EH",
    "country": "Západní Sahara"
  }, {
    "code": "ZW",
    "country": "Zimbabwe"
  }];
  _exports.default = _default;
});