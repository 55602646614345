define("huvepharma-aviapp-admin/serializers/invitation", ["exports", "ember-data", "huvepharma-aviapp-admin/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    primaryKey: 'token',
    attrs: {
      company: {
        embedded: 'always'
      },
      owner: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});