define("huvepharma-aviapp-admin/base/enum/countries/en-vi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AC",
    "country": "Ascension Island"
  }, {
    "code": "AD",
    "country": "Andorra"
  }, {
    "code": "AE",
    "country": "Các Tiểu Vương quốc Ả Rập Thống nhất"
  }, {
    "code": "AF",
    "country": "Afghanistan"
  }, {
    "code": "AG",
    "country": "Antigua và Barbuda"
  }, {
    "code": "AI",
    "country": "Anguilla"
  }, {
    "code": "AL",
    "country": "Albania"
  }, {
    "code": "AM",
    "country": "Armenia"
  }, {
    "code": "AO",
    "country": "Angola"
  }, {
    "code": "AQ",
    "country": "Nam Cực"
  }, {
    "code": "AR",
    "country": "Argentina"
  }, {
    "code": "AS",
    "country": "Samoa thuộc Mỹ"
  }, {
    "code": "AT",
    "country": "Áo"
  }, {
    "code": "AU",
    "country": "Australia"
  }, {
    "code": "AW",
    "country": "Aruba"
  }, {
    "code": "AX",
    "country": "Quần đảo Åland"
  }, {
    "code": "AZ",
    "country": "Azerbaijan"
  }, {
    "code": "BA",
    "country": "Bosnia và Herzegovina"
  }, {
    "code": "BB",
    "country": "Barbados"
  }, {
    "code": "BD",
    "country": "Bangladesh"
  }, {
    "code": "BE",
    "country": "Bỉ"
  }, {
    "code": "BF",
    "country": "Burkina Faso"
  }, {
    "code": "BG",
    "country": "Bulgaria"
  }, {
    "code": "BH",
    "country": "Bahrain"
  }, {
    "code": "BI",
    "country": "Burundi"
  }, {
    "code": "BJ",
    "country": "Benin"
  }, {
    "code": "BL",
    "country": "St. Barthélemy"
  }, {
    "code": "BM",
    "country": "Bermuda"
  }, {
    "code": "BN",
    "country": "Brunei"
  }, {
    "code": "BO",
    "country": "Bolivia"
  }, {
    "code": "BQ",
    "country": "Ca-ri-bê Hà Lan"
  }, {
    "code": "BR",
    "country": "Brazil"
  }, {
    "code": "BS",
    "country": "Bahamas"
  }, {
    "code": "BT",
    "country": "Bhutan"
  }, {
    "code": "BV",
    "country": "Đảo Bouvet"
  }, {
    "code": "BW",
    "country": "Botswana"
  }, {
    "code": "BY",
    "country": "Belarus"
  }, {
    "code": "BZ",
    "country": "Belize"
  }, {
    "code": "CA",
    "country": "Canada"
  }, {
    "code": "CC",
    "country": "Quần đảo Cocos (Keeling)"
  }, {
    "code": "CD",
    "country": "Congo - Kinshasa"
  }, {
    "code": "CF",
    "country": "Cộng hòa Trung Phi"
  }, {
    "code": "CG",
    "country": "Congo - Brazzaville"
  }, {
    "code": "CH",
    "country": "Thụy Sĩ"
  }, {
    "code": "CI",
    "country": "Côte d’Ivoire"
  }, {
    "code": "CK",
    "country": "Quần đảo Cook"
  }, {
    "code": "CL",
    "country": "Chile"
  }, {
    "code": "CM",
    "country": "Cameroon"
  }, {
    "code": "CN",
    "country": "Trung Quốc"
  }, {
    "code": "CO",
    "country": "Colombia"
  }, {
    "code": "CR",
    "country": "Costa Rica"
  }, {
    "code": "CU",
    "country": "Cuba"
  }, {
    "code": "CV",
    "country": "Cape Verde"
  }, {
    "code": "CW",
    "country": "Curaçao"
  }, {
    "code": "CX",
    "country": "Đảo Giáng Sinh"
  }, {
    "code": "CY",
    "country": "Síp"
  }, {
    "code": "CZ",
    "country": "Séc"
  }, {
    "code": "DE",
    "country": "Đức"
  }, {
    "code": "DG",
    "country": "Diego Garcia"
  }, {
    "code": "DJ",
    "country": "Djibouti"
  }, {
    "code": "DK",
    "country": "Đan Mạch"
  }, {
    "code": "DM",
    "country": "Dominica"
  }, {
    "code": "DO",
    "country": "Cộng hòa Dominica"
  }, {
    "code": "DZ",
    "country": "Algeria"
  }, {
    "code": "EA",
    "country": "Ceuta & Melilla"
  }, {
    "code": "EC",
    "country": "Ecuador"
  }, {
    "code": "EE",
    "country": "Estonia"
  }, {
    "code": "EG",
    "country": "Ai Cập"
  }, {
    "code": "EH",
    "country": "Tây Sahara"
  }, {
    "code": "ER",
    "country": "Eritrea"
  }, {
    "code": "ES",
    "country": "Tây Ban Nha"
  }, {
    "code": "ET",
    "country": "Ethiopia"
  }, {
    "code": "EZ",
    "country": "Eurozone"
  }, {
    "code": "FI",
    "country": "Phần Lan"
  }, {
    "code": "FJ",
    "country": "Fiji"
  }, {
    "code": "FK",
    "country": "Quần đảo Falkland"
  }, {
    "code": "FM",
    "country": "Micronesia"
  }, {
    "code": "FO",
    "country": "Quần đảo Faroe"
  }, {
    "code": "FR",
    "country": "Pháp"
  }, {
    "code": "GA",
    "country": "Gabon"
  }, {
    "code": "GB",
    "country": "Vương quốc Anh"
  }, {
    "code": "GD",
    "country": "Grenada"
  }, {
    "code": "GE",
    "country": "Georgia"
  }, {
    "code": "GF",
    "country": "Guiana thuộc Pháp"
  }, {
    "code": "GG",
    "country": "Guernsey"
  }, {
    "code": "GH",
    "country": "Ghana"
  }, {
    "code": "GI",
    "country": "Gibraltar"
  }, {
    "code": "GL",
    "country": "Greenland"
  }, {
    "code": "GM",
    "country": "Gambia"
  }, {
    "code": "GN",
    "country": "Guinea"
  }, {
    "code": "GP",
    "country": "Guadeloupe"
  }, {
    "code": "GQ",
    "country": "Guinea Xích Đạo"
  }, {
    "code": "GR",
    "country": "Hy Lạp"
  }, {
    "code": "GS",
    "country": "Nam Georgia & Quần đảo Nam Sandwich"
  }, {
    "code": "GT",
    "country": "Guatemala"
  }, {
    "code": "GU",
    "country": "Guam"
  }, {
    "code": "GW",
    "country": "Guinea-Bissau"
  }, {
    "code": "GY",
    "country": "Guyana"
  }, {
    "code": "HK",
    "country": "Hồng Kông, Trung Quốc"
  }, {
    "code": "HM",
    "country": "Quần đảo Heard và McDonald"
  }, {
    "code": "HN",
    "country": "Honduras"
  }, {
    "code": "HR",
    "country": "Croatia"
  }, {
    "code": "HT",
    "country": "Haiti"
  }, {
    "code": "HU",
    "country": "Hungary"
  }, {
    "code": "IC",
    "country": "Canary Islands"
  }, {
    "code": "ID",
    "country": "Indonesia"
  }, {
    "code": "IE",
    "country": "Ireland"
  }, {
    "code": "IL",
    "country": "Israel"
  }, {
    "code": "IM",
    "country": "Đảo Man"
  }, {
    "code": "IN",
    "country": "Ấn Độ"
  }, {
    "code": "IO",
    "country": "Lãnh thổ Ấn Độ Dương thuộc Anh"
  }, {
    "code": "IQ",
    "country": "Iraq"
  }, {
    "code": "IR",
    "country": "Iran"
  }, {
    "code": "IS",
    "country": "Iceland"
  }, {
    "code": "IT",
    "country": "Italy"
  }, {
    "code": "JE",
    "country": "Jersey"
  }, {
    "code": "JM",
    "country": "Jamaica"
  }, {
    "code": "JO",
    "country": "Jordan"
  }, {
    "code": "JP",
    "country": "Nhật Bản"
  }, {
    "code": "KE",
    "country": "Kenya"
  }, {
    "code": "KG",
    "country": "Kyrgyzstan"
  }, {
    "code": "KH",
    "country": "Campuchia"
  }, {
    "code": "KI",
    "country": "Kiribati"
  }, {
    "code": "KM",
    "country": "Comoros"
  }, {
    "code": "KN",
    "country": "St. Kitts và Nevis"
  }, {
    "code": "KP",
    "country": "Triều Tiên"
  }, {
    "code": "KR",
    "country": "Hàn Quốc"
  }, {
    "code": "KW",
    "country": "Kuwait"
  }, {
    "code": "KY",
    "country": "Quần đảo Cayman"
  }, {
    "code": "KZ",
    "country": "Kazakhstan"
  }, {
    "code": "LA",
    "country": "Lào"
  }, {
    "code": "LB",
    "country": "Li-băng"
  }, {
    "code": "LC",
    "country": "St. Lucia"
  }, {
    "code": "LI",
    "country": "Liechtenstein"
  }, {
    "code": "LK",
    "country": "Sri Lanka"
  }, {
    "code": "LR",
    "country": "Liberia"
  }, {
    "code": "LS",
    "country": "Lesotho"
  }, {
    "code": "LT",
    "country": "Litva"
  }, {
    "code": "LU",
    "country": "Luxembourg"
  }, {
    "code": "LV",
    "country": "Latvia"
  }, {
    "code": "LY",
    "country": "Libya"
  }, {
    "code": "MA",
    "country": "Ma-rốc"
  }, {
    "code": "MC",
    "country": "Monaco"
  }, {
    "code": "MD",
    "country": "Moldova"
  }, {
    "code": "ME",
    "country": "Montenegro"
  }, {
    "code": "MF",
    "country": "St. Martin"
  }, {
    "code": "MG",
    "country": "Madagascar"
  }, {
    "code": "MH",
    "country": "Quần đảo Marshall"
  }, {
    "code": "MK",
    "country": "Bắc Macedonia"
  }, {
    "code": "ML",
    "country": "Mali"
  }, {
    "code": "MM",
    "country": "Myanmar (Miến Điện)"
  }, {
    "code": "MN",
    "country": "Mông Cổ"
  }, {
    "code": "MO",
    "country": "Macao, Trung Quốc"
  }, {
    "code": "MP",
    "country": "Quần đảo Bắc Mariana"
  }, {
    "code": "MQ",
    "country": "Martinique"
  }, {
    "code": "MR",
    "country": "Mauritania"
  }, {
    "code": "MS",
    "country": "Montserrat"
  }, {
    "code": "MT",
    "country": "Malta"
  }, {
    "code": "MU",
    "country": "Mauritius"
  }, {
    "code": "MV",
    "country": "Maldives"
  }, {
    "code": "MW",
    "country": "Malawi"
  }, {
    "code": "MX",
    "country": "Mexico"
  }, {
    "code": "MY",
    "country": "Malaysia"
  }, {
    "code": "MZ",
    "country": "Mozambique"
  }, {
    "code": "NA",
    "country": "Namibia"
  }, {
    "code": "NC",
    "country": "New Caledonia"
  }, {
    "code": "NE",
    "country": "Niger"
  }, {
    "code": "NF",
    "country": "Đảo Norfolk"
  }, {
    "code": "NG",
    "country": "Nigeria"
  }, {
    "code": "NI",
    "country": "Nicaragua"
  }, {
    "code": "NL",
    "country": "Hà Lan"
  }, {
    "code": "NO",
    "country": "Na Uy"
  }, {
    "code": "NP",
    "country": "Nepal"
  }, {
    "code": "NR",
    "country": "Nauru"
  }, {
    "code": "NU",
    "country": "Niue"
  }, {
    "code": "NZ",
    "country": "New Zealand"
  }, {
    "code": "OM",
    "country": "Oman"
  }, {
    "code": "PA",
    "country": "Panama"
  }, {
    "code": "PE",
    "country": "Peru"
  }, {
    "code": "PF",
    "country": "Polynesia thuộc Pháp"
  }, {
    "code": "PG",
    "country": "Papua New Guinea"
  }, {
    "code": "PH",
    "country": "Philippines"
  }, {
    "code": "PK",
    "country": "Pakistan"
  }, {
    "code": "PL",
    "country": "Ba Lan"
  }, {
    "code": "PM",
    "country": "Saint Pierre và Miquelon"
  }, {
    "code": "PN",
    "country": "Quần đảo Pitcairn"
  }, {
    "code": "PR",
    "country": "Puerto Rico"
  }, {
    "code": "PS",
    "country": "Lãnh thổ Palestine"
  }, {
    "code": "PT",
    "country": "Bồ Đào Nha"
  }, {
    "code": "PW",
    "country": "Palau"
  }, {
    "code": "PY",
    "country": "Paraguay"
  }, {
    "code": "QA",
    "country": "Qatar"
  }, {
    "code": "RE",
    "country": "Réunion"
  }, {
    "code": "RO",
    "country": "Romania"
  }, {
    "code": "RS",
    "country": "Serbia"
  }, {
    "code": "RU",
    "country": "Nga"
  }, {
    "code": "RW",
    "country": "Rwanda"
  }, {
    "code": "SA",
    "country": "Ả Rập Xê-út"
  }, {
    "code": "SB",
    "country": "Quần đảo Solomon"
  }, {
    "code": "SC",
    "country": "Seychelles"
  }, {
    "code": "SD",
    "country": "Sudan"
  }, {
    "code": "SE",
    "country": "Thụy Điển"
  }, {
    "code": "SG",
    "country": "Singapore"
  }, {
    "code": "SH",
    "country": "St. Helena"
  }, {
    "code": "SI",
    "country": "Slovenia"
  }, {
    "code": "SJ",
    "country": "Svalbard và Jan Mayen"
  }, {
    "code": "SK",
    "country": "Slovakia"
  }, {
    "code": "SL",
    "country": "Sierra Leone"
  }, {
    "code": "SM",
    "country": "San Marino"
  }, {
    "code": "SN",
    "country": "Senegal"
  }, {
    "code": "SO",
    "country": "Somalia"
  }, {
    "code": "SR",
    "country": "Suriname"
  }, {
    "code": "SS",
    "country": "Nam Sudan"
  }, {
    "code": "ST",
    "country": "São Tomé và Príncipe"
  }, {
    "code": "SV",
    "country": "El Salvador"
  }, {
    "code": "SX",
    "country": "Sint Maarten"
  }, {
    "code": "SY",
    "country": "Syria"
  }, {
    "code": "SZ",
    "country": "Swaziland"
  }, {
    "code": "TA",
    "country": "Tristan da Cunha"
  }, {
    "code": "TC",
    "country": "Quần đảo Turks và Caicos"
  }, {
    "code": "TD",
    "country": "Chad"
  }, {
    "code": "TF",
    "country": "Lãnh thổ phía Nam Thuộc Pháp"
  }, {
    "code": "TG",
    "country": "Togo"
  }, {
    "code": "TH",
    "country": "Thái Lan"
  }, {
    "code": "TJ",
    "country": "Tajikistan"
  }, {
    "code": "TK",
    "country": "Tokelau"
  }, {
    "code": "TL",
    "country": "Timor-Leste"
  }, {
    "code": "TM",
    "country": "Turkmenistan"
  }, {
    "code": "TN",
    "country": "Tunisia"
  }, {
    "code": "TO",
    "country": "Tonga"
  }, {
    "code": "TR",
    "country": "Thổ Nhĩ Kỳ"
  }, {
    "code": "TT",
    "country": "Trinidad và Tobago"
  }, {
    "code": "TV",
    "country": "Tuvalu"
  }, {
    "code": "TW",
    "country": "Đài Loan"
  }, {
    "code": "TZ",
    "country": "Tanzania"
  }, {
    "code": "UA",
    "country": "Ukraina"
  }, {
    "code": "UG",
    "country": "Uganda"
  }, {
    "code": "UM",
    "country": "Các tiểu đảo xa của Hoa Kỳ"
  }, {
    "code": "UN",
    "country": "United Nations"
  }, {
    "code": "US",
    "country": "Hoa Kỳ"
  }, {
    "code": "UY",
    "country": "Uruguay"
  }, {
    "code": "UZ",
    "country": "Uzbekistan"
  }, {
    "code": "VA",
    "country": "Thành Vatican"
  }, {
    "code": "VC",
    "country": "St. Vincent và Grenadines"
  }, {
    "code": "VE",
    "country": "Venezuela"
  }, {
    "code": "VG",
    "country": "Quần đảo Virgin thuộc Anh"
  }, {
    "code": "VI",
    "country": "Quần đảo Virgin thuộc Mỹ"
  }, {
    "code": "VN",
    "country": "Việt Nam"
  }, {
    "code": "VU",
    "country": "Vanuatu"
  }, {
    "code": "WF",
    "country": "Wallis và Futuna"
  }, {
    "code": "WS",
    "country": "Samoa"
  }, {
    "code": "XK",
    "country": "Kosovo"
  }, {
    "code": "YE",
    "country": "Yemen"
  }, {
    "code": "YT",
    "country": "Mayotte"
  }, {
    "code": "ZA",
    "country": "Nam Phi"
  }, {
    "code": "ZM",
    "country": "Zambia"
  }, {
    "code": "ZW",
    "country": "Zimbabwe"
  }];
  _exports.default = _default;
});