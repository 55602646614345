define("huvepharma-aviapp-admin/base/enum/countries/en-de", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AF",
    "country": "Afghanistan"
  }, {
    "code": "EG",
    "country": "Ägypten"
  }, {
    "code": "AX",
    "country": "Ålandinseln"
  }, {
    "code": "AL",
    "country": "Albanien"
  }, {
    "code": "DZ",
    "country": "Algerien"
  }, {
    "code": "AS",
    "country": "Amerikanisch-Samoa"
  }, {
    "code": "VI",
    "country": "Amerikanische Jungferninseln"
  }, {
    "code": "UM",
    "country": "Amerikanische Überseeinseln"
  }, {
    "code": "AD",
    "country": "Andorra"
  }, {
    "code": "AO",
    "country": "Angola"
  }, {
    "code": "AI",
    "country": "Anguilla"
  }, {
    "code": "AQ",
    "country": "Antarktis"
  }, {
    "code": "AG",
    "country": "Antigua und Barbuda"
  }, {
    "code": "GQ",
    "country": "Äquatorialguinea"
  }, {
    "code": "AR",
    "country": "Argentinien"
  }, {
    "code": "AM",
    "country": "Armenien"
  }, {
    "code": "AW",
    "country": "Aruba"
  }, {
    "code": "AC",
    "country": "Ascension"
  }, {
    "code": "AZ",
    "country": "Aserbaidschan"
  }, {
    "code": "ET",
    "country": "Äthiopien"
  }, {
    "code": "AU",
    "country": "Australien"
  }, {
    "code": "BS",
    "country": "Bahamas"
  }, {
    "code": "BH",
    "country": "Bahrain"
  }, {
    "code": "BD",
    "country": "Bangladesch"
  }, {
    "code": "BB",
    "country": "Barbados"
  }, {
    "code": "BY",
    "country": "Belarus"
  }, {
    "code": "BE",
    "country": "Belgien"
  }, {
    "code": "BZ",
    "country": "Belize"
  }, {
    "code": "BJ",
    "country": "Benin"
  }, {
    "code": "BM",
    "country": "Bermuda"
  }, {
    "code": "BT",
    "country": "Bhutan"
  }, {
    "code": "BO",
    "country": "Bolivien"
  }, {
    "code": "BQ",
    "country": "Bonaire,Sint Eustatius und Saba"
  }, {
    "code": "BA",
    "country": "Bosnien und Herzegowina"
  }, {
    "code": "BW",
    "country": "Botsuana"
  }, {
    "code": "BR",
    "country": "Brasilien"
  }, {
    "code": "VG",
    "country": "Britische Jungferninseln"
  }, {
    "code": "IO",
    "country": "Britisches Territorium im Indischen Ozean"
  }, {
    "code": "BN",
    "country": "Brunei Darussalam"
  }, {
    "code": "BG",
    "country": "Bulgarien"
  }, {
    "code": "BF",
    "country": "Burkina Faso"
  }, {
    "code": "BI",
    "country": "Burundi"
  }, {
    "code": "CV",
    "country": "Cabo Verde"
  }, {
    "code": "EA",
    "country": "Ceuta und Melilla"
  }, {
    "code": "CL",
    "country": "Chile"
  }, {
    "code": "CN",
    "country": "China"
  }, {
    "code": "CK",
    "country": "Cookinseln"
  }, {
    "code": "CR",
    "country": "Costa Rica"
  }, {
    "code": "CI",
    "country": "Côte d’Ivoire"
  }, {
    "code": "CW",
    "country": "Curaçao"
  }, {
    "code": "DK",
    "country": "Dänemark"
  }, {
    "code": "DE",
    "country": "Deutschland"
  }, {
    "code": "DG",
    "country": "Diego Garcia"
  }, {
    "code": "DM",
    "country": "Dominica"
  }, {
    "code": "DO",
    "country": "Dominikanische Republik"
  }, {
    "code": "DJ",
    "country": "Dschibuti"
  }, {
    "code": "EC",
    "country": "Ecuador"
  }, {
    "code": "SV",
    "country": "El Salvador"
  }, {
    "code": "ER",
    "country": "Eritrea"
  }, {
    "code": "EE",
    "country": "Estland"
  }, {
    "code": "FK",
    "country": "Falklandinseln"
  }, {
    "code": "FO",
    "country": "Färöer"
  }, {
    "code": "FJ",
    "country": "Fidschi"
  }, {
    "code": "FI",
    "country": "Finnland"
  }, {
    "code": "FR",
    "country": "Frankreich"
  }, {
    "code": "GF",
    "country": "Französisch-Guayana"
  }, {
    "code": "PF",
    "country": "Französisch-Polynesien"
  }, {
    "code": "TF",
    "country": "Französische Süd- und Antarktisgebiete"
  }, {
    "code": "GA",
    "country": "Gabun"
  }, {
    "code": "GM",
    "country": "Gambia"
  }, {
    "code": "GE",
    "country": "Georgien"
  }, {
    "code": "GH",
    "country": "Ghana"
  }, {
    "code": "GI",
    "country": "Gibraltar"
  }, {
    "code": "GD",
    "country": "Grenada"
  }, {
    "code": "GR",
    "country": "Griechenland"
  }, {
    "code": "GL",
    "country": "Grönland"
  }, {
    "code": "GP",
    "country": "Guadeloupe"
  }, {
    "code": "GU",
    "country": "Guam"
  }, {
    "code": "GT",
    "country": "Guatemala"
  }, {
    "code": "GG",
    "country": "Guernsey"
  }, {
    "code": "GN",
    "country": "Guinea"
  }, {
    "code": "GW",
    "country": "Guinea-Bissau"
  }, {
    "code": "GY",
    "country": "Guyana"
  }, {
    "code": "HT",
    "country": "Haiti"
  }, {
    "code": "HN",
    "country": "Honduras"
  }, {
    "code": "IN",
    "country": "Indien"
  }, {
    "code": "ID",
    "country": "Indonesien"
  }, {
    "code": "IQ",
    "country": "Irak"
  }, {
    "code": "IR",
    "country": "Iran"
  }, {
    "code": "IE",
    "country": "Irland"
  }, {
    "code": "IS",
    "country": "Island"
  }, {
    "code": "IM",
    "country": "Isle of Man"
  }, {
    "code": "IL",
    "country": "Israel"
  }, {
    "code": "IT",
    "country": "Italien"
  }, {
    "code": "JM",
    "country": "Jamaika"
  }, {
    "code": "JP",
    "country": "Japan"
  }, {
    "code": "YE",
    "country": "Jemen"
  }, {
    "code": "JE",
    "country": "Jersey"
  }, {
    "code": "JO",
    "country": "Jordanien"
  }, {
    "code": "KY",
    "country": "Kaimaninseln"
  }, {
    "code": "KH",
    "country": "Kambodscha"
  }, {
    "code": "CM",
    "country": "Kamerun"
  }, {
    "code": "CA",
    "country": "Kanada"
  }, {
    "code": "IC",
    "country": "Kanarische Inseln"
  }, {
    "code": "KZ",
    "country": "Kasachstan"
  }, {
    "code": "QA",
    "country": "Katar"
  }, {
    "code": "KE",
    "country": "Kenia"
  }, {
    "code": "KG",
    "country": "Kirgisistan"
  }, {
    "code": "KI",
    "country": "Kiribati"
  }, {
    "code": "CC",
    "country": "Kokosinseln"
  }, {
    "code": "CO",
    "country": "Kolumbien"
  }, {
    "code": "KM",
    "country": "Komoren"
  }, {
    "code": "CG",
    "country": "Kongo-Brazzaville"
  }, {
    "code": "CD",
    "country": "Kongo-Kinshasa"
  }, {
    "code": "XK",
    "country": "Kosovo"
  }, {
    "code": "HR",
    "country": "Kroatien"
  }, {
    "code": "CU",
    "country": "Kuba"
  }, {
    "code": "KW",
    "country": "Kuwait"
  }, {
    "code": "LA",
    "country": "Laos"
  }, {
    "code": "LS",
    "country": "Lesotho"
  }, {
    "code": "LV",
    "country": "Lettland"
  }, {
    "code": "LB",
    "country": "Libanon"
  }, {
    "code": "LR",
    "country": "Liberia"
  }, {
    "code": "LY",
    "country": "Libyen"
  }, {
    "code": "LI",
    "country": "Liechtenstein"
  }, {
    "code": "LT",
    "country": "Litauen"
  }, {
    "code": "LU",
    "country": "Luxemburg"
  }, {
    "code": "MG",
    "country": "Madagaskar"
  }, {
    "code": "MW",
    "country": "Malawi"
  }, {
    "code": "MY",
    "country": "Malaysia"
  }, {
    "code": "MV",
    "country": "Malediven"
  }, {
    "code": "ML",
    "country": "Mali"
  }, {
    "code": "MT",
    "country": "Malta"
  }, {
    "code": "MA",
    "country": "Marokko"
  }, {
    "code": "MH",
    "country": "Marshallinseln"
  }, {
    "code": "MQ",
    "country": "Martinique"
  }, {
    "code": "MR",
    "country": "Mauretanien"
  }, {
    "code": "MU",
    "country": "Mauritius"
  }, {
    "code": "YT",
    "country": "Mayotte"
  }, {
    "code": "MX",
    "country": "Mexiko"
  }, {
    "code": "FM",
    "country": "Mikronesien"
  }, {
    "code": "MC",
    "country": "Monaco"
  }, {
    "code": "MN",
    "country": "Mongolei"
  }, {
    "code": "ME",
    "country": "Montenegro"
  }, {
    "code": "MS",
    "country": "Montserrat"
  }, {
    "code": "MZ",
    "country": "Mosambik"
  }, {
    "code": "MM",
    "country": "Myanmar"
  }, {
    "code": "NA",
    "country": "Namibia"
  }, {
    "code": "NR",
    "country": "Nauru"
  }, {
    "code": "NP",
    "country": "Nepal"
  }, {
    "code": "NC",
    "country": "Neukaledonien"
  }, {
    "code": "NZ",
    "country": "Neuseeland"
  }, {
    "code": "NI",
    "country": "Nicaragua"
  }, {
    "code": "NL",
    "country": "Niederlande"
  }, {
    "code": "NE",
    "country": "Niger"
  }, {
    "code": "NG",
    "country": "Nigeria"
  }, {
    "code": "NU",
    "country": "Niue"
  }, {
    "code": "KP",
    "country": "Nordkorea"
  }, {
    "code": "MP",
    "country": "Nördliche Marianen"
  }, {
    "code": "MK",
    "country": "Nordmazedonien"
  }, {
    "code": "NF",
    "country": "Norfolkinsel"
  }, {
    "code": "NO",
    "country": "Norwegen"
  }, {
    "code": "OM",
    "country": "Oman"
  }, {
    "code": "AT",
    "country": "Österreich"
  }, {
    "code": "PK",
    "country": "Pakistan"
  }, {
    "code": "PS",
    "country": "Palästinensische Autonomiegebiete"
  }, {
    "code": "PW",
    "country": "Palau"
  }, {
    "code": "PA",
    "country": "Panama"
  }, {
    "code": "PG",
    "country": "Papua-Neuguinea"
  }, {
    "code": "PY",
    "country": "Paraguay"
  }, {
    "code": "PE",
    "country": "Peru"
  }, {
    "code": "PH",
    "country": "Philippinen"
  }, {
    "code": "PN",
    "country": "Pitcairninseln"
  }, {
    "code": "PL",
    "country": "Polen"
  }, {
    "code": "PT",
    "country": "Portugal"
  }, {
    "code": "XA",
    "country": "Pseudo-Accents"
  }, {
    "code": "XB",
    "country": "Pseudo-Bidi"
  }, {
    "code": "PR",
    "country": "Puerto Rico"
  }, {
    "code": "MD",
    "country": "Republik Moldau"
  }, {
    "code": "RE",
    "country": "Réunion"
  }, {
    "code": "RW",
    "country": "Ruanda"
  }, {
    "code": "RO",
    "country": "Rumänien"
  }, {
    "code": "RU",
    "country": "Russland"
  }, {
    "code": "SB",
    "country": "Salomonen"
  }, {
    "code": "ZM",
    "country": "Sambia"
  }, {
    "code": "WS",
    "country": "Samoa"
  }, {
    "code": "SM",
    "country": "San Marino"
  }, {
    "code": "ST",
    "country": "São Tomé und Príncipe"
  }, {
    "code": "SA",
    "country": "Saudi-Arabien"
  }, {
    "code": "SE",
    "country": "Schweden"
  }, {
    "code": "CH",
    "country": "Schweiz"
  }, {
    "code": "SN",
    "country": "Senegal"
  }, {
    "code": "RS",
    "country": "Serbien"
  }, {
    "code": "SC",
    "country": "Seychellen"
  }, {
    "code": "SL",
    "country": "Sierra Leone"
  }, {
    "code": "ZW",
    "country": "Simbabwe"
  }, {
    "code": "SG",
    "country": "Singapur"
  }, {
    "code": "SX",
    "country": "Sint Maarten"
  }, {
    "code": "SK",
    "country": "Slowakei"
  }, {
    "code": "SI",
    "country": "Slowenien"
  }, {
    "code": "SO",
    "country": "Somalia"
  }, {
    "code": "HK",
    "country": "Sonderverwaltungsregion Hongkong"
  }, {
    "code": "MO",
    "country": "Sonderverwaltungsregion Macau"
  }, {
    "code": "ES",
    "country": "Spanien"
  }, {
    "code": "SJ",
    "country": "Spitzbergen und Jan Mayen"
  }, {
    "code": "LK",
    "country": "Sri Lanka"
  }, {
    "code": "BL",
    "country": "St. Barthélemy"
  }, {
    "code": "SH",
    "country": "St. Helena"
  }, {
    "code": "KN",
    "country": "St. Kitts und Nevis"
  }, {
    "code": "LC",
    "country": "St. Lucia"
  }, {
    "code": "MF",
    "country": "St. Martin"
  }, {
    "code": "PM",
    "country": "St. Pierre und Miquelon"
  }, {
    "code": "VC",
    "country": "St. Vincent und die Grenadinen"
  }, {
    "code": "ZA",
    "country": "Südafrika"
  }, {
    "code": "SD",
    "country": "Sudan"
  }, {
    "code": "GS",
    "country": "Südgeorgien und die Südlichen Sandwichinseln"
  }, {
    "code": "KR",
    "country": "Südkorea"
  }, {
    "code": "SS",
    "country": "Südsudan"
  }, {
    "code": "SR",
    "country": "Suriname"
  }, {
    "code": "SZ",
    "country": "Swasiland"
  }, {
    "code": "SY",
    "country": "Syrien"
  }, {
    "code": "TJ",
    "country": "Tadschikistan"
  }, {
    "code": "TW",
    "country": "Taiwan"
  }, {
    "code": "TZ",
    "country": "Tansania"
  }, {
    "code": "TH",
    "country": "Thailand"
  }, {
    "code": "TL",
    "country": "Timor-Leste"
  }, {
    "code": "TG",
    "country": "Togo"
  }, {
    "code": "TK",
    "country": "Tokelau"
  }, {
    "code": "TO",
    "country": "Tonga"
  }, {
    "code": "TT",
    "country": "Trinidad und Tobago"
  }, {
    "code": "TA",
    "country": "Tristan da Cunha"
  }, {
    "code": "TD",
    "country": "Tschad"
  }, {
    "code": "CZ",
    "country": "Tschechien"
  }, {
    "code": "TN",
    "country": "Tunesien"
  }, {
    "code": "TR",
    "country": "Türkei"
  }, {
    "code": "TM",
    "country": "Turkmenistan"
  }, {
    "code": "TC",
    "country": "Turks- und Caicosinseln"
  }, {
    "code": "TV",
    "country": "Tuvalu"
  }, {
    "code": "UG",
    "country": "Uganda"
  }, {
    "code": "UA",
    "country": "Ukraine"
  }, {
    "code": "HU",
    "country": "Ungarn"
  }, {
    "code": "UY",
    "country": "Uruguay"
  }, {
    "code": "UZ",
    "country": "Usbekistan"
  }, {
    "code": "VU",
    "country": "Vanuatu"
  }, {
    "code": "VA",
    "country": "Vatikanstadt"
  }, {
    "code": "VE",
    "country": "Venezuela"
  }, {
    "code": "AE",
    "country": "Vereinigte Arabische Emirate"
  }, {
    "code": "US",
    "country": "Vereinigte Staaten"
  }, {
    "code": "GB",
    "country": "Vereinigtes Königreich"
  }, {
    "code": "VN",
    "country": "Vietnam"
  }, {
    "code": "WF",
    "country": "Wallis und Futuna"
  }, {
    "code": "CX",
    "country": "Weihnachtsinsel"
  }, {
    "code": "EH",
    "country": "Westsahara"
  }, {
    "code": "CF",
    "country": "Zentralafrikanische Republik"
  }, {
    "code": "CY",
    "country": "Zypern"
  }];
  _exports.default = _default;
});