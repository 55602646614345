define("huvepharma-aviapp-admin/templates/components/tag-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tBpxjPQX",
    "block": "[[[10,0],[14,0,\"mod-tags\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"truncatedItems\"]]],null]],null],null,[[[1,\"    \"],[11,1],[24,\"role\",\"button\"],[16,0,[29,[[52,[30,1,[\"class\"]],[28,[37,3],[\"tag \",[30,1,[\"class\"]]],null],\"tag\"]]]],[4,[38,4],[\"click\",[28,[37,5],[[30,2],[30,1,[\"id\"]]],null]],null],[12],[1,\"\\n      \"],[1,[30,1,[\"display\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"isTruncationEnabled\"]],[[[1,\"    \"],[11,1],[24,\"role\",\"button\"],[24,0,\"tag show-more\"],[4,[38,4],[\"click\",[30,0,[\"onToggleTruncated\"]]],null],[12],[1,\"\\n      ...\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"item\",\"@onTagPressed\"],false,[\"each\",\"-track-array\",\"if\",\"concat\",\"on\",\"fn\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/components/tag-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});