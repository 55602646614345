define("huvepharma-aviapp-admin/components/header/huve-actionable-label-header", ["exports", "@huvepharma/huvepharma-aviapp-components/components/header/actionable-label-header"], function (_exports, _actionableLabelHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _actionableLabelHeader.default;
    }
  });
});