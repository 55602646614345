define("huvepharma-aviapp-admin/components/loadable/huve-link", ["exports", "@huvepharma/huvepharma-aviapp-components/components/loadable/loadable-link"], function (_exports, _loadableLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _loadableLink.default;
    }
  });
});