define("huvepharma-aviapp-admin/templates/components/programs/save-as-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XGOcJf3f",
    "block": "[[[6,[39,0],null,[[\"yesLabel\",\"noLabel\",\"yesButtonAction\",\"noButtonAction\",\"show\",\"header\",\"headerText\",\"headerCloseAction\"],[[28,[37,1],[\"label_save_as_program\"],null],[28,[37,1],[\"app_action_cancel\"],null],[28,[37,2],[[30,0],[28,[37,3],[[33,4]],null]],null],[28,[37,2],[[30,0],[28,[37,3],[[33,5]],null]],null],[33,6],true,[28,[37,1],[\"action_save_as\"],null],[28,[37,2],[[30,0],[28,[37,3],[[33,5]],null]],null]]],[[\"default\"],[[[[6,[39,7],null,[[\"changeset\"],[[33,8]]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-sm-12\"],[12],[1,\"\\n\"],[6,[30,1,[\"field-input\"]],null,[[\"prop\",\"label\"],[\"name\",\"customgraphs_modal_save_as_body\"]],[[\"default\"],[[[[1,\"          \"],[1,[28,[30,2,[\"label\"]],null,[[\"required\"],[true]]]],[1,\"\\n          \"],[1,[28,[30,2,[\"input\"]],null,[[\"update\",\"placeholder\",\"testId\"],[[28,[37,2],[[30,0],[33,9],[33,8]],null],[28,[37,1],[\"placeholder_enter_name\"],null],\"program-save-as-name\"]]]],[1,\"\\n          \"],[1,[30,2,[\"errors\"]]],[1,\"\\n\"]],[2]]]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]]]]]],[]]]]]],[\"form\",\"field\"],false,[\"modal/huve-yes-no\",\"t\",\"action\",\"optional\",\"onConfirmSaveAs\",\"onCancelSaveAs\",\"show\",\"form-builder/huve-base-form\",\"changeset\",\"onChangeName\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/components/programs/save-as-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});