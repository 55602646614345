define("huvepharma-aviapp-admin/controllers/users/add", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    flash: (0, _service.inject)(),
    error: false,
    actions: {
      close: function close() {
        this.transitionToRoute('users');
      },
      saveUser: function saveUser() {
        var _this = this;

        this.flash.clear();
        this.model.save().then(function () {
          _this.flash.success({
            message: 'user_creation_success'
          });

          _this.transitionToRoute('users');
        }).catch(function () {
          _this.flash.error({
            message: 'user_creation_failed',
            sticky: true
          });
        });
      }
    }
  });

  _exports.default = _default;
});