define("huvepharma-aviapp-admin/base/enum/continents/en-nl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AF",
    "continent": "Afrika"
  }, {
    "code": "NA",
    "continent": "Noord-Amerika"
  }, {
    "code": "OC",
    "continent": "Oceanië"
  }, {
    "code": "AN",
    "continent": "Antarctica"
  }, {
    "code": "AS",
    "continent": "Azië"
  }, {
    "code": "EU",
    "continent": "Europa"
  }, {
    "code": "SA",
    "continent": "Zuid-Amerika"
  }];
  _exports.default = _default;
});