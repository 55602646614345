define("huvepharma-aviapp-admin/templates/users/edit-access-rights-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "267wpY5J",
    "block": "[[[6,[39,0],null,[[\"close\",\"headerTitle\"],[[28,[37,1],[[30,0],\"close\"],null],[28,[37,2],[\"user_visibility_rights_edit_label\"],null]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,3],null,[[\"icon\",\"label\",\"action\",\"isLoading\",\"isDisabled\"],[\"save\",[28,[37,2],[\"action_save\"],null],[28,[37,1],[[30,0],\"save\"],null],[33,4],[33,5]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"header/huve-detail-header\",\"action\",\"t\",\"header/huve-header-icon-button\",\"isLoading\",\"userNotEditable\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/users/edit-access-rights-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});