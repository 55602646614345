define("huvepharma-aviapp-admin/base/enum/countries/en-fr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AC",
    "country": "Île de l’Ascension"
  }, {
    "code": "AD",
    "country": "Andorre"
  }, {
    "code": "AE",
    "country": "Émirats arabes unis"
  }, {
    "code": "AF",
    "country": "Afghanistan"
  }, {
    "code": "AG",
    "country": "Antigua-et-Barbuda"
  }, {
    "code": "AI",
    "country": "Anguilla"
  }, {
    "code": "AL",
    "country": "Albanie"
  }, {
    "code": "AM",
    "country": "Arménie"
  }, {
    "code": "AO",
    "country": "Angola"
  }, {
    "code": "AQ",
    "country": "Antarctique"
  }, {
    "code": "AR",
    "country": "Argentine"
  }, {
    "code": "AS",
    "country": "Samoa américaines"
  }, {
    "code": "AT",
    "country": "Autriche"
  }, {
    "code": "AU",
    "country": "Australie"
  }, {
    "code": "AW",
    "country": "Aruba"
  }, {
    "code": "AX",
    "country": "Îles Åland"
  }, {
    "code": "AZ",
    "country": "Azerbaïdjan"
  }, {
    "code": "BA",
    "country": "Bosnie-Herzégovine"
  }, {
    "code": "BB",
    "country": "Barbade"
  }, {
    "code": "BD",
    "country": "Bangladesh"
  }, {
    "code": "BE",
    "country": "Belgique"
  }, {
    "code": "BF",
    "country": "Burkina Faso"
  }, {
    "code": "BG",
    "country": "Bulgarie"
  }, {
    "code": "BH",
    "country": "Bahreïn"
  }, {
    "code": "BI",
    "country": "Burundi"
  }, {
    "code": "BJ",
    "country": "Bénin"
  }, {
    "code": "BL",
    "country": "Saint-Barthélemy"
  }, {
    "code": "BM",
    "country": "Bermudes"
  }, {
    "code": "BN",
    "country": "Brunéi Darussalam"
  }, {
    "code": "BO",
    "country": "Bolivie"
  }, {
    "code": "BQ",
    "country": "Pays-Bas caribéens"
  }, {
    "code": "BR",
    "country": "Brésil"
  }, {
    "code": "BS",
    "country": "Bahamas"
  }, {
    "code": "BT",
    "country": "Bhoutan"
  }, {
    "code": "BW",
    "country": "Botswana"
  }, {
    "code": "BY",
    "country": "Biélorussie"
  }, {
    "code": "BZ",
    "country": "Belize"
  }, {
    "code": "CA",
    "country": "Canada"
  }, {
    "code": "CC",
    "country": "Îles Cocos"
  }, {
    "code": "CD",
    "country": "Congo-Kinshasa"
  }, {
    "code": "CF",
    "country": "République centrafricaine"
  }, {
    "code": "CG",
    "country": "Congo-Brazzaville"
  }, {
    "code": "CH",
    "country": "Suisse"
  }, {
    "code": "CI",
    "country": "Côte d’Ivoire"
  }, {
    "code": "CK",
    "country": "Îles Cook"
  }, {
    "code": "CL",
    "country": "Chili"
  }, {
    "code": "CM",
    "country": "Cameroun"
  }, {
    "code": "CN",
    "country": "Chine"
  }, {
    "code": "CO",
    "country": "Colombie"
  }, {
    "code": "CR",
    "country": "Costa Rica"
  }, {
    "code": "CU",
    "country": "Cuba"
  }, {
    "code": "CV",
    "country": "Cap-Vert"
  }, {
    "code": "CW",
    "country": "Curaçao"
  }, {
    "code": "CX",
    "country": "Île Christmas"
  }, {
    "code": "CY",
    "country": "Chypre"
  }, {
    "code": "CZ",
    "country": "Tchéquie"
  }, {
    "code": "DE",
    "country": "Allemagne"
  }, {
    "code": "DG",
    "country": "Diego Garcia"
  }, {
    "code": "DJ",
    "country": "Djibouti"
  }, {
    "code": "DK",
    "country": "Danemark"
  }, {
    "code": "DM",
    "country": "Dominique"
  }, {
    "code": "DO",
    "country": "République dominicaine"
  }, {
    "code": "DZ",
    "country": "Algérie"
  }, {
    "code": "EA",
    "country": "Ceuta et Melilla"
  }, {
    "code": "EC",
    "country": "Équateur"
  }, {
    "code": "EE",
    "country": "Estonie"
  }, {
    "code": "EG",
    "country": "Égypte"
  }, {
    "code": "EH",
    "country": "Sahara occidental"
  }, {
    "code": "ER",
    "country": "Érythrée"
  }, {
    "code": "ES",
    "country": "Espagne"
  }, {
    "code": "ET",
    "country": "Éthiopie"
  }, {
    "code": "EZ",
    "country": "Eurozone"
  }, {
    "code": "FI",
    "country": "Finlande"
  }, {
    "code": "FJ",
    "country": "Fidji"
  }, {
    "code": "FK",
    "country": "Îles Malouines"
  }, {
    "code": "FM",
    "country": "États fédérés de Micronésie"
  }, {
    "code": "FO",
    "country": "Îles Féroé"
  }, {
    "code": "FR",
    "country": "France"
  }, {
    "code": "GA",
    "country": "Gabon"
  }, {
    "code": "GB",
    "country": "Royaume-Uni"
  }, {
    "code": "GD",
    "country": "Grenade"
  }, {
    "code": "GE",
    "country": "Géorgie"
  }, {
    "code": "GF",
    "country": "Guyane française"
  }, {
    "code": "GG",
    "country": "Guernesey"
  }, {
    "code": "GH",
    "country": "Ghana"
  }, {
    "code": "GI",
    "country": "Gibraltar"
  }, {
    "code": "GL",
    "country": "Groenland"
  }, {
    "code": "GM",
    "country": "Gambie"
  }, {
    "code": "GN",
    "country": "Guinée"
  }, {
    "code": "GP",
    "country": "Guadeloupe"
  }, {
    "code": "GQ",
    "country": "Guinée équatoriale"
  }, {
    "code": "GR",
    "country": "Grèce"
  }, {
    "code": "GS",
    "country": "Géorgie du Sud et îles Sandwich du Sud"
  }, {
    "code": "GT",
    "country": "Guatemala"
  }, {
    "code": "GU",
    "country": "Guam"
  }, {
    "code": "GW",
    "country": "Guinée-Bissau"
  }, {
    "code": "GY",
    "country": "Guyana"
  }, {
    "code": "HK",
    "country": "R.A.S. chinoise de Hong Kong"
  }, {
    "code": "HN",
    "country": "Honduras"
  }, {
    "code": "HR",
    "country": "Croatie"
  }, {
    "code": "HT",
    "country": "Haïti"
  }, {
    "code": "HU",
    "country": "Hongrie"
  }, {
    "code": "IC",
    "country": "Îles Canaries"
  }, {
    "code": "ID",
    "country": "Indonésie"
  }, {
    "code": "IE",
    "country": "Irlande"
  }, {
    "code": "IL",
    "country": "Israël"
  }, {
    "code": "IM",
    "country": "Île de Man"
  }, {
    "code": "IN",
    "country": "Inde"
  }, {
    "code": "IO",
    "country": "Territoire britannique de l’océan Indien"
  }, {
    "code": "IQ",
    "country": "Irak"
  }, {
    "code": "IR",
    "country": "Iran"
  }, {
    "code": "IS",
    "country": "Islande"
  }, {
    "code": "IT",
    "country": "Italie"
  }, {
    "code": "JE",
    "country": "Jersey"
  }, {
    "code": "JM",
    "country": "Jamaïque"
  }, {
    "code": "JO",
    "country": "Jordanie"
  }, {
    "code": "JP",
    "country": "Japon"
  }, {
    "code": "KE",
    "country": "Kenya"
  }, {
    "code": "KG",
    "country": "Kirghizistan"
  }, {
    "code": "KH",
    "country": "Cambodge"
  }, {
    "code": "KI",
    "country": "Kiribati"
  }, {
    "code": "KM",
    "country": "Comores"
  }, {
    "code": "KN",
    "country": "Saint-Christophe-et-Niévès"
  }, {
    "code": "KP",
    "country": "Corée du Nord"
  }, {
    "code": "KR",
    "country": "Corée du Sud"
  }, {
    "code": "KW",
    "country": "Koweït"
  }, {
    "code": "KY",
    "country": "Îles Caïmans"
  }, {
    "code": "KZ",
    "country": "Kazakhstan"
  }, {
    "code": "LA",
    "country": "Laos"
  }, {
    "code": "LB",
    "country": "Liban"
  }, {
    "code": "LC",
    "country": "Sainte-Lucie"
  }, {
    "code": "LI",
    "country": "Liechtenstein"
  }, {
    "code": "LK",
    "country": "Sri Lanka"
  }, {
    "code": "LR",
    "country": "Libéria"
  }, {
    "code": "LS",
    "country": "Lesotho"
  }, {
    "code": "LT",
    "country": "Lituanie"
  }, {
    "code": "LU",
    "country": "Luxembourg"
  }, {
    "code": "LV",
    "country": "Lettonie"
  }, {
    "code": "LY",
    "country": "Libye"
  }, {
    "code": "MA",
    "country": "Maroc"
  }, {
    "code": "MC",
    "country": "Monaco"
  }, {
    "code": "MD",
    "country": "Moldavie"
  }, {
    "code": "ME",
    "country": "Monténégro"
  }, {
    "code": "MF",
    "country": "Saint-Martin"
  }, {
    "code": "MG",
    "country": "Madagascar"
  }, {
    "code": "MH",
    "country": "Îles Marshall"
  }, {
    "code": "MK",
    "country": "Macédoine"
  }, {
    "code": "ML",
    "country": "Mali"
  }, {
    "code": "MM",
    "country": "Myanmar (Birmanie)"
  }, {
    "code": "MN",
    "country": "Mongolie"
  }, {
    "code": "MO",
    "country": "R.A.S. chinoise de Macao"
  }, {
    "code": "MP",
    "country": "Îles Mariannes du Nord"
  }, {
    "code": "MQ",
    "country": "Martinique"
  }, {
    "code": "MR",
    "country": "Mauritanie"
  }, {
    "code": "MS",
    "country": "Montserrat"
  }, {
    "code": "MT",
    "country": "Malte"
  }, {
    "code": "MU",
    "country": "Maurice"
  }, {
    "code": "MV",
    "country": "Maldives"
  }, {
    "code": "MW",
    "country": "Malawi"
  }, {
    "code": "MX",
    "country": "Mexique"
  }, {
    "code": "MY",
    "country": "Malaisie"
  }, {
    "code": "MZ",
    "country": "Mozambique"
  }, {
    "code": "NA",
    "country": "Namibie"
  }, {
    "code": "NC",
    "country": "Nouvelle-Calédonie"
  }, {
    "code": "NE",
    "country": "Niger"
  }, {
    "code": "NF",
    "country": "Île Norfolk"
  }, {
    "code": "NG",
    "country": "Nigéria"
  }, {
    "code": "NI",
    "country": "Nicaragua"
  }, {
    "code": "NL",
    "country": "Pays-Bas"
  }, {
    "code": "NO",
    "country": "Norvège"
  }, {
    "code": "NP",
    "country": "Népal"
  }, {
    "code": "NR",
    "country": "Nauru"
  }, {
    "code": "NU",
    "country": "Niue"
  }, {
    "code": "NZ",
    "country": "Nouvelle-Zélande"
  }, {
    "code": "OM",
    "country": "Oman"
  }, {
    "code": "PA",
    "country": "Panama"
  }, {
    "code": "PE",
    "country": "Pérou"
  }, {
    "code": "PF",
    "country": "Polynésie française"
  }, {
    "code": "PG",
    "country": "Papouasie-Nouvelle-Guinée"
  }, {
    "code": "PH",
    "country": "Philippines"
  }, {
    "code": "PK",
    "country": "Pakistan"
  }, {
    "code": "PL",
    "country": "Pologne"
  }, {
    "code": "PM",
    "country": "Saint-Pierre-et-Miquelon"
  }, {
    "code": "PN",
    "country": "Îles Pitcairn"
  }, {
    "code": "PR",
    "country": "Porto Rico"
  }, {
    "code": "PS",
    "country": "Territoires palestiniens"
  }, {
    "code": "PT",
    "country": "Portugal"
  }, {
    "code": "PW",
    "country": "Palaos"
  }, {
    "code": "PY",
    "country": "Paraguay"
  }, {
    "code": "QA",
    "country": "Qatar"
  }, {
    "code": "RE",
    "country": "La Réunion"
  }, {
    "code": "RO",
    "country": "Roumanie"
  }, {
    "code": "RS",
    "country": "Serbie"
  }, {
    "code": "RU",
    "country": "Russie"
  }, {
    "code": "RW",
    "country": "Rwanda"
  }, {
    "code": "SA",
    "country": "Arabie saoudite"
  }, {
    "code": "SB",
    "country": "Îles Salomon"
  }, {
    "code": "SC",
    "country": "Seychelles"
  }, {
    "code": "SD",
    "country": "Soudan"
  }, {
    "code": "SE",
    "country": "Suède"
  }, {
    "code": "SG",
    "country": "Singapour"
  }, {
    "code": "SH",
    "country": "Sainte-Hélène"
  }, {
    "code": "SI",
    "country": "Slovénie"
  }, {
    "code": "SJ",
    "country": "Svalbard et Jan Mayen"
  }, {
    "code": "SK",
    "country": "Slovaquie"
  }, {
    "code": "SL",
    "country": "Sierra Leone"
  }, {
    "code": "SM",
    "country": "Saint-Marin"
  }, {
    "code": "SN",
    "country": "Sénégal"
  }, {
    "code": "SO",
    "country": "Somalie"
  }, {
    "code": "SR",
    "country": "Suriname"
  }, {
    "code": "SS",
    "country": "Soudan du Sud"
  }, {
    "code": "ST",
    "country": "Sao Tomé-et-Principe"
  }, {
    "code": "SV",
    "country": "El Salvador"
  }, {
    "code": "SX",
    "country": "Saint-Martin (partie néerlandaise)"
  }, {
    "code": "SY",
    "country": "Syrie"
  }, {
    "code": "SZ",
    "country": "Swaziland"
  }, {
    "code": "TA",
    "country": "Tristan da Cunha"
  }, {
    "code": "TC",
    "country": "Îles Turques-et-Caïques"
  }, {
    "code": "TD",
    "country": "Tchad"
  }, {
    "code": "TF",
    "country": "Terres australes françaises"
  }, {
    "code": "TG",
    "country": "Togo"
  }, {
    "code": "TH",
    "country": "Thaïlande"
  }, {
    "code": "TJ",
    "country": "Tadjikistan"
  }, {
    "code": "TK",
    "country": "Tokélaou"
  }, {
    "code": "TL",
    "country": "Timor oriental"
  }, {
    "code": "TM",
    "country": "Turkménistan"
  }, {
    "code": "TN",
    "country": "Tunisie"
  }, {
    "code": "TO",
    "country": "Tonga"
  }, {
    "code": "TR",
    "country": "Turquie"
  }, {
    "code": "TT",
    "country": "Trinité-et-Tobago"
  }, {
    "code": "TV",
    "country": "Tuvalu"
  }, {
    "code": "TW",
    "country": "Taïwan"
  }, {
    "code": "TZ",
    "country": "Tanzanie"
  }, {
    "code": "UA",
    "country": "Ukraine"
  }, {
    "code": "UG",
    "country": "Ouganda"
  }, {
    "code": "UM",
    "country": "Îles mineures éloignées des États-Unis"
  }, {
    "code": "UN",
    "country": "Nations Unies"
  }, {
    "code": "US",
    "country": "États-Unis"
  }, {
    "code": "UY",
    "country": "Uruguay"
  }, {
    "code": "UZ",
    "country": "Ouzbékistan"
  }, {
    "code": "VA",
    "country": "État de la Cité du Vatican"
  }, {
    "code": "VC",
    "country": "Saint-Vincent-et-les-Grenadines"
  }, {
    "code": "VE",
    "country": "Venezuela"
  }, {
    "code": "VG",
    "country": "Îles Vierges britanniques"
  }, {
    "code": "VI",
    "country": "Îles Vierges des États-Unis"
  }, {
    "code": "VN",
    "country": "Vietnam"
  }, {
    "code": "VU",
    "country": "Vanuatu"
  }, {
    "code": "WF",
    "country": "Wallis-et-Futuna"
  }, {
    "code": "WS",
    "country": "Samoa"
  }, {
    "code": "XK",
    "country": "Kosovo"
  }, {
    "code": "YE",
    "country": "Yémen"
  }, {
    "code": "YT",
    "country": "Mayotte"
  }, {
    "code": "ZA",
    "country": "Afrique du Sud"
  }, {
    "code": "ZM",
    "country": "Zambie"
  }, {
    "code": "ZW",
    "country": "Zimbabwe"
  }];
  _exports.default = _default;
});