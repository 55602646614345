define("huvepharma-aviapp-admin/templates/company/edit-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "dqElRWt6",
    "block": "[[[1,[28,[35,0],null,[[\"close\",\"headerTitle\",\"testId\"],[[28,[37,1],[[30,0],\"close\"],null],[28,[37,2],[\"company_edit_label_heading\"],null],\"company\"]]]],[1,\"\\n\"]],[],false,[\"header/huve-detail-header\",\"action\",\"t\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/company/edit-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});