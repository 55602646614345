define("huvepharma-aviapp-admin/validations/models/settings", ["exports", "ember-changeset-validations/validators", "huvepharma-aviapp-admin/validations/change-description"], function (_exports, _validators, _changeDescription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    firstName: (0, _changeDescription.default)([(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    })], 'label_firstname'),
    name: (0, _changeDescription.default)([(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    })], 'label_last_name'),
    username: (0, _changeDescription.default)([(0, _validators.validateFormat)({
      allowBlank: false,
      type: 'email'
    })], 'label_email')
  };
  _exports.default = _default;
});