define("huvepharma-aviapp-admin/templates/clients/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GyMje1Pz",
    "block": "[[[41,[30,0,[\"model\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@changeset\",\"@poultries\",\"@model\"],[[30,0,[\"changeset\"]],[30,0,[\"poultries\"]],[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"clients/add-client\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/clients/add.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});