define("huvepharma-aviapp-admin/components/header/huve-page-header", ["exports", "@huvepharma/huvepharma-aviapp-components/components/header/page-header"], function (_exports, _pageHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _pageHeader.default;
    }
  });
});