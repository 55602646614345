define("huvepharma-aviapp-admin/base/enum/country-continent-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "countryCode": "AO",
    "continent": "AF"
  }, {
    "countryCode": "BF",
    "continent": "AF"
  }, {
    "countryCode": "BI",
    "continent": "AF"
  }, {
    "countryCode": "BJ",
    "continent": "AF"
  }, {
    "countryCode": "BW",
    "continent": "AF"
  }, {
    "countryCode": "CD",
    "continent": "AF"
  }, {
    "countryCode": "CF",
    "continent": "AF"
  }, {
    "countryCode": "CG",
    "continent": "AF"
  }, {
    "countryCode": "CI",
    "continent": "AF"
  }, {
    "countryCode": "CM",
    "continent": "AF"
  }, {
    "countryCode": "CV",
    "continent": "AF"
  }, {
    "countryCode": "DJ",
    "continent": "AF"
  }, {
    "countryCode": "DZ",
    "continent": "AF"
  }, {
    "countryCode": "EG",
    "continent": "AF"
  }, {
    "countryCode": "ER",
    "continent": "AF"
  }, {
    "countryCode": "ET",
    "continent": "AF"
  }, {
    "countryCode": "GA",
    "continent": "AF"
  }, {
    "countryCode": "GH",
    "continent": "AF"
  }, {
    "countryCode": "GM",
    "continent": "AF"
  }, {
    "countryCode": "GN",
    "continent": "AF"
  }, {
    "countryCode": "GQ",
    "continent": "AF"
  }, {
    "countryCode": "GW",
    "continent": "AF"
  }, {
    "countryCode": "KE",
    "continent": "AF"
  }, {
    "countryCode": "KM",
    "continent": "AF"
  }, {
    "countryCode": "LR",
    "continent": "AF"
  }, {
    "countryCode": "LS",
    "continent": "AF"
  }, {
    "countryCode": "LY",
    "continent": "AF"
  }, {
    "countryCode": "MA",
    "continent": "AF"
  }, {
    "countryCode": "MG",
    "continent": "AF"
  }, {
    "countryCode": "ML",
    "continent": "AF"
  }, {
    "countryCode": "MR",
    "continent": "AF"
  }, {
    "countryCode": "MU",
    "continent": "AF"
  }, {
    "countryCode": "MW",
    "continent": "AF"
  }, {
    "countryCode": "MZ",
    "continent": "AF"
  }, {
    "countryCode": "NA",
    "continent": "AF"
  }, {
    "countryCode": "NE",
    "continent": "AF"
  }, {
    "countryCode": "NG",
    "continent": "AF"
  }, {
    "countryCode": "RE",
    "continent": "AF"
  }, {
    "countryCode": "RW",
    "continent": "AF"
  }, {
    "countryCode": "SC",
    "continent": "AF"
  }, {
    "countryCode": "SD",
    "continent": "AF"
  }, {
    "countryCode": "SH",
    "continent": "AF"
  }, {
    "countryCode": "SL",
    "continent": "AF"
  }, {
    "countryCode": "SN",
    "continent": "AF"
  }, {
    "countryCode": "SO",
    "continent": "AF"
  }, {
    "countryCode": "SS",
    "continent": "AF"
  }, {
    "countryCode": "ST",
    "continent": "AF"
  }, {
    "countryCode": "SZ",
    "continent": "AF"
  }, {
    "countryCode": "TD",
    "continent": "AF"
  }, {
    "countryCode": "TG",
    "continent": "AF"
  }, {
    "countryCode": "TN",
    "continent": "AF"
  }, {
    "countryCode": "TZ",
    "continent": "AF"
  }, {
    "countryCode": "UG",
    "continent": "AF"
  }, {
    "countryCode": "YT",
    "continent": "AF"
  }, {
    "countryCode": "ZA",
    "continent": "AF"
  }, {
    "countryCode": "ZM",
    "continent": "AF"
  }, {
    "countryCode": "ZW",
    "continent": "AF"
  }, {
    "countryCode": "AQ",
    "continent": "AN"
  }, {
    "countryCode": "AE",
    "continent": "AS"
  }, {
    "countryCode": "AF",
    "continent": "AS"
  }, {
    "countryCode": "AM",
    "continent": "AS"
  }, {
    "countryCode": "AZ",
    "continent": "AS"
  }, {
    "countryCode": "BD",
    "continent": "AS"
  }, {
    "countryCode": "BH",
    "continent": "AS"
  }, {
    "countryCode": "BN",
    "continent": "AS"
  }, {
    "countryCode": "BT",
    "continent": "AS"
  }, {
    "countryCode": "CC",
    "continent": "AS"
  }, {
    "countryCode": "CN",
    "continent": "AS"
  }, {
    "countryCode": "CX",
    "continent": "AS"
  }, {
    "countryCode": "GE",
    "continent": "AS"
  }, {
    "countryCode": "HK",
    "continent": "AS"
  }, {
    "countryCode": "ID",
    "continent": "AS"
  }, {
    "countryCode": "IL",
    "continent": "AS"
  }, {
    "countryCode": "IN",
    "continent": "AS"
  }, {
    "countryCode": "IO",
    "continent": "AS"
  }, {
    "countryCode": "IQ",
    "continent": "AS"
  }, {
    "countryCode": "IR",
    "continent": "AS"
  }, {
    "countryCode": "JO",
    "continent": "AS"
  }, {
    "countryCode": "JP",
    "continent": "AS"
  }, {
    "countryCode": "KG",
    "continent": "AS"
  }, {
    "countryCode": "KH",
    "continent": "AS"
  }, {
    "countryCode": "KP",
    "continent": "AS"
  }, {
    "countryCode": "KR",
    "continent": "AS"
  }, {
    "countryCode": "KW",
    "continent": "AS"
  }, {
    "countryCode": "KZ",
    "continent": "AS"
  }, {
    "countryCode": "LA",
    "continent": "AS"
  }, {
    "countryCode": "LB",
    "continent": "AS"
  }, {
    "countryCode": "LK",
    "continent": "AS"
  }, {
    "countryCode": "MM",
    "continent": "AS"
  }, {
    "countryCode": "MN",
    "continent": "AS"
  }, {
    "countryCode": "MO",
    "continent": "AS"
  }, {
    "countryCode": "MV",
    "continent": "AS"
  }, {
    "countryCode": "MY",
    "continent": "AS"
  }, {
    "countryCode": "NP",
    "continent": "AS"
  }, {
    "countryCode": "OM",
    "continent": "AS"
  }, {
    "countryCode": "PH",
    "continent": "AS"
  }, {
    "countryCode": "PK",
    "continent": "AS"
  }, {
    "countryCode": "PS",
    "continent": "AS"
  }, {
    "countryCode": "QA",
    "continent": "AS"
  }, {
    "countryCode": "SA",
    "continent": "AS"
  }, {
    "countryCode": "SG",
    "continent": "AS"
  }, {
    "countryCode": "SY",
    "continent": "AS"
  }, {
    "countryCode": "TH",
    "continent": "AS"
  }, {
    "countryCode": "TJ",
    "continent": "AS"
  }, {
    "countryCode": "TM",
    "continent": "AS"
  }, {
    "countryCode": "TR",
    "continent": "AS"
  }, {
    "countryCode": "TW",
    "continent": "AS"
  }, {
    "countryCode": "UZ",
    "continent": "AS"
  }, {
    "countryCode": "VN",
    "continent": "AS"
  }, {
    "countryCode": "YE",
    "continent": "AS"
  }, {
    "countryCode": "AD",
    "continent": "EU"
  }, {
    "countryCode": "AL",
    "continent": "EU"
  }, {
    "countryCode": "AT",
    "continent": "EU"
  }, {
    "countryCode": "BA",
    "continent": "EU"
  }, {
    "countryCode": "BE",
    "continent": "EU"
  }, {
    "countryCode": "BG",
    "continent": "EU"
  }, {
    "countryCode": "BY",
    "continent": "EU"
  }, {
    "countryCode": "CH",
    "continent": "EU"
  }, {
    "countryCode": "CY",
    "continent": "EU"
  }, {
    "countryCode": "CZ",
    "continent": "EU"
  }, {
    "countryCode": "DE",
    "continent": "EU"
  }, {
    "countryCode": "DK",
    "continent": "EU"
  }, {
    "countryCode": "EE",
    "continent": "EU"
  }, {
    "countryCode": "ES",
    "continent": "EU"
  }, {
    "countryCode": "FI",
    "continent": "EU"
  }, {
    "countryCode": "FO",
    "continent": "EU"
  }, {
    "countryCode": "FR",
    "continent": "EU"
  }, {
    "countryCode": "GB",
    "continent": "EU"
  }, {
    "countryCode": "GI",
    "continent": "EU"
  }, {
    "countryCode": "GR",
    "continent": "EU"
  }, {
    "countryCode": "HR",
    "continent": "EU"
  }, {
    "countryCode": "HU",
    "continent": "EU"
  }, {
    "countryCode": "IE",
    "continent": "EU"
  }, {
    "countryCode": "IM",
    "continent": "EU"
  }, {
    "countryCode": "IS",
    "continent": "EU"
  }, {
    "countryCode": "IT",
    "continent": "EU"
  }, {
    "countryCode": "LI",
    "continent": "EU"
  }, {
    "countryCode": "LT",
    "continent": "EU"
  }, {
    "countryCode": "LU",
    "continent": "EU"
  }, {
    "countryCode": "LV",
    "continent": "EU"
  }, {
    "countryCode": "MC",
    "continent": "EU"
  }, {
    "countryCode": "MD",
    "continent": "EU"
  }, {
    "countryCode": "ME",
    "continent": "EU"
  }, {
    "countryCode": "MK",
    "continent": "EU"
  }, {
    "countryCode": "MT",
    "continent": "EU"
  }, {
    "countryCode": "NL",
    "continent": "EU"
  }, {
    "countryCode": "NO",
    "continent": "EU"
  }, {
    "countryCode": "PL",
    "continent": "EU"
  }, {
    "countryCode": "PT",
    "continent": "EU"
  }, {
    "countryCode": "RO",
    "continent": "EU"
  }, {
    "countryCode": "RU",
    "continent": "EU"
  }, {
    "countryCode": "SE",
    "continent": "EU"
  }, {
    "countryCode": "SI",
    "continent": "EU"
  }, {
    "countryCode": "SK",
    "continent": "EU"
  }, {
    "countryCode": "SM",
    "continent": "EU"
  }, {
    "countryCode": "UA",
    "continent": "EU"
  }, {
    "countryCode": "UK",
    "continent": "EU"
  }, {
    "countryCode": "VA",
    "continent": "EU"
  }, {
    "countryCode": "AG",
    "continent": "NA"
  }, {
    "countryCode": "AI",
    "continent": "NA"
  }, {
    "countryCode": "AW",
    "continent": "NA"
  }, {
    "countryCode": "BB",
    "continent": "NA"
  }, {
    "countryCode": "BM",
    "continent": "NA"
  }, {
    "countryCode": "BQ",
    "continent": "NA"
  }, {
    "countryCode": "BS",
    "continent": "NA"
  }, {
    "countryCode": "BZ",
    "continent": "NA"
  }, {
    "countryCode": "CA",
    "continent": "NA"
  }, {
    "countryCode": "CR",
    "continent": "NA"
  }, {
    "countryCode": "CU",
    "continent": "NA"
  }, {
    "countryCode": "CW",
    "continent": "NA"
  }, {
    "countryCode": "DM",
    "continent": "NA"
  }, {
    "countryCode": "DO",
    "continent": "NA"
  }, {
    "countryCode": "GD",
    "continent": "NA"
  }, {
    "countryCode": "GL",
    "continent": "NA"
  }, {
    "countryCode": "GP",
    "continent": "NA"
  }, {
    "countryCode": "GT",
    "continent": "NA"
  }, {
    "countryCode": "HN",
    "continent": "NA"
  }, {
    "countryCode": "HT",
    "continent": "NA"
  }, {
    "countryCode": "JM",
    "continent": "NA"
  }, {
    "countryCode": "KN",
    "continent": "NA"
  }, {
    "countryCode": "KY",
    "continent": "NA"
  }, {
    "countryCode": "LC",
    "continent": "NA"
  }, {
    "countryCode": "MQ",
    "continent": "NA"
  }, {
    "countryCode": "MS",
    "continent": "NA"
  }, {
    "countryCode": "MX",
    "continent": "NA"
  }, {
    "countryCode": "NI",
    "continent": "NA"
  }, {
    "countryCode": "PA",
    "continent": "NA"
  }, {
    "countryCode": "PM",
    "continent": "NA"
  }, {
    "countryCode": "PR",
    "continent": "NA"
  }, {
    "countryCode": "SV",
    "continent": "NA"
  }, {
    "countryCode": "SX",
    "continent": "NA"
  }, {
    "countryCode": "TC",
    "continent": "NA"
  }, {
    "countryCode": "TT",
    "continent": "NA"
  }, {
    "countryCode": "US",
    "continent": "NA"
  }, {
    "countryCode": "VC",
    "continent": "NA"
  }, {
    "countryCode": "VG",
    "continent": "NA"
  }, {
    "countryCode": "VI",
    "continent": "NA"
  }, {
    "countryCode": "AS",
    "continent": "OC"
  }, {
    "countryCode": "AU",
    "continent": "OC"
  }, {
    "countryCode": "CK",
    "continent": "OC"
  }, {
    "countryCode": "FJ",
    "continent": "OC"
  }, {
    "countryCode": "FM",
    "continent": "OC"
  }, {
    "countryCode": "GU",
    "continent": "OC"
  }, {
    "countryCode": "KI",
    "continent": "OC"
  }, {
    "countryCode": "MH",
    "continent": "OC"
  }, {
    "countryCode": "MP",
    "continent": "OC"
  }, {
    "countryCode": "NC",
    "continent": "OC"
  }, {
    "countryCode": "NF",
    "continent": "OC"
  }, {
    "countryCode": "NR",
    "continent": "OC"
  }, {
    "countryCode": "NU",
    "continent": "OC"
  }, {
    "countryCode": "NZ",
    "continent": "OC"
  }, {
    "countryCode": "PF",
    "continent": "OC"
  }, {
    "countryCode": "PG",
    "continent": "OC"
  }, {
    "countryCode": "PW",
    "continent": "OC"
  }, {
    "countryCode": "SB",
    "continent": "OC"
  }, {
    "countryCode": "TK",
    "continent": "OC"
  }, {
    "countryCode": "TL",
    "continent": "OC"
  }, {
    "countryCode": "TO",
    "continent": "OC"
  }, {
    "countryCode": "TV",
    "continent": "OC"
  }, {
    "countryCode": "UM",
    "continent": "OC"
  }, {
    "countryCode": "VU",
    "continent": "OC"
  }, {
    "countryCode": "WF",
    "continent": "OC"
  }, {
    "countryCode": "WS",
    "continent": "OC"
  }, {
    "countryCode": "AR",
    "continent": "SA"
  }, {
    "countryCode": "BO",
    "continent": "SA"
  }, {
    "countryCode": "BR",
    "continent": "SA"
  }, {
    "countryCode": "CL",
    "continent": "SA"
  }, {
    "countryCode": "CO",
    "continent": "SA"
  }, {
    "countryCode": "EC",
    "continent": "SA"
  }, {
    "countryCode": "FK",
    "continent": "SA"
  }, {
    "countryCode": "GF",
    "continent": "SA"
  }, {
    "countryCode": "GY",
    "continent": "SA"
  }, {
    "countryCode": "PE",
    "continent": "SA"
  }, {
    "countryCode": "PY",
    "continent": "SA"
  }, {
    "countryCode": "SR",
    "continent": "SA"
  }, {
    "countryCode": "UY",
    "continent": "SA"
  }, {
    "countryCode": "VE",
    "continent": "SA"
  }];
  _exports.default = _default;
});