define("huvepharma-aviapp-admin/components/charts/huve-summary-chart", ["exports", "@huvepharma/huvepharma-aviapp-components/components/charts/summary-chart"], function (_exports, _summaryChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _summaryChart.default;
    }
  });
});