define("huvepharma-aviapp-admin/routes/invitation/accept", ["exports", "@ember/routing/route", "huvepharma-aviapp-admin/mixins/header-hidden-route"], function (_exports, _route, _headerHiddenRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_headerHiddenRoute.default, {
    skipSessionExecution: true,

    /**
     * Retrieves the user whom initiated the invitation
     * @param Url parameter
     */
    model: function model(param) {
      // Find the invitation
      return this.store.findRecord('invitation', param.token).catch(function () {
        return null;
      });
    },

    /**
     * After the model has been loaded
     * @param model The model
     */
    afterModel: function afterModel(model) {
      if (!model) {
        this.transitionTo('invitation.notfound');
      }
    },

    /**
     * Render the template
     */
    renderTemplate: function renderTemplate() {
      this.render(); // Remove the navigation bar

      this.disconnectOutlet({
        outlet: 'header',
        parentView: 'application'
      }); // Remove the company switch

      this.disconnectOutlet({
        outlet: 'company-switch',
        parentView: 'application'
      });
    },

    /**
     * Setup the controller
     * @param controller The controller
     * @param model The model
     */
    setupController: function setupController(controller, model) {
      controller.set('model', model);
    }
  });

  _exports.default = _default;
});