define("huvepharma-aviapp-admin/models/scoring-report", ["exports", "huvepharma-aviapp-admin/base/models/scoring-report"], function (_exports, _scoringReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _scoringReport.default.extend({});

  _exports.default = _default;
});