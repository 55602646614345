define("huvepharma-aviapp-admin/base/models/flock-report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var FlockReport = _emberData.default.Model.extend({
    flock: _emberData.default.belongsTo('flock', {
      async: true
    }),
    modules: _emberData.default.attr(),
    notes: _emberData.default.attr('string'),
    startedAt: _emberData.default.attr('date'),
    finishedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    farm: _emberData.default.belongsTo('farm', {
      async: true
    }),
    owner: _emberData.default.belongsTo('user', {
      async: true
    }),
    isSoftDeleted: _emberData.default.attr('boolean', {
      defaultValue: false
    })
  });

  var _default = FlockReport;
  _exports.default = _default;
});