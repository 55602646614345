define("huvepharma-aviapp-admin/authenticators/api", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "huvepharma-aviapp-admin/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: "".concat(_environment.default.APP.api.url, "/oauth/token"),
    serverTokenRevocationEndpoint: "".concat(_environment.default.APP.api.url, "/oauth/revoke"),
    makeRequest: function makeRequest(url, data) {
      data.client_id = data.client_id || '8a4e4e74d9ef04577dd535b0c05bd6f7cac43698';
      data.client_secret = '983290aa083b0e9749e2d8b48f57fb2f256819de';
      data.password = data.username ? data.username.password : '';
      data.username = data.username ? data.username.identification : '';
      return this._super(url, data);
    }
  });

  _exports.default = _default;
});