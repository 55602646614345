define("huvepharma-aviapp-admin/base/enum/continents/en-tr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AF",
    "continent": "Afrika"
  }, {
    "code": "NA",
    "continent": "Kuzey Amerika"
  }, {
    "code": "OC",
    "continent": "Okyanusya"
  }, {
    "code": "AN",
    "continent": "Antarktika"
  }, {
    "code": "AS",
    "continent": "Asya"
  }, {
    "code": "EU",
    "continent": "Avrupa"
  }, {
    "code": "SA",
    "continent": "Güney Amerika"
  }];
  _exports.default = _default;
});