define("huvepharma-aviapp-admin/base/enum/poultry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "BROILERS": "Broilers",
    "LAYERS": "Layers",
    "TURKEYS": "Turkeys"
  };
  _exports.default = _default;
});