define("huvepharma-aviapp-admin/controllers/company-switch/company-switch", ["exports", "@sentry/browser", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, Sentry, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    session: (0, _service.inject)(),
    showCompanySwitch: false,
    loginCheck: false,
    justLoggedIn: false,
    companies: (0, _object.computed)('session.me', function () {
      var filteredCompanies = (this.get('session.me.companies') || []).filterBy('role', 'admin');
      return filteredCompanies.sortBy('name');
    }),
    showCompanySwitchButton: (0, _object.computed)('session.me', function () {
      var adminCompanies = (this.get('session.me.companies') || []).filterBy('role', 'admin');
      return adminCompanies.get('length') > 1;
    }),
    actions: {
      /**
       * Hide the company switch modal
       */
      hideCompanySwitch: function hideCompanySwitch() {
        this.set('showCompanySwitch', false);
      },

      /**
       * Show the company switch modal
       */
      showCompanySwitchModal: function showCompanySwitchModal() {
        this.set('showCompanySwitch', true);
      },

      /**
       * switch to another companyId
       * @param company the company to switch to
       */
      switchToCompany: function switchToCompany(company) {
        var _this = this;

        // only make a switch whe the company actually changed
        if (this.get('session.data.loginCompany._id') !== company._id || this.justLoggedIn) {
          this.session.set('data.loginCompany', company); // Set the UserContext for Sentry

          var context = this.get('session.isSuperUser') ? {
            user: this.get('session.me.id')
          } : {
            user: this.get('session.me.id'),
            company: this.get('session.data.loginCompany._id')
          };
          Sentry.setUser(context);
          this.store.findRecord('company', company._id).then(function (companyParam) {
            var me = _this.get('session.me');

            me.set('company', companyParam);
            me.save().then(function () {
              if (_this.justLoggedIn) {
                if (_this.get('session.isSuperUser')) {
                  _this.transitionToRoute('dashboard');
                } else {
                  _this.transitionToRoute('users');
                }

                _this.set('justLoggedIn', false);
              } else {
                _this.send('transitionToRoot');
              }
            });
          });
        }
      },

      /**
       * Logout
       */
      logout: function logout() {
        this.transitionToRoute('session.logout');
      }
    }
  });

  _exports.default = _default;
});