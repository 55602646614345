define("huvepharma-aviapp-admin/routes/company/company", ["exports", "@ember/service", "huvepharma-aviapp-admin/routes/protected"], function (_exports, _service, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _protected.default.extend({
    session: (0, _service.inject)(),
    metrics: (0, _service.inject)(),

    /**
     * Build the model
     */
    model: function model(params, transition) {
      var companyId;

      if (params && params.id && this.get('session.isSuperUser')) {
        // An ID was passed into the route, lets find the company by the route id
        this.set('routeId', params.id);
        companyId = params.id;
      } else if (!this.get('session.isSuperUser')) {
        // No ID was specified, find the users default company
        companyId = this.get('session.data.loginCompany._id');
      } else {
        transition.abort();
      }

      return this.store.findRecord('company', companyId);
    },
    afterModel: function afterModel() {
      this.metrics.trackPage({
        page: '/Admin/Companies/Detail',
        title: 'Company Detail Screen'
      });
    },

    /**
     * Setup the controller
     */
    setupController: function setupController(controller, model) {
      if (model.get('users')) {
        model.get('users').forEach(function (user) {
          return user.set('companyRole', 'user');
        });
      }

      if (model.get('admins')) {
        model.get('admins').forEach(function (admin) {
          return admin.set('companyRole', 'admin');
        });
      }

      if (model.get('reporters')) {
        model.get('reporters').forEach(function (reporter) {
          return reporter.set('companyRole', 'reporter');
        });
      }

      if (model.get('caretakers')) {
        model.get('caretakers').forEach(function (reporter) {
          return reporter.set('companyRole', 'caretaker');
        });
      }

      if (model.get('supervisors')) {
        model.get('supervisors').forEach(function (reporter) {
          return reporter.set('companyRole', 'supervisor');
        });
      }

      if (model.get('subCompanies')) {
        var openSubCompanies = model.subCompanies.filter(function (subcompany) {
          return !subcompany.isSoftDeleted;
        }).map(function (subcompany) {
          return {
            id: subcompany._id,
            open: false
          };
        });
        controller.set('openSubCompanies', openSubCompanies);
      }

      model.subCompanies.forEach(function (subCompany) {
        return subCompany.complexes.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      });
      model.subCompanies.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      controller.set('model', model);
      controller.set('routeId', this.routeId);
    }
  });

  _exports.default = _default;
});