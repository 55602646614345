define("huvepharma-aviapp-admin/routes/dashboard/index", ["exports", "rsvp", "@ember/service", "huvepharma-aviapp-admin/routes/auth/authenticated-superuser"], function (_exports, _rsvp, _service, _authenticatedSuperuser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticatedSuperuser.default.extend({
    metrics: (0, _service.inject)(),

    /**
     * Retrieve all companies and add the owner to each
     * @returns {Test.Promise|*|RSVP.Promise} Resolves to companies
     */
    model: function model() {
      return this.store.query('company', {
        isApproved: false,
        isRejected: false
      });
    },

    /**
     * Fetches owner for all companies
     * @param companies
     * @return {Array} companies
     */
    afterModel: function afterModel(companies) {
      this.metrics.trackPage({
        page: '/Admin/Dashboard',
        title: 'Dashboard Screen'
      });
      return (0, _rsvp.allSettled)(companies.map(function (company) {
        return company.get('owner');
      })).then(function () {
        return companies;
      });
    },

    /**
     * Set model and filtered model
     * @param controller Companies controller
     * @param model Companies
     */
    setupController: function setupController(controller, model) {
      var companies = model.filter(function (company) {
        return company.get('id') != null;
      });
      controller.set('model', companies);
      controller.set('displayedRequests', controller.model);
    }
  });

  _exports.default = _default;
});