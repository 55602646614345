define("huvepharma-aviapp-admin/templates/about/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ho0XvkCj",
    "block": "[[[10,\"main\"],[14,0,\"container mod-content\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@version\",\"@preventDefault\",\"@me\",\"@disableDeletion\",\"@openLink\"],[[30,0,[\"version\"]],false,[30,0,[\"session\",\"me\"]],[30,0,[\"session\",\"isSuperUser\"]],[28,[37,1],[[30,0],\"openLink\"],null]]],null],[1,\"\\n\"],[13]],[],false,[\"about-content\",\"action\"]]",
    "moduleName": "huvepharma-aviapp-admin/templates/about/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});